import React, {useEffect, useState, useSyncExternalStore} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getItem} from "../../services/LocaleStorage";
import {PulseLoader} from "react-spinners";
import http from '../../services/Http'
import {useLocation} from "react-router-dom";
import {toast} from "react-toastify";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SyncIcon from '@material-ui/icons/Sync';
import {getRealUser, getUser, isVirtualConnexion, setUser, setVirtualUser} from "../../app/user";
import {addBankInList, getBankAccount, setBankAccounts} from "../../app/filters";
import PopupSyncBO from "../dashboard/PopupSyncBO";
import PopupAddBankAccount from "../dashboard/PopupAddBankAccount";

const MesInfos = () => {
    const [editableUser, setEditableUser] = useState({})

    //tableau champs mes infos
    const [monTab, setMonTab] = useState([]);
    const [monTabValidationErrors, setMonTabValidationErrors] = useState({});
    //tableau champs profile
    const [dataUserProfile, setDataUserProfile] = useState([])

    const [userProfileAddress, setUserProfileAddress] = useState({});
    //Tableau champs bank account
    const [dataBankAccount, setDataBankAccount] = useState([])
    const [toggleBankAccount, setToggleBankAccount] = useState(false);
    //Tableau champ Manager/person
    const [dataPerson, setDataPerson] = useState([])
    const [togglePerson, setTogglePerson] = useState(false);
    //Tableau champ employés
    const [dataEmploye, setDataEmploye] = useState([])
    const [toggleEmploye, setToggleEmploye] = useState(false);

    const [userProfileId, setUserProfileId] = useState(0)
    //Message de succcès : Le nom des props correspondent aux différentes valeurs que peut prendre le param type de la fonction handleSubmit
    const [sucessMessage, setSucessMessage] = useState({user: '', profil: '', 'bank-accounts': ''});
    const [submittingUserData, setSubmittingUserData] = useState(false);
    const [afficheInfos, setAfficheInfos] = useState(false);
    const [afficheBanks, setAfficheBanks] = useState(false);

    const [syncPopupIsOpened, setSyncPopupIsOpened] = useState(null|false);
    const [addBankAccountPopupIsOpened, setAddBankAccountPopupIsOpened] = useState(null|false);
    const [isLoading, setIsLoading] = useState(null|false);
    const [isPopupConfirmBADeletionOpened, setPopupConfirmBADeletionOpened] = useState(false);
    const [bankAccIdToDelete, setBankAccIdToDelete] = useState(null);

    const dispatch = useDispatch()
    const isVirtualConnect = useSelector(isVirtualConnexion)
    const realUser = useSelector(getRealUser)
    const frontUser = useSelector(getUser)
    const bankAccount = useSelector(getBankAccount)
    useEffect(() => {

        setUserProfileAddress({})
        if (!http.getClientIdFromUrl()) {
            setProfile(frontUser.profile)
            const cloneStoreUser = Object.assign({}, frontUser)
            delete cloneStoreUser.profile
            setEditableUser(cloneStoreUser)
            setMonTab([
                ["Num client", frontUser.reference, "reference"],
                ["créé le", frontUser.created_at, "created_at"],
                ["Date_crea_client", frontUser.creation_date, "creation_date"],
                ["Login Client", frontUser.email, "email"],
                ["Display Name", frontUser.display_name, "display_name"],
                ["Phone", frontUser.phone, "phone"],
            ])
        } else {
            fetchUser(http.getClientIdFromUrl())
        }

        if (editableUser.reference && dataUserProfile.length) return

        //Récupère le bon id en fonction de l'utilisateur connecté et de s'il y a un param dans l'url
        const clientID = http.getClientIdFromUrl()
        if (clientID) {
            fetchBankAccount(clientID)
            fetchPerson(clientID)
            fetchEmploye(clientID)
        } else {
            fetchBankAccount(frontUser.id)
            fetchPerson(frontUser.id)
            fetchEmploye(frontUser.id)
            setEditableUser(frontUser)
        }
    }, [frontUser]);

    const fetchUser = (userID) => {

        const url = http.getClientRoleFromUrl() === "client" ? `/clients?client_id=${userID}&include[]=profile` : `/admins_n?admin_n_id=${userID}&include[]=profile`
        const token = getItem('bbbToken')
        http.get(url, {'Authorization': 'Bearer ' + token})
            .then(data => {
                setProfile(data.profile)
                console.log(data)
                delete (data.profile)
                setEditableUser(data)
                setMonTab([
                    ["Num client", data.reference, "reference"],
                    ["créé le", data.created_at, "created_at"],
                    ["Date_crea_client", data.creation_date, "creation_date"],
                    ["Login Client", data.email, "email"],
                    ["Display Name", data.display_name, "display_name"],
                    ["Phone", data.phone, "phone"],
                ])
            })
            .catch(err => {
                setEditableUser({})
            })
    }

    //Récupère les comptes bancaire utilisateur
    const fetchBankAccount = (id) => {
        const url = `/bank_accounts?client_id=${id}`
        const token = getItem('bbbToken')
        fetch(process.env.REACT_APP_API_URL + url, {
            method: 'GET',
            requirements: token,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            if (response.ok) {
                response.json().then(json => {
                    if(!("No Content" in json)){
                        setDataBankAccount(json)
                    }
                })
            } else {
                console.log(response)
            }
        })
    }

    //Récupère les managers d'un utilisateur
    const fetchPerson = (id) => {
        const url = `/manager?client_id=${id}`
        const token = getItem('bbbToken')
        fetch(process.env.REACT_APP_API_URL + url, {
            method: 'GET',
            requirements: token,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            credentials: 'same-origin',
        }).then(response => {
            if (response.ok) {
                response.json().then(json => {
                    setDataPerson(json)
                    console.log(json)
                })
            } else {
                console.log(response)
            }
        })
    }

    // Récupère les employés utilisateur
    const fetchEmploye = (id) => {
        const url = `/employee?client_id=${id}`
        const token = getItem('bbbToken')
        fetch(process.env.REACT_APP_API_URL + url, {
            method: 'GET',
            requirements: token,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            credentials: 'same-origin',
        }).then(response => {
            if (response.ok) {
                response.json().then(json => {
                    setDataEmploye(json)
                })
            }
        })
    }

    // Champs profil utilisateur
    const setProfile = (profile) => {
        setDataUserProfile([
            ['Date entree', profile.entry_date, 'entry_date'],
            ['Date_exercise_entree', profile.exercise_entry_date, 'exercise_entry_date'],
            ['exercise_date', profile.exercise_date, 'exercise_date'],
            ['Nom client', profile.name, 'name'],
            ['Client_status', profile.client_status, 'client_status'],
            ['Siret client', profile.siret, 'siret'],
            ['Tel client', profile.phone, 'phone'],
            ['Mail client', profile.mail_address, 'mail_address'],
            ['Site_web_client', profile.website, 'website'],
            ['Nom commercial client', profile.business_name, 'business_name'],
            ['Commentaire_client', profile.comment, 'comment'],
            ['Capital client', profile.capital, 'capital'],
            ['Regime tva client', profile.tax_regime, 'tax_regime'],
            ['Periode tva client', profile.tax_periode, 'tax_periode'],
            ['Regime fiscal client', profile.tax_system, 'tax_system'],
            ['Regime impôt client', profile.income_tax, 'income_tax'],
            ['Bilan entree', profile.last_balance_sheet_entry?.initial_treasury, 'last_balance_sheet_entry']
        ])
        if (profile.address)
            setUserProfileAddress(profile.address)
        setUserProfileId(profile.id)
        console.log(profile.id)
    }

    //Champs compte bancaire utilisateur
    const setBankAccount = (account) => {
        setDataBankAccount([
            ['Nom', account.name, 'name'],
            ['statut', account.status, 'status'],
            ['date creation', account.created_at, 'created_at'],
            ['code', account.code, 'code'],
            ['address', account.address, 'address'],
            ['iban', account.iban, 'iban'],
            ['BIC', account.bic, 'bic'],
        ])

    }

    const createNewBankAcc = ()=> {
        const token = getItem('bbbToken')

        const form = new FormData(document.getElementById('new-bank-account-form'))
        form.append('client_id', frontUser.id)
        const urlBank = process.env.REACT_APP_API_URL + '/bank_account'
        fetch(urlBank, {
            method: 'POST',
            'Access-Control-Allow-Credentials': true,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: form,
        }).then(response => {
            if (response.ok) {
                toast.success('Compte bancaire créé.')
                response.json().then(json=>{
                    console.log(json)
                    dispatch(addBankInList(json))
                })

            } else {
                console.log('error')
            }
        })
    }

    const handleSubmit = (e, type) => {
        e.preventDefault()
        //Je vérifie quelle requête envoyer pour quel formulaire envoyé. Chaque formulaire son bouton vu que les req diffèrent
        let form = new FormData(document.getElementById(type === 'user' ? 'mes-infos-form' : type === 'profil' ? 'mon-profil-form' : ''))
        if (type === "user") {
            form.append('client_id', editableUser.id)
            // Remove unchanged fields (email, phone) from the form that we'll be submitted!
            Array('phone', 'email').forEach(key => {
                if (type === 'user' && form.get(key) === editableUser[key]) {
                    form.delete(key)
                }
            })
        }
        if (type === 'profil') {
            const profile = {}
            form.forEach((val, key) => profile[key] = val)
            profile.address = {}
            document.querySelectorAll("input.profileAddress").forEach(input => profile.address = {
                ...profile.address,
                [input.name]: input.value
            })
            Array('phone', 'mail_address').forEach(key => {
                // find permet de verifier une condition pour chaque element d'un tableau et de retourner le premier element qui la vérifie
                const dataUserProfileProp = dataUserProfile.find((prop) => prop[2] === key)
                if (profile[key] === dataUserProfileProp[1]) {
                    delete profile[key]
                }
            })
            form = JSON.stringify(profile)
        }

        const token = getItem('bbbToken')
        const url = type === 'user' ? '/user/update' : '/user/profile/update/' + userProfileId
        setSubmittingUserData(true)
        const headers = type === 'profil' ? {
            'Authorization': 'Bearer ' + token,
            'Content-Type': "application/json"
        } : {
            'Authorization': 'Bearer ' + token,
        }

        fetch(process.env.REACT_APP_API_URL + url, {
            method: 'POST',
            headers: headers,
            body: form
        }).then(response => {
            // Rehydrate le store user afin que l'application affiche les bonnes valeurs de l'utilisateur.
            setSubmittingUserData(false)
            if (response.ok) {
                if (type === "user") {
                    response.json().then(newUser => {
                        isVirtualConnect ? dispatch(setVirtualUser(newUser)) : dispatch(setUser(newUser))
                        setMonTabValidationErrors({})
                    }).catch(err => {
                        toast.info("Erreur lors de la modification")
                    })
                }
                toast.success('Modification mise à jour avec succès')

                //Inscrire le message dans la bonne prop de successMessage
                // let sm = {user: '',profil: '','bank-accounts': ''}
                // sm[type] = 'Votre opération a été traitée avec succès'
                // setSucessMessage(sm)
                // toast.success('Modification mise à jour avec succès')
            } else {
                response.json().then(errors => {
                    setMonTabValidationErrors(errors)
                    setSucessMessage({user: '', profil: '', 'bank-accounts': ''})
                }).catch(err => {
                    toast.info("Erreur lors de la modification")
                })
            }
        }).catch(err => {
            // toast.info("Erreur lors de la modification")
            setSubmittingUserData(false)
        })
    }

    const getSubmitButton = () => {
        if (!submittingUserData) {
            return <button type="submit" className=" my-8 bg-grisbleu text-violetFonce p-2 rounded-lg">Enregistrer les informations</button>
        }
        return <button className="w-36 my-8 bg-grisbleu text-violetFonce p-2 rounded-lg">
            <PulseLoader
                color={'purple'} size={10}
            />
        </button>
    }


    const createTdInput = (payload) => {

        if (payload.name === "id" || payload.name === "user_id" || payload.name === "relationship" || payload.name === "created_at") {
            return
        }

        if (realUser.role !== 'admin_p') {
            return <td key={payload.id} className="text-black">{payload.content}</td>
        } else {
            if (payload.name === 'status') {
                return <td key={payload.id}>
                    <select name={payload.name} defaultValue={payload.content} className="bg-transparent h-14 uppercase text-black">
                        <option value="TNS">tns</option>
                        <option value="SALARIE">salarié</option>
                    </select></td>
            }
            if (payload.name === 'birthdate') {
                return <td key={payload.id}>
                    <input type="date" name={payload.name} defaultValue={payload.content} className="bg-transparent h-14 text-black"/>
                </td>
            }
            return <td key={payload.id} className="flex">
                <input size={payload.name === "mails" ? "12" : "8"} defaultValue={payload.content} name={payload.name} placeholder={payload.name}
                       className="bg-transparent text-black px-1"/>
            </td>

        }
    }

    const handleSubmitBankEdit = (e) => {
        e.preventDefault()
        if (realUser.role !== 'client') return
        let toastId = toast.loading('Enregistrement des modifications...')
        const forms = document.querySelectorAll('.bank-account-infos')
        const token = getItem('bbbToken')
        Promise.all(Array.from(forms).map(form => {
            let inputs = form.querySelectorAll('input')
            const formData = new FormData()
            
            inputs.forEach(input => {
                formData.append(input.name, input.value)
            })

            const url = process.env.REACT_APP_API_URL + '/bank_account/' + form.dataset.bankaccountid
            
            return fetch(url, {
                method: 'POST',
                'Access-Control-Allow-Credentials': true,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                body: formData,
            })
        })).then(responses => {
            if (responses.every(response => response.ok)) {
                toast.update(toastId, {
                    render: 'Modifications enregistrées avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 3000
                })
            } else {
                toast.update(toastId, {
                    render: 'Une erreur est survenue',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 3000
                })
            }

            // reload the page
            window.location.reload()
        })
    }

    const closePopupSyncBO = () => {
        setSyncPopupIsOpened(false)
    }

    const closePopupAddBankAccount = () => {
      setAddBankAccountPopupIsOpened(false)
    }

    const openSyncBOPopup = (bankAccountId) => {
        setSyncPopupIsOpened(bankAccountId)
    }

    const deleteBankAccount = async (bankAccId) => {
        setIsLoading(bankAccId)
        const token = getItem('bbbToken')
        const url = `${process.env.REACT_APP_API_URL}/bank_account/${bankAccId}`
        const response = await fetch(url, {
            method: 'DELETE',
            'Access-Control-Allow-Credentials': true,
            headers: {
                'Authorization': 'Bearer ' + token
            },
        })

        setIsLoading(false)
        setPopupConfirmBADeletionOpened(false)
        if (response.ok) {
            setDataBankAccount(dataBankAccount.filter((ba) => ba.id !== bankAccId))
            dispatch(setBankAccounts(dataBankAccount.filter((ba) => ba.id !== bankAccId)));
            toast.success('Le compte bancaire a bien été supprimé.')
        } else {
            const responseData = await response.json()

            if (responseData.status == 'warning') {
                toast.warn(responseData.message)
            }

            if (responseData.error) {
                toast.error(responseData.error)
            } else {
                toast.error('Le compte bancaire n\'a pas pu être supprimé.')
            }
        }
    }

    const requestBADeletion = (bankAccId) => {
        setPopupConfirmBADeletionOpened(true)
        setBankAccIdToDelete(bankAccId)
    }

    const openAddNewBankAccountPopup = () => {
      setAddBankAccountPopupIsOpened(true)
    }

    // ArrowDropUpIcon
    return (
        <React.Fragment>
            <section>
                <div className="parent-table text-xs 2xl:text-sm overflow-x-hidden">
                    {
                        bankAccount === null && realUser.role !== "client" ? <div className="pb-8">
                                <h1 className="text-red">Veuillez enregistrer <span className="font-bold">un compte bancaire</span> pour avoir l'accès total à BBB</h1>
                                <div className="flex w-full">
                                    <form id="new-bank-account-form">
                                        <input name="name" type="text" placeholder="nom*"/>
                                        <input name="address" type="text" placeholder="adresse*"/>
                                        <input name="code" type="text" placeholder="code*"/>
                                        {/*<input name="iban" type="text" placeholder="iban"/>*/}
                                        {/*<input name="bic" type="text" placeholder="bic"/>*/}
                                        <button type="button" className="my-8 bg-grisbleu text-violetFonce p-2 rounded-lg" onClick={createNewBankAcc}>Enregistrer les informations</button>
                                    </form>
                                </div>
                            </div>
                            : null
                    }
                    <div className="flex mb-4">

                        {afficheInfos ?
                            <button className="text-black pr-2" onClick={() => setAfficheInfos(false)}><ArrowDropUpIcon/></button>
                            :
                            <button className="text-black pr-2" onClick={() => setAfficheInfos(true)}><ArrowDropDownIcon/></button>

                        }

                        <h1 className="font-bold text-xl text-black cursor-pointer" onClick={() => setAfficheInfos(!afficheInfos)}>Mes infos</h1>
                    </div>
                    {afficheInfos ? <div>
                        <form id="mes-infos-form" onSubmit={(e) => handleSubmit(e, 'user')}>
                            {monTab.length === 0 ? '' :
                                <table className="w-full table-auto overflow-hidden mt-8">
                                    <tbody>
                                    {
                                        monTab.map((val, index) => (
                                            <tr>
                                                <div className="flex w-full">

                                                    <div className="w-3/12">
                                                        <td key={val[0]}
                                                            className={(index % 2 === 0 ? "bg-grisbleu uppercase text-black p-2 h-14 flex justify-between items-center  bordureTd font-semibold" : "uppercase text-black p-2 h-14  flex justify-between items-center  bordureTd font-semibold")}>{val[0]}</td>
                                                    </div>

                                                    {
                                                        // Si l'utilisateur est un admin alors on affiche les informations modifiables (input)
                                                        realUser.role === 'admin_p' ?

                                                            <div key={val[1]}
                                                                 className={(index % 2 === 0 ? "bg-grisbleu w-full ml-16px h-14 flex flex-col bordureTd" : "uppercase text-black h-14  ml-16px w-full flex flex-col bordureTd")}>
                                                                <td key={val[1]}
                                                                    className={(index % 2 === 0 ? "bg-grisbleu uppercase text-black  flex flex-col h-12 pl-6" : " flex flex-col uppercase text-black h-12  pl-6")}>
                                                                    {
                                                                        val[2] === 'reference' ? <td key={val[1]}
                                                                                                     className={(index % 2 === 0 ? "bg-grisbleu uppercase text-black  " : "uppercase text-black p-2 h-14 ")}>{val[1]}</td>
                                                                            : val[2] === 'created_at' || val[2] === 'creation_date' ?
                                                                                <input type="date" className="bg-none-input h-12" defaultValue={val[1]} name={val[2]}/>
                                                                                :
                                                                                <input type="text" className="bg-none-input h-12"
                                                                                       defaultValue={val[1]} name={val[2]}/>
                                                                    }

                                                                    {/* message d'erreur sous l'input qui correspond */}
                                                                    {monTabValidationErrors[val[2]] ?
                                                                        <span
                                                                            className="text-red-400 text-xs h-2">{monTabValidationErrors[val[2]]}</span>
                                                                        : ''
                                                                    }
                                                                </td>
                                                            </div>
                                                            // Si l'utilisateur est un client alors on lui affiche ses information en mode lecture (non modifiables)
                                                            : <div key={val[1]}
                                                                   className={(index % 2 === 0 ? "bg-grisbleu w-full ml-16px h-14 p-2 flex items-center" : "uppercase text-black p-2 h-14  ml-16px w-full")}>
                                                                <td key={val[1]}
                                                                    className={(index % 2 === 0 ? "bg-grisbleu uppercase text-black  pl-6" : "uppercase text-black p-2 h-14  pl-6")}>{val[1]}</td>
                                                            </div>
                                                    }

                                                </div>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            }
                            {/*Message de succès */}
                            {sucessMessage.user !== ""
                                ? <p className="p-3 pl-1 text-purple-200 md:w-2/3 lg:w-2/3 mt-5 bg-purple-800">{sucessMessage.user}</p>
                                : ''
                            }
                            {realUser.role === 'admin_p' ? getSubmitButton() : ""}
                        </form>
                        {/*Affiche tous les formulaire si !admin N*/}
                        {
                            frontUser.role === 'admin_n' || frontUser.role === 'admin_p' ? '' : <React.Fragment>
                                <form id="mon-profil-form" onSubmit={(e) => handleSubmit(e, 'profil')}>
                                    {dataUserProfile.length === 0 ? '' :
                                        <table className="w-full table-auto overflow-hidden">
                                            <tbody>
                                            {
                                                dataUserProfile.map((val, index) => (
                                                    <tr>
                                                        <div className="flex w-full">

                                                            <div className="w-3/12">
                                                                <td key={val[0]}
                                                                    className={(index % 2 === 0 ? "bg-grisbleu uppercase text-black p-2 h-14 flex justify-between items-center  bordureTd font-semibold" : "uppercase text-black p-2 h-14  flex justify-between items-center  bordureTd font-semibold")}>{val[0]}</td>
                                                            </div>
                                                            {
                                                                // Si l'utilisateur est un admin alors on affiche les informations modifiables (input, select)
                                                                realUser.role === 'admin_p' ?

                                                                    <div key={val[1]}
                                                                         className={(index % 2 === 0 ? "bg-grisbleu w-full ml-16px h-14 flex flex-col bordureTd" : "uppercase text-black  h-14  ml-16px w-full flex flex-col bordureTd")}>
                                                                        <td key={val[1]}
                                                                            className={(index % 2 === 0 ? "bg-grisbleu uppercase text-black  h-12 flex flex-col pl-6" : "uppercase text-black h-12  flex flex-col pl-6")}>
                                                                            {
                                                                                val[2] === 'client_status' ?
                                                                                    <select name={val[2]} defaultValue={val[1]} className="bg-none-input h-12">
                                                                                        <option value="SASU">SASU</option>
                                                                                        <option value="SAS">SAS</option>
                                                                                        <option value="EURL">EURL</option>
                                                                                        <option value="SARL">SARL</option>
                                                                                        <option value="SELARL">SELARL</option>
                                                                                        {/*<option value="MICRO">Micro</option>*/}
                                                                                        {/*<option value="EL">EL</option>*/}
                                                                                        <option value="EIRL">EIRL</option>
                                                                                    </select>
                                                                                    : val[2] === 'tax_system' ?
                                                                                        <select name={val[2]} className="bg-none-input h-12" defaultValue={val[1]}>
                                                                                            <option value="M">M</option>
                                                                                        </select> :
                                                                                        val[2] === 'income_tax' ?
                                                                                            <select name={val[2]} defaultValue={val[1]} className="bg-none-input h-12">
                                                                                                <option value="IR">IR</option>
                                                                                            </select>
                                                                                            : val[2] === 'exercise_date' || val[2] === 'exercise_entry_date' || val[2] === 'entry_date' ?
                                                                                                <input type="date" className="bg-none-input h-12" defaultValue={val[1]} name={val[2]}/>
                                                                                                : <input type="text" className="bg-none-input h-12"
                                                                                                         defaultValue={val[1]} name={val[2]}/>

                                                                            }
                                                                            {/* message d'erreur sous l'input qui correspond */}
                                                                            {monTabValidationErrors[val[2]] ?
                                                                                <span
                                                                                    className="text-red-400 text-xs h-2">{monTabValidationErrors[val[2]]}</span>
                                                                                : ''
                                                                            }
                                                                            {/* Si l'utilisateur est un client alors on lui affiche ses information en mode lecture (non modifiables)*/}
                                                                        </td>
                                                                    </div>

                                                                    : <div key={val[1]}
                                                                           className={(index % 2 === 0 ? "bg-grisbleu w-full ml-16px h-14 p-2 flex items-center" : "uppercase text-black p-2 h-14  ml-16px w-full")}>
                                                                        <td key={val[1]}
                                                                            className={(index % 2 === 0 ? "bg-grisbleu uppercase text-black  pl-6" : "uppercase text-black p-2 h-14  pl-6")}>{val[1]}</td>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </tr>
                                                ))

                                            }
                                            {realUser.role === 'admin_p' ?
                                                <tr>
                                                    <div className="flex w-full">

                                                        <div className="w-3/12">
                                                            <td className="uppercase text-black p-2 h-14  flex justify-between items-center  bordureTd font-semibold">Addresse</td>
                                                        </div>
                                                        <div className="uppercase text-black p-2 h-14  ml-16px w-full flex items-center bordureTd">
                                                            {userProfileAddress.num ?
                                                                <td className="uppercase text-black p-2 h-14  flex justify-between items-center  ">
                                                                    <input type="text" placeholder="num" name="num" size="5"
                                                                           className="profileAddress"
                                                                           defaultValue={userProfileAddress.num}/>

                                                                    <input type="text" placeholder="street" name="street" size="12"
                                                                           className="profileAddress"
                                                                           defaultValue={userProfileAddress.street}/>
                                                                    {/*<input type="text" placeholder="comp" name="comp" size="12"*/}
                                                                    {/*       className="profileAddress"*/}
                                                                    {/*       defaultValue={userProfileAddress.comp}/>*/}
                                                                    <input type="text" placeholder="code" name="code" size="5"
                                                                           className="profileAddress"
                                                                           defaultValue={userProfileAddress.code}/>
                                                                    <input type="text" placeholder="ville" name="ville" size="12"
                                                                           className="profileAddress"
                                                                           defaultValue={userProfileAddress.ville}/>
                                                                </td>
                                                                : <td/>
                                                            }
                                                        </div>

                                                    </div>
                                                </tr>
                                                :
                                                <tr>
                                                    <div className="flex w-full">

                                                        <div className="w-3/12">
                                                            <td className="uppercase text-black p-2 h-14 flex justify-between items-center   ">Addresse</td>
                                                        </div>

                                                        {userProfileAddress.num ?
                                                            <React.Fragment>
                                                                <div className="uppercase text-black h-14  ml-2 flex items-center">
                                                                    <td><span className="mr-2">{userProfileAddress.num}</span></td>
                                                                </div>
                                                                <div className="uppercase text-black h-14  ml-2 flex items-center">
                                                                    <td><span className="mr-2">{userProfileAddress.street}</span></td>
                                                                </div>
                                                                {/*<div className="uppercase text-black h-14  ml-2 flex items-center">*/}
                                                                {/*    <td><span className="mr-2">{userProfileAddress.comp}</span></td>*/}
                                                                {/*</div>*/}

                                                                <div className="uppercase text-black h-14  ml-2 flex items-center">

                                                                    <td><span className="mr-2">{userProfileAddress.code}</span></td>
                                                                </div>

                                                                <div className="uppercase text-black h-14  ml-2 flex items-center">

                                                                    <td><span className="mr-2">{userProfileAddress.ville}</span></td>
                                                                </div>
                                                            </React.Fragment>
                                                            : <td/>}
                                                    </div>
                                                </tr>
                                            }
                                            </tbody>
                                        </table>
                                    }
                                    {/*Seul l'admin peut voir ce bouton*/}

                                    {sucessMessage.profil !== ""
                                        ?
                                        <p className="p-3 pl-1 text-purple-200 md:w-2/3 lg:w-2/3 mt-5 bg-purple-800">{sucessMessage.profil}</p>
                                        : ''
                                    }
                                    {realUser.role === 'admin_p' && dataUserProfile.length > 0 ? getSubmitButton() : ""}
                                </form>
                                {/*<form id="bank-account-infos" onSubmit={(e) => handleSubmit(e, 'bank-account')}>*/}
                                {/*    {dataBankAccount.length === 0 ? '' :*/}

                                {/*        <table className="w-full table-auto overflow-hidden">*/}
                                {/*            <tbody>*/}
                                {/*            {*/}
                                {/*                dataBankAccount.map((bankAcc, index) =>*/}
                                {/*                    <tr className={index !== 0 && !toggleBankAccount ? 'hidden' : ''}>*/}
                                {/*                        <div className="flex w-full">*/}

                                {/*                            <div className="w-3/12 ">*/}
                                {/*                                <td key={bankAcc.id}*/}
                                {/*                                    className={(index % 2 === 0 ? "bg-grisbleu uppercase text-black h-14 flex justify-between items-center  bordureTd p-2 font-semibold" : "uppercase text-black h-14 flex justify-between items-center  bordureTd p-2 font-semibold")}>*/}
                                {/*                                    <span>Compte bancaire</span>*/}
                                {/*                                    {*/}
                                {/*                                        index !== 0 ? '' : toggleBankAccount ?*/}
                                {/*                                            <ArrowDropUpIcon onClick={() => setToggleBankAccount(false)}/> :*/}
                                {/*                                            <ArrowDropDownIcon onClick={() => setToggleBankAccount(true)}/>*/}
                                {/*                                    }*/}
                                {/*                                </td>*/}
                                {/*                            </div>*/}
                                {/*                            <div*/}
                                {/*                                className={`${index % 2 === 0 ? "bg-grisbleu bordureTd flex w-9-12 h-14" : " bordureTd flex w-9-12 h-14"} items-center gap-2`}>*/}
                                {/*                                {*/}
                                {/*                                    Object.keys(bankAcc).map(key => <React.Fragment*/}
                                {/*                                        key={key}>{createTdInput({*/}
                                {/*                                        content: bankAcc[key],*/}
                                {/*                                        name: key*/}
                                {/*                                    })}</React.Fragment>)*/}
                                {/*                                }*/}
                                {/*                            </div>*/}

                                {/*                        </div>*/}
                                {/*                    </tr>*/}
                                {/*                )}*/}
                                {/*            </tbody>*/}
                                {/*        </table>*/}
                                {/*    }*/}
                                {/*</form>*/}
                                <form id="person-infos" onSubmit={(e) => handleSubmit(e, 'person')}>
                                    {dataPerson.length === 0 ? '' :
                                        <table className="w-full table-auto overflow-hidden">
                                            <tbody>
                                            {
                                                dataPerson.map((person, index) =>
                                                    <tr className={index !== 0 && !togglePerson ? 'hidden' : ''}>
                                                        <div className="flex w-full">

                                                            <div className="w-3/12">
                                                                <td key={person.id}
                                                                    className={(index % 2 === 0 ? "bg-grisbleu uppercase text-black h-14 flex justify-between items-center  bordureTd p-2 font-semibold" : "uppercase text-black h-14 flex justify-between items-center  bordureTd p-2 font-semibold")}>
                                                                    <span>Nom dirigeant</span>
                                                                    {
                                                                        index !== 0 ? '' : togglePerson ?
                                                                            <ArrowDropUpIcon onClick={() => setTogglePerson(false)}/> :
                                                                            <ArrowDropDownIcon onClick={() => setTogglePerson(true)}/>
                                                                    }
                                                                </td>
                                                            </div>
                                                            <div
                                                                className={(index % 2 === 0 ? "bg-grisbleu bordureTd flex w-9-12 h-14 items-center gap-2" : "items-center gap-2 bordureTd flex w-9-12 h-14")}>
                                                                {
                                                                    Object.keys(person).map(key => <React.Fragment
                                                                        key={key}>{createTdInput({
                                                                        content: person[key],
                                                                        name: key
                                                                    })}</React.Fragment>)
                                                                }
                                                            </div>

                                                        </div>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    }
                                    {/*Seul l'admin peut voir ce bouton*/}
                                    {/*{user.role === 'admin_p' && dataUserProfile.length > 0 ? getSubmitButton() : ""}*/}

                                </form>
                                <form id="employe-infos" onSubmit={(e) => handleSubmit(e, 'employe')}>
                                    {dataEmploye.length === 0 ? '' :
                                        <table className="w-full table-auto overflow-hidden">
                                            <tbody>
                                            {
                                                dataEmploye.map((employe, index) =>
                                                    <tr className={index !== 0 && !toggleEmploye ? 'hidden' : ''}>
                                                        <div className="flex w-full">

                                                            <div className="w-3/12 ">
                                                                <td key={employe.id}
                                                                    className={(index % 2 === 0 ? "bg-grisbleu uppercase text-black h-14 flex justify-between items-center  bordureTd p-2 font-semibold" : "uppercase text-black h-14 flex justify-between items-center  bordureTd p-2 font-semibold")}>
                                                                    <span>Employes</span>
                                                                    {
                                                                        index !== 0 ? '' : toggleEmploye ?
                                                                            <ArrowDropUpIcon onClick={() => setToggleEmploye(false)}/> :
                                                                            <ArrowDropDownIcon onClick={() => setToggleEmploye(true)}/>
                                                                    }
                                                                </td>
                                                            </div>
                                                            <div
                                                                className={(index % 2 === 0 ? "bg-grisbleu bordureTd flex w-9-12 h-14 items-center gap-2" : "items-center gap-2 bordureTd flex w-9-12 h-14")}>
                                                                {
                                                                    Object.keys(employe).map(key => <React.Fragment
                                                                        key={key}>{createTdInput({
                                                                        content: employe[key],
                                                                        name: key
                                                                    })}</React.Fragment>)
                                                                }
                                                            </div>

                                                        </div>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    }
                                    {/*Seul l'admin peut voir ce bouton*/}
                                    {/*{user.role === 'admin_p' && dataUserProfile.length > 0 ? getSubmitButton() : ""}*/}

                                </form>
                            </React.Fragment>
                        }

                    </div> : ""}


                    <div className="flex mb-4">

                        {afficheBanks ?
                            <button className="text-black pr-2" onClick={() => setAfficheBanks(false)}><ArrowDropUpIcon/></button>
                            :
                            <button className="text-black pr-2" onClick={() => setAfficheBanks(true)}><ArrowDropDownIcon/></button>

                        }

                        <h1 className="font-bold text-xl text-black cursor-pointer" onClick={() => setAfficheBanks(!afficheBanks)}>Mes comptes bancaires</h1>
                    </div>

                    {afficheBanks ? 
                        <div className="relative">
                            {dataBankAccount.length === 0 ? 'Aucun compte bancaire' :
                                <table className="w-full table-auto overflow-hidden rounded-lg">
                                    <thead className="bgPurpuleCustom text-white">
                                    <tr>
                                        <th className="uppercase p-1 h-14 font-semibold">Compte bancaire</th>
                                        <th className="uppercase p-1 h-14 font-semibold">Numéro de compte</th>
                                        {/* <th className="uppercase p-1 h-14 font-semibold">Adresse</th> */}
                                        <th className="uppercase p-1 h-14 font-semibold">IBAN</th>
                                        <th className="uppercase p-1 h-14 font-semibold">BIC</th>
                                        <th className="uppercase p-1 h-14 font-semibold">1ère Opération</th>
                                        <th className="uppercase p-1 h-14 font-semibold">Tréso initiale</th>
                                        <th className="uppercase p-1 h-14 font-semibold">Delta tréso</th>
                                        <th className="uppercase p-1 h-14 font-semibold">Connecté</th>
                                        <th className="uppercase p-1 h-14 font-semibold"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {dataBankAccount.map((bankAcc, index) =>
                                            <tr key={bankAcc.id} className='text-center bank-account-infos' data-bankaccountid={bankAcc.id}>
                                                <td>
                                                    <input name="name" type="text" defaultValue={bankAcc.name}/>
                                                </td>
                                                <td>
                                                    <input name="number" type="text" defaultValue={bankAcc.number}/>
                                                </td>
                                                {/* <td>
                                                    <input name="address" type="text" defaultValue={bankAcc.address}/>
                                                </td> */}
                                                <td>
                                                    <input name="iban" type="text" defaultValue={bankAcc.iban}/>
                                                </td>
                                                <td>
                                                    <input name="bic" type="text" defaultValue={bankAcc.bic}/>
                                                </td>
                                                <td>
                                                    <span>{bankAcc.first_operation_date}</span>
                                                </td>
                                                <td>
                                                    <input name="initial_treasury" type="number" defaultValue={bankAcc.initial_treasury} />
                                                </td>
                                                <td>
                                                    <input name="delta_treasury" type="number" defaultValue={bankAcc.delta_treasury} />
                                                </td>
                                                <td>
                                                    <span>{bankAcc.is_connected ? 'Oui' : 'Non'}</span>
                                                </td>
                                                <td>
                                                    {syncPopupIsOpened && syncPopupIsOpened == bankAcc.id ?
                                                        <span className="ml-auto text-[10px] indicateursView:text-[18px]">
                                                            <PulseLoader color={'black'} size={7}/>
                                                        </span>
                                                    :
                                                        <SyncIcon className="hover:text-green-500 cursor-pointer" onClick={(e) => openSyncBOPopup(bankAcc.id)} />
                                                    }
                                                    {isLoading && isLoading == bankAcc.id ? 
                                                        <span className="ml-auto text-[10px] indicateursView:text-[18px]">
                                                            <PulseLoader color={'black'} size={7}/>
                                                        </span>
                                                    : 
                                                        !bankAcc.is_connected ?
                                                            <DeleteIcon className="hover:text-red-500 cursor-pointer" onClick={(e) => requestBADeletion(bankAcc.id)} />
                                                        :
                                                            <DeleteForeverIcon className="hover:text-yellow-500 cursor-not-allowed" />
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            }
                            {<button className="btn-add btn-add-bankaccount" onClick={openAddNewBankAccountPopup}>+</button>}
                            {addBankAccountPopupIsOpened ? <PopupAddBankAccount close={closePopupAddBankAccount} /> : null}
                            {syncPopupIsOpened ? <PopupSyncBO close={closePopupSyncBO} bankAccountId={syncPopupIsOpened} /> : null}

                            <button type="button" onClick={handleSubmitBankEdit} className="my-8 bg-grisbleu text-violetFonce p-2 rounded-lg">Enregistrer les informations</button>
                        </div> 
                    : 
                        null
                    }
                </div>
                {isPopupConfirmBADeletionOpened ? 
                    <div className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
                        <div className="sidebar:w-[65.8rem] mx-auto bg-white rounded-lg shadow-xl sidebar:p-[4.6rem] p-8 w-2/3 z-100">
                            <button className="float-right pt-0 text-[#717579]" onClick={(e) => setPopupConfirmBADeletionOpened(false)}><CloseIcon/></button>
                            <div className="parent-table w-full mt-[2.2rem]">
                                <div className="flex text-black bg-grisClaire p-8 mt-8 rounded-xl text-base sidebar:text-[24px]">
                                    <div className="mr-4"><p className="font-bold">Attention:</p></div>
                                    <div>
                                        <p>les opérations présentes liées à ce compte bancaire seront ecrasées, ainsi que toutes les associations réalisées à ces opérations.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between mt-8">
                                <button className="bg-redCorail text-white rounded-xl p-2" type="button" onClick={(e) => setPopupConfirmBADeletionOpened(false)}>
                                    Annuler
                                </button>
                                <button className="bg-[#09BD72] text-white rounded-xl p-2" type="button" onClick={(e) => deleteBankAccount(bankAccIdToDelete)}> 
                                    {isLoading ? 
                                        <PulseLoader color={'white'} size={7} />
                                    :
                                        'Confirmer'
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                :
                    null
                }
            </section>
        </React.Fragment>
    )
}
export default MesInfos