import React, {useEffect, useRef, useState} from "react";
import http from "../../services/Http";
import {getItem} from "../../services/LocaleStorage";
import {toast} from "react-toastify";
import { ResizableBox } from "react-resizable";
import BackupIcon from "@material-ui/icons/Backup";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {getUser} from "../../app/user";
import {useSelector} from "react-redux";
import HelpIcon from "@material-ui/icons/Help";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import ModalCategory from "../client/modalCategory";
import PopupSelectImport from "./PopupSelectImport";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import Loader from "../Loader";
import {useDropzone} from 'react-dropzone'
import {PulseLoader} from "react-spinners";
import {calculateHT, calculateTTC} from "../../utils/calculations";
import {getDatesFilter, getOptional} from "../../app/filters";
import deleteSmall from "../../assets/img/deleteSmall.png";
import Pagination from "../Pagination";
import deleteImg from "../../assets/img/delete.png";
import PopupOCRReprocess from "../Tables/PopupOCRReprocess";
import formatDate from "../../utils/formatDate";
import formatCurrency from "../../utils/formatAmount";

const PopupImportOpBancaire = ({
    close, 
    rowToAffect, 
    fetchOperationsBancaires, 
    refreshStatsAndGraphs
}) => {

    const [categories, setCategories] = useState([])
    const [operation, setOperation] = useState({});
    const [nameFile, setNameFile] = useState('')
    const [extensionFile, setExtensionFile] = useState('')
    const [selectedCat, setSelectedCat] = useState(null);
    const [affectation, setAffectation] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [affectationSelected, setAffectationSelected] = useState([]);
    const [popupSelectImport, setPopupSelectImport] = useState(false);
    const [openTabDocuments, setOpenTabDocuments] = useState(false);
    const [documents, setDocuments] = useState({data: [], per_page: 10, page: 1, count: 0});
    const frontUser = useSelector(getUser)
    const [startDateDocuments, setStartDateDocuments] = useState(null);
    const [endDateDocument, setEndDateDocument] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCategSuggestion, setIsLoadingCategSuggestion] = useState(false);
    const [isSubmissionLoading, setIsSubmissionLoading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [files, setFiles] = useState([]);
    const optional = useSelector(getOptional)
    const token = getItem('bbbToken')
    const [searchTerms, setSearchTerms] = useState("");
    const [searchTermsTimer, setSearchTermsTimer] = useState(null);
    const [sortTerms, setSortTerms] = useState("operation_date");
    const [sortOrder, setSortOrder] = useState("DESC");
    const [toggleUseOcr, setToggleUseOcr] = useState(false);
    const [suggestedSubCategories, setSuggestedSubCategories] = useState([])
    const [isSubCategSuggested, setIsSubCategSuggested] = useState(false)
    const [isAmountRestrictionPopupDisplayed, setIsAmountRestrictionPopupDisplayed] = useState(false);
    const [isAffectationLoading, setIsAffectationLoading] = useState(false);

    //Pagination
    const [currentPage, setCurrentPage] = useState(documents.page);
    const [currentPerPage, setCurrentPerPage] = useState(documents.per_page);

    const paginate = (perPage, pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const changePerPage = (e) => {
        setCurrentPage(1)
        setCurrentPerPage(e.target.value)
    }

    //Relance req au changement de dates dans la modale
    useEffect(() => {
        if(selectedCat !== null) {
            fetchDocuments()
        }
    }, [startDateDocuments, endDateDocument, selectedCat?.id, optional, sortOrder, sortTerms, currentPage, currentPerPage]);

    const dateFilters = useSelector(getDatesFilter);
    useEffect(() => {
        if (Array.isArray(dateFilters)) {
            setStartDateDocuments(dateFilters[0]);
            setEndDateDocument(dateFilters[1]);
        }
    }, dateFilters);

    const changeDate = (e) => {
        if (e.target.id === "date-start-documents") {
            e.target.value !== '' ? setStartDateDocuments(e.target.value) : setStartDateDocuments(false)
        }
        if (e.target.id === "date-end-documents") {
            e.target.value !== '' ? setEndDateDocument(e.target.value) : setEndDateDocument(false)
        }
    }

    const openDocuments = () => {
        setPopupSelectImport(false)
        setOpenTabDocuments(true)
    }
    useEffect(() => {
        fetchAffectationOperation()
    }, [])

    useEffect(() => {
        if (searchTermsTimer) {
            clearTimeout(searchTermsTimer)
        }
        setSearchTermsTimer(setTimeout(() => {
            fetchCategories()
        }, 500))
    }, [searchTerms]);

    useEffect(() => {
        setOperation(rowToAffect)
        setComment(rowToAffect.comment)
        setSelectedCat(rowToAffect.sub_category)
    }, [rowToAffect]);


    const openFile = () => {
        if (nameFile !== "" && affectation.length !== 0 && extensionFile !== "") {
            window.open(process.env.REACT_APP_STORAGE_URL + affectation[0].invoice.receipt_path, '_blank');
        }
    }
    useEffect(() => {
        if (nameFile !== "" && affectation.length !== 0 && extensionFile !== "") {
            document.getElementById('balise-img-modal-operation').setAttribute('src', process.env.REACT_APP_STORAGE_URL + affectation[0].invoice.receipt_path)
        } else if (nameFile !== "" && extensionFile !== "") {
            document.getElementById('balise-img-modal-operation').setAttribute('src', URL.createObjectURL(document.getElementById('invoice-operation-input').files[0]))
            setPopupSelectImport(false)
        }
    }, [nameFile, extensionFile]);

    //Glisser - déposer file
    const {getRootProps, getInputProps} = useDropzone({
        accept: ['image/*', 'application/pdf'], onDrop: (acceptedFiles) => {
            setFiles(acceptedFiles.map((file) => Object.assign(file, {
                preview: URL.createObjectURL(file),
            })));
        }, noClick: true,
    });
    const dragNDrop = (e) => {
        document.getElementById('invoice-operation-input').files = e.dataTransfer.files;

        if (document.getElementById('invoice-operation-input').files.length !== 0) {
            setNameFile(document.getElementById('invoice-operation-input').files[0].name.split('.')[0]);
            setExtensionFile(document.getElementById('invoice-operation-input').files[0].name.split('.')[1]);
        }
    }

    // --- Partie Sub_cat ---
    const setCat = (cat) => {
        setSelectedCat(cat);
        hideSearchPopUp();
    }

    useEffect(() => {
        if (selectedCat?.is_vat_blocked) {
            updateCalculs('vat', selectedCat.vat);
        }
    }, [selectedCat]);

    const fetchCategories = async() => {
        if (!['achat', 'vente'].includes(rowToAffect.type)) return;
        
        const token = getItem('bbbToken');

        let response = await fetch(process.env.REACT_APP_API_URL +'/categories/' + rowToAffect.type , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({searchTerms: searchTerms})

        });

        if (response.status === 200){
            let body = await response.json()
            setCategories(body)
        }
    }

    // Search Input: showing Keywords after clicking on the input field
    const showKeywords = () => {
        document.querySelector('.search-pop-up').classList.remove('hide-pop-up')
        document.body.style = "overflow: hidden"
    }
    const closePopup = () => {
        setPopupSelectImport(false)
    }
    // Search Pop Up: Hiding the Pop Up
    const hideSearchPopUp = () => {
        document.querySelector('.search-pop-up').classList.add('hide-pop-up')
        document.body.style = "overflow: auto"
    }

    // // Search Input : Showing sub-categories on clicking on the down arrow
    // const showsubCatsegories = (e) => {
    //     e.target.closest('.category').nextSibling.classList.toggle('hide')
    //     e.target.classList.toggle('rotate')
    // }


    const changeInput = (e) => {
        if (e.target.files.length === 0) {
            setNameFile('')
            setExtensionFile('')
        } else {
            // take all elements of the file name except the last one
            setNameFile(e.target.files[0].name.split('.').slice(0, -1).join('.')) // jean.pierre.jpg => jean.piere
            setExtensionFile(e.target.files[0].name.split('.').at(-1)) // jean.pierre.jpg => jpg
        }
    }

    const uploadInvoice = async () => {

        const fileInput = document.getElementById('invoice-operation-input').files
        //Envoie de la requête si l'input contient un fichier sinon return
        if (fileInput.length === 0) {
            toast.info('Veuillez choisir un fichier')
            return
        }

        const token = getItem('bbbToken')
        const authUrl = process.env.REACT_APP_API_URL + '/invoice/scan'
        let formdata = new FormData()
        formdata.append('receipt', fileInput[0])
        formdata.append('invoice_type', operation.type)
        formdata.append('client_id', frontUser.id)
        formdata.append('transaction_id', operation.id)
        formdata.append('use_ocr', toggleUseOcr)

        // formdata.append('vat_value', (operation.amount_with_vat - operation.amount).toFixed(2))
        // formdata.append('vat_blocked', 'false')
        // formdata.append('amount', operation.amount)
        // if (selectedCat) formdata.append('sub_category_id', selectedCat.id)
        formdata.delete('operation_date')
        setIsLoading(true)

        let response = await fetch(authUrl, {
            method: 'POST', body: formdata, headers: {
                'Authorization': 'Bearer ' + token
            }
        });

        setIsLoading(false)
        if (response.status=== 200) {
            response.json().then(response => {
                const formatedResponse = [response.affectation]
                formatedResponse[0].invoice = response.invoice
                // response.affectations.invoice = response.invoice
                // // Modification de la réponse avant de faire le setAffection, pour avoir la même structure que la réponse de la requête GET /transaction.
                // let newAffect = [response.affectation]
                setAffectation(formatedResponse)

                if (formatedResponse[0].invoice.receipt_path !== null) {
                    let file = formatedResponse[0].invoice.receipt_path.split('/')
                    file = file[file.length - 1]
                    file = file.split('.')
                    setNameFile(file[0])
                    setExtensionFile(file[1])
                } else {
                    setNameFile("")
                    setExtensionFile("")
                }

                fetchOperationsBancaires()
                toast.success("Affectation de l'opération bancaire à cette pièce réussie.")
            })
        } else {
            toast.error("L\'affectation de l'opération bancaire à cette pièce a échoué")
        }
    }


    const delImage = () => {
        if (affectation.length === 0) {
            document.getElementById('invoice-operation-input').value = null
            setNameFile('')
            setExtensionFile('')
        } else {
            const token = getItem('bbbToken')

            fetch(process.env.REACT_APP_API_URL + '/affectation/' + affectation[0].id, {
                method: 'DELETE', requirements: token, headers: {
                    'Authorization': 'Bearer ' + token
                },
            })
            .then(response => {
                if (response.ok) {
                    fetchAffectationOperation()
                    fetchOperationsBancaires()
                    toast.success('L\'affectation a été supprimé avec succès.')
                }
            });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = getItem('bbbToken');

        setIsSubmissionLoading(true);

        if(nameFile !== ""){
            await uploadInvoice()
        }

        // Update the OCR reprocessed document
        let vat;
        let amount;
        let amountWithVat;
        if (Object.keys(ocrReprocessedInvoice).length > 0) {
            let formDocument = new FormData();

            formDocument.append('client_id', frontUser.id)

            const { invoice: docInvoice } = affectation[0];

            if (ocrReprocessedInvoice.date) {
                formDocument.append('operation_date', ocrReprocessedInvoice.date);
            }
            if (ocrReprocessedInvoice.libelle) {
                formDocument.append('label', ocrReprocessedInvoice.libelle);
            }

            if (ocrReprocessedInvoice.montant_ht) {
                amount = parseFloat(ocrReprocessedInvoice.montant_ht);
                formDocument.append('amount', amount);
            } else {
                amount = parseFloat(docInvoice.amount);
                formDocument.append('amount', amount);
            }

            if (affectation.sub_category?.is_vat_blocked) {
                vat = docInvoice.vat;
                amountWithVat = ocrReprocessedInvoice.montant_ttc;
                
                // Calculate amount HT based on the blocked VAT rate and the OCR reprocessed TTC amount
                amount = calculateHT(ocrReprocessedInvoice.montant_ttc, docInvoice.vat);
                
                formDocument.append('vat', vat);
                formDocument.append('vat_value', (((amountWithVat * 100) - (amount * 100)) / 100).toFixed(2));
                formDocument.append('amount_with_vat', amountWithVat);
                formDocument.append('amount', amount);
            } else {
                vat = parseFloat(ocrReprocessedInvoice.vat);
                amount = parseFloat(ocrReprocessedInvoice.montant_ht);
                amountWithVat = parseFloat(ocrReprocessedInvoice.montant_ttc);

                formDocument.append('vat', vat);
                formDocument.append('amount', amount);
                formDocument.append('amount_with_vat', amountWithVat);

                if (ocrReprocessedInvoice.vat_value) {
                    formDocument.append('vat_value', parseFloat(ocrReprocessedInvoice.vat_value).toFixed(2));
                } else {
                    formDocument.append('vat_value', (((amountWithVat * 100) - (amount * 100)) / 100).toFixed(2));
                }
            }

            fetch(`${process.env.REACT_APP_API_URL}/invoice/${docInvoice.id}`, {
                method: 'POST', 
                headers: {
                    'Authorization': `Bearer ${token}`,
                }, 
                body: formDocument
            })
            .then(response => {
                if (response.ok) {
                    toast.success('Mise à jour des informations de la facture avec succès.');
                }
            })
            .catch(() => {
                toast.info("Erreur lors de la modification");
            });
        }

        //Modification des données de l'opération
        let form = new FormData(document.getElementById('affectation-operation'))
        form.append('type', operation.type)
        form.append('vat_value', (operation.amount_with_vat - operation.amount).toFixed(2))
        form.append('vat_blocked', 'false')
        form.append('code_fiscal', '206000')
        form.append('operation_date', operation.operation_date.split(' ')[0])
        form.append('amount', operation.amount)
        form.append('amount_with_vat', operation.amount_with_vat)
        form.append('feed_ai', true);

        form.append('comment',  comment ?? '');

        if (selectedCat) {
            form.append('sub_category_id', selectedCat.id)
			form.append('is_sub_categ_suggested', false)
        }
        form.append('vat', operation.vat ?? 0)

        let response = await fetch(`${process.env.REACT_APP_API_URL}/transaction/${operation.id}`, {
            method: 'POST', 
            headers: {
                'Authorization': `Bearer ${token}`,
            }, 
            body: form
        });
        setIsSubmissionLoading(false);
        if (response.status === 200) {
            fetchOperationsBancaires();
            refreshStatsAndGraphs();
            let body = await response.json();
            setOperation(body);
            close();
            toast.success('Modification mise à jour avec succès');
        } else {
            toast.error("Erreur lors de la modification");
            close();
        }
    }

    const fetchAffectationOperation = () => {
        const token = getItem('bbbToken')

        //Récupe l'affectation d'une op bancaire précise ici
        let endpoint = '/affectations?transaction_id=' + rowToAffect.id + '&include[]=invoice'
        setIsLoading(true)
        http.get(endpoint, {
            'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token
        })
            .then((data) => {
                setAffectation(data.affectations)
                setIsLoading(false)
                if (data.affectations.length !== 0) {
                    if (data.affectations[0].invoice.receipt_path !== null) {
                        let file = data.affectations[0].invoice.receipt_path.split('/')
                        file = file[file.length - 1]
                        file = file.split('.')
                        setNameFile(file[0])
                        setExtensionFile(file[1])
                    } else {
                        setNameFile("")
                        setExtensionFile("")
                    }

                } else {
                    setNameFile('')
                    setExtensionFile('')
                    setAffectation([])
                }

            })
            .catch((error) => {
                console.log(error)
                setIsLoading(false)
            });
    }

    const fetchDocuments = async (perPage = documents.per_page, page = documents.page) => {
        setIsLoading(true)
        let body = {
            client_id: frontUser.id,
            type: rowToAffect.type === 'achat' ? 'achat' : 'vente',
            page: currentPage ?? page,
            per_page: currentPerPage ?? perPage,
            from_operation_date: startDateDocuments ?? null,
            to_operation_date: endDateDocument ?? null,
            operation_date: operation.operation_date,
            operation_label: operation.label,
            operation_amount: operation.amount_with_vat,
            component: 'bankop_affectation',
            sort: {
                field: sortTerms,
                order: sortOrder
            },
        }
        
        let response = await fetch(process.env.REACT_APP_API_URL +'/filter-invoices' , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(body)

        })
        if (response.status === 200) {
            let body = await response.json()
            setDocuments(body)
        } else {
            setDocuments({data: [], per_page: 10, page: 1, count: 0})
        }
        setIsLoading(false)
    }
    const sendAffectation = (document) => {
        if (parseFloat(document.amount_with_vat) < parseFloat(rowToAffect.amount_with_vat)) {
            setIsAmountRestrictionPopupDisplayed(true);
            return;
        }

        setIsAffectationLoading(document.id);

        const token = getItem('bbbToken')
        const formdata = new FormData();
        formdata.append('transaction_id', rowToAffect.id)
        formdata.append('invoice_id', document.id)
        
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/affectation`, {
            method: 'POST', 
            body: formdata, 
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            setIsLoading(false);
            setIsAffectationLoading(false);
            if (response.ok) {
                fetchAffectationOperation()
                fetchOperationsBancaires()
                setOpenTabDocuments(false)
                toast.success("Affectation de l'opération bancaire à cette pièce réussie.")
            } else {
                toast.error("L\'affectation de l'opération bancaire à cette pièce a échoué")
            }
        })
        .catch(error => {
            setIsLoading(false);
            setIsAffectationLoading(false);
            console.error(error);
        });
    }

    const updateCalculs = (newValuePropName, newValue) => {
        let newValues = {[newValuePropName]: newValue}
        if (newValuePropName === "vat") {
            newValues.amount = calculateHT(operation.amount_with_vat, newValue)
        }else{
            if(newValuePropName === "amount") {
                newValues.amount_with_vat = calculateTTC(newValue, operation.vat)
            } else {
                newValues.amount = calculateHT(newValue, operation.vat)
            }
        }

        setOperation({...operation, ...newValues})
    }

    useEffect(() => {
        // rowToAffect is operation passed via props
        if (rowToAffect?.label) {
            fetchSuggestedSubCategories(rowToAffect);
        } else if (operation?.label) {
            fetchSuggestedSubCategories(operation);
        }
    }, [])

	useEffect(() => {
		if (selectedCat != null) {
			saveSuggestedSubCategory()
		}
	}, [isSubCategSuggested])

	const saveSuggestedSubCategory = async () => {
		let form = new FormData();

		if (selectedCat == null) return;

		form.append('sub_category_id', selectedCat.id)
		form.append('is_sub_categ_suggested', true)
        form.append('feed_ai', false);

        const token = getItem('bbbToken')
		const url = `/transaction/${operation.id}`
        let response = await fetch(process.env.REACT_APP_API_URL + url, {
            method: 'POST', headers: {
                'Authorization': 'Bearer ' + token,
            }, body: form
        })
        if (response.status === 200) {
			fetchAffectationOperation()
			fetchOperationsBancaires()
            // No need to display any notification when the suggested categ gets saved behind the scene
            // toast.success('Modification mise à jour avec succès')
        } else {
            // toast.error("Erreur lors de la modification")
        }
	}

    const fetchSuggestedSubCategories = async (op = null) => {
        setIsLoadingCategSuggestion(true)
        const token = getItem('bbbToken')
        const url = process.env.REACT_APP_API_URL + '/transaction/categories/suggest'
        const formdata = new FormData();

        if (!op) op = operation;

        formdata.append('text', op.label)
        formdata.append('item_type', op.type)

        try {
            const response = await fetch(url, {
                                method: 'POST',
                                body: formdata,
                                headers: {
                                    'Authorization': 'Bearer ' + token
                                }
                            })
            
            setIsLoadingCategSuggestion(false)
            
            if (response.ok) {
                const res = await response.json()

                // Sort the suggested categories by putting the selected one on top of the list
                const sortedSubCategories = res.results.sort((a, b) => {
                    if (a.id === op?.sub_category?.id) return -1; // Place checked item first
                    if (b.id === op?.sub_category?.id) return 1;
                    return 0; // Keep the order for unchecked items
                });

                setSuggestedSubCategories(sortedSubCategories);

                const shouldSuggestAndPreselectSubCateg = ((!op?.sub_category?.id && !isSubCategSuggested) || op?.is_sub_categ_suggested);

				if (shouldSuggestAndPreselectSubCateg) {
					setSelectedCat(res.results[0]);
					setIsSubCategSuggested(true);
				}
            } else {
                toast.error("Une erreur s'est produite en essayant de suggérer des catégories")
            }
        } catch (error) {
            toast.error("Une erreur s'est produite en essayant de suggérer des catégories")
        }
    }

	const displaySubCategoryName = () => {
		let subCategoryName = '';

		if (selectedCat?.display_name) {
			subCategoryName = selectedCat?.display_name;
		} else if (operation.sub_category?.display_name) {
			subCategoryName = operation.sub_category?.display_name;
		} else {
			return <HelpIcon fontSize="small" />;
		}

		if (operation?.is_sub_categ_suggested) {
			return <>{subCategoryName} <HelpIcon fontSize="small" /></>
		}

		return subCategoryName;
	}

    const handleChangeSuggestedCateg = (sc) => {
        setSelectedCat(sc);
        const token = getItem('bbbToken')
        const url = process.env.REACT_APP_API_URL + '/transaction/' + operation.id
        let formdata = new FormData(document.getElementById('affectation-operation'))
        formdata.append('sub_category_id', sc.id)
        formdata.append('client_id', frontUser.id)
        formdata.append('feed_ai', false)
        formdata.delete('operation_date')

        fetch(url, {
            method: 'POST', 
            body: formdata, 
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => {
            if (response.ok) {
            } else {
                toast.error("L\'affectation de la catégorrie a échoué")
            }
        });

        if (operation?.is_sub_categ_suggested) {
            setIsSubCategSuggested(true);
        }
    }
    const [comment, setComment] = useState(rowToAffect?.comment || '');
    // const [comment, setComment] = useState(operation.comment);


    const handleChange = (e) => {
        setComment(e.target.value);
    }

    const emptyComment = () => {
        setComment('');
    }

    // Keep the popup height resizable
    const [width, setWidth] = useState(1319);
    const [height, setHeight] = useState(540);

    const [maxConstraints, setMaxConstraints] = useState([0, 0]);
    const containerRef = useRef(null);

    useEffect(() => {
        const updateConstraints = () => {
            const containerWidth = containerRef.current.offsetWidth;
            const containerHeight = containerRef.current.offsetHeight;
            
            const maxWidth = containerWidth * 0.9;
            const maxHeight = containerHeight * 0.9;

            setMaxConstraints([maxWidth, maxHeight]);
        };
    
        updateConstraints();
        window.addEventListener('resize', updateConstraints);
    
        return () => {
            window.removeEventListener('resize', updateConstraints);
        };
    }, []);

    const onResize = (event, { size }) => {
        setWidth(size.width);
        setHeight(size.height);
    };

    const documentsTable = useRef(null);
    const [isOCRPopupReprocessOpen, setIsOCRPopupReprocessOpen] = useState(false);
    const [isOCRReprocessConfirmed, setIsOCRReprocessConfirmed] = useState(false);
    const [isOCRReprocessLoading, setIsOCRReprocessLoading] = useState(false);
    const [ocrReprocessedInvoice, setOCRReprocessedInvoice] = useState({});

    const handleOCRReprocessedChanges = (e) => {
        const newValues = {};
        const fieldName = e.target.name;
        const fieldValue = ['montant_ht', 'montant_ttc'].includes(fieldName) ? (parseFloat(e.target.value) || 0) : e.target.value;

        if (fieldName == 'libelle') {
            newValues.libelle = fieldValue;
        } else if (fieldName == 'montant_ht') {
            const amountWithVAT = calculateTTC(fieldValue, ocrReprocessedInvoice.vat);
            newValues.montant_ht = fieldValue;
            newValues.montant_ttc = amountWithVAT;
            newValues.vat_value = amountWithVAT - fieldValue;
        } else if (fieldName == 'montant_ttc') {
            const amount = calculateHT(fieldValue, ocrReprocessedInvoice.vat);
            newValues.montant_ttc = fieldValue;
            newValues.montant_ht = amount;
            newValues.vat_value = fieldValue - amount;
        }

        setOCRReprocessedInvoice((prev) => ({
            ...prev,
            ...newValues
        }));
    }

    const confirmOCRReprocess = async () => {
        setIsOCRReprocessConfirmed(true);
        setIsOCRPopupReprocessOpen(false);
        setIsOCRReprocessLoading(true);

        const toastId = toast.loading('Analyse est en cours, cette opération peut prendre quelques minutes...');

        const token = getItem('bbbToken');
        await fetch(`${process.env.REACT_APP_API_URL}/invoice/${affectation[0].invoice.id}/rescan`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            if (response.ok) {
                response.json().then((res) => {
                    setOCRReprocessedInvoice(res.invoice);
                    toast.update(toastId, {
                        render: "L'analyse est terminée avec succès",
                        type: toast.TYPE.SUCCESS,
                        isLoading: false,
                        autoClose: 3000
                    });
                });
            }

            setIsOCRReprocessLoading(false);
            setIsOCRReprocessConfirmed(false);

            documentsTable.current.scrollIntoView({ behavior: "smooth", block: "center" });
        })
        .catch(error => {
            console.error(error);
            setIsOCRReprocessLoading(false);
            setIsOCRReprocessConfirmed(false);
            toast.update(toastId, {
                render: "Opération échouée",
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 3000
            });
        });
    }

    return (
            <div ref={containerRef} className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
                <Loader active={isLoading}/>
                <ResizableBox
                    id="resizable-element"
                    width={width}
                    height={height}
                    className="m-auto"
                    minConstraints={[1000, 540]}
                    maxConstraints={maxConstraints}
                    onResize={onResize}
                    axis={'y'}
                    resizeHandles={['se']}
                >
                    <div className="w-11/12 xl:w-[1000px] 2xl:w-[1319px] h-full bg-white rounded-lg shadow-xl p-8 z-100 relative overflow-x-auto overflow-y-scroll">
                        <h3 className="font-bold text-xl pb-4">Détails de l'opération</h3>
                        <button className="absolute top-0 right-0 px-2 rounded-xl m-4" onClick={close}><CloseIcon/></button>
                        <section className="flex affect-aperc mb-8">
                            <div className="affect w-full mr-4">
                                <div className="op-label">
                                    <form id="affectation-operation">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>DATE</th>
                                                    <th>LIBELLÉ DE L'OPÉRATION</th>
                                                    <th>Montant HT</th>
                                                    <th>TVA</th>
                                                    <th>Montant TTC</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <input 
                                                            className="input-operation" 
                                                            type="date" 
                                                            name="operation_date"
                                                            defaultValue={operation.operation_date && operation.operation_date.split(' ')[0]} 
                                                        />
                                                    </td>
                                                    <td className="bg-none">
                                                        <div className="flex flex-col-reverse text-black">
                                                            <input 
                                                                name="label"
                                                                className="input-operation" 
                                                                value={operation.label}
                                                                onKeyPress={(e) => { 
                                                                    if (e.key === 'Enter') {
                                                                        e.preventDefault();
                                                                        fetchSuggestedSubCategories();
                                                                    }
                                                                }}
                                                                onChange={e => setOperation({...operation, label: e.target.value})}
                                                            />
                                                            <span 
                                                                style={{ color: isSubCategSuggested || operation.is_sub_categ_suggested ? '#FD5353' : '#818181' }}
                                                                className="truncate" 
                                                                title={selectedCat?.display_name ?? operation.sub_category?.display_name}>
                                                                {displaySubCategoryName()}
                                                            </span>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <input 
                                                            className="input-operation" 
                                                            name="amount" 
                                                            disabled
                                                            onChange={e => updateCalculs('amount', e.target.value)}
                                                            value={operation.amount+' €'} size="8"
                                                        />
                                                    </td>
                                                    <td>
                                                        <span className="input-operation-vente font-normal">{operation.vat}</span>
                                                    </td>

                                                    <td>
                                                        <input 
                                                            className="input-operation" 
                                                            name="amount_with_vat" 
                                                            disabled
                                                            onChange={e => setOperation({ ...operation, amount_with_vat: e.target.value })}
                                                            value={operation.amount_with_vat+' €'} size="8"
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </form>
                                </div>

                                {/*Affiche les affectations sélectionné*/}
                                {openTabDocuments ? "" : affectationSelected.map((affect, index) => {
                                    return <React.Fragment key={affect.id}>
                                        <h3 className="mt-8">Affectation {index + 1}</h3>
                                        <div className="flex justify-between items-center">
                                            <table className="mr-2 affectations-piece">
                                                <thead>
                                                    <tr>
                                                        <th>DATE</th>
                                                        <th>LIBELLÉ DES OPÉRATIONS</th>
                                                        <th>Montant HT</th>
                                                        <th>TVA</th>
                                                        <th>Montant TTC</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><span>{affect.invoice.displayable_operation_date}</span></td>
                                                        <td><span>{affect.invoice.label}</span></td>
                                                        <td><span>{affect.invoice.amount}</span></td>
                                                        <td><span>{affect.invoice.vat}</span></td>
                                                        <td><span>{affect.invoice.amount_with_vat}</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </React.Fragment>
                                })}

                                {!openTabDocuments && 
                                    <div className="flex w-full mt-8">
                                        <div className="w-4/6">
                                            <h3>{operation.sub_category !== null ? "Editer la catégorie" : "Choisir la catégorie"}</h3>
                                            <div className="flex ">
                                                <div className="select">
                                                    <ul className="font-semibold">
                                                        {isLoadingCategSuggestion ?
                                                            <PulseLoader color={'black'} size={24} />
                                                        :   
                                                            suggestedSubCategories.map((sc, index) => (
                                                                <li className="flex text-sm" key={index}>
                                                                    <input 
                                                                        type="radio" 
                                                                        name="subCategoryId" 
                                                                        className="mr-2" 
                                                                        id={`suggestedCateg-${index}`} 
                                                                        value={sc.id} 
                                                                        onChange={() => handleChangeSuggestedCateg(sc)}
                                                                        checked={sc.id == selectedCat?.id}
                                                                    />
                                                                    <label htmlFor={`suggestedCateg-${index}`}>{sc.display_name}</label>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                    <div
                                                        className="w-full text-lilaFonce bg-gris rounded-lg flex items-center justify-center py-2 mt-4 cursor-pointer"
                                                        onClick={showKeywords}
                                                    >
                                                        <h4>Autre catégories</h4>
                                                        <ArrowDropDownIcon/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-2/6">
                                            <div className="flex items-center justify-end">
                                                <p className="text-gray-500 uppercase mr-4">tva</p>
                                                <div className="flex relative justify-between bg-grey rounded-xl py-2 px-2">
                                                    {selectedCat?.is_vat_blocked || (selectedCat === null && operation.sub_category?.is_vat_blocked) ? 
                                                        <select className="bg-grey w-full" disabled>
                                                            <option value={selectedCat?.vat ?? operation.sub_category?.vat}>{selectedCat?.vat ?? operation.sub_category?.vat}%</option>
                                                        </select>
                                                    : 
                                                        <>
                                                            <span className="">{Number(operation.vat)}%</span>
                                                            <ArrowDropDownIcon/>
                                                            <select 
                                                                className="bg-grey absolute w-full h-full opacity-0 top-0 left-0" 
                                                                value={operation.vat}
                                                                onChange={e => updateCalculs('vat', e.target.value)}
                                                            >
                                                                <option value="20.0">20%</option>
                                                                <option value="13.0">13%</option>
                                                                <option value="10.0">10%</option>
                                                                <option value="8.5">8.5%</option>
                                                                <option value="5.5">5.5%</option>
                                                                <option value="2.1">2.1%</option>
                                                                <option value="0.0">0%</option>
                                                            </select>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <div className="items-center mt-4 relative">
                                                <textarea
                                                    name="comment" 
                                                    className="bg-grey p-4 rounded-xl placeholder:italic placeholder:text-slate-400" 
                                                    style={{ resize: 'none', height: '185px', width: '100%', borderBottom: '35px solid rgb(238 238 238)' }}
                                                    placeholder="Écrivez ici votre commentaire..."
                                                    onChange={handleChange}
                                                    value={comment ?? ''}
                                                ></textarea>
                                                <img 
                                                    src={deleteImg} 
                                                    style={{ bottom: '15px', right: '5px' }}
                                                    className="w-6 h-6 absolute cursor-pointer" 
                                                    alt="delete"
                                                    onClick={emptyComment}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/*Affiche l'affectation en bdd*/}
                                {openTabDocuments ? 
                                    "" 
                                : 
                                    affectation.length === 0 ? 
                                        "" 
                                    : 
                                        affectation.map((affect) => (
                                            <React.Fragment key={affect.id}>
                                                <h3 className="mt-8">Document associé</h3>
                                                <div className="flex justify-between items-center">
                                                    <table className="mr-2 affectations-piece" ref={documentsTable}>
                                                        <thead>
                                                            <tr>
                                                                <th>DATE</th>
                                                                <th>LIBELLÉ DU DOCUMENT</th>
                                                                {rowToAffect.type == 'vente' && <th>Référence</th>}
                                                                <th>Montant HT</th>
                                                                <th>TVA</th>
                                                                <th>Montant TTC</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <span>{affect.invoice.displayable_operation_date}</span>
                                                                </td>
                                                                <td>
                                                                    {affect.invoice?.sub_category?.display_name ? 
                                                                        <span className="truncate" title={affect.invoice?.sub_category?.display_name}>
                                                                            {affect.invoice?.sub_category?.display_name}
                                                                        </span> 
                                                                    : 
                                                                        null
                                                                    }
                                                                    <p>{affect.invoice.label}</p>
                                                                </td>
                                                                {rowToAffect.type == 'vente' && 
                                                                    <td>
                                                                        <span>{affect.invoice.reference}</span>
                                                                    </td>
                                                                }
                                                                <td>
                                                                    <span>{formatCurrency(affect.invoice.amount)}</span>
                                                                </td>
                                                                <td><span>{affect.invoice.vat} %</span></td>
                                                                <td><span>{formatCurrency(affect.invoice.amount_with_vat)}</span></td>
                                                            </tr>
                                                        </tbody>
                                                        {Object.keys(ocrReprocessedInvoice).length > 0 && 
                                                            <tfoot className="ocr-reprocessed">
                                                                <tr>
                                                                    <td>{formatDate(ocrReprocessedInvoice.date)}</td>
                                                                    <td>
                                                                        <input 
                                                                            type="text" 
                                                                            name="libelle" 
                                                                            value={ocrReprocessedInvoice.libelle} 
                                                                            onChange={(e) => handleOCRReprocessedChanges(e)}
                                                                        />
                                                                    </td>
                                                                    {rowToAffect.type == 'vente' && 
                                                                        <td>
                                                                            <span>{affect.invoice.reference}</span>
                                                                        </td>
                                                                    }
                                                                    <td>
                                                                        <input
                                                                            name="montant_ht"
                                                                            value={ocrReprocessedInvoice.montant_ht}
                                                                            onChange={(e) => handleOCRReprocessedChanges(e)}
                                                                        />
                                                                    </td>
                                                                    <td>{ocrReprocessedInvoice.vat} %</td>
                                                                    <td>
                                                                        <input
                                                                            name="montant_ttc"
                                                                            value={ocrReprocessedInvoice.montant_ttc}
                                                                            onChange={(e) => handleOCRReprocessedChanges(e)}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </tfoot>
                                                        }
                                                    </table>
                                                    {/*<div className="w-12">*/}
                                                    {/*    <button className="bg-redCorail p-2 rounded-lg text-white" onClick={() => deleteBDDAffectationInvoice(affect)}>*/}
                                                    {/*        <DeleteOutline/>*/}
                                                    {/*    </button>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </React.Fragment>
                                        ))
                                }

                            </div>

                            {/*Section d'upload*/}
                            <div className="flex flex-col justify-start items-end w-2/6 min-w-[270px]">
                                <div className="text-black bg-gris p-4 rounded-lg flex flex-col h-[319px] w-full sidebar:h-[392px] overflow-hidden">
                                    <div className="flex justify-between">
                                        <h2 
                                            className="text-center text-md pb-4 truncate font-bold cursor-default" 
                                            title={nameFile !== "" ? nameFile : 'Associer un document'}
                                        >
                                            {nameFile !== '' ? nameFile : 'Associer un document'}
                                        </h2>
                                        {nameFile !== "" && extensionFile !== "" ? 
                                            <div className="cursor-pointer w-6 h-6 shrink-0" onClick={() => delImage()}>
                                                <img src={deleteSmall} className="w-6 h-6" alt="delete"/>
                                            </div> 
                                        : 
                                            null
                                        }
                                    </div>

                                    <div id="upload-fichier" className={`flex justify-center items-center w-full h-full ${nameFile !== "" ? "" : " border-dashed border-2 border-grisDashed "} rounded-xl mx-auto relative`} {...getRootProps()}>
                                        {nameFile !== "" && extensionFile !== "" ? 
                                            <>
                                                <embed 
                                                    className="object-cover w-full h-full rounded-xl"
                                                    id="balise-img-modal-operation"
                                                    alt="" 
                                                    type={extensionFile === "pdf" ? "application/pdf" : "image/" + extensionFile}
                                                />
                                            </>

                                            : 
                                                affectation.length === 0 ? 
                                                    <div className="upload" onClick={() => setPopupSelectImport(true)}>
                                                        <div className="flex flex-col justify-center items-center text-center h-full" onDrop={(e) => dragNDrop(e)}>
                                                            <div>
                                                                <button type="button" className="svg-upload"><BackupIcon/></button>
                                                            </div>
                                                            <div className="text" >
                                                                <h5>Charger un fichier</h5>
                                                                <h6>PDF, DOC, PPT, JPG, PNG</h6>
                                                            </div>

                                                            <label className="relative my-2 inline-flex items-center cursor-pointer" onClick={(e)=> {e.stopPropagation()}}>
                                                                <input type="checkbox" checked={toggleUseOcr} className="sr-only peer shadow-inner" onChange={()=> {setToggleUseOcr(!toggleUseOcr)}}/>
                                                                <div
                                                                    className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:shadow-inner rounded-full peer dark:bg-[#EFEFEF] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#5A45AA]"></div>
                                                                <span className="ms-3 text-sm font-medium text-[#5A45AA]">OCR</span>
                                                            </label>
                                                        </div>
                                                    </div> 
                                                : 
                                                    <div className="upload">
                                                        <div className="flex flex-col justify-center text-center">
                                                            <div className="text">
                                                                <p>Cette facture n'a pas de pièce jointe.</p>
                                                                <p>Vous pouvez lui en attribuer une dans le menu Mes documents.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                        }
                                        <form action="" id="send-operation">
                                            <input 
                                                type="file" 
                                                name="invoice_file" 
                                                id="invoice-operation-input"  
                                                {...getInputProps()}
                                                className="hidden" 
                                                onChange={(e) => changeInput(e)}
                                            />
                                        </form>
                                    </div>

                                    {nameFile !== "" ?
                                        <div className="cursor-pointer text-right mt-2">
                                            <a href="#" onClick={openFile}><OpenInNewIcon /></a>
                                        </div>
                                    : 
                                        null
                                    }

                                    {popupSelectImport && <PopupSelectImport closePopup={closePopup} openDocuments={openDocuments}/>}
                                </div>

                                <div className="w-full text-left">
                                    <label className="relative my-2 inline-flex items-center cursor-pointer" onClick={(e)=> {e.stopPropagation()}}>
                                        <input 
                                            type="checkbox" 
                                            className="sr-only peer shadow-inner" 
                                            checked={isOCRReprocessConfirmed} 
                                            onChange={()=> setIsOCRPopupReprocessOpen(!isOCRPopupReprocessOpen)} 
                                        />
                                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:shadow-inner rounded-full peer dark:bg-[#EFEFEF] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#5A45AA]"></div>
                                        <span className="ms-3 mr-3 text-sm font-medium text-[#5A45AA]">
                                            OCR 
                                        </span>
                                        {isOCRReprocessLoading ? <PulseLoader color={'purple'} size={8} /> : null}
                                    </label>
                                </div>

                                <button 
                                    type="button"
                                    className="p-2 bg-greenSuccess rounded-2xl text-white px-8 mt-4" 
                                    onClick={(e) => handleSubmit(e)}
                                >
                                    {isSubmissionLoading ? <PulseLoader color={'white'} size={10} /> : 'Valider'}
                                </button>

                                {isOCRPopupReprocessOpen && 
                                    <PopupOCRReprocess 
                                        close={() => setIsOCRPopupReprocessOpen(false)}
                                        confirmOCRReprocess={() => confirmOCRReprocess(true)}
                                    />
                                }
                            </div>
                        </section>

                        {openTabDocuments && 
                            <section className="flex justify-between gap-4">
                                {/*sidebar*/}
                                <section className="fill-black">
                                    <div className="close-btn">
                                        {/*<CloseIcon onClick={this.toggleMenu} />*/}
                                    </div>

                                    <p className="font-bold text-black text-sm">Menu</p>
                                    <div className="mb-2 rounded-xl mt-2 pick-input">
                                        <div className="w-full flex">
                                            <DateRangeIcon/>
                                            <h4 className="font-normal">Période Affichée</h4>
                                        </div>
                                        <div id="date-operation-filter">
                                            <div className="flex text-xs items-center dates-filtre border bg-grisClaire rounded-lg p-2 font-bold mt-2">
                                                <DateRangeIcon/>
                                                <span>Du</span>
                                                <input 
                                                    type="date" 
                                                    id="date-start-documents" 
                                                    className="bg-none-input" 
                                                    value={startDateDocuments}
                                                    onChange={(e) => changeDate(e)} 
                                                />
                                            </div>
                                            <div className="flex text-xs items-center dates-filtre border bg-grisClaire rounded-lg p-2 font-bold mt-2">
                                                <DateRangeIcon/>
                                                <span>Au</span>
                                                <input 
                                                    type="date" 
                                                    id="date-end-documents" 
                                                    className="bg-none-input" 
                                                    value={endDateDocument}
                                                    onChange={(e) => changeDate(e)} 
                                                />
                                            </div>
                                        </div>
                                        <form className="mb-2 mt-2 w-full flex input-field-popup ml-0 justify-between items-center">
                                            <input type="month" name="periodeaffiche"/>
                                            <ArrowDropDownIcon size="small" className="pointer-events-none"/>
                                        </form>
                                    </div>
                                    <div className="mb-2 mt-2 pick-input">
                                        <div className="w-full flex">
                                            <AccountBalanceWalletIcon/>
                                            <h4>Compte bancaire</h4>
                                        </div>
                                        <form className="mb-2 mt-2 w-full flex input-field-popup ml-0 justify-between items-center">
                                            <select className="bg-transparent">
                                                <option>Caisse épargne</option>
                                                <option>1</option>
                                                <option>1</option>
                                                <option>1</option>
                                            </select>
                                            <ArrowDropDownIcon size="small" className="pointer-events-none"/>
                                        </form>
                                    </div>
                                </section>
                                {/*Tableau des documents*/}
                                {openTabDocuments && 
                                    <div className="w-full">
                                        <button className=" float-right" onClick={() => setOpenTabDocuments(false)}><CloseIcon/></button>
                                        <div className="overflow-auto rounded-lg w-full">
                                            <table className="w-full">
                                                <thead className="bgPurpuleCustom text-white">
                                                <tr>
                                                    <th className="w-12 p-3 text-sm font-semibold tracking-wide text-left whitespace-nowrap">Date  <ArrowDropDownIcon onClick={()=> {setSortTerms("operation_date"); setSortOrder("DESC")}}/> <ArrowDropUpIcon  onClick={()=> {setSortTerms("operation_date"); setSortOrder("ASC")}}/></th>
                                                    <th className="w-12 p-3 text-sm font-semibold tracking-wide text-left">Référence</th>
                                                    <th className="p-3 text-sm font-semibold tracking-wide text-left">Label</th>
                                                    <th className="w-12 p-3 text-sm font-semibold tracking-wide text-left whitespace-nowrap">Mont.HT  <ArrowDropDownIcon onClick={()=> {setSortTerms("amount"); setSortOrder("DESC")}}/> <ArrowDropUpIcon  onClick={()=> {setSortTerms("amount"); setSortOrder("ASC")}}/></th>
                                                    <th className="w-12 p-3 text-sm font-semibold tracking-wide text-left whitespace-nowrap">TVA  <ArrowDropDownIcon onClick={()=> {setSortTerms("vat"); setSortOrder("DESC")}}/> <ArrowDropUpIcon  onClick={()=> {setSortTerms("vat"); setSortOrder("ASC")}}/></th>
                                                    <th className="w-12 p-3 text-sm font-semibold tracking-wide text-left whitespace-nowrap">Mont.TTC  <ArrowDropDownIcon onClick={()=> {setSortTerms("amountWithVat"); setSortOrder("DESC")}}/> <ArrowDropUpIcon  onClick={()=> {setSortTerms("amountWithVat"); setSortOrder("ASC")}}/></th>
                                                    <th className="p-4 text-sm font-semibold tracking-wide text-left"></th>
                                                </tr>
                                                </thead>
                                                {documents.data.length === 0 ? 
                                                    <tbody>
                                                        <tr>
                                                            <td className="p-4" colSpan="6">Vous n'avez aucuns documents à selectionner</td>
                                                        </tr>
                                                    </tbody> 
                                                :
                                                    <tbody className="divide-y divide-gray-200">
                                                        {documents?.data.map((document, index) => {
                                                            return (
                                                                <tr key={document.id} className={`${index % 2 === 0 ? "bg-grisbleu" : ""}`}>
                                                                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{document.displayable_operation_date}</td>
                                                                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{document.reference}</td>
                                                                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{document.label}</td>
                                                                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{document.amount}</td>
                                                                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{document.vat}</td>
                                                                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{document.amount_with_vat}</td>
                                                                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                                                        <button 
                                                                            className="p-2 rounded-xl bg-primary text-white" 
                                                                            onClick={() => sendAffectation(document)}
                                                                        >
                                                                            {isAffectationLoading == document.id ? <PulseLoader color={'white'} size={5} /> : 'Choisir'}
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                }
                                            </table>
                                        </div>
                                        <div>
                                        <div className="flex justify-between">
                                            <div className="flex items-center font-semibold">
                                                <p>Afficher:</p>
                                                <div className="relative border border-gray-300 rounded-lg min-w-[84px] mx-2 px-4 py-2">
                                                    <select id="select-op-per-page" className="bg-white w-full rounded-lg" value={currentPerPage ?? 100} onChange={changePerPage}>
                                                        <option value="10">10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                    <ArrowDropDownIcon pointerEvents="none" className="text-gray-400 right-2 top-1/2 -translate-y-1/2 absolute"/>
                                                </div>
                                                <p>par page</p>
                                            </div>
                                            <Pagination data={documents} paginate={paginate} />
                                        </div>
                                        </div>
                                    </div>
                                }
                            </section>
                        }

                        <section className="search-pop-up hide-pop-up">
                            <div className="layer">
                                <CloseIcon onClick={hideSearchPopUp}/>
                                <div className="items">
                                    <div className="flex relative input-field">
                                        <input 
                                            type="text" 
                                            name="search" 
                                            placeholder="Recherche..." 
                                            onChange={(e)=> setSearchTerms(e.target.value)}
                                        />
                                        <SearchIcon/>
                                    </div>
                                    <div id="keywords" className="keywords">
                                        {categories.map((category) => 
                                            <ModalCategory 
                                                key={category.id} 
                                                category={category}
                                                subCats={category.sub_categories} 
                                                id={operation.id}
                                                setCat={setCat} 
                                                hideSearchPopUp={hideSearchPopUp} 
                                                info="operation"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </section>

                        {isAmountRestrictionPopupDisplayed ? 
                            <div className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
                                <div className="sidebar:w-[65.8rem] mx-auto bg-white rounded-lg shadow-xl sidebar:p-[3rem] p-4 w-2/3 z-100">
                                    <button className="float-right pt-0 text-[#717579]" onClick={() => setIsAmountRestrictionPopupDisplayed(false)}><CloseIcon/></button>
                                    <div className="parent-table w-full mt-[2.2rem]">
                                        <div className="flex text-black bg-grisClaire p-8 mt-8 rounded-xl text-base sidebar:text-[24px]">
                                            <div className="mr-4"><p className="font-bold">Attention:</p></div>
                                            <div>
                                                <p>Le montant du document à associer est inferieur au montant de l'opération bancaire. Vous devez dérouler l'opération bancaire en plusieurs sous-opérations pour permettre l’association de ce document.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-end mt-8">
                                        <button className="bg-redCorail text-white rounded-xl p-2" type="button" onClick={() => setIsAmountRestrictionPopupDisplayed(false)}>
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        :
                            null
                        }
                    </div>
                </ResizableBox>
            </div>
    )

}
export default PopupImportOpBancaire