import React from "react";

import CloseIcon from "@material-ui/icons/Close";
import RegistrationAdminN from "../Mon BBB/registrationAdminN";

const PopupRegistrationAdmin = ({close}) => {

    return (
        <div
            className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
            <div className="w-2/3 h-screen-5/6 overflow-y-scroll 2xl:overflow-y-hidden mx-auto bg-white rounded-lg shadow-xl p-8 z-100">
                <button className="float-right pt-0" onClick={close}><CloseIcon/></button>
                <div className="parent-table w-full">
                    <RegistrationAdminN close={close}/>
                </div>

            </div>
        </div>
    )
}
export default PopupRegistrationAdmin