import React, {forwardRef, useImperativeHandle, useState} from 'react'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MonthTable from '../client/HelperComponent/MonthTable';

const SimusTable = forwardRef((props, ref) => {
    let keyVal = 0
    const object = props.data
    const {onSave} = props
    const {type} = props
    const {display_name} = props

    let lines = []
    let table_header = {
        amount: 0,
        vat_amount: 0,
        grand_amount: 0
    }
    for (const parentKey in object.data_by_categories)
    {
        let line = {
            id: performance.now().toString().replace('.', 7)*Math.random()*Math.random(),
            key: performance.now().toString().replace('.', 7)*Math.random()*Math.random(),
            date: parentKey,
            subRows: [],
            subRowsOpened: false,
            vat: 0,
            amount: 0,
            grand_amount: 0,
            vat_amount: 0
        }
        let count = 0
        let total_vat = 0
        let total_vat_amount = 0
        let total_amount = 0
        let total_grand_amount = 0
        for (const key in object.data_by_categories[parentKey])
        {
            let subRow = {
                id: performance.now().toString().replace('.', 7)*Math.random()*Math.random(),
                key: performance.now().toString().replace('.', 7)*Math.random()*Math.random(),
                date: key,
                monthTableOpened: false,
                vat: object.data_by_categories[parentKey][key].total.vat,
                amount: object.data_by_categories[parentKey][key].total.amount,
                grand_amount: object.data_by_categories[parentKey][key].total.grand_amount,
                vat_amount: object.data_by_categories[parentKey][key].total.vat_amount,
                monthly: {...object.data_by_categories[parentKey][key].monthly},
                subRowsOpened: false,
                editVat: false,
                editAmount: false,
            }
            line.subRows.push(subRow)
            count += 1
            total_vat += subRow.vat
            total_vat_amount += subRow.vat_amount
            total_amount += subRow.amount
            total_grand_amount += subRow.grand_amount
            // console.log(object.data_by_categories[parentKey][key].total.vat)
        }
        if (count != 0)
            line = {
                ...line,
                vat: total_vat / count,
                amount: total_amount / count,
                grand_amount: total_grand_amount / count,
                vat_amount: total_vat_amount / count
            }
        lines.push(line)
    }
    
    const [data, setData] = useState(lines)

    

    const setMonthlyData = (id, monthlyData) => {

        let tmpData = [...data]

        for (let key in tmpData) {
            for(let childKey in tmpData[key].subRows) {
                if (tmpData[key].subRows[childKey].id === id) {
                    tmpData[key].subRows[childKey].monthly = monthlyData
                    setData(tmpData)
                    return ;
                }
            }
        }
    }

    const toggleMonthTable = (id) => {

        // console.log(data.find(row => row.id == id))
        const tmpData = [...data]
        tmpData.map((row) => {
            row.subRows.map((subRow) => {
                if (subRow.id === id)
                {
                    subRow.monthTableOpened = !subRow.monthTableOpened
                    setData(tmpData)
                    return 
                }
            })
        })
    }

    const toggleSubRows = (id) => {
        //trouve l'objet de la row
        const updatedRow = data.find(row => row.id === id)
        //inverse prop subRowsOpened (boolean)
        updatedRow.subRowsOpened = !updatedRow.subRowsOpened
        // copie du state et modifie le bon objet
        const newData = data.map((row) =>
            row.id === id ? updatedRow : row
        )
        // MAJ du state
        setData(newData)
    }

    useImperativeHandle(ref, () => ({
        toggleCheckbox(checked) {
            const updatedRows = data.map(row => {
                row.subRowsOpened = checked
                return row
            })
            setData(updatedRows)
        }
    }))

    const editSubRowInput = (id, el, event = 'click') => {
        let tmp = [...data]

        tmp.forEach((row, index) => {
            let target = row.subRows.find(subRow => subRow.id == id)
            if (target && !(event == 'click' && target[el]))
            {
                target[el] = !target[el]
                row.subRows.map((obj) => {
                    obj = (obj.id == id) ? target : obj
                    setData(tmp)
                    return ;
                })   
            }
        })
    }

    const calculateEverything = (newData, doNotCalculate = []) => {
        console.log("\n\n---- START CALCULATE EVERYTHING ----")
        console.log(newData)
        newData.forEach((row, index) => {
            let subRowAmount = 0
            let subRowVat = 0
            let count = 0
            
            row.subRows.forEach((subRow, index) => {
                if (!(doNotCalculate.includes('amount')))
                {
                    let amount = 0.0
                    for (let obj in subRow.monthly) {
                        amount += parseFloat(subRow.monthly[obj].amount)               
                    }
                    subRow.amount = parseFloat(amount.toFixed(2))
                }
                subRow.vat_amount = parseFloat((subRow.amount * (subRow.vat / 100.00)).toFixed(2))
                subRow.grand_amount = parseFloat(subRow.amount) + parseFloat(subRow.vat_amount)
                subRowAmount += parseFloat(subRow.amount)
                subRowVat += parseFloat(subRow.vat)
                count++;
            })
            row.amount = parseFloat((subRowAmount / count).toFixed(2))
            row.vat = parseFloat((subRowVat / count).toFixed(2))
            row.vat_amount = parseFloat(row.amount * ((row.vat / 100.00)).toFixed(2))
            row.grand_amount = row.vat_amount + row.amount
        })
        console.log("---- END CALCULATE EVERYTHING ----\n\n")
        return newData
    }

    const splitAmountOnMonth = (arr, key) => {
        arr.map((row) => {
            row.subRows.map(subRow => {
                if (subRow.key == key)
                {
                    let amountByMonth = subRow.amount / 12
                    for (let mKey in subRow.monthly) {
                        subRow.monthly[mKey].amount = parseFloat(amountByMonth.toFixed(2))
                        subRow.monthly[mKey].vat_amount = parseFloat((subRow.monthly[mKey].amount * (subRow.monthly[mKey].vat / 100.00)).toFixed(2))
                        subRow.monthly[mKey].grand_amount = parseFloat((subRow.monthly[mKey].amount + subRow.monthly[mKey].vat_amount).toFixed(2))
                        // console.log(subRow.monthly[mKey])
                    }
                    return arr;
                }
            })
        })
        return arr;
    }

    const onChangeEvent = (e, key, el) => {
        let tmp = [...data]

        tmp.forEach((row, index) => {
            let target = row.subRows.find(subRow => subRow.key == key)
            
            if (target)
            {
                target[el] = parseFloat(e.target.value).toFixed(0)
                target[el] = isNaN(target[el]) ? 0 : target[el]
                
                row.subRows.map((obj) => {
                    obj = (obj.key == obj.key) ? target : obj
                    tmp = splitAmountOnMonth(tmp, key)
                    tmp = calculateEverything(tmp, ['amount'])
                    setData(tmp)
                    return ;
                })
            }
        })
    }

    const editableInput = (key, id, value, el, targetedEl) => {
        return <input
                    className="sub-cat-input"
                    value={value}
                    autoFocus
                    onChange={(e) => {
                        onChangeEvent(e, key, targetedEl)
                    }}
                    onBlur={() => {
                        onBlurEvent(id, el)
                        onSave(data, type)
                    }} />
    }

    const onBlurEvent = (id, el) => {
        console.log("Blur event called")
        editSubRowInput(id, el, 'blur')
    }

    let rowFunc = (rows, isSubRow, monthTableClass) => {
        let ret = []
        rows.forEach(subRow => {
            keyVal++;
            subRow.key = keyVal;
            if (!isSubRow)
            {
                ret.push(
                        <tr key={keyVal.toString()} className="border-b border-gray-200 main-line" data-key={keyVal.toString()}>
                            <td className="py-3 pl-6 text-left whitespace-nowrap">
                                <span className="default-text">{subRow.date}</span>
                            </td>
                            <td className="py-3 pl-6 text-left align-center">
                                <span>{subRow.vat}%</span>
                            </td>

                            <td className="py-3 pl-6 text-left">
                                <span>{subRow.amount.toFixed(2)}</span>
                            </td>
                            <td className="py-3 pl-6 text-left">
                                <span>{subRow.vat_amount.toFixed(2)}</span>
                            </td>
                            <td className="py-3 px-6">
                                <span>{subRow.grand_amount.toFixed(2)}</span>
                            </td>
                            <td className="text-center">
                                { subRow.subRowsOpened ? <button><ArrowDropUpIcon className={"main-arrow " + (type == 'depense' ? "orange" : "")} onClick={() => toggleSubRows(subRow.id)}/></button> : <button><ArrowDropDownIcon className={"main-arrow " + (type == 'depense' ? "orange" : "")} onClick={() => toggleSubRows(subRow.id)}/></button> }
                            </td>
                        </tr>
                )
            } else {
                ret.push(
                    <>
                        <tr key={keyVal.toString()} className="border-b border-gray-200 sub-row" data-key={keyVal.toString()}>
                            <td className="py-3 pl-6 text-left whitespace-nowrap sub-title">
                                <span className="default-text">{subRow.date}</span>
                            </td>
                            <td className="py-3 pl-6 text-left align-center">
                                <span onClick={() => {editSubRowInput(subRow.id, 'editVat')}}>{subRow.editVat ? editableInput(subRow.key, subRow.id, subRow.vat, 'editVat', 'vat') : subRow.vat}%</span>
                            </td>
                            <td className="py-3 pl-6 text-left">
                                <span onClick={() => {editSubRowInput(subRow.id, 'editAmount')}}>{subRow.editAmount ? editableInput(subRow.key,subRow.id, subRow.amount, 'editAmount', 'amount')  : subRow.amount}</span>
                            </td>
                            <td className="py-3 pl-6 text-left">
                                <span>{subRow.vat_amount.toFixed(2)}</span>
                            </td>
                            <td className="py-3 px-6 text-left">
                                <div className="flex">
                                    <span>{subRow.grand_amount.toFixed(2)}</span>
                                </div>
                            </td>
                            <td className="text-center">
                                { subRow.monthTableOpened ? <button><ArrowDropUpIcon onClick={() => toggleMonthTable(subRow.id)}/></button> : <button><ArrowDropDownIcon onClick={() => toggleMonthTable(subRow.id)}/></button> }
                            </td>
                        </tr>
                        {subRow.monthTableOpened ? 
                        <tr className={"monthTable " + monthTableClass}>
                            <td colSpan="100%" className="p-0">
                                <MonthTable
                                    onBlur={() => {
                                        const tmp = calculateEverything(data)
                                        onSave(tmp, type)
                                    }}
                                    setData={setMonthlyData} id={subRow.id}
                                    data={subRow.monthly}
                                    startAt={object.start_at} />
                            </td>
                        </tr> : 
                        ""}
                        
                    </>
                )
            }
        })
        return ret
    }

    let renderTable = () => {
        let res = data
        let ret = []
        let className = ''
        let monthClassName = ''
        data.forEach((row, index) => {
            keyVal++;
            className = index%2 === 0 ? "bg-mes-simus-" + type + 's' + "-body" : ""
            if (type == 'recette')
                monthClassName = index%2 === 0 ? "pinkTable" : ""
            else if (type == 'depense')
                monthClassName = index%2 === 0 ? "orangeTable" : ""
            ret.push(<tbody className={(className)} key={keyVal.toString()}>{rowFunc([row], false, monthClassName)}</tbody>)
            keyVal++;
            ret.push(<tbody className={"sub-rows "+ (row.subRowsOpened ? "" : "hidden ") + className} key={keyVal.toString()}>{rowFunc(row.subRows, true, monthClassName)}</tbody>)
        })
        return (ret)
    }

    data.forEach((item, index) => {
        table_header.vat_amount += item.vat_amount
        table_header.amount += item.amount
        table_header.grand_amount += item.grand_amount
    })

    return (
        <React.Fragment>
            <div className="w-full content-table client-info">
            <section className={type + 's' + " flex justify-center"}>
                <div className="parent-table w-full">
                    <table className="w-full table-auto overflow-hidden min-w-full">
                        <thead>
                        <tr className="capitalize text-sm leading-normal">
                            <th className="pl-6 text-left">
                                <div className="text items-center">
                                    <span className="label">{display_name}</span>
                                </div>
                            </th>
                            <th className="pl-6 text-left">
                                <div className="text items-center">
                                    <span className="label">TVA</span>
                                </div>
                            </th>

                            <th className="pl-6 text-left none-mobile hasAmount">
                                <div className="text items-center">
                                    <span className="label">Montant HT</span>
                                    <span className="amount">{table_header.amount.toFixed(2)}</span>
                                </div>
                            </th>

                            <th className="pl-6 text-left none-mobile hasAmount">
                                <div className="text items-center">
                                    <span className="label">Montant TVA</span>
                                    <span className="amount">{table_header.vat_amount.toFixed(2)}</span>
                                </div>
                            </th>
                            <th className="pl-6 text-left none-mobile hasAmount">
                                <div className="text items-center">
                                    <span className="label">Montant TTC</span>
                                    <span className="amount">{table_header.grand_amount.toFixed(2)}</span>
                                </div>
                            </th>
                            <th className="pl-6 text-left none-mobile">
                            </th>

                        </tr>
                        </thead>
                        {renderTable()}
                    </table>
                </div>
            </section>
            </div>

        </React.Fragment>
    )
})

export default SimusTable