import React, {useEffect, useState} from "react";
import {getItem} from "../../services/LocaleStorage";
import {toast} from "react-toastify";
import http from "../../services/Http";
import {useSelector} from "react-redux";
import {getRealUser, getUser} from "../../app/user";
import CloseIcon from "@material-ui/icons/Close";
import {getBankAccount, getDatesFilter} from "../../app/filters";

const ImportationBancaire = ({closeModal, fetchOperationsBancaires}) => {
    const [bankAccount, setBankAccount] = useState([]);
    const user = useSelector(getUser)

    const declenchInput = () => {
        document.getElementById('declenche-input').click()
    }

    useEffect(() => {
        fetchBankAccount()
    }, []);

    const fetchBankAccount = () => {
        const token = getItem('bbbToken')

        let endpoint = '/bank_accounts?client_id=' + user.id
        http.get(endpoint, {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
            .then((data) => {
                setBankAccount(data)
                console.log(data)

            })
            .catch((error) => {

            })
        // }

    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const token = getItem('bbbToken')

        const formData = new FormData(e.target)
        formData.append('client_id', http.getClientIdFromUrl() ? http.getClientIdFromUrl() : user.id)


        const authUrl = process.env.REACT_APP_API_URL + '/transaction/import'

        //Envoie du fichier relevé bancaire
        fetch(authUrl, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            // .then(response => response.json())
            .then(async res => {
                if ( res.status === 202) {
                    toast.success('La transaction a été ajouté avec succès')
                    fetchOperationsBancaires()
                }
                if(res.status === 400) {
                    const error = ( await res.json()).message
                    toast.error(error)
                }
                closeModal()

            })
            .catch(err => {
                // TODO: catch update
                toast.error(err.message)
                closeModal()
            })
    }

    return (
        <div className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center">
            <div className="w-2/3 mx-auto bg-white rounded-lg shadow-xl p-8 z-100">
                <button className="float-right" onClick={closeModal}><CloseIcon/></button>
                <div className="w-full m-8">
                    <h1 className="text-black font-bold mb-8">Importation de relevé bancaire</h1>
                    <form className="flex" action="" id="send-importation-bancaire" onSubmit={handleSubmit}>
                        <div className="bg-gris w-3/6 mr-8 p-4 rounded-xl">
                            <div className="border-dashed border-2 border-gray rounded-xl h-full p-8 flex flex-col justify-center">
                                <h2 className="font-semibold compte-bancaire text-center">Compte bancaire</h2>
                                <select name="bank_id" className="rounded-lg p-2 font-semibold select-violet w-3/6 mx-auto">
                                    {bankAccount.map((account) => {
                                        return <option key={account.id} value={account.id}>Caisse épargne {account.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="bg-gris w-3/6 mr-8 p-4 rounded-xl" onClick={declenchInput}>
                            <div className="border-dashed border-2 border-gray rounded-xl h-full p-8 flex items-center justify-center">

                                <input type="file" name="transactions_file" id="declenche-input" className="hidden"/>

                                <div className="flex flex-col">
                                    <p className="font-semibold text-black compte-bancaire">Faites glisser la pièce ou cliquer</p>
                                    <p className="font-semibold text-black text-center"> pour importer</p>
                                </div>
                            </div>
                        </div>
                    </form>
                    <button type="submit"
                            className="bg-greenSuccess text-xl font-semibold px-5 py-3 float-right mr-8 mt-8 rounded-xl text-white"
                            form="send-importation-bancaire">OK
                    </button>
                </div>
            </div>
        </div>
    );
}
export default ImportationBancaire