import '../../assets/style/dashboard.css'
import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {getItem} from "../../services/LocaleStorage";
import {toast} from "react-toastify";

const CreateAccount = () => {

    const [error, setError] = useState('');
    const history = useHistory()

    const [data, setData] = useState([]);
    const [emptyInput, setEmptyInput] = useState(true);

    useEffect(() => {
        setError(error)
    }, []);

    const handleChange = () => {
        let check = false
        document.getElementById("create-account").querySelectorAll("input").forEach(input => {
            if(input.type === "password" || input.type === "text" || input.type === "email"){
                if (input.value === "") {
                    check = true
                }
            }else if(input.type === "checkbox"){
                if (!input.checked){
                    check = true
                }
            }
        })
        setEmptyInput(check)


    }

    const handleSubmit = () => {

        if (emptyInput) {
            setError('Veuillez saisir le formulaire au complet')
            return
        }
        if (document.getElementById('password').value !== document.getElementById('conf-password').value) {
            setError('Les mots de passe ne correspondent pas')
            return
        }

        const authUrl = process.env.REACT_APP_API_URL + '/register'

        const formdata = new FormData(document.getElementById('create-account'))

        formdata.append("status", "OFFRE");
        formdata.append("role", "client");
        formdata.append("created_at", new Date().toISOString().split('T')[0]);
        formdata.append("creation_date", new Date().toISOString().split('T')[0]);
        formdata.append('reference', 'REFF123')

        //Enregistrer un client depuis espace Register
        fetch(authUrl, {
            method: 'POST',
            'Access-Control-Allow-Credentials': true,
            headers: {
                // 'Authorization': 'Bearer ' + token
            },
            body: formdata,
        })
            .then(async response => {


                if (response.ok) {
                    response.json().then(response => {
                        history.push('/dashboard')
                        setData(response)
                    })
                } else {
                    toast.error("L'enregistrement du nouveau user a échoué")
                }
            })
    }

    return (

        <div className="m-auto text-center md:w-1/3 lg:w-1/3 lg:pt-10 2xl:pt-28" id="registration">
            <h1 className="font-semibold text-black mb-8">Je me connecte à mon espace <span className="text-lilaFonce font-bold">Blabliblou</span></h1>
            <form className="text-sm 2xl:text-lg" id="create-account">

                <div className="m-2 border w-full rounded-xl flex">
                    <input className="w-full text-center p-2 h-14 rounded-2xl bg-violetClaire" type="text" name="display_name" placeholder="Nom"
                           onChange={handleChange}/>
                </div>
                <span className="error text-red-500 font-semibold" id="display_name-error"/>

                <div className="m-2 border w-full rounded-xl flex">
                    <input className="w-full text-center p-2 h-14 rounded-2xl bg-violetClaire" type="text" name="first_name" placeholder="Prénom"
                           onChange={handleChange}/>
                </div>
                <span className="error text-red-500 font-semibold" id="first_name-error"/>

                <div className="m-2 border w-full rounded-xl flex">
                    <input className="w-full text-center p-2 h-14 rounded-2xl bg-violetClaire" type="email" name="email" placeholder="Mon Mail de connexion"
                           onChange={handleChange}/>
                </div>
                <span className="error text-red-500 font-semibold" id="email-error"/>


                <div className="m-2 border w-full rounded-xl flex">
                    <input className="w-full text-center p-2 h-14 rounded-2xl bg-violetClaire" type="password" id="password" name="password" placeholder="Mon mot de passe"
                           onChange={handleChange}/>
                </div>
                <span className="error text-red-500 font-semibold" id="password-error"/>

                <div className="m-2 border w-full rounded-xl flex">
                    <input type="password" name="repeat-password" id="conf-password" className="w-full text-center p-2 h-14 rounded-2xl bg-violetClaire"
                           placeholder="Répéter le mot de passe" onChange={handleChange}/>
                </div>
                <span className="error text-red-500 font-semibold" id="repeat-password-error"/>
                <span className="error text-red-500 font-semibold" id="repeat-password-error">{error}</span>

                <div className="flex w-full">
                    <input type="checkbox" className="black mr-2" onChange={handleChange}/>
                    <span className="text-black">Je suis d'accord avec les termes et les conditions</span>
                </div>


                <div className="flex flex-col w-full">
                    <div className="m-2 w-full">
                        <div className={`w-full rounded-xl p-2 w-full border border-lilaFonce ${emptyInput ? ' text-lilaFonce' : 'text-white bg-lilaFonce'}`}>
                            <button type="button" className="font-semibold" onClick={handleSubmit}>Créer un compte</button>
                        </div>
                    </div>
                    <div className="m-2 w-full">
                        <div className="w-full text-lilaFonce rounded-xl font-semibold p-2 w-full border border-lilaFonce">
                            <Link to="/dashboard">J'ai déjà un compte</Link>
                        </div>
                    </div>
                </div>

            </form>
        </div>

    )
}
export default CreateAccount