import CloseIcon from '@material-ui/icons/Close';

const AlertPopup = ({ message, confirm, cancel, close }) => {
  return (
    <div className="w-full h-full fixed top-0 left-0 flex text-black z-[70] bg-opacity-50 bg-black items-center desktop-operation">
		<div className="w-2/3 mx-auto bg-white rounded-lg shadow-xl p-8 z-[80]">
			<button className="float-right pt-0" onClick={close}>
				<CloseIcon/>
			</button>
			<div className="parent-table w-full">
				<div className="flex text-black bg-grisClaire inline p-2 mt-8 text-xl">
					<p className="flex flex-col">
						<span>
							<span className="font-bold">Attention : </span>
							{message}
						</span>
					</p>
				</div>
			</div>
			<div className="flex justify-end space-x-4 mt-8">
				{cancel ? 
					<button 
						className="bg-redCorail text-white rounded-xl px-6 p-2" 
						type="button" 
						onClick={cancel || close}
					>
						Annuler
					</button>
				:
					null
				}
				{confirm ? 
					<button 
						className="bg-greenSuccess text-white rounded-xl px-6 p-2" 
						type="button" 
						onClick={confirm}
					>
						Confirmer
					</button>
				:
					null
				}
			</div>
		</div>
	</div>
  );
}

export default AlertPopup;
