import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getRealUser, getUser} from "../../app/user";
import {Link} from "react-router-dom";
import {Search, ArrowDownward, DeleteOutlineOutlined} from "@material-ui/icons";
import http from "../../services/Http";
import {PulseLoader} from "react-spinners";


const MonContrat = () => {
    const user = useSelector(getUser)
    const realUser = useSelector(getRealUser)

    const [isAdmin, setIsAdmin] = useState(false);
    useEffect(() => {
        if (user.role === "admin_p" || http.getClientRoleFromUrl() === "admin_p")
            setIsAdmin(true)
    }, [user]);


    return (
        <div>
            <h1 className="font-bold text-black text-xl mb-8">Mon contrat</h1>
            <div className="relative">

                <div className="overflow-auto rounded-lg w-full">
                    <table className="w-full">
                        <thead className="bgPurpuleCustom text-white">
                        <tr>
                            <th className="p-4 text-sm font-semibold tracking-wide text-left">
                                <div className="flex flex-col justify-start whitespace-nowrap">
                                    <span>Date contrat</span>
                                    <span className="invisible">invisible</span>
                                </div>
                            </th>
                            <th className="p-4 text-sm font-semibold tracking-wide text-left">
                                <div className="flex flex-col justify-start whitespace-nowrap">
                                    <span>Nom responsable</span>
                                    <span className="invisible">invisible</span>
                                </div>
                            </th>
                            <th className="p-4 text-sm font-semibold tracking-wide text-left">
                                <div className="flex flex-col justify-start whitespace-nowrap">
                                    <span>Type contrat</span>
                                    <span className="invisible">invisible</span>
                                </div>
                            </th>
                        </tr>

                        </thead>
                        {
                            0 ?
                                <tbody>
                                <tr>
                                    <td className="p-0" colSpan="3">
                                        <PulseLoader
                                            color={'purple'} size={10}/>
                                    </td>
                                </tr>
                                </tbody>
                                // :
                                // contrat === null || contrat.data.length === 0 ?
                                //     <tbody>
                                //     <tr>
                                //         <td className="p-4" colSpan="3">Vous n'avez aucun contrat</td>
                                //     </tr>
                                //     </tbody>
                                :
                                <tbody className="divide-y divide-gray-200">
                                {/*{contrat?.data.map((doc, index) => {*/}
                                {/*    return <tr key={doc.id} className={` ${index % 2 === 0 ? "bg-grisbleu" : ""}`}>*/}
                                {/*        <td className="p-3 text-sm text-gray-700 whitespace-nowrap"></td>*/}
                                {/*        <td className="p-3 text-sm text-gray-700 whitespace-nowrap"></td>*/}
                                {/*    </tr>*/}
                                {/*})*/}
                                {/*}*/}
                                </tbody>
                        }
                    </table>
                </div>
                {realUser.role !== "client" ?
                    <button className="btn-add">+</button> : null}

            </div>
        </div>


    )


}
export default MonContrat