import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import http from '../services/Http'

const BBBMenu = ({user}) => {

    const [state, setState] = useState({
        queryParams: {
            virtualUserId: null,
            virtualUserType: null,
        }
    })

    const setQueryParams = (queryParams) => {
        setState(state => ({...state, queryParams}))
    }

    /**
     *
     * @param {string} path
     * @returns string
     */
    const builUrlPath = (path) => {
        if (state.queryParams.virtualUserId && state.queryParams.virtualUserType) {
            return `${path}?vusertype=${state.queryParams.virtualUserType}&vuserid=${state.queryParams.virtualUserId}`
        }
        return path
    }

    /**
     * Return the role of the user owning the current session
     * @returns string
     */
    const loggedUserRole = () => {
        // Le role de l'espace client qui chargé
        const clientRole = http.getClientRoleFromUrl()

        // Peut etre que l'admin P qui consule l'espace d'un admin N!
        // Donc il pourra avoir plus d'options..

        if (clientRole) return clientRole
        return user.role
    }

    useEffect(() => {
        (() => {
            const usp = new URLSearchParams(window.location.search)
            const virtualUserType = usp.get('vusertype')
            const virtualUserId = usp.get('vuserid')
            setQueryParams({
                virtualUserType,
                virtualUserId,
            })
        })()
    }, [])

    const menu = () => {
        // Menu admin p
        if (loggedUserRole() == 'admin_p')
            return <React.Fragment>
                <li><Link to={builUrlPath('/admin-p')}>Gestion utilisateurs</Link></li>
                {<li>
                    <Link to={builUrlPath('/mon-bbb')}>Mon Blabliblou</Link>
                </li>}

            </React.Fragment>
        // Menu client
        if (loggedUserRole() == 'client')
            return <React.Fragment>
                <li><Link to={builUrlPath('/dashboard')} title="Gérez votre activité ">Ma Gestion</Link></li>
                <li><Link to={builUrlPath('/mes-documents')} title="Suivez et saisissez vos factures d’achat et de vente En cours ">Mes
                    documents</Link></li>
                <li><Link to={builUrlPath('/mes-simus')} title="Simulez votre activité">Mes simulations</Link></li>
                <li><Link to={builUrlPath('/mon-bbb')}>Mon Blabliblou</Link></li>
                <li><Link to={builUrlPath('#')}>Aide-FAQ</Link></li>
            </React.Fragment>

        // Menu admin n
        if (loggedUserRole() == 'admin_n')
            return <React.Fragment>
                <li><Link to={builUrlPath('/')}>Dashboard</Link></li>
                <li><Link to={builUrlPath('/mon-bbb')}>Mon Blabliblou</Link></li>
                <li><Link to={builUrlPath('/messagerie-admin-n')}>Messagerie</Link></li>
            </React.Fragment>
    }

    return menu()

}

export default BBBMenu