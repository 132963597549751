import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";

import {getItem} from "../../services/LocaleStorage";
import http from "../../services/Http";
import {getUser} from "../../app/user";
import Pagination from "../Pagination";
import Loader from "../Loader";

const ListeClientsAdminN = () => {

    const [dataClients, setDataClients] = useState([])
    const [openMessagerieCient, setOpenMessagerieCient] = useState(false);
    const user = useSelector(getUser)
    const [isLoading, setIsLoading] = useState(false)

    //Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [clientsPerPage, setClientsPerPage] = useState(8);
    const [currentClients, setCurrentClients] = useState([])

    const paginate = (pageNumbers) => {
        setCurrentPage(pageNumbers)
    }

    useEffect(() => {

        const last = currentPage * clientsPerPage
        const first = (currentPage * clientsPerPage) - clientsPerPage
        const newCurrClients = dataClients.slice(first, last)
        setCurrentClients(newCurrClients)
    }, [dataClients, currentPage])
    //Fin Pagination

    useEffect(() => {
        if ('id' in user) {
            fetchClients(http.getClientIdFromUrl() ? http.getClientIdFromUrl() : user.id)
        }
    }, [user]);

    const openEspaceClient = (e, client) => {
        e.stopPropagation();
        window.open("/dashboard?vusertype=" + client.role + "&vuserid=" + client.id, '_blank')
    }

    const openMessagerie = (client) => {
        window.open("/access-messagerie-client?vusertype=" + client.role + "&vuserid=" + client.id, '_blank')
    }

    const fetchClients = async (id) => {
        const token = getItem('bbbToken')
        setIsLoading(true)

        try {
            // Récupère la liste des clients d'un Admin N
            fetch(process.env.REACT_APP_API_URL + '/clients?admin_n_id=' + id, {
                method: 'GET',
                requirements: token,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            }).then(response => {
                setIsLoading(false)

                if (response.ok) {
                    response.json().then(response => {
                        setDataClients(response)

                    })
                } else {
                    toast.info('Pas de clients')
                }
            })

        } catch (error) {
            setIsLoading(false)

        }
    }

    return (
        <div className="w-full content">
            <Loader active={isLoading}/>
           <section className="mt-28">
               <div className="overflow-auto rounded-lg w-full">
                   <table className="w-full">
                       <thead className="bgPurpuleCustom text-white">
                       <tr>
                           <th className="w-12 p-3 text-sm font-semibold tracking-wide whitespace-nowrap text-left">Date clôture</th>
                           <th className="w-12 p-3 text-sm font-semibold tracking-wide whitespace-nowrap text-left">Statut</th>
                           <th className="p-4 text-sm font-semibold tracking-wide whitespace-nowrap text-left">Nom client</th>
                           <th className="p-4 text-sm font-semibold tracking-wide whitespace-nowrap text-center">Messagerie en attente</th>
                           <th className="p-4 text-sm font-semibold tracking-wide whitespace-nowrap text-center">Prochaine Echéance</th>
                           <th className="p-4 text-sm font-semibold tracking-wide whitespace-nowrap text-center">Message Admin P</th>
                           <th className="p-4 text-sm font-semibold tracking-wide whitespace-nowrap text-center">Paiements</th>
                           <th className="p-4 text-sm font-semibold tracking-wide whitespace-nowrap text-center">Connexion à l'espace client</th>
                           {/*<th className="text-sm font-semibold absolute">*/}
                           {/*    {user.role === "admin_p" ?*/}
                           {/*        <button className="btn-add"*/}
                           {/*                onClick={() => {*/}
                           {/*                    setOpenPopupRegistrationAdminN(true)*/}
                           {/*                }}>+</button> : ""*/}
                           {/*    }*/}
                           {/*</th>*/}
                       </tr>

                       </thead>
                       {
                           dataClients === null || dataClients.length === 0 ?
                               <tbody>
                               <tr>
                                   <td className="p-4" colSpan="3">Vous n'avez aucun client</td>
                               </tr>
                               </tbody>
                               :
                               <tbody className="divide-y divide-gray-200">
                               {dataClients.map((client, index) => {
                                   return <tr key={client.id} className={` ${index % 2 === 0 ? "bg-grisbleu" : ""}`} /* onClick={() => openMessagerie(client)} */>
                                       <td className="p-3 text-sm text-gray-700 whitespace-nowrap text-left">{client.creation_date}</td>
                                       <td className="p-3 text-sm text-gray-700 whitespace-nowrap text-left">{client.status}</td>
                                       <td className="p-3 text-sm text-gray-700 whitespace-nowrap text-left">{client.display_name}</td>
                                       <td className="p-3 text-sm text-gray-700 whitespace-nowrap text-left">--</td>
                                       <td className="p-3 text-sm text-gray-700 whitespace-nowrap text-left">--</td>
                                       <td className="p-3 text-sm text-gray-700 whitespace-nowrap text-left">--</td>
                                       <td className="p-3 text-sm text-gray-700 whitespace-nowrap text-left">--</td>
                                       <td className="p-3 text-sm text-gray-700 flex justify-center">
                                           <button className="bg-greenSuccess p-2 rounded-lg text-white" onClick={(e) => {
                                               openEspaceClient(e, client)
                                           }}>OK
                                           </button>
                                       </td>

                                   </tr>
                               })
                               }
                               </tbody>
                       }
                   </table>
               </div>
           </section>
            <Pagination postsPerPage={clientsPerPage} totalPosts={dataClients.length} paginate={paginate} currentPage={currentPage}/>
        </div>


    )
}
export default ListeClientsAdminN