import React, {useState} from "react";
import {toast} from "react-toastify";
import {PulseLoader} from "react-spinners";
import CloseIcon from "@material-ui/icons/Close";

import calendar from "../../assets/img/periode.png";
import {getBankAccount} from "../../app/filters";
import {getItem} from "../../services/LocaleStorage";
import {useSelector} from "react-redux";
import {getUser} from "../../app/user";

const PopupConfirmFecExport = ({closeModale, type}) => {
    const [startDate, setStartDate] = useState(new Date().getFullYear() + "-01-01");
    const [endDate, setEndDate] = useState(new Date().getFullYear() + "-12-31");
    const frontUser = useSelector(getUser);
    const [isLoading, setIsLoading] = useState(false);
    const bankAccount = useSelector(getBankAccount);
    const [unblockedCount, setUnblockedCount] = useState(null);

    const verifyPeriod = async () => {
        const token = getItem("bbbToken")
        let response = await fetch(process.env.REACT_APP_API_URL + `/export/verify-period?client_id=${frontUser.id}&bank_id=${bankAccount.id}&start_date=${startDate}&end_date=${endDate}&type=${type}`, {
            method: 'GET', 'Access-Control-Allow-Credentials': true, headers: {
                'Authorization': 'Bearer ' + token, 'Content-Type': "application/json"
            },

        })
        if(response.status === 200){
            let data = await response.json()
            setUnblockedCount(data.unblocked_count)
        }else{
            toast.error("Impossible de vérifier la période saisie.")
        }
    }
    const exportFec = async () => {
        setIsLoading(true);
        const token = getItem("bbbToken")

        let response = await fetch(process.env.REACT_APP_API_URL + '/export/fec', {
            method: 'POST', 'Access-Control-Allow-Credentials': true, headers: {
                'Authorization': 'Bearer ' + token, 'Content-Type': "application/json"
            },
            body: JSON.stringify({ type: type, bank_id: bankAccount.id, start_date: startDate, end_date: endDate }),
            responseType: 'blob'
        });

        setIsLoading(false);

        if(response.status === 200){
            // create file link in browser's memory
            const href = URL.createObjectURL(await response.blob());

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'fec.zip'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);

            toast.success("L'export des opérations bancaires a été effectué avec succès.");
        } else {
            const res = await response.json();
            if (res.message) {
                toast.error(res.message);
            } else {
                toast.error("L'export des opérations bancaires a échoué.");
            }
        }

        closeModale();
    }

    return (
        <div
            className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
            {/*modale export periode*/}
            {unblockedCount !== null ? <><div className="sidebar:w-[65.8rem] mx-auto bg-white rounded-lg shadow-xl sidebar:p-[4.6rem] p-8 w-2/3 z-100">
                <button className="float-right pt-0 text-[#717579]" onClick={closeModale}><CloseIcon/></button>
                <div className="parent-table w-full mt-[2.2rem]">
                    <div className="flex text-black bg-grisClaire p-8 mt-8 rounded-xl text-base sidebar:text-[24px]">
                        <div className="mr-4"><p className="font-bold">Attention : </p></div>
                        <div>
                            <p>Seules les lignes bloquées seront exportées</p>
                            <p>Sur la période selectionnée, {unblockedCount} lignes ne sont pas bloquées.</p>
                        </div>
                        {/*Seules les lignes bloquées seront exportées*/}
                        {/**/}

                    </div>
                </div>
                <div className="flex justify-between mt-8">
                    <button className="bg-redCorail text-white rounded-xl p-2" type="button" onClick={closeModale}> Annuler
                    </button>
                    <button
                        className="bg-[#09BD72] text-white rounded-xl p-2" 
                        type="button" 
                        onClick={exportFec}
                    >
                        {isLoading ? <PulseLoader color={'white'} size={10}/> : 'Confirmer'}                        
                    </button>
                </div>
            </div></> : <div className="w-[28.3rem] p-[3rem] mx-auto bg-white rounded-lg shadow-xl z-100 text-[#717579]">
                <button className="float-right pt-0" onClick={closeModale}><CloseIcon/></button>
                <div className="parent-table w-full mt-[0.3rem]">
                   <h1 className="text-[32px] font-bold text-black">Exportation FEC</h1>
                    <div className="w-full flex items-center mt-[1.6rem] h-[2rem]">
                        <img className="w-[31px] h-[31px]" src={calendar} alt="calendar"/>
                        <h2 className="pl-[0.9rem] text-[24px] font-semibold">Période</h2>
                    </div>
                    {/*mes dates*/}
                        <div className="flex justify-between mt-[0.9rem]">
                            <div className=" px-[0.9rem] flex justify-center w-[10.6rem] rounded-lg border border-[#EEEEEE] py-1 h-[3.4rem] items-center mr-[1.2rem]">
                                <div className="flex text-xs items-center dates-filtre font-bold">
                                    <span>Du</span>
                                    <input id="input-period-from" type="date" className="bg-none-input custom-calendar" min="1990-01-01" max="2300-01-01"  defaultValue={new Date().getFullYear() + "-01-01"} onChange={(e) => {setStartDate(e.target.value)}}/>
                                </div>
                            </div>

                            <div className=" px-[0.9rem] flex justify-center w-[10.6rem] rounded-lg border border-[#EEEEEE] py-1 h-[3.4rem] items-center">
                                <div className="flex text-xs items-center dates-filtre font-bold ">
                                    <span>Au</span>
                                    <input id="input-period-to" type="date" className="bg-none-input custom-calendar" min="1990-01-01" max="2300-12-31"  defaultValue={new Date().getFullYear() + "-12-31"} onChange={(e) => {setEndDate(e.target.value)}}/>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="float-right mt-8 ">
                    <button className="bg-[#09BD72] w-[6.4rem] h-[2.8rem] text-white rounded-full p-2 uppercase font-semibold" type="button" onClick={verifyPeriod}> Ok
                    </button>

                </div>
            </div>}
        </div>
    )
}
export default PopupConfirmFecExport