import '../assets/style/dashboard.css'
import React from "react";
import {getItem} from "../services/LocaleStorage";
import {Link} from "@material-ui/core";
import {useHistory} from "react-router-dom";

const Register = () => {
    const history = useHistory()

    const handleChange = () => {
        document.querySelectorAll('.error').innerText = ''
    }

    // const handleError = (id, error) => {
    //     document.getElementById(id + '-error').innerText = error
    // }

    const handleSubmit = (e) => {
        e.preventDefault()

        const token = getItem('bbbToken')
        const authUrl = process.env.REACT_APP_API_URL + '/register'

        const formdata = new FormData(document.getElementById('new-enregistrement'));
        formdata.append("status", "OFFRE");
        formdata.append("role", "client");
        formdata.append("created_at", new Date().toISOString().split('T')[0]);
        formdata.append("creation_date", new Date().toISOString().split('T')[0]);
        formdata.append('reference', 'REFF123')

        //Enregistrer un client depuis espace Register
        fetch(authUrl, {
            method: 'POST',
            'Access-Control-Allow-Credentials': true,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: formdata,
        })
        .then(async response => {
            if (response.ok) {
                response.json().then(() => {
                    history.push('/dashboard')
                })
            } else {
                console.log('l\'enregistrement du nouveau user c\'est pas fait')
            }
        })
    }

    return (
        //OLD
        <React.Fragment>
            <section className=" mt-28">
                <div className="parent-table w-full content flex">

                    <div className="m-auto text-center md:w-1/3 md:pt-28 lg:w-1/3 lg:mt-28" id="registration">
                        <h1 className="font-semibold text-black mb-8">Je me connecte à mon espace <span className="text-lilaFonce font-bold">Blabliblou</span></h1>
                        <form onSubmit={handleSubmit} action="" id="new-enregistrement">

                            <div className="m-2 border w-full rounded-xl flex">
                                {/*<input className="w-full text-center p-2 h-14 rounded-2xl bg-violetClaire" type="text" name="username" placeholder="username" onChange={handleChange} />*/}
                                <input className="w-full text-center p-2 h-14 rounded bg-gris" type="text" name="username" placeholder="username" onChange={handleChange}/>
                            </div>
                            <span className="error text-red-500 font-semibold" id="username-error"/>

                            <div className="m-2 border w-full rounded-xl flex">
                                {/*<input type="text" name="display_name" className="w-full text-center p-2 h-14 rounded-2xl bg-violetClaire" placeholder="display_name" onChange={handleChange}/>*/}
                                <input type="text" name="display_name" className="w-full text-center p-2 h-14 rounded" placeholder="display_name" onChange={handleChange}/>
                            </div>
                            <span className="error text-red-500 font-semibold" id="display_name-error"/>

                            <div className="m-2 border w-full rounded-xl flex">
                                {/*<input type="email" name="email" className="w-full text-center p-2 h-14 rounded-2xl bg-violetClaire" placeholder="email" onChange={handleChange}/>*/}
                                <input type="email" name="email" className="w-full text-center p-2 h-14 rounded" placeholder="email" onChange={handleChange}/>
                            </div>
                            <div className="m-2 border w-full rounded-xl flex">
                                <input type="text" name="phone" className="w-full text-center p-2 h-14 rounded bg-gris" placeholder="phone" onChange={handleChange}/>
                            </div>

                            <div className="m-2 border w-full rounded-xl flex">
                                {/*<input type="password" name="password" className="w-full text-center p-2 h-14 rounded-2xl bg-violetClaire"  placeholder="Mon mot de passe" onChange={handleChange}/>*/}
                                <input type="password" name="password" className="w-full text-center p-2 h-14 rounded" placeholder="password" onChange={handleChange}/>
                            </div>
                            <span className="error text-red-500 font-semibold" id="password-error"/>
                            <div className="m-2 border w-full rounded-xl flex">
                                <input type="password" name="repeat-password" className="w-full text-center p-2 h-14 rounded-2xl bg-violetClaire"
                                       placeholder="Répéter le mot de passe" onChange={handleChange}/>
                            </div>
                            <span className="error text-red-500 font-semibold" id="repeat-password-error"/>
                            <div className="flex w-full">
                                <input type="checkbox" className="black"/>
                                <span className="text-black">Je suis d'accord avec les termes et les conditions</span>
                            </div>
                            <div className="flex flex-col w-full">
                                <div className="m-2 w-full">
                                    <button className="bg-primary text-white font-bold rounded-xl p-2 w-full" type="submit">Créer un compte</button>
                                </div>
                                <div className="m-2 w-full">
                                    <button className="text-lilaFonce rounded-xl font-bold p-2 w-full border border-lilaFonce"><Link to="/auth">J'ai déjà un compte</Link></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
export default Register