import React from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

function ArrowShowDetails() {
    let hello = (e) => {
        const parent = e.target.closest("tbody").nextSibling;
        if (parent != null) {
            e.target.classList.toggle('rotate');
            parent.classList.toggle('show-sub-rows');
        }
    }
    return (
        <div>
            <ArrowDropDownIcon onClick={hello} />
        </div>
    )
}

export default ArrowShowDetails
