import React, {useEffect, useState} from 'react'
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import GeneralStats from "../dashboard/GeneralStats";
import ResultsChart from "../dashboard/ResultsChart";
import DetailTableDashboard from "../dashboard/DetailTableDashboard";
import EcartResults from "../dashboard/EcartResults";
import {getBankAccount, getDatesFilter} from "../../app/filters";
import {getUser} from "../../app/user";
import {getItem} from "../../services/LocaleStorage";
import http from "../../services/Http";
import { tresoGraphDataInit, resultatGraphDataInit, caGraphDataInit, fraisGraphDataInit } from "../../utils/loadingInitData";

import { Chart, registerables } from 'chart.js';

// Enregistrer les plugins de Chart.js
Chart.register(...registerables);

// Définir le plugin de padding pour la légende
const legendPaddingPlugin = {
  id: 'legendPadding',
  beforeInit(chart) {
    const originalFit = chart.legend.fit;
    chart.legend.fit = function fit() {
      originalFit.bind(chart.legend)();
      this.height += 30; // Ajustez cette valeur pour ajouter plus ou moins d'espace entre la légende et le graphique
    };
  }
};

// Enregistrer le plugin personnalisé
Chart.register(legendPaddingPlugin);


const StatsAndGraphs = () => {
    // const user = useSelector(state => state.user)
    const [statSelect, setStatSelect] = useState(0)

    const dateFilters = useSelector(getDatesFilter)
    const frontUser = useSelector(getUser)
    const bankAccount = useSelector(getBankAccount)
    //Graphiques
    const [tresoGraphData, setTresoGraphData] = useState(tresoGraphDataInit)
    const [resultatsGraphData, setResultatsGraphData] = useState(resultatGraphDataInit)
    const [caGraphData, setCaGraphData] = useState(caGraphDataInit);
    const [fraisGraphData, setFraisGraphData] = useState(fraisGraphDataInit);
    //Tableau
    const [detailFactures, setDetailFactures] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [month, setMonth] = useState(null);

    useEffect(() => {
        fetchAllIndicators();
        getMonth();
    }, []);

    useEffect(() => {
        if (statSelect > 0) {
            fetchDetails();
        }
    }, [statSelect]);

    const getMonth = () => {
        const moisFr = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre']
        const start = new Date(dateFilters[0])
        const end = new Date(dateFilters[1])
        start.setDate(15)
        end.setDate(16)
        let months = []
        while (start < end) {
            months.push(moisFr[start.getMonth()])
            start.setMonth(start.getMonth() + 1)
        }
        setMonth(months)
    }

    const fetchAllIndicators = () => {
        let warningMessages = []
        const infos = [
            {
                nameIndicator: "tréso"
            },
            {
                nameIndicator: "résultats"
            },
            {
                nameIndicator: "ca"
            },
            {
                nameIndicator: "dépenses"
            },
        ]

        Promise.all([
            fetchTresoGraphData(),
            fetchResultatsGraphData(),
            fetchCaGraphData(),
            fetchFraisGraphData()
        ]).then(async responses => {
            const datas = await Promise.all(responses.map(resp => resp.json()))
            console.log('statsAndGraph', datas);
            datas.forEach((content, index) => {
                if (responses[index].status === 424) {
                    warningMessages = [...warningMessages, `Aucune données de ${infos[index].nameIndicator} trouvées.`]
                } else if (responses[index].status === 200) {
                    if (index === 0) {
                        setTresoGraphData([
                            {
                                label: "Tréso en cours",
                                backgroundColor: '#b260fd',
                                data: Object.values(content.treasury),
                                borderRadius: 10
                            },
                            {
                                label: "Tréso prévu",
                                backgroundColor: '#670fb6',
                                data: Object.values(content.expected_treasury),
                                borderRadius: 10
                            },
                            {
                                label: "Tréso n-1",
                                backgroundColor: '#30006d',
                                data: Object.values(content.previous_treasury),
                                borderRadius: 10
                            },
                        ])
                    } else if (index === 1) {
                        setResultatsGraphData([
                            {
                                label: "Résultat HT payés",
                                backgroundColor: '#63d2b9',
                                data: Object.values(content.resultat.ht_encaisse),
                                borderRadius: 10
                            },
                            {
                                label: "Résultat HT prévu",
                                backgroundColor: '#047b7b',
                                data: Object.values(content.resultat.ht_prevu),
                                borderRadius: 10
                            },
                            {
                                label: "Résultat HT estimé",
                                backgroundColor: '#022b2f',
                                data: Object.values(content.resultat.ht_estime),
                                borderRadius: 10
                            },
                            {
                                label: "Résultat n-1",
                                backgroundColor: '#9ddddd',
                                data: Object.values(content.resultat.previous_ht),
                                borderRadius: 10
                            },
                            {
                                label: "Marge commerciale",
                                backgroundColor: '#ccf4ff',
                                data: Object.values(content.resultat.marge_commerciale),
                                borderRadius: 10
                            },
                        ])
                    } else if (index === 2) {
                        setCaGraphData([
                            {
                                label: "CA HT encaissé",
                                backgroundColor: '#ff6489',
                                data: Object.values(content.ca_ht_encaisse),
                                borderRadius: 10
                            },
                            {
                                label: "CA HT prévu",
                                backgroundColor: '#c6007f',
                                data: Object.values(content.ca_ht_prevu),
                                borderRadius: 10
                            },
                            {
                                label: "CA HT estimé",
                                backgroundColor: '#500234',
                                data: Object.values(content.ca_ht_estime),
                                borderRadius: 10
                            },
                            {
                                label: "CA HT n-1",
                                backgroundColor: '#cd2561',
                                data: Object.values(content.previous_ca_ht),
                                borderRadius: 10
                            },
                        ])
                    } else if (index === 3) {
                        setFraisGraphData([
                            {
                                label: "Dépenses HT payées",
                                backgroundColor: '#f9a150',
                                data: Object.values(content.frais_ht_payes),
                                borderRadius: 10
                            },
                            {
                                label: "Dépenses HT prévues",
                                backgroundColor: '#f25e0a',
                                data: Object.values(content.frais_ht_prevu),
                                borderRadius: 10
                            },
                            {
                                label: "Dépenses HT estimées",
                                backgroundColor: '#b25600',
                                data: Object.values(content.frais_ht_estime),
                                borderRadius: 10
                            },
                            {
                                label: "Dépenses HT n-1",
                                backgroundColor: '#ffa471',
                                data: Object.values(content.previous_frais_ht),
                                borderRadius: 10
                            },
                        ]);
                    }
                }
            })
            if (warningMessages.length > 0) {
                warningMessages.map((wm, index) => {
                    toast.warning(wm)
                })
            }
        })
    }

    // 1ere
    const fetchTresoGraphData = () => {
        const token = getItem("bbbToken")
        let endpoint = `/stats/dashboard/treasury?bank_id=${bankAccount.id}&start_at=${dateFilters[0]}&end_at=${dateFilters[1]}`

        return fetch(process.env.REACT_APP_API_URL + endpoint, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
    }

    // 2eme
    const fetchResultatsGraphData = () => {
        const token = getItem("bbbToken")
        let endpoint = '/stats/dashboard/resultat?client_id=' + frontUser.id + '&bank_id=' + bankAccount.id + "&start_at=" + dateFilters[0] + "&end_at=" + dateFilters[1]

        return fetch(process.env.REACT_APP_API_URL + endpoint, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
    }

    // 3eme
    const fetchCaGraphData = () => {
        const token = getItem("bbbToken")
        let endpoint = '/stats/dashboard/ca-periods?client_id=' + frontUser.id + '&bank_id=' + bankAccount.id + "&start_at=" + dateFilters[0] + "&end_at=" + dateFilters[1]

        return fetch(process.env.REACT_APP_API_URL + endpoint, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
    }

    // 4eme
    const fetchFraisGraphData = () => {
        const token = getItem("bbbToken")
        let endpoint = '/stats/dashboard/frais-periods?client_id=' + frontUser.id + '&bank_id=' + bankAccount.id + "&start_at=" + dateFilters[0] + "&end_at=" + dateFilters[1]

        return fetch(process.env.REACT_APP_API_URL + endpoint, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
    }

    // Details Achat / Ventes
    const fetchDetails = () => {
        const token = getItem("bbbToken");

        setIsLoading(true);

        let endpoint = '/stats/dashboard/operations-stats?client_id=' + frontUser.id + '&bank_id=' + bankAccount.id + "&start_at=" + dateFilters[0] + "&end_at=" + dateFilters[1]
        endpoint += statSelect === 3 ? "&type=vente" : statSelect === 4 ? "&type=achat" : ""

        http.get(endpoint, {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
        .then((data) => {
            setDetailFactures(data);
            setIsLoading(false);
        })
        .catch((error) => {
            // toast.error("Une erreur est survenue lors du chargement des details.")
            setIsLoading(false);
        });
    }

    return (
        <React.Fragment>
            {/*<NavLink to="/account">Aller au compte de : {user.clientName}</NavLink>*/}
            <GeneralStats setSelectState={setStatSelect} selectedState={statSelect}/>
            {month !== null ? 
                <React.Fragment>
                    {statSelect === 1 &&
                        <div>
                            <ResultsChart dataset={tresoGraphData} months={month} statSelect={1} />
                        </div>
                    }
                    {statSelect === 2 &&
                        <div>
                            <ResultsChart dataset={resultatsGraphData} months={month} statSelect={2} />
                            <EcartResults datasetResult={resultatsGraphData} datasetCA={caGraphData} months={month} />
                        </div>
                    }
                    {statSelect === 3 &&
                        <div>
                            <ResultsChart dataset={caGraphData} months={month} statSelect={3} />
                            <DetailTableDashboard dataset={caGraphData} months={month} detailFactures={detailFactures} statSelect={3} datasetCA={caGraphData} isLoading={isLoading} />
                        </div>
                    }
                    {statSelect === 4 && 
                        <div>
                            <ResultsChart dataset={fraisGraphData} months={month} statSelect={4} />
                            {/*<Donuts/>*/}
                            <DetailTableDashboard dataset={fraisGraphData} months={month} detailFactures={detailFactures} statSelect={4} datasetFrais={fraisGraphData} isLoading={isLoading} />
                        </div>}
                </React.Fragment> 
            : 
                ''
            }
        </React.Fragment>
    )
}

export default StatsAndGraphs