import React, {useRef, useEffect, useState} from "react";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DeleteIcon from "@material-ui/icons/Delete";
import BackupIcon from "@material-ui/icons/Backup";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DescriptionIcon from "@material-ui/icons/Description";
import {DeleteOutlineOutlined, EditOutlined, Search} from "@material-ui/icons";
import {PulseLoader} from "react-spinners";
import {useDropzone} from "react-dropzone";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";

import {getItem} from "../../services/LocaleStorage";
import {getRealUser, getUser} from "../../app/user";
import Loader from "../../components/Loader";
import mailround from "../../assets/img/mailround.png";
import Pagination from "../Pagination";
import triangleUp from "../../assets/img/triangleUp.png";
import triangleDown from "../../assets/img/triangleDown.png";
import mailIcon from "../../assets/img/mail-icon.png";
import edit from "../../assets/img/edit.png";
import corbeille from "../../assets/img/corbeille.png";
import DetailsModal from '../client/detailsModal';

const Courriers = ({
    loading, 
    fetchCourriers, 
    courriers, 
    loadingComp, 
    setSortTerms, 
    setSortOrder
}) => {
    const [nameFile, setNameFile] = useState('')
    const frontUser = useSelector(getUser)
    const [fileInInput, setFileInInput] = useState(false);
    const user = useSelector(getUser)
    const [files, setFiles] = useState([]);

    const [affectCourrierModale, setAffectCourrierModale] = useState(false);
    const [rowToAffect, setRowToAffect] = useState({});

    // Function to scroll to and select a specific row
    const [highlightedRow, setHighlightedRow] = useState(null);
    const rowRefs = useRef({});
    const scrollToRow = (rowId) => {
        if (rowRefs.current[rowId]) {
            rowRefs.current[rowId].scrollIntoView({ behavior: "smooth", block: "center" });
            setHighlightedRow(rowId);
            setTimeout(() => setHighlightedRow(null), 2500); // Highlight fades after 2 seconds
        }
    };

    const removeAttachment = () => {
        document.getElementById("invoice-courrier-input").value = null
        setFileInInput(false)
    }

    const changeInputUpload = (e) => {
        let files;
        if (e.target.files) {
            files = e.target.files;
        }
        if (e.dataTransfer && e.dataTransfer.files) {
            files = e.dataTransfer.files;
        }
        
        let totalSize = 0;
        Array.from(files).forEach((file) => {
            totalSize += file.size;

            if ((file.size / 1024) / 1024 > 20) {
                toast.error('Un seul document ne doit pas dépasser 20MB');
                removeAttachment();
                return;
            }
        });

        const isAllowedSize = ((totalSize / 1024) / 1024 <= 128) && files.length <= 50;
        if (!isAllowedSize) {
            toast.error('Limite de 50 fichiers et 128MB en total est dépassée !');
            removeAttachment();
            return;
        }

        if (files.length == 1) {
            setFileInInput(true);
            let placeholder = document.getElementById('file-placeholder');
            const imgSrc = URL.createObjectURL(files[0]);
            const imgEmbed = `<embed className="object-cover h-full" src="${imgSrc}" alt="" id="balise-img"/>`;
            placeholder.innerHTML = imgEmbed;
        } else if (files.length > 1) {
            setFileInInput(true);
            let placeholder = document.getElementById('file-placeholder');
            placeholder.innerHTML = `<span>${files.length} fichiers séléctionnés</span>`;
        } else {
            setFileInInput(false)
        }
    }
    // Glisser - déposer file
    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        },
    });
    const dragNDrop = (e) => {
        document.getElementById('invoice-courrier-input').files = e.dataTransfer.files
        changeInputUpload(e);
    }

    const uploadCourrier = (e) => {
        const token = getItem('bbbToken')

        const form = new FormData(document.getElementById('send-courrier-invoice'))
        form.append('client_id', frontUser.id)
        form.append('type', 'courrier')
        form.append('label', 'courrier')

        if (fileInInput) {
            setFileInInput(false)

            fetch(process.env.REACT_APP_API_URL + '/invoice', {
                method: 'POST',
                requirements: token,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                body: form
            }).then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        toast.success('Ajout du courrier avec succès.')
                        fetchCourriers(courriers.per_page, courriers.page)
                        document.getElementById("invoice-courrier-input").value = null
                    })
                } else {
                    toast.error('L\'ajout du courrier a échoué.')
                }
            })
        }
    }
    const declenchInput = () => {
        document.getElementById('invoice-courrier-input').click()
    }

    //Delete un courrier lor du clique sur la corbeille
    const delDoc = (doc) => {
        const token = getItem('bbbToken')

        const fetchDel = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + '/invoice/' + doc.toString(), {
                    method: 'DELETE',
                    requirements: token,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                })

                if (response.status === 200) {
                    fetchCourriers(courriers.per_page, courriers.page)
                    toast.success('Le courrier a bien été supprimé.')
                } else {
                    toast.error('La suppression du courrier a échoué.')
                }
            } catch (error) {
                toast.error('La suppression du courrier a échoué.')
            }
        }

        fetchDel()
    }
    
    const openAffectModale = (id) => {
        setRowToAffect(courriers.data.find(elem => elem.id === id));
        setAffectCourrierModale(true);
    }

    const close = () => {
        setAffectCourrierModale(false);
    }

    return (
        <React.Fragment>
            <div className="w-full flex py-20">
                <div className="flex flex-col w-3/6">
                </div>
                <div className="w-3/6 flex justify-center">
                    <div className="vente-upload">
                        <div className="w-full rounded-lg items-center mb-8">
                            <div className="w-upload text-black h-full mx-auto bg-gris rounded-xl">
                                <h3 className="text-center mb-0 p-2 font-bold w-full">Joindre un fichier</h3>
                                <div className="bg-gris vente-upload w-full rounded-lg flex justify-center items-center ">
                                    <div 
                                        className={`flex justify-center items-center ${fileInInput ? "" : " border-dashed border-2 border-grisDashed "} vente-upload charge-img rounded-xl mx-auto`}
                                        onClick={declenchInput}
                                        onDrop={(e) => { dragNDrop(e) }}
                                    >
                                        <div id="file-placeholder" className={!fileInInput ? "hidden" : ""}></div>
                                        {/* <embed className={fileInInput ? "object-cover h-full" : "hidden"} src="" alt=""
                                               id="balise-img"/> */}
                                        <div className={fileInInput ? "hidden" : "flex flex-col justify-center text-center"}>
                                            <div>
                                                <button type="button" className="svg-upload"><BackupIcon/></button>
                                            </div>
                                            <div className="text">
                                                <form id="send-courrier-invoice">
                                                    <input 
                                                        type="file" 
                                                        name="receipt[]" 
                                                        id="invoice-courrier-input"
                                                        className="hidden" 
                                                        multiple="multiple"
                                                        onChange={(e) => changeInputUpload(e)}
                                                    />
                                                </form>
                                                <h5 className="font-semibold">Charger un fichier</h5>
                                                <h6>PDF, DOC, PPT, JPG, PNG</h6>
                                                <span>{nameFile}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <button 
                                type="button" 
                                disabled={!fileInInput}
                                className={`w-full p-2 bg-greenSuccess rounded-xl ${fileInInput ? "" : "bg-opacity-50 cursor-not-allowed"} text-white flex items-center justify-center mr-2`}
                                onClick={(e) => uploadCourrier(e)}
                            >
                                <DescriptionIcon/>
                                <span className="mx-auto px-6 ">Envoyer</span>
                            </button>

                            <button 
                                type="button"
                                disabled={!fileInInput} 
                                className={`w-full p-2 bg-redCorail rounded-xl ${fileInInput ? "" : "bg-opacity-50 cursor-not-allowed"} text-white flex items-center justify-center`}
                                onClick={removeAttachment}
                            >
                                <DeleteIcon/>
                                <span className="mx-auto px-6">Supprimer</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Loader active={loading}/>

            <div className="overflow-auto rounded-lg w-full">
                <table className="w-full documents-table">
                    <thead className="bgPurpuleCustom text-white">
                        <tr>
                            <th className="w-12 p-4 text-sm font-semibold tracking-wide text-left">
                                <div className="flex flex-col justify-start whitespace-nowrap">
                                    <div className="tracking-wide whitespace-nowrap flex">
                                        Date
                                        <img 
                                            alt="" 
                                            src={triangleUp} 
                                            className="mx-1 w-3.5 h-3.5 cursor-pointer" 
                                            onClick={()=> {
                                                setSortTerms("operation_date"); 
                                                setSortOrder("ASC");
                                            }}
                                        />
                                        <img 
                                            alt="" 
                                            src={triangleDown} 
                                            className="w-3.5 h-3.5 cursor-pointer" 
                                            onClick={()=> {
                                                setSortTerms("operation_date"); 
                                                setSortOrder("DESC");
                                            }}
                                        />
                                    </div>
                                    <span className="invisible">invisible</span>
                                </div>
                            </th>
                            <th className="p-4 text-sm font-semibold tracking-wide text-left">
                                <div className="flex flex-col justify-start whitespace-nowrap">
                                    <span>Libellé des courriers</span>
                                    <span className="invisible">invisible</span>
                                </div>
                            </th>
                            <th className="p-4 text-sm font-semibold tracking-wide text-left w-40">
                                <div className="flex flex-col justify-start">
                                    <span></span>
                                    <span className="invisible">invisible</span>
                                </div>
                            </th>
                            <th className="p-4 text-sm font-semibold tracking-wide text-left">
                                <div className="flex flex-col justify-center whitespace-nowrap">
                                    <div className="tracking-wide whitespace-nowrap flex justify-center">
                                        <span>Mont. HT</span>
                                        <img 
                                            alt="" 
                                            src={triangleUp} 
                                            className="mx-1 w-3.5 h-3.5 cursor-pointer" 
                                            onClick={()=> {
                                                setSortTerms("amount"); 
                                                setSortOrder("ASC");
                                            }}
                                        />
                                        <img 
                                            alt="" 
                                            src={triangleDown} 
                                            className="w-3.5 h-3.5 cursor-pointer" 
                                            onClick={()=> {
                                                setSortTerms("amount"); 
                                                setSortOrder("DESC");
                                            }}
                                        />
                                    </div>
                                    <span className="invisible">invisible</span>
                                </div>
                            </th>
                            <th className="p-4 text-sm font-semibold tracking-wide">
                                <div className="flex flex-col justify-center whitespace-nowrap">
                                    <div className="tracking-wide whitespace-nowrap flex justify-center">
                                        <span>TVA</span>
                                        <img 
                                            alt="" 
                                            src={triangleUp} 
                                            className="mx-1 w-3.5 h-3.5 cursor-pointer" 
                                            onClick={()=> {
                                                setSortTerms("vat"); 
                                                setSortOrder("ASC");
                                            }}
                                        />
                                        <img 
                                            alt="" 
                                            src={triangleDown} 
                                            className="w-3.5 h-3.5 cursor-pointer" 
                                            onClick={()=> {
                                                setSortTerms("vat"); 
                                                setSortOrder("DESC");
                                            }}
                                        />
                                    </div>
                                    <span className="invisible">invisible</span>
                                </div>
                            </th>
                            <th className="p-4 text-sm font-semibold tracking-wide">
                                <div className="flex flex-col justify-center whitespace-nowrap">
                                    <div className="tracking-wide whitespace-nowrap flex justify-center">
                                        <span>Mont. TTC</span>
                                        <img 
                                            alt="" 
                                            src={triangleUp} 
                                            className="mx-1 w-3.5 h-3.5 cursor-pointer" 
                                            onClick={()=> {
                                                setSortTerms("amountWithVat"); 
                                                setSortOrder("ASC");
                                            }}
                                        />
                                        <img 
                                            alt="" 
                                            src={triangleDown} 
                                            className="w-3.5 h-3.5 cursor-pointer" 
                                            onClick={()=> {
                                                setSortTerms("amountWithVat"); 
                                                setSortOrder("DESC");
                                            }}
                                        />
                                    </div>
                                    <span className="invisible">invisible</span>
                                </div>
                            </th>
                            <th className="p-4 text-sm font-semibold tracking-wide">
                                <div className="flex flex-col justify-start whitespace-nowrap text-center">
                                    <span>Pièces</span>
                                    <span className="invisible">invisible</span>
                                </div>
                            </th>
                            {/*edit*/}
                            <th className="p-4 text-sm font-semibold tracking-wide text-left w-20">
                                <div className="flex flex-col justify-start">
                                    <span></span>
                                    <span className="invisible">invisible</span>
                                </div>
                            </th>
                            {/*delete*/}
                            <th className="p-4 text-sm font-semibold tracking-wide text-left w-20">
                                <div className="flex flex-col justify-start">
                                    <span></span>
                                    <span className="invisible">invisible</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    {loadingComp ?
                        <tbody>
                            <tr>
                                <td className="p-0" colSpan="3">
                                    <PulseLoader color={'purple'} size={10} />
                                </td>
                            </tr>
                        </tbody> 
                    :
                        courriers === null || courriers.data.length === 0 ?
                            <tbody>
                                <tr>
                                    <td className="p-4" colSpan="3">Vous n'avez aucun courrier</td>
                                </tr>
                            </tbody>
                        :
                            <tbody className="divide-y divide-gray-200">
                                {courriers?.data.map((document, index) => {
                                    return (
                                        <tr 
                                            key={document.id} 
                                            ref={(el) => (rowRefs.current[document.id] = el)}
                                            className={`${index % 2 === 0 ? "bg-grisbleu" : ""} ${highlightedRow == document.id ? "highlighted-row" : ""}`}
                                        >
                                            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{document.displayable_operation_date} {document.id}</td>
                                            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{document.label}</td>
                                            <td className="p-3 text-sm text-gray-700 whitespace-nowrap flex justify-center" >
                                                <img src={mailIcon} className="img-mailround w-40" alt="message"/>
                                            </td>
                                            <td className="p-3 text-sm text-gray-700 whitespace-nowrap text-center">{document.amount}</td>
                                            <td className="p-3 text-sm text-gray-700 whitespace-nowrap text-center">{document.vat_value}</td>
                                            <td className="p-3 text-sm text-gray-700 whitespace-nowrap text-center">{document.amount_with_vat}</td>
                                            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                                <div className="flex justify-center">
                                                    {document.receipt_path !== null ?
                                                        <a href={process.env.REACT_APP_STORAGE_URL + document.receipt_path}
                                                        target="_blank" rel="noreferrer">
                                                            <div className="relative p-0">
                                                                <AttachFileIcon className="fill-black"/>
                                                                <span
                                                                    className={`${document.receipt_path === null ? "bg-redCorail" : "bg-greenSuccess"} top-icon-rounded`}></span>
                                                            </div>
                                                        </a>
                                                    : 
                                                        <div className="relative p-0">
                                                            <button type="button">
                                                                <AttachFileIcon className="fill-black"/>
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            </td>
                                            <td className="p-3 text-sm text-gray-700 whitespace-nowrap w-20">
                                                <button 
                                                    className="bg-[#707579] rounded-full p-3"
                                                    onClick={() => openAffectModale(document.id)}
                                                >
                                                    <img src={edit} alt="edit"/>
                                                </button>
                                            </td>
                                            <td className="p-3 text-sm text-gray-700 whitespace-nowrap w-20">
                                                {/*{document.receipt_path !== null &&*/}
                                                {/*    <a href={process.env.REACT_APP_STORAGE_URL + document.receipt_path} target="_blank"*/}
                                                {/*       rel="noreferrer"*/}
                                                {/*       className=" inline-block bg-lilaFonce text-white p-2 rounded-lg">*/}
                                                {/*        <Search/>*/}
                                                {/*    </a>*/}
                                                {/*}*/}
                                                <button className="bg-[#C4C4C4] p-2 h-full rounded-lg text-white" onClick={() => delDoc(document.id)}>
                                                    <img src={corbeille} alt="delete" className=" w-[19px] h-[19px] max-w-[19px]"/>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                    }
                </table>
            </div>
            {affectCourrierModale &&
                <DetailsModal 
                    rowToAffect={rowToAffect} 
                    scrollToRow={() => scrollToRow(rowToAffect.id)}
                    close={close} 
                    isLastInvoice={!courriers.data.some(el => el.id > rowToAffect.id)}
                    fetchResources={() => fetchCourriers(courriers.per_page, courriers.page)}
                />
            }
            <Pagination data={courriers} paginate={fetchCourriers}/>
        </React.Fragment>
    )
}

export default Courriers