import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getRealUser, getUser} from "../../app/user";
import {getItem} from "../../services/LocaleStorage";
import {Search, ArrowDownward, DeleteOutlineOutlined, Face} from "@material-ui/icons";
import AddFacture from "./addFacture";
import Loader from "../Loader";
import Pagination from "../Pagination";
import PopupDelDoc from "./popupDelDoc";
import PopupDelFacture from "./popupDelFacture";
import {PulseLoader} from "react-spinners";

const MesFactures = () => {
    const user = useSelector(getUser)
    const realUser = useSelector(getRealUser)

    const [dataFactures, setDataFactures] = useState([])
    const [addFacturesModalOpen, setAddFacturesModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchFactures()
    }, []);


    //Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [facturesPerPage, setFacturesPerPage] = useState(8);
    const [currentFactures, setCurrentFactures] = useState([])
    const [openPopupDelFacture, setOpenPopupDelFacture] = useState(false);
    const [factureId, setFactureId] = useState('');
    const paginate = (pageNumbers) => {
        setCurrentPage(pageNumbers)
    }

    useEffect(() => {

        const last = currentPage * facturesPerPage
        const first = (currentPage * facturesPerPage) - facturesPerPage
        const newCurrFactures = dataFactures.slice(first, last)
        setCurrentFactures(newCurrFactures)
    }, [dataFactures, currentPage])
    //Fin Pagination

    const closeModal = () => {
        setAddFacturesModalOpen(false)
        setOpenPopupDelFacture(false)
    }

    const fetchFactures = () => {
        const token = getItem('bbbToken')
        try {
            setIsLoading(true)
            fetch(process.env.REACT_APP_API_URL + '/client/bbb-invoice?client_id=' + user.id, {
                method: 'GET',
                requirements: token,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            }).then(response => {
                if (response.ok) {
                    setIsLoading(false)
                    response.json().then(response => {
                        setDataFactures(response)
                    })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }


    const del = (factureId) => {
        setOpenPopupDelFacture(true)
        setFactureId(parseInt(factureId))
    }
    return (
        <div className="w-full">
            <Loader active={isLoading}/>
            <div>
                <h1 className="font-bold text-black text-xl my-8">Mes factures</h1>
                <div className="overflow-auto rounded-lg w-full">
                    <table className="w-full">
                        <thead className="bgPurpuleCustom text-white">
                        <tr>
                            <th className="p-4 text-sm font-semibold tracking-wide text-left">
                                <div className="flex flex-col justify-start">
                                    <span>Date factures</span>
                                    <span className="invisible">invisible</span>
                                </div>
                            </th>
                            <th className="p-4 text-sm font-semibold tracking-wide text-left">
                                <div className="flex flex-col justify-start">
                                    <span>Détails</span>
                                    <span className="invisible">invisible</span>
                                </div>
                            </th>
                            <th className="p-4 text-sm font-semibold tracking-wide text-center">
                                <div className="flex flex-col justify-start">
                                    <span>Statut paiement</span>
                                    <span>ooo</span>
                                </div>
                            </th>

                            <th className="p-4 text-sm font-semibold tracking-wide text-center">
                                <div className="flex flex-col justify-start">
                                    <span></span>
                                    <span className="invisible">invisible</span>
                                </div>

                            </th>
                            <th className="text-sm font-semibold tracking-wide text-center absolute">
                                <button type="button" className="btn-add"
                                        onClick={() => {
                                            setAddFacturesModalOpen(true)
                                        }}>+</button>
                            </th>
                        </tr>
                        </thead>
                        {
                            currentFactures === null || currentFactures.length === 0 ?
                                <tbody>
                                <tr>
                                    <td className="p-4" colSpan="4">Vous n'avez aucune facture</td>
                                </tr>
                                </tbody>
                                :
                                <tbody className="divide-y divide-gray-200">
                                {currentFactures?.map((facture, index) => {
                                    return <tr key={facture.id} className={`${index % 2 === 0 ? "bg-grisbleu" : ""}`}>
                                        <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{facture.pay_before}</td>
                                        <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{facture.readable_pay_before}</td>
                                        {facture.status === "unpaid" && <td className="colors flex justify-center border-gray-200 border-b font-bold">
                                            <span className="bg-redDanger"></span>
                                        </td>}
                                        {facture.status === "partially_paid" && <td className="colors flex justify-center border-gray-200 border-b font-bold">
                                            <span className="bg-orange-500"></span>
                                        </td>}
                                        {facture.status === "paid" && <td className="colors flex justify-center border-gray-200 border-b font-bold">
                                            <span className="bg-greenSuccess"></span>
                                        </td>}

                                        <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                            <div className=" flex justify-between">
                                                <div>
                                                    <a href={process.env.REACT_APP_STORAGE_URL + facture.receipt_path} target="_blank"
                                                       rel="noreferrer" className="inline-block bg-lilaFonce text-white p-2 rounded-lg">
                                                        <Search/>
                                                    </a>

                                                    <button type="button" className="ml-2 bg-lilaFonce text-white p-2 rounded-lg">
                                                        <a href={process.env.REACT_APP_STORAGE_URL + facture.receipt_path} download><ArrowDownward/></a>
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button" className="bg-redCorail text-white p-2 rounded-lg ml-2" onClick={() => del(facture.id)}>
                                                        <DeleteOutlineOutlined/>
                                                    </button>
                                                </div>

                                            </div>

                                        </td>
                                    </tr>
                                })
                                }
                                </tbody>
                        }
                    </table>
                </div>


                <div className="mr-20"><Pagination postsPerPage={facturesPerPage} totalPosts={dataFactures.length} paginate={paginate} currentPage={currentPage}/></div>
            </div>
            {addFacturesModalOpen && <AddFacture close={closeModal} fetchFactures={fetchFactures}/>}
            {openPopupDelFacture && <PopupDelFacture closeModale={closeModal} factureId={factureId} fetchFacture={fetchFactures}/>}

        </div>
    )
}
export default MesFactures