export const calculateTTC = (ht, tva) => {
    // check if ht and tva are numbers, if they are strings, convert them to numbers
    if (typeof ht === 'string')
        ht = parseFloat(ht)
    if (typeof tva === 'string')
        tva = parseFloat(tva)

    // check if ht and tva are numbers
    if (isNaN(ht) || isNaN(tva))
        return null

    // convert decimal to integer to avoid floating point errors
    ht = ht * 100

    // calculate TTC
    const ttc = ht + (ht * tva / 100)
    // convert back to decimal
    // TODO: Respecter les règles d'arrondis quand celles-ci seront données
    return (ttc / 100).toFixed(2)
}

export const calculateHT = (ttc, tva) => {
    // check if ttc and tva are numbers, if they are strings, convert them to numbers
    if (typeof ttc === 'string')
        ttc = parseFloat(ttc)
    if (typeof tva === 'string')
        tva = parseFloat(tva)

    // check if ttc and tva are numbers
    if (isNaN(ttc) || isNaN(tva))
        return null

    // convert decimal to integer to avoid floating point errors
    ttc = ttc * 100

    // calculate HT
    const ht = ttc / (1 + tva / 100)
    // convert back to decimal
    // TODO: Respecter les règles d'arrondis quand celles-ci seront données
    return (ht / 100).toFixed(2)
}