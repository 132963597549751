import { createSlice } from "@reduxjs/toolkit";

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        messages: { data: [], count: 0 },
        otherNotifications: { data: [], count: 0 }, 
    },
    reducers: {
        setMessages: (state, action) => {
            state.messages.data = action.payload.data;
            state.messages.count = action.payload.count;
        },
        setOtherNotifications: (state, action) => {
            state.otherNotifications.data = action.payload.data;
            state.otherNotifications.count = action.payload.count;
        },
    },
});

export const { setMessages, setOtherNotifications } = notificationsSlice.actions;

export default notificationsSlice.reducer;

export const getMessages = (state) => state.notifications.messages;

export const getOtherNotifications = (state) => state.notifications.otherNotifications;
