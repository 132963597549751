// Copied from affectAperc.js component
import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import BackupIcon from "@material-ui/icons/Backup";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { PulseLoader } from "react-spinners";
import { toast } from "react-toastify";

import {getItem} from "../../services/LocaleStorage";
import ModalCategory from "./modalCategory";
import {getUser} from "../../app/user";
import http from "../../services/Http";
import PopupDelPiece from "../Tables/PopupDelPiece";
import PopupAffectAlreadyExist from "../Tables/PopupAffectAlreadyExist";
import {setLoaderSubscribe, setLoaderUnsubscribe} from "../../app/loader";
import {calculateHT, calculateTTC} from "../../utils/calculations";
import CurrencyInput from "../../utils/CurrencyInput";
import {getBankAccounts} from "../../app/filters";
import Pagination from "../Pagination";
import calendrier from "../../assets/img/calendrier.png";
import wallet from "../../assets/img/wallet.png";
import deleteSmall from "../../assets/img/deleteSmall.png";
import deleteVerySmall from "../../assets/img/deleteVerySmall.png";
import deleteImg from "../../assets/img/delete.png";

const DetailsModal = ({
    close, 
    rowToAffect, 
    scrollToRow,
    fetchResources, 
    isLastInvoice,
}) => {

    const dispatch = useDispatch()

    const [categories, setCategories] = useState([])
    const [dateOperation, setDateOperation] = useState('')

    const [affectation, setAffectation] = useState({label: '', amount_with_vat: '', vat: '', amount: '' })
    const [nameFile, setNameFile] = useState('')
    const [extensionFile, setExtensionFile] = useState('')

    const [fileInBDD, setFileInBDD] = useState(false)
    //Va cacher des section et afficher le tableau des opérations bancaire
    const [openTabOpBancaire, setOpenTabOpBancaire] = useState(false)
    const [openPopupDelPiece, setOpenPopupDelPiece] = useState(false)
    const [openPopupAffectAlreadyExist, setOpenPopupAffectAlreadyExist] = useState(false)
    const [opBancaireSelected, setOpBancaireSelected] = useState([]);
    const [affectationsInvoice, setAffectationsInvoice] = useState([]);
    const [selectedCat, setSelectedCat] = useState(null);
    const [searchTermsCategory, setSearchTermsCategory] = useState("");
    const [searchTermsTimer, setSearchTermsTimer] = useState(null);
    const [opPagination, setOpPagination] = useState({ per_page: 10, page: 1})

    // eslint-disable-next-line no-unused-vars
    const [sortTerms, setSortTerms] = useState("operation_date");
    // eslint-disable-next-line no-unused-vars
    const [sortOrder, setSortOrder] = useState("DESC");

    const [opBancaires, setOpBancaires] = useState({data: [], count: 0 });
    const [startDate, setStartDate] = useState(new Date().getFullYear() + "-01-01");
    const [endDate, setEndDate] = useState(new Date().getFullYear() + "-12-31");
    const [selectedBankAccount, setSelectedBankAccount] = useState(null);
    const [selectedOp, setSelectedOp] = useState(null);
    const [operationsFilters, setOperationsFilters] = useState(
        {
            searchTerms: "",
            minAmount: "",
            maxAmount: "",
        }
    );

    const bankAccounts= useSelector(getBankAccounts)

    const [isSubmissionLoading, setIsSubmissionLoading] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [numPiece, setNumPiece] = useState(null)

    const [comment, setComment] = useState(rowToAffect.comment);
    const [vat, setVat] = useState({ amount: rowToAffect.vat_value, rate: rowToAffect.vat });

    useEffect(() => {
        setOpPagination({ ...opPagination, page: 1})
    }, [startDate, endDate, selectedCat, selectedBankAccount]);

    useEffect(() => {
        if (selectedCat !== null) {
            fetchOperationsBancaires()
        }
    }, [opPagination]);
    const delImage = () => {
        document.getElementById('invoice-receipt-input').value = null
        setNameFile('')
    }

    //charger un fichier
    const declenchInput = () => {
        document.getElementById('invoice-receipt-input').click()
    }

    const changeInput = (e) => {
        if (e.target.files.length === 0) {
            setNameFile('')
            setExtensionFile('')
        } else {
            // take all elements of the file name except the last one
            setNameFile(e.target.files[0].name.split('.').slice(0, -1).join('.')) // jean.pierre.jpg => jean.piere
            setExtensionFile(e.target.files[0].name.split('.').at(-1)) // jean.pierre.jpg => jpg
        }
    }

    const openFile = () => {
        if (nameFile !== "" && affectation.receipt_path  !== null) {
            window.open(process.env.REACT_APP_STORAGE_URL + affectation.receipt_path, '_blank');
        }
    }

    useEffect(() => {
        if (affectation.id) return
        if (rowToAffect.receipt_path !== null) {
            let file = rowToAffect.receipt_path.split('/')
            file = file[file.length - 1]
            file = file.split('.')
            // take all elements of the file name except the last one
            setNameFile(file.slice(0, -1).join('.'))
            setExtensionFile(file.at(-1))
            setDateOperation(rowToAffect.operation_date.split(' ')[0])
            setAffectation(rowToAffect)
            setFileInBDD(rowToAffect.receipt_path !== null)
        } else {
            setAffectation(rowToAffect)
            setDateOperation('')
            setNameFile('')
            setExtensionFile('')
        }
        setSelectedCat(rowToAffect.sub_category)
    }, []);

    useEffect(() => {
        const baliseImgModal = document.getElementById('balise-img-modal')
        if (fileInBDD && extensionFile !== "") {
            if (baliseImgModal.src === process.env.REACT_APP_STORAGE_URL + affectation.receipt_path) return
            baliseImgModal.setAttribute('src', process.env.REACT_APP_STORAGE_URL + affectation.receipt_path)
        } else if (nameFile !== "" && extensionFile !== "") {
            // if (baliseImgModal.src === )
            baliseImgModal.setAttribute('src', URL.createObjectURL(document.getElementById('invoice-receipt-input').files[0]))
        }

    }, [fileInBDD, nameFile, affectation, extensionFile]);

    const dragNDrop = (e) => {
        document.getElementById('invoice-receipt-input').files = e.dataTransfer.files
        if (document.getElementById('invoice-receipt-input').files.length !== 0) {
            setNameFile(document.getElementById('invoice-receipt-input').files[0].name.split('.').slice(0, -1).join('.'))
            setExtensionFile(document.getElementById('invoice-receipt-input').files[0].name.split('.').at(-1))
        }
    }

    const fetchCategories = async() => {
        if (!['achat', 'vente'].includes(rowToAffect.type)) return;
        const token = getItem('bbbToken');

        let response = await fetch(process.env.REACT_APP_API_URL +'/categories/' + rowToAffect.type , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({searchTerms: searchTermsCategory})
        });

        if (response.status === 200){
            let body = await response.json()
            setCategories(body)
        }
    }

    // Search Pop Up: Hiding the Pop Up
    const hideSearchPopUp = () => {
        document.querySelector('.search-pop-up').classList.add('hide-pop-up')
        document.body.style = "overflow: auto"
    }

    const uploadInvoice = async () => {
        //Envoie de la requête si l'input contient un fichier sinon return
        if (document.getElementById('invoice-receipt-input').files.length === 0 && !nameFile) {
            toast.info('Veuillez choisir un fichier')
            return
        }

        const token = getItem('bbbToken')
        const authUrl = process.env.REACT_APP_API_URL + '/invoice/' + affectation.id
        const formdata = new FormData(document.getElementById('send-invoice'));
        formdata.append('type', affectation.type)
        formdata.append("client_id", frontUser.id)

        let response = await fetch(authUrl, {
            method: 'POST', body: formdata, headers: {
                'Authorization': 'Bearer ' + token
            }
        });

        if (response.status === 200) {
            setFileInBDD(true)
            let body = await response.json()

            setAffectation(body)
            // setNameFile(response.receipt_path)
            let file = body.receipt_path.split('/')
            file = file[file.length - 1]
            file = file.split('.')
            // take all elements of the file name except the last one
            setNameFile(file.slice(0, -1).join('.'))
            setExtensionFile(file.at(-1))
            fetchResources()
            // close()
            toast.success('La facture a été modifié avec succès')
        } else {
            toast.error('La modification de la facture a échoué')
        }
    }

    const handleSubmit = async () => {
        const token = getItem('bbbToken')

        setIsSubmissionLoading(true);

        if(nameFile !== ""){
            uploadInvoice()
        }

        //Modification des données de la facture modale affectation
        let form = new FormData(document.getElementById('affectation-operation'));

        form.append('client_id', frontUser.id)
        form.append('vat', affectation.vat ?? 0)
        form.append('comment', comment ?? '');
        const amount = parseFloat(affectation.amount)
        const amountWithVat = parseFloat(affectation.amount_with_vat)
        if (typeof amount === 'number' && typeof amountWithVat === 'number') {
            form.append('vat_value', (((amountWithVat * 100) - (amount * 100)) / 100).toFixed(2))
        }

        const url = '/invoice/' + affectation.id
        await fetch(process.env.REACT_APP_API_URL + url, {
            method: 'POST', headers: {
                'Authorization': 'Bearer ' + token,
            }, body: form
        })
        .then(response => {
            if (response.ok) {
                fetchResources();
                setIsSubmissionLoading(false);

                response.json().then(newInvoice => {
                    setAffectation(newInvoice);
                    close();
                })
                .catch(() => {
                    toast.error("Erreur lors de la modification");
                })
                toast.success('Mise à jour des informations de la facture avec succès.');
                scrollToRow();
            }
        })
        .catch(() => {
            setIsSubmissionLoading(false);
            toast.info("Erreur lors de la modification");
        });

        //Création des affectations
        // Promise.all(opBancaireSelected.map((op) => {
        //     let form = new FormData()
        //     form.append('invoice_id', affectation.id)
        //     form.append('transaction_id', op.id)
        //     form.append('comment', 'mon comm daffect')
        //     form.append('amount', op.amount)

        //     if (selectedCat) form.append('sub_category_id', selectedCat.id)

        //     const url = '/affectation'
        //     return fetch(process.env.REACT_APP_API_URL + url, {
        //         method: 'POST', headers: {
        //             'Authorization': 'Bearer ' + token,
        //         }, body: form
        //     })
        // })).then(resp => {
        //     if (resp.some((r) => !r.ok)) {
        //         toast.error('L\'affectation a échoué')
        //         close()
        //     } else {
        //         toast.info('L\'affectation s\'est bien passée')
        //         fetchOperationsBancaires()
        //         close()
        //     }
        // })
    }

    const fetchAffectationInvoice = () => {
        // Chargement des affectations d'une invoice ici
        const token = getItem('bbbToken')

        let endpoint = '/affectations?include[]=transaction&invoice_id=' + rowToAffect.id
        http.get(endpoint, {
            'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token
        })
        .then((data) => {
            //data contient un obj affectation, invoice et transaction
            setAffectationsInvoice(data.affectations)
        })
        .catch(() => {
            setAffectationsInvoice([])
        });
    }

    //Concerne le tableau des opérations bancaire
    const frontUser = useSelector(getUser)
    const token = getItem('bbbToken')

    useEffect(() => {
        if (searchTermsTimer) {
            clearTimeout(searchTermsTimer)
        }
        setSearchTermsTimer(setTimeout(() => {
            fetchCategories()
        }, 500))
    }, [searchTermsCategory]);

    useEffect(() => {
        fetchAffectationInvoice()
    }, [affectation]);

    const closeModale = () => {
        setOpenPopupDelPiece(false)
        setOpenPopupAffectAlreadyExist(false)
    }

    const selectOperation = (op) => {
        const token = getItem('bbbToken')

        //Vérifier si l'opération à déjà une pièce affecté.
        let endpoint = '/affectations?transaction_id=' + op.id
        dispatch(setLoaderSubscribe())
        http.get(endpoint, {
            'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token
        })
        .then((data) => {
            if (data.affectations.length === 0) {
                setOpenTabOpBancaire(false)

                let form = new FormData()
                form.append('invoice_id', affectation.id)
                if (numPiece) form.append('reference', numPiece)
                form.append('transaction_id', op.id)
                form.append('comment', 'commentaire')
                form.append('amount', op.amount)

                if (selectedCat) form.append('sub_category_id', selectedCat.id)

                const url = '/affectation'
                return fetch(process.env.REACT_APP_API_URL + url, {
                    method: 'POST', headers: {
                        'Authorization': 'Bearer ' + token,
                    }, body: form
                })
                .then(async resp => {
                    if (resp.ok) {
                        const body = await resp.json()
                        console.log(body)
                        setAffectationsInvoice([...affectationsInvoice, body])
                        toast.info('L\'affectation s\'est bien passée')
                        fetchOperationsBancaires()
                        dispatch(setLoaderUnsubscribe())

                    } else {
                        toast.error('L\'affectation a échoué')
                        dispatch(setLoaderUnsubscribe())

                    }
                })
            } else {
                // L'opération a déja une pièce attaché alors on affiche une modale
                setOpenPopupAffectAlreadyExist(true)
            }
            dispatch(setLoaderUnsubscribe())
        })
        .catch(() => {
            dispatch(setLoaderUnsubscribe())
        });
    }

    /*Supprime la ligne d'affectation selectionné*/
    const deleteAffectation = (op) => {
        const updatedReturnOpBancaire = opBancaireSelected.filter(ope => ope.id !== op.id)
        setOpBancaireSelected(updatedReturnOpBancaire)
    }

    /*Supprime la ligne en BDD*/
    const deleteBDDAffectationInvoice = (affectation) => {
        dispatch(setLoaderSubscribe())
        fetch(process.env.REACT_APP_API_URL + '/affectation/' + affectation.id, {
            method: 'DELETE', requirements: token, headers: {
                'Authorization': 'Bearer ' + token
            },
        })
        .then(response => {
            if (response.ok) {
                dispatch(setLoaderUnsubscribe())
                // console.log('je supp')
                fetchOperationsBancaires()
                fetchAffectationInvoice()
                toast.success('L\'affectation a été supprimé avec succès.')
            } else {
                dispatch(setLoaderUnsubscribe())
            }
        })
        .catch(() => {
            dispatch(setLoaderUnsubscribe())
        });
    }

    const fetchOperationsBancaires = async (perPage = opPagination.per_page, page = opPagination.page) => {
        if (!selectedCat) return;

        let updateOperationFilters = {}
        Object.entries(operationsFilters).forEach(([key, value]) => {
            if (value !== "") {
                updateOperationFilters[key] = value
            }
        })

        let body = {
            client_id: frontUser.id,
            type: rowToAffect.type === 'achat' ? 'achat' : 'vente',
            page: page,
            per_page: perPage,
            bank_account_id: selectedBankAccount === "Tous" ? null : selectedBankAccount,
            sort: {
                field: sortTerms,
                order: sortOrder
            },
            filters: {...updateOperationFilters, sub_category_id: selectedCat.id}
        }

        if(startDate !== "" && startDate !== null){
            body.from_operation_date = startDate
        }
        if(endDate !== "" && endDate !== null){
            body.to_operation_date = endDate
        }
        let response = await fetch(process.env.REACT_APP_API_URL +'/filter-transactions' , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(body)

        })
        if (response.status === 200){
            let body = await response.json()
            setOpBancaires({data: body?.data ?? [], count: body?.count ?? 0 })
        } else {
            setOpBancaires({data: [], count: 0 })
        }
    }

    // --- Partie Sub_cat ---
    const setCat = (cat) => {
        setSelectedCat(cat)
        hideSearchPopUp()
    }

    useEffect(() => {
        if (selectedCat?.is_vat_blocked) {
            updateCalculs("vat", selectedCat.vat)
        }
    }, [selectedCat]);

    const updateCalculs = (newValuePropName, newValue) => {
		let newValues = {[newValuePropName]: newValue}

        const updateVatAndAmount = (newAmount, vatRate, amountWithVat = affectation.amount_with_vat) => {
            const vatAmount = ((amountWithVat - newAmount) * 100).toFixed(2) / 100;
            setVat({ amount: vatAmount.toFixed(2), rate: vatRate });
            return vatAmount;
        };

        switch (newValuePropName) {
            case "vat": {
                const newAmount = calculateHT(affectation.amount_with_vat, newValue);
                newValues.amount = newAmount;
                updateVatAndAmount(newAmount, newValue);
                break;
            }
            case "amount": {
                const newAmountWithVat = calculateTTC(newValue, vat.rate);
                newValues.amount_with_vat = newAmountWithVat;
                updateVatAndAmount(newValue, vat.rate, newAmountWithVat);
                break;
            }
            case "amount_with_vat": {
                const newAmount = calculateHT(newValue, vat.rate);
                newValues.amount = newAmount;
                updateVatAndAmount(newAmount, vat.rate, newValues.amount_with_vat);
                newValues.amount_with_vat = newValue;
                break;
            }
            default: {
                const newAmount = calculateHT(newValue, vat.rate);
                newValues.amount = newAmount;
                updateVatAndAmount(newAmount, vat.rate, newValues.amount_with_vat);
                newValues.amount_with_vat = newValue;
                break;
            }
        }

      	setAffectation({...affectation, ...newValues})
    }

    useEffect(() => {
        if(selectedOp !== null){
            console.log(selectedOp)
            // selectOperation(selectedOp)
        }
        affectationsInvoice.map(affecta => {
            console.log(affecta.transaction.id)
            if(affecta.transaction.id === selectedOp){
                console.log("meme id")
            }
        })
    }, [selectedOp]);

    const reset = () => {
        setOpenTabOpBancaire(false);
        setSelectedOp(null)
    }

    const handleChange = (e) => {
        setComment(e.target.value);
    }

    const emptyComment = () => {
        setComment('');
    }

    return (
        <React.Fragment>
            <div className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
                <div className="w-11/12 xl:w-[1000px] 2xl:w-[1319px]  h-11/12 xl:h-[550px]  overflow-y-scroll m-auto bg-white rounded-lg shadow-xl p-8 z-100 relative">
                    <h3  className="font-bold text-xl pb-4">
                        {!openTabOpBancaire ? "Détails du document" : "Suggestions d'opérations bancaires à associer"}
                    </h3>
                    <button 
                        className="absolute top-0 right-0 px-2 rounded-xl m-4 z-100" 
                        onClick={()=> {return openTabOpBancaire ? reset() : close() }}
                    >
                        <CloseIcon/>
                    </button>
                    <section className="flex justify-between affect-aperc relative h-auto">
                        <div className="affect w-full mr-4">
                            <div className="op-label">
                                {!openTabOpBancaire &&  <form id="affectation-operation" className="flex">
                                    <table className="mr-12">
                                        <thead>
                                            <tr>
                                                <th>DATE</th>
                                                <th>LIBELLÉ DU DOCUMENT</th>
                                                {rowToAffect.type == 'vente' ? 
                                                    <th>Num pièce</th> 
                                                : null}
                                                <th>Montant HT</th>
                                                <th>TVA</th>
                                                <th>Montant TTC</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <input 
                                                        className="input-operation-vente" 
                                                        type="date" 
                                                        name="operation_date"
                                                        defaultValue={dateOperation}
                                                    />
                                                </td>
                                                <td className="bg-none">
                                                    <div className="flex flex-col">
                                                        <input 
                                                            name="label"
                                                            type="text" 
                                                            tabIndex={1}
                                                            className="input-operation-vente"
                                                            onChange={e => setAffectation({ ...affectation, label: e.target.value })}
                                                            value={affectation.label}       
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                <CurrencyInput
                                                    tabIndex={2}
                                                    name="amount"
                                                    value={affectation.amount}
                                                    onChange={(newValue) => updateCalculs('amount', newValue)}
                                                    onBlur={() => {
                                                        if (affectation.amount === "") {
                                                            updateCalculs('amount', "0.00");
                                                        }
                                                    }}
                                                />
                                                </td>
                                                <td>
                                                    <span className="input-operation-vente font-normal">
                                                        {vat.amount} € (<span className="grayed-text">{affectation.vat} %</span>)
                                                        <input type="hidden" name="vat_value" value={vat.amount} />
                                                        <input type="hidden" name="vat" value={affectation.vat} />
                                                    </span>
                                                </td>
                                                <td>
                                                    <CurrencyInput
                                                        tabIndex={3}
                                                        name="amount_with_vat"
                                                        value={affectation.amount_with_vat}
                                                        onChange={(newValue) => updateCalculs('amount_with_vat', newValue)}
                                                        onBlur={() => {
                                                            if (affectation.amount_with_vat === "") {
                                                                updateCalculs('amount_with_vat', "0.00");
                                                            }
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </form>}
                            </div>

                            {/*Affiche les affectations selectionné*/}
                            {openTabOpBancaire ? null : opBancaireSelected.map((op, index) => {
                                return <React.Fragment key={op.id}>
                                    <h3 className="mt-8">Affectation {index + 1}</h3>
                                    <div className="flex justify-between items-center">
                                        <table className="mr-2 affectations-piece">
                                            <thead>
                                                <tr>
                                                    <th>DATE</th>
                                                    <th>LIBELLÉ DES OPÉRATIONS</th>
                                                    <th>Montant HT</th>
                                                    <th>TVA</th>
                                                    <th>Montant TTC</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><span>{op.displayable_operation_date}</span></td>
                                                    <td>
                                                        {op.sub_category?.display_name ? 
                                                            <span className="truncate" title={op.sub_category?.display_name}>
                                                                {op.sub_category?.display_name}
                                                            </span> 
                                                        : null}
                                                        <p>{op.label}</p>
                                                    </td>
                                                    <td><span>{op.amount}</span></td>
                                                    <td><span>{op.vat}</span></td>
                                                    <td><span>{op.amount_with_vat}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="w-12">
                                            <button 
                                                className="bg-[#C4C4C4] p-2 rounded-lg text-white shrink"
                                                onClick={() => deleteAffectation(op)}
                                            >
                                                <img src={deleteVerySmall} className="w-4 h-4" alt="delete"/>
                                            </button>
                                        </div>
                                    </div>
                                </React.Fragment>
                            })}
                            {!openTabOpBancaire && <div className="w-full">
                                <div className="flex w-full">
                                    <div className="w-full flex items-end flex-col">
                                        <div className="flex items-center mr-12 mt-4">
                                            <p className="text-gray-500 uppercase">tva</p>
                                            <div className="flex relative justify-between bg-grey rounded-xl py-2 px-2 ml-4">
                                                {selectedCat?.is_vat_blocked || (selectedCat === null && affectation.sub_category?.is_vat_blocked)
                                                    ? <select className="bg-grey w-full" disabled defaultValue={selectedCat?.vat ?? affectation.sub_category?.vat}>
                                                        <option value={selectedCat?.vat ?? affectation.sub_category?.vat}>
                                                            {selectedCat?.vat ?? affectation.sub_category?.vat}%
                                                        </option>
                                                    </select>
                                                    : 
                                                    <>
                                                        <span className="">{Number(affectation.vat)}%</span>
                                                        <ArrowDropDownIcon/>
                                                        <select 
                                                            className="bg-grey absolute w-full h-full opacity-0 top-0 left-0" 
                                                            value={affectation.vat}
                                                            onChange={e => updateCalculs('vat', e.target.value)}
                                                        >
                                                            <option value="20.00">20%</option>
                                                            <option value="13.00">13%</option>
                                                            <option value="10.00">10%</option>
                                                            <option value="8.50">8.5%</option>
                                                            <option value="5.50">5.5%</option>
                                                            <option value="2.10">2.1%</option>
                                                            <option value="0.00">0%</option>
                                                        </select>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className="items-center mr-12 mt-4 relative">
                                            <textarea
                                                ame="comment" 
                                                className="bg-grey p-4 rounded-xl placeholder:italic placeholder:text-slate-400" 
                                                style={{ resize: 'none', height: '235px', minWidth: '300px', width: '100%', borderBottom: '35px solid rgb(238 238 238)' }}
                                                placeholder="Écrivez ici votre commentaire..."
                                                onChange={handleChange}
                                                value={comment ?? ''}
                                            ></textarea>
                                            <img 
                                                src={deleteImg} 
                                                style={{ bottom: '15px', right: '5px' }}
                                                className="w-6 h-6 absolute cursor-pointer" 
                                                alt="delete"
                                                onClick={emptyComment}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Affiche les affectations en bdd*/}
                                {!openTabOpBancaire && <div className="flex flex-col mt-b ">
                                    {affectationsInvoice.length !== 0 ?  <h2 className="font-bold text-xl pt-10">Opérations bancaires associées</h2> : null}

                                    {affectationsInvoice[0]?.transaction === null ? null : affectationsInvoice.map(affecta => {
                                        return (
                                            <React.Fragment key={affecta.id}>
                                                <div className="flex justify-between items-center">
                                                    <table className="mr-2 my-2  affectations-piece">
                                                        <thead>
                                                            <tr>
                                                                <th>DATE</th>
                                                                <th>LIBELLÉ DES OPÉRATIONS</th>
                                                                <th>Montant HT</th>
                                                                <th>TVA</th>
                                                                <th>Montant TTC</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="border-b text-center text-xs text-black border-gray-200">
                                                                <td><span>{affecta.transaction.displayable_operation_date}</span></td>
                                                                <td><span>{affecta.transaction.label}</span></td>
                                                                <td><span>{affecta.transaction.amount}</span></td>
                                                                <td><span>{affecta.transaction.vat}</span></td>
                                                                <td><span>{affecta.transaction.amount_with_vat}</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    {/*BDD DELETE*/}
                                                    <div className="w-12">
                                                        <button 
                                                            className="bg-[#C4C4C4] p-2 rounded-lg text-white shrink"
                                                            onClick={() => deleteBDDAffectationInvoice(affecta)}
                                                        >
                                                            <img src={deleteVerySmall} className="w-4 h-4" alt="delete"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                </div>}
                            </div>}
                        </div>
                        {!openTabOpBancaire && <div className="flex flex-col justify-between items-end w-2/6 min-w-[270px] gap-y-4">
                            <div className="text-black bg-gris p-4 rounded-lg flex flex-col h-[319px] w-full sidebar:h-[392px]">
                                <div className="flex justify-between">
                                    <h2 className="text-center text-md pb-4 truncate font-bold cursor-default" 
                                        title={nameFile !== "" ? nameFile : 'Associer un document'} 
                                    >
                                        {nameFile !== '' ? nameFile : 'Associer un document'}
                                    </h2>
                                    {nameFile !== "" && extensionFile !== "" ?
                                        <div 
                                            className="cursor-pointer w-6 h-6 shrink-0" 
                                            onClick={fileInBDD ? () => setOpenPopupDelPiece(true) : delImage}
                                        >
                                            <img src={deleteSmall} className="w-6 h-6" alt="delete"/>
                                        </div> 
                                    : null}
                                </div>
                                <div 
                                    id="upload-fichier"
                                    className={`flex justify-center items-center w-full h-full ${nameFile !== "" ? "" : " border-dashed border-2 border-grisDashed "} rounded-xl mx-auto relative`}
                                >
                                    {nameFile !== "" && extensionFile !== "" ? 
                                        <>
                                            <embed 
                                                className="object-cover w-full h-full rounded-xl"
                                                id="balise-img-modal"
                                                alt="" 
                                                type={extensionFile === "pdf" ? "application/pdf" : "image/" + extensionFile}
                                            />
                                        </>
                                    :
                                        <>
                                            <div 
                                                className=" upload flex flex-col justify-center text-center h-full" 
                                                onClick={declenchInput} onDrop={(e) => { dragNDrop(e)}}
                                            >
                                                <div>
                                                    <button type="button" className="svg-upload"><BackupIcon/></button>
                                                </div>
                                                <div className="text" >
                                                    <h5>Charger un fichier</h5>
                                                    <h6>PDF, DOC, PPT, JPG, PNG</h6>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <form action="" id="send-invoice">
                                        <input 
                                            type="file" 
                                            name="receipt" 
                                            id="invoice-receipt-input"
                                            className="hidden" 
                                            onChange={(e) => changeInput(e)}
                                        />
                                    </form>
                                </div>
                                {nameFile !== "" ? 
                                    <div className="cursor-pointer text-right mt-2">
                                        <a href="#" onClick={openFile}><OpenInNewIcon /></a>
                                    </div>
                                : 
                                    null
                                }
                            </div>
                            <button
                                type="button"
                                className="p-2 bg-greenSuccess rounded-2xl text-white px-8" 
                                onClick={(e) => handleSubmit(e)}
                            >
                                {isSubmissionLoading ? <PulseLoader color={'white'} size={10} /> : 'Valider'}
                            </button>
                        </div>}
                    </section>

                    {/*Affiche une sous-modale de confirmation de suppression de la modale*/}
                    {openPopupDelPiece && 
                        <PopupDelPiece 
                            type={rowToAffect.type}
                            closeModale={closeModale} 
                            closeAffect={close} 
                            fetchResources={fetchResources}
                            idInvoice={affectation.id} 
                            isLastInvoice={isLastInvoice}
                        />
                    }
                    {/*Affiche une sous-modale indiquant que l'opération bancaire choisie a déjà une affectation existante : entourer d'un if*/}
                    {openPopupAffectAlreadyExist && <PopupAffectAlreadyExist closeModale={closeModale}/>}

                    {/*Modal categorie/sous-categorie*/}
                    <section className="search-pop-up hide-pop-up">
                        <div className="layer">
                            <CloseIcon onClick={hideSearchPopUp}/>
                            <div className="items">
                                <div className="flex relative input-field">
                                    <input 
                                        type="text" 
                                        name="search" 
                                        placeholder="Recherche..." 
                                        onChange={(e)=> {setSearchTermsCategory(e.target.value)}}
                                    />
                                    <SearchIcon/>
                                </div>
                                <div id="keywords" className="keywords">
                                    {categories.map((category) => 
                                        <ModalCategory 
                                            key={category.id} 
                                            category={category}
                                            subCats={category.sub_categories}
                                            setCat={setCat} 
                                            hideSearchPopUp={hideSearchPopUp} 
                                            info="invoice" id={affectation.id}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                    {openTabOpBancaire && <section className="flex">
                        {openTabOpBancaire && <section className="fill-black mr-12 top-30">
                            {/* Searchbar*/}
                            <div className="w-[17.5rem]">
                                <div className="rounded-xl flex relative border border-[#EEEEEE] shadow-md h-[2.3rem] sidebar:h-[2.7rem] items-center text-xs sidebar:text-base sidebar:py-1">
                                    <input 
                                        type="text" 
                                        className="w-5/6 rounded-xl pl-[1rem]" 
                                        name="search" 
                                        placeholder="Recherche..." 
                                        value={operationsFilters.searchTerms} 
                                        onChange={(e) => setOperationsFilters({ ...operationsFilters, searchTerms: e.target.value })}
                                    />
                                    <SearchIcon/>
                                </div>
                                <div className="w-full flex pt-[1.2rem]">
                                    <div className=" w-1/2 mr-2 rounded-xl flex h-[2.3rem] relative border border-[#EEEEEE] sidebar:py-0.5">
                                        <input 
                                            type="number" 
                                            name="min" 
                                            className="w-full px-4 py-1 rounded-xl text-xs sidebar:text-base" 
                                            placeholder="Min TTC" 
                                            onChange={(e) => setOperationsFilters({ ...operationsFilters, minAmount: e.target.value })} 
                                            value={operationsFilters.minAmount}
                                        />
                                    </div>
                                    <div className=" w-1/2 ml-2 rounded-xl flex h-[2.3rem] relative border border-[#EEEEEE] sidebar:py-0.5">
                                        <input 
                                            type="number" 
                                            className="w-full px-4 py-1 rounded-xl text-xs sidebar:text-base" 
                                            name="max" 
                                            placeholder="Max TTC" 
                                            onChange={(e) => setOperationsFilters({ ...operationsFilters, maxAmount: e.target.value })} 
                                            value={operationsFilters.maxAmount}
                                        />
                                    </div>
                                </div>
                                <div className="bg-lila mt-[1.2rem] px-4 sidebar:py-1 rounded-lg flex h-[2.3rem] relative border border-[#EEEEEE]">
                                    <button 
                                        type="button" 
                                        className="text-white font-semibold sidebar:text-lg text-xs text-center w-full"
                                        onClick={() => setOpPagination({...opPagination, page: 1})} 
                                    >
                                        Filtrer
                                    </button>
                                </div>

                            </div>
                            {/*end searchbar*/}

                            <div className="mb-2 rounded-xl pick-input mt-10">
                                <div className="w-full flex">
                                    <img src={calendrier} alt="date"/>
                                    <h4 className="font-normal">Période Sélectionnée</h4>
                                </div>
                                <div className="flex justify-between w-[17.5rem] mt-[1.5rem] sidebar:mt-[0.9rem]">
                                    <div className=" px-[0.9rem] flex justify-center w-[48%] rounded-lg border h-[2.3rem] sidebar:py-1 sidebar:h-[2.7rem] items-center">
                                        <div className="flex text-xs items-center dates-filtre font-bold ">
                                            <span className="uppercase">Du</span>
                                            <input 
                                                id="input-period-from" 
                                                type="date" 
                                                className="bg-none-input custom-calendar" 
                                                min="1990-01-01" 
                                                max="2300-01-01"
                                                onChange={(e) => setStartDate(e.target.value)}
                                                defaultValue={new Date().getFullYear() + "-01-01"}
                                            />
                                        </div>
                                    </div>
                                    <div className=" px-[0.9rem] flex justify-center w-[48%] mt-[1.2rem] sidebar:mt-0 rounded-lg border h-[2.3rem] sidebar:py-1 sidebar:h-[2.7rem] items-center">
                                        <div className="flex text-xs items-center dates-filtre font-bold ">
                                            <span className="uppercase">AU</span>
                                            <input 
                                                id="input-period-to" 
                                                type="date" 
                                                className="bg-none-input custom-calendar" 
                                                min="1990-01-01" 
                                                max="2300-12-31"
                                                onChange={(e) => setEndDate(e.target.value)}
                                                defaultValue={new Date().getFullYear() + "-12-31"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2 mt-8 pick-input">
                                <div className="w-full flex mb-[0.9rem]">
                                    <img className="w-[25px] sidebar:w-[30px]" src={wallet} alt="wallet"/>
                                    <h4>Compte bancaire</h4>
                                </div>
                                <form className="mb-2 mt-2 w-full flex input-field-popup ml-0">
                                    <select 
                                        className="bg-transparent" 
                                        onChange={(e)=> setSelectedBankAccount(e.target.value)}
                                    >
                                        <option value="Tous">Tous</option>
                                        {bankAccounts.map((bankAccount) => 
                                            <option key={bankAccount.id} value={bankAccount.id}>{bankAccount.name}
                                            </option>
                                        )}
                                    </select>
                                    <ArrowDropDownIcon size="small" className="pointer-events-none"/>
                                </form>
                            </div>
                        </section>}

                        <div className="flex flex-col w-full">
                            {openTabOpBancaire && opBancaires.data.map((operation) => {
                                    return affectationsInvoice.some(elem => elem.transaction?.id === operation.id) ? null : <div key={operation.id} className="w-full relative">
                                        <div className="justify-between items-center flex">
                                            <input 
                                                type="radio" 
                                                name="op-to-affect" 
                                                data-id={operation.id} 
                                                className="op-radio"
                                                onClick={()=> {setSelectedOp(operation)}}
                                            />
                                            <table className="bg-[#f2f5ff] rounded-lg shadow-none shadow-inherit w-[97%] max-h-[106px] mt-8">
                                                <thead>
                                                    <tr>
                                                        <th className="p-3.5 text-[#717579] font-semibold text-left">Date</th>
                                                        <th className="p-3.5 text-[#717579] font-semibold text-left">Libellé de l'opération</th>
                                                        <th className="p-3.5 text-[#717579] font-semibold text-left">Montant HT</th>
                                                        <th className="p-3.5 text-[#717579] font-semibold text-left">TVA</th>
                                                        <th className="p-3.5 text-[#717579] font-semibold text-left">Montant TTC</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr key={operation.id}>
                                                        <td className="text-[#171b1e] p-3.5 align-bottom">
                                                            <span>{operation.displayable_operation_date}</span>
                                                        </td>
                                                        <td className="text-[#171b1e] p-3.5">
                                                            <div className="flex">
                                                                <span className="text-black">{operation.sub_category !== null ? operation.sub_category.display_name : ""}</span>
                                                            </div>
                                                            <span>{operation.label}</span>
                                                        </td>
                                                        <td className="text-[#171b1e] p-3.5 align-bottom">{operation.amount}</td>
                                                        <td className="text-[#171b1e] p-3.5 align-bottom"> {operation.vat}</td>
                                                        <td className="text-[#171b1e] p-3.5 align-bottom">{operation.amount_with_vat}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                })
                            }
                            <div className={`flex ${opBancaires.data.length !== 0 ? "justify-between items-center" : "justify-end"}`}>
                                <Pagination 
                                    data={{...opPagination, count: opBancaires.count}} 
                                    paginate={(per_page, page) => setOpPagination({ ...opPagination, per_page, page })}
                                />
                                {opBancaires.data.length !== 0 ?
                                    <div>
                                        <button 
                                            className={`bg-vertBouton rounded-lg px-6 py-2 text-white ${selectedOp === null ? "cursor-not-allowed opacity-50" : ""}`} 
                                            onClick={() => selectedOp !== null ? selectOperation(selectedOp) : null}
                                        >
                                            Valider
                                        </button>
                                    </div> 
                                : null}
                            </div>
                        </div>
                    </section>}
                </div>
            </div>
        </React.Fragment>
    );
}

export default DetailsModal;