import React, {useState, useEffect} from 'react'
import OperationsBancaires from "../components/dashboard/OperationsBancaires";
import StatsAndGraphs from "../components/Mes Simus/statsAndGraphs";
import '../assets/style/dashboard.css'
import {useDispatch} from "react-redux";
import {setPageTitle} from "../app/filters";
import {useLocation} from "react-router-dom";
import {getItem} from "../services/LocaleStorage";

const DashboardPage = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    useEffect(() => {
        dispatch(setPageTitle('Ma Gestion'));
    }, [])

    // Incldue a shared state between the two components to reload StatsAndGraphs from its sibling OperationsBancaires
    const [dataVersion, setDataVersion] = useState(0);
    const refreshStatsAndGraphs = () => {
        setDataVersion(prevVersion => prevVersion + 1);
    }

    const informAPI = async (bankAccountId, connectionId) => {
        const token = getItem("bbbToken")
        let endpoint = '/bank_account/connection-succeeded?bank_account_id=' + bankAccountId + '&connection_id=' + connectionId

        const response =  await fetch(process.env.REACT_APP_API_URL + endpoint, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        if(response.status === 200){
            window.location.href = '/dashboard'
        }
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        if(searchParams.get('linxo_redirect') && searchParams.get('bbb_bank')){
            informAPI(searchParams.get('bbb_bank'), searchParams.get('connection_id'))
        }
    }, [])

    return (

        <div className="w-full content dashboard">
            {dataVersion > 0 ? <StatsAndGraphs key={dataVersion} /> : null}
            <OperationsBancaires refreshStatsAndGraphs={refreshStatsAndGraphs} />
        </div>
    )
}

export default DashboardPage