import React, {forwardRef, useImperativeHandle, useState} from 'react'
import {NavigateBefore, NavigateNext} from "@material-ui/icons";
import MobileFraisTableFragment1 from "./Frais Fragment/MobileFraisTableFragment1";
import MobileFraisTableFragment2 from "./Frais Fragment/MobileFraisTableFragment2";

const MobileFrais = forwardRef((props, ref) => {

    let compteurSliderFrais = 0;

    const slide = (offset) => {
        const newCompteur = compteurSliderFrais +offset
        if(newCompteur <0){
            compteurSliderFrais   = 0
        }else if(newCompteur<2){
            compteurSliderFrais   = newCompteur
        }else{
            compteurSliderFrais  = 1
        }
        let slide1 = document.getElementById("slide-frais-1")
        let slide2 = document.getElementById("slide-frais-2")

        const w = slide1.getBoundingClientRect().width
        const decal = -w * compteurSliderFrais

        const slideArray = [slide1, slide2]
        slideArray.forEach(el =>{
            el.style.transform = `translateX(${decal}px)`
        })
    }

    return (
        <div>
            <div className="w-3/6 flex text-black mt-3">
                <div>
                    <div className="py-1 mb-3 mr-3"> <p>Rémunération estimée</p></div>
                    <div className="py-1 mr-3"> <p>Rémunération actuelle</p></div>
                </div>
                <div className="flex-col">
                    <div className="rounded-lg px-20 py-1 bg-gris  mb-3 w-4 h-8">

                    </div>
                    <div className="rounded-lg px-20 py-1 bg-gris w-4 h-8">
                        <p className="text-lilaFonce text-center">18200</p>
                    </div>
                </div>
            </div>
            <div className="flex overflow-hidden">
                <MobileFraisTableFragment1/>
                <MobileFraisTableFragment2/>
            </div>
            <div className="flex justify-center">
                <button onClick={()=> slide(-1)}><NavigateBefore/></button>
                <button onClick={()=> slide(1)}><NavigateNext/></button>
            </div>
        </div>
    )
})

export default MobileFrais