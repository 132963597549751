import React, { useState } from "react";
import { PulseLoader } from "react-spinners";
import CloseIcon from "@material-ui/icons/Close";

const ExportAccountPopup = ({ closeModal, exportAccount, isLoading }) => {

    const [fileFormat, setFileFormat] = useState(null);

    const triggerExport = async () => {
        if (isLoading) return;
        exportAccount(fileFormat);
    }

    return (
        <div className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center export-account">
            <div className="w-2/3 mx-auto bg-white rounded-lg shadow-xl p-8 z-100">
                <button className="float-right" onClick={closeModal}><CloseIcon/></button>
                <div className="w-full m-8">
                    <h1 className="text-black font-bold mb-8">Export du compte BBB</h1>
                    <div className="w-full">
                        {isLoading ? 
                            "Le fichier sera téléchargé dans un instant"
                        :
                            <ul className="flex space-x-4 justify-center">
                                <li className="list-none">
                                    <input type="radio" id="csv" name="file-format" onChange={() => setFileFormat('csv')} />
                                    <label className="radio-label" htmlFor="csv">CSV</label>
                                </li>
                                <li className="list-none">
                                    <input type="radio" id="excel" name="file-format" onChange={() => setFileFormat('excel')} />
                                    <label className="radio-label" htmlFor="excel">Excel</label>
                                </li>
                                <li className="list-none">
                                    <input type="radio" id="pdf" name="file-format" onChange={() => setFileFormat('pdf')} />
                                    <label className="radio-label" htmlFor="pdf">PDF</label>
                                </li>
                            </ul>
                        }
                    </div>
                    <button 
                        type="submit"
                        className="bg-greenSuccess text-xl font-semibold px-5 py-3 float-right mr-8 mt-8 rounded-xl text-white" 
                        onClick={triggerExport}
                    >
                        {isLoading ? <PulseLoader color={'white'} size={10} /> : 'Export'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ExportAccountPopup;
