import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import '../../assets/style/dashboard.css';
import {getItem} from "../../services/LocaleStorage";
import {getUser} from "../../app/user";
import Loader from "../Loader";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";

const RegistrationClient = () => {
    const user = useSelector(getUser)

    const [isLoading, setIsLoading] = useState(false)

    const [isPasswordHidden, setIsPasswordHidden] = useState(true)
    const [passwordType, setPasswordType] = useState('password')

    const changeVisibility = (val) => {
        setIsPasswordHidden(val)
        setPasswordType(val ? 'password' : 'text')
    }

    //Dirigeants
    const [managers, setManagers] = useState([{
        id: 1,
        first_name: "",
        last_name: "",
        address: "",
        // reference: "",
        email: "",
        phone: "",
        status: "TNS",
        birthdate: ""
    }]);

    const addManagerForm = () => {
        let idd = managers[managers.length - 1].id + 1
        setManagers([...managers, {
            id: idd,
            first_name: "",
            last_name: "",
            address: "",
            // reference: "",
            email: "",
            phone: "",
            status: "TNS",
            birthdate: ""
        }])
    }

    // Supprimer une ligne Dirigeant
    const removeManagerForm = (index) => {
        if (managers.length > 1) {
            let updatedManagers = [...managers]
            updatedManagers.splice(index, 1)
            setManagers(updatedManagers)
        }
    }

    //Comptes bancaires
    const [banksAccounts, setBanksAccounts] = useState([{
        id: 1,
        name: "",
        address: "",
        // code: "",
        iban: "",
        bic: "",
        // balance: "",
        initial_treasury: 0,
        delta_treasury: 0
    }]);

    const addBanksAccounts = () => {
        let idd = banksAccounts[banksAccounts.length - 1].id + 1
        setBanksAccounts([...banksAccounts, {
            id: idd,
            name: "",
            address: "",
            // code: "",
            iban: "",
            bic: "",
            // balance: "",
            initial_treasury: 0,
            delta_treasury: 0
        }])
        console.log(banksAccounts)
    }
    const removeBankForm = (index) => {
        if (banksAccounts.length > 1) {
            let updatedBank = [...banksAccounts]
            updatedBank.splice(index, 1)
            setBanksAccounts(updatedBank)
        }
    }

    //Employés
    const [employes, setEmployes] = useState([{
        id: 1,
        first_name: "",
        last_name: "",
        address: "",
        // reference: "",
        email: "",
        phone: "",
        status: "TNS",
        birthdate: "",
    }]);

    // Ajoute une ligne Employés
    const addEmployes = () => {
        let idd = employes[employes.length - 1].id + 1
        setEmployes([...employes, {
            id: idd,
            first_name: "",
            last_name: "",
            address: "",
            // reference: "",
            email: "",
            phone: "",
            status: "TNS",
            birthdate: "",
        }])
        console.log(employes)
    }
    // Supprime une ligne Employés
    const removeEmployesForm = (index) => {
        if (employes.length > 1) {
            let updatedEmployes = [...employes]
            updatedEmployes.splice(index, 1)
            setEmployes(updatedEmployes)
        }
    }

    //Mise à jour des formulaires bank, employés, managers
    const updateForms = (e, index, form) => {
        let updatedForm = form === "bank" ? banksAccounts : form === "employes" ? employes : managers
        updatedForm[index][e.target.name] = e.target.value
        form === "bank" ? setBanksAccounts(updatedForm) : form === "employes" ? setEmployes(updatedForm) : setManagers(updatedForm)
    }

    /**
     *
     * @param {Array|Object} sourceErrors
     * @param {string} targetedProperty
     * @return Array
     * Force convert errors to array
     * This method could be moved to a sperated plugin so that it can be reused
     */
    const validationErrorsToIndexedArray = (sourceErrors, targetedProperty) => {
        if (!sourceErrors.hasOwnProperty(targetedProperty)) return;

        if (!(Array.isArray(sourceErrors[targetedProperty]) || typeof (sourceErrors[targetedProperty]) === 'object')) return sourceErrors[targetedProperty];

        const responseErrors = []
        var lastKey = 0
        if (Array.isArray(sourceErrors[targetedProperty])) {
            lastKey = sourceErrors[targetedProperty].length - 1
        }
        if (typeof (sourceErrors[targetedProperty]) == 'object') {
            lastKey = Object.keys(sourceErrors[targetedProperty])[Object.keys(sourceErrors[targetedProperty]).length - 1]
        }

        for (var i = 0; i <= lastKey; i++) {
            if (sourceErrors[targetedProperty][i])
                responseErrors[i] = sourceErrors[targetedProperty][i]
            else
                responseErrors[i] = {}
        }

        return responseErrors
    }

    const perepareValidationErrors = (data) => {
        const errors = {}
        for (var prop in data) {
            // Object|Arrray that should be converted
            if (['bank_accounts', 'employees'].includes(prop)) {
                errors[prop] = validationErrorsToIndexedArray(data, prop)
            } else
                errors[prop] = data[prop]
        }
        return errors
    }

    const register = () => {
        const data = {}

        data.managers = managers
        //Supprime la prop id de l'obj manager
        data.managers.forEach(manager => delete manager.id)


        data.employees = employes
        data.employees.forEach(employe => delete employe.id)

        data.bank_accounts = banksAccounts
        data.bank_accounts.forEach(bank => delete bank.id)

        document.querySelectorAll('.infos-register').forEach(input => {
            data[input.name] = input.value
        })
        //Adresse
        data.address = {}
        document.querySelectorAll('.infos-register-address').forEach(input => {
            data.address[input.name] = input.value
        })
        //Bilan entré
        data.last_balance_sheet_entry = {}
        document.querySelectorAll('.infos-register-bilan').forEach(input => {
            data.last_balance_sheet_entry[input.name] = input.value
        })

        const token = getItem('bbbToken')

        fetch(process.env.REACT_APP_API_URL + '/client/create', {
            method: 'POST',
            'Access-Control-Allow-Credentials': true,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then(response => {
            if (response.ok) {
                document.querySelectorAll('.register-error').forEach(span => {
                    span.innerText = ""
                })
                toast.success("Inscription réussie")

                response.json().then(data => {
                    console.log(data)
                    window.open("/dashboard?vusertype=" + data.user.role + "&vuserid=" + data.user.id)
                })


            } else {
                response.json().then(json => {
                    const errors = perepareValidationErrors(json)

                    document.querySelectorAll('.register-error').forEach(span => {
                        span.innerText = ""
                    })
                    console.log(errors)
                    Object.keys(errors).forEach(prop => {
                        if (typeof errors[prop] === "string") {
                           const span =  document.querySelector(".error-" + prop)
                            if(span !== null){
                                span.innerText = errors[prop]
                            }
                        } else if (typeof errors[prop] === "object" && !Array.isArray(errors[prop])) {
                            Object.keys(errors[prop]).forEach(subProp => {
                                const span = document.querySelector(".error-" + prop + "-" + subProp)
                                if (span !== null)
                                    span.innerText = errors[prop][subProp]
                            })
                        } else if (Array.isArray(errors[prop])) {
                            errors[prop].forEach((object, index) => {
                                Object.keys(object).forEach(subProp => {
                                    const span = document.querySelector(".error-" + prop + "-" + index + "-" + subProp)
                                    if (span !== null)
                                        span.innerText = errors[prop][index][subProp]
                                })

                            })
                        }

                    })
                })
                toast.error("L'inscription a échoué")
            }
        }).catch((err) => {
            console.log(err)
        })
    }


    return (

        <React.Fragment>
            <div className="w-full mb-0 content">
                <section className=" mt-28">
                    <Loader active={isLoading}/>
                    <div className="parent-table">
                        <div className="flex flex-col pb-0">
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase bordureTopTd bg-form-blue text-sm font-semibold pl-6"><p>Date
                                    entree</p></div>
                                <div className="w-full h-14 flex flex-col items-center text-black bordureTd bordureTopTd bg-form-blue">
                                    <input type="date" name="entry_date" className="w-full h-12 infos-register bg-form-blue pl-6"/>
                                    <span className="text-red-500 text-xs w-full register-error error-entry_date"/>
                                </div>
                            </div>
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase text-sm font-semibold pl-6"><p>Date_exercice_entree</p>
                                </div>
                                <div className="w-full h-14 flex flex-col items-center text-black bordureTd">
                                    <input type="date" name="exercise_entry_date" className="w-full h-12 infos-register pl-6"/>
                                    <span className="text-red-500 text-xs w-full register-error error-exercise_entry_date"></span>

                                </div>
                            </div>
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase text-sm font-semibold bg-form-blue pl-6"><p>Statut blabliblou
                                    client</p></div>
                                <div className="w-full h-14 flex flex-col items-center text-black bordureTd bg-form-blue">
                                    <select name="status" className="bg-none-input w-full h-12 infos-register bg-transparent pl-6">
                                        <option value="OFFRE">OFFRE</option>
                                        <option value="EN_COURS">EN_COURS</option>
                                        <option value="RESILIE">RESILIE</option>
                                        <option value="RESILIE_BBB">RESILIE_BBB</option>
                                    </select>
                                    <span className="text-red-500 text-xs w-full register-error error-status"></span>

                                </div>
                            </div>
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase text-sm font-semibold pl-6"><p>Login client</p></div>
                                <div className="w-full h-14 flex flex-col items-center text-black bordureTd">
                                    <input type="text" name="email" placeholder="mail_address" className="w-full h-12 infos-register pl-6"/>
                                    <span className="text-red-500 text-xs w-full register-error error-email"></span>
                                </div>
                            </div>
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 flex items-center bordureTd text-black uppercase bg-form-blue text-sm font-semibold pl-6"><p>pwd_client</p></div>
                                <div className="w-full h-14 flex flex-col items-center text-black bg-form-blue bordureTd">
                                    <div className="w-full flex h-10">
                                        <input type={passwordType} name="password" className="w-full h-12 infos-register bg-transparent pl-6"
                                               placeholder="password"/>
                                        <p className="w-1/6 m-auto">
                                            <button type="button" className="w-full h-12"
                                                    onClick={changeVisibility.bind(this, !isPasswordHidden)}>
                                                {isPasswordHidden ? <VisibilityOffIcon fontSize="small"/> :
                                                    <VisibilityIcon fontSize="small"/>}
                                            </button>
                                        </p>
                                    </div>
                                    <div className="h-4 text-red-500 text-xs w-full"><span className="text-red-500 text-xs w-full h-4 register-error error-password"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase text-sm font-semibold pl-6"><p>nom_client</p></div>
                                <div className="w-full h-14 flex flex-col items-center text-black bordureTd">
                                    <input type="text" name="name" placeholder="name" className="w-full h-12 infos-register pl-6"/>
                                    <span className="text-red-500 text-xs w-full register-error error-name"></span>

                                </div>
                            </div>
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase bg-form-blue text-sm font-semibold pl-6"><p>Username</p></div>
                                <div className="w-full h-14 flex flex-col items-center text-black bg-form-blue bordureTd">
                                    <input type="text" placeholder="username" name="username" className="w-full h-12 infos-register bg-transparent pl-6"/>
                                    <span className="text-red-500 text-xs w-full register-error error-username"></span>
                                </div>
                            </div>
                            {/*Contract et username ne figure pas sur le figma*/}
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase text-sm font-semibold pl-6"><p>Contract</p></div>
                                <div className="w-full h-14 flex items-center bordureTd">
                                    <div className="flex h-14 flex-col w-full">
                                        <select name="income_tax" className="bg-none-input text-black w-full h-12 infos-register pl-6">
                                            <option value="BIC">BIC</option>
                                            <option value="BIC">BNC</option>
                                            <option value="BIC">SCI</option>
                                            <option value="BIC">MICRO</option>
                                        </select>
                                        <span className="text-red-500 text-xs w-full register-error error-income_tax"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase bordureTd text-sm font-semibold bg-form-blue pl-6">
                                    <p>Nom commercial client</p>
                                </div>
                                <div className="w-full h-14 flex flex-col items-center text-black bordureTd bg-form-blue">
                                    <input type="text" name="business_name" placeholder="business_name" className="w-full h-12 infos-register bg-transparent pl-6"/>
                                    <span className="text-red-500 text-xs w-full register-error error-business_name"></span>
                                </div>
                            </div>

                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase text-sm font-semibold pl-6"><p>client_status</p></div>
                                <div className="w-full h-14 flex flex-col items-center text-black bordureTd">
                                    <select name="client_status" className="bg-none-input w-full h-12 infos-register pl-6">
                                        <option value="SAS">SAS</option>
                                        <option value="SASU">SASU</option>
                                        <option value="EURL">EURL</option>
                                        <option value="SARL">SARL</option>
                                        <option value="SELARL">SELARL</option>
                                        <option value="Micro">Micro</option>
                                        <option value="EI">EI</option>
                                        <option value="EIRL">EIRL</option>
                                    </select>
                                    <span className="text-red-500 text-xs w-full bg-white h-2 register-error error-client_status"></span>

                                </div>
                            </div>
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase text-sm font-semibold bg-form-blue pl-6"><p>siret_client</p></div>
                                <div className="w-full h-14 flex flex-col items-center text-black bordureTd bg-form-blue">
                                    <input type="text" name="siret" placeholder="siret" className="w-full h-12 infos-register bg-transparent pl-6"/>
                                    <span className="text-red-500 text-xs w-full register-error error-siret"></span>
                                </div>
                            </div>
                            {/*Adresse décomposé*/}
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase  text-sm font-semibold pl-6"><p>Adresse_client</p></div>
                                <div className="w-full h-14 flex items-center text-black bordureTd text-sm">
                                    <div className="flex flex-col h-14 mr-2">
                                        <input type="text" name="num"
                                               className="w-full h-12 rounded mr-2 infos-register-address bg-transparent pl-6"
                                               placeholder="num" size="2"/>
                                        <span className="text-red-500 mr-2 text-xs register-error error-address-num"></span>
                                    </div>
                                    <div className="flex flex-col h-14 mr-2">
                                        <input type="text" name="street"
                                               className="w-full h-12 rounded mr-2 infos-register-address bg-transparent"
                                               placeholder="street"/>
                                        <span className="text-red-500 mr-2 text-xs register-error error-address-street"></span>
                                    </div>
                                    {/*<div className="flex flex-col h-14 mr-2">*/}
                                    {/*    <input type="text" name="comp"*/}
                                    {/*           className="w-full h-12 rounded mr-2 infos-register-address bg-transparent"*/}
                                    {/*           placeholder="comp"/>*/}
                                    {/*    <span className="text-red-500 mr-2 text-xs register-error error-address-comp"></span>*/}
                                    {/*</div>*/}
                                    <div className="flex flex-col h-14 mr-2">
                                        <input type="text" name="code"
                                               className="w-full h-12 rounded mr-2 infos-register-address bg-transparent"
                                               placeholder="code" size="4"/>

                                        <span className="text-red-500 mr-2 text-xs register-error error-address-code"></span>
                                    </div>
                                    <div className="flex flex-col h-14">
                                        <input type="text" name="ville"
                                               className="w-full h-12 rounded infos-register-address bg-transparent" placeholder="ville"
                                        />
                                        <span className="text-red-500 mr-2 text-xs register-error error-address-ville"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase bg-form-blue text-sm font-semibold pl-6"><p>tel_client</p></div>
                                <div className="w-full h-14 flex flex-col items-center text-black bg-form-blue bordureTd">
                                    <input type="text" name="phone" placeholder="phone" className="w-full h-12 infos-register bg-transparent pl-6"/>
                                    <span className="text-red-500 text-xs w-full register-error error-phone"></span>
                                </div>
                            </div>
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase text-sm font-semibold pl-6"><p>Mail_client</p></div>
                                <div className="w-full h-14 flex flex-col items-center text-black bordureTd">
                                    <input type="text" name="client_email" placeholder="mail_address" className="w-full h-12 infos-register pl-6"/>
                                    <span className="text-red-500 text-xs w-full register-error error-client_email"></span>
                                </div>
                            </div>
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase bg-form-blue text-sm font-semibold pl-6"><p>site_web_client</p>
                                </div>
                                <div className="w-full h-14 flex flex-col items-center text-black bg-form-blue bordureTd">
                                    <input type="text" name="website" placeholder="http://google.com" className="w-full h-12 infos-register bg-transparent pl-6"/>
                                    <span className="text-red-500 text-xs w-full register-error error-website"></span>

                                </div>
                            </div>
                            {/*<div className="flex w-full">*/}
                            {/*    <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase text-sm font-semibold pl-6"><p>commentaire_client</p></div>*/}
                            {/*    <div className="w-full h-14 flex flex-col items-center text-black bordureTd">*/}
                            {/*        <input type="text" name="comment" placeholder="comment" className="w-full h-12 infos-register pl-6"/>*/}
                            {/*        <span className="text-red-500 text-xs w-full register-error error-comment"></span>*/}

                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase bg-form-blue text-sm font-semibold pl-6"><p>Date_crea_client</p>
                                </div>
                                <div className="w-full h-14 flex flex-col items-center text-black bg-form-blue bordureTd">
                                    <input type="date" name="creation_date" className="w-full h-12 infos-register bg-transparent pl-6"/>
                                    <span className="text-red-500 text-xs w-full  register-error error-creation_date"></span>
                                </div>
                            </div>
                            {
                                managers.map((manager, index) =>

                                    <div className="flex w-full manager" key={manager.id}>
                                        <div className="w-3/12 mr-2 h-14 flex items-center text-black uppercase text-sm font-semibold bordureTd pl-6"><p>Nom Dirigeant</p>
                                            <button type="button" className="p-2" onClick={addManagerForm}>+
                                            </button>
                                            <button type="button" className="p-2" onClick={() => removeManagerForm(index)}>-
                                            </button>
                                        </div>

                                        <div className="w-full h-14 flex items-center text-black bordureTd">
                                            {
                                                Object.keys(manager).map(prop => prop === "id" ? '' :
                                                    <div className="flex flex-col h-14 mr-2" key={prop}>
                                                        {prop === "status" ? <select name={prop} className="w-full h-12 mr-2 bg-transparent text-sm"
                                                                                     onChange={(e) => updateForms(e, index, "managers")}>
                                                                <option value="TNS">TNS</option>
                                                                <option value="SALARIE">SALARIE</option>
                                                            </select>
                                                            : prop === "birthdate" ? <input type="date"
                                                                                            className="w-full h-12 mr-2 bg-transparent text-sm"
                                                                                            name={prop}
                                                                                            onChange={(e) => updateForms(e, index, "managers")}/>

                                                                : <input type="text"
                                                                         className={`${prop === "first_name" ? "w-full h-12 mr-2 bg-transparent text-sm pl-6" : "w-full h-12 mr-2 bg-transparent text-sm"}`}
                                                                         name={prop}
                                                                         placeholder={prop}
                                                                         onChange={(e) => updateForms(e, index, "managers")}/>}
                                                        <span className={`text-red-500 text-xs w-full register-error error-managers-${index}-${prop}`}></span>
                                                    </div>)
                                            }
                                        </div>
                                    </div>
                                )
                            }
                            {/*<span className="register-error error-managers text-red-500 text-xs"></span>*/}
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase text-sm font-semibold bg-form-blue pl-6"><p>Date
                                    exercice_client</p>
                                </div>
                                <div className="w-full h-14 flex flex-col items-center text-black bordureTd bg-form-blue">
                                    <input type="date" name="exercise_date" className="w-full h-12 infos-register bg-transparent pl-6"/>
                                    <span className="text-red-500 text-xs w-full register-error error-exercise_date"></span>
                                </div>
                            </div>
                            {/*<div className="flex w-full">*/}
                            {/*    <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase bg-form-blue text-sm font-semibold pl-6"><p>date ex client x2</p>*/}
                            {/*    </div>*/}
                            {/*    <div className="w-full h-14 flex flex-col items-center text-black bg-form-blue bordureTd">*/}
                            {/*        <input type="date" name="exercice_date" className="w-full h-12 infos-register bg-transparent pl-6"/>*/}
                            {/*        <span className="text-red-500 text-xs w-full register-error error-exercice_date"></span>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase text-sm font-semibold pl-6"><p>capital client</p></div>
                                <div className="w-full h-14 flex flex-col items-center text-black bordureTd">
                                    <input type="text" name="capital" placeholder="capital" className="w-full h-12 infos-register pl-6"/>
                                    <span className="text-red-500 text-xs w-full register-error error-capital"></span>
                                </div>
                            </div>
                            {/*Manque le champ nb_salarie_client*/}
                            {
                                employes.map((employe, index) =>

                                    <div className="flex w-full manager" key={employe.id}>
                                        <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase text-sm font-semibold bg-form-blue pl-6">
                                            <p>Nom salarie client</p>
                                            <button type="button" className="p-2" onClick={addEmployes}>+
                                            </button>
                                            <button type="button" className="p-2" onClick={() => removeEmployesForm(index)}>-
                                            </button>
                                        </div>

                                        <div className="w-full h-14 flex items-center text-black bordureTd bg-form-blue">
                                            {
                                                Object.keys(employe).map(prop => prop === "id" ? '' :
                                                    <div key={prop} className="flex flex-col h-14 mr-2">
                                                        {prop === "status" ? <select name={prop} className="w-full h-12 mr-2 bg-transparent text-sm"
                                                                                     onChange={(e) => updateForms(e, index, "employes")}>
                                                                <option value="TNS">TNS</option>
                                                                <option value="SALARIE">SALARIE</option>
                                                            </select>
                                                            : prop === "birthdate" ? <input type="date"
                                                                                            className="w-full h-12 mr-2 bg-transparent text-sm"
                                                                                            name={prop}
                                                                                            onChange={(e) => updateForms(e, index, "employes")}/>

                                                                : <input type="text"
                                                                         className={`${prop === "first_name" ? "w-full h-12 mr-2 bg-transparent text-sm pl-6" : "w-full h-12 mr-2 bg-transparent text-sm"}`}
                                                                         name={prop}
                                                                         placeholder={prop}
                                                                         onChange={(e) => updateForms(e, index, "employes")}/>}
                                                        <span className={`text-red-500 text-xs w-full register-error error-employees-${index}-${prop}`}></span>
                                                    </div>)
                                            }
                                        </div>
                                    </div>
                                )
                            }
                            {/*<span className="register-error error-employees text-red-500 text-xs"></span>*/}
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase text-sm font-semibold pl-6"><p>Regime tva client </p>
                                </div>
                                <div className="w-full h-14 flex flex-col items-center text-black  bordureTd">
                                    <input type="text" name="tax_regime" placeholder="tax_regime" className="w-full h-12 infos-register pl-6"/>
                                    <span className="text-red-500 text-xs w-full register-error error-tax_regime"></span>
                                </div>
                            </div>
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase text-sm font-semibold bg-form-blue pl-6"><p>periode tva client</p>
                                </div>
                                <div className="w-full h-14 flex flex-col items-center text-black bordureTd bg-form-blue">
                                    <input type="text" name="tax_periode" placeholder="tax_period" className="w-full h-12 infos-register bg-transparent pl-6"/>
                                    <span className="text-red-500 text-xs w-full register-error error-tax_periode"></span>
                                </div>
                            </div>
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase text-sm font-semibold pl-6"><p>Regime fiscal client</p>
                                </div>
                                <div className="w-full h-14 flex items-center bordureTd">
                                    <div className="flex flex-col w-full ">
                                        <select name="tax_system" className="bg-none-input text-black w-full h-12 infos-register pl-6">
                                            <option value="M">M</option>
                                        </select>
                                        <span className="text-red-500 text-xs w-full register-error error-tax_system"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase text-sm font-semibold bg-form-blue pl-6"><p>Regime impôt
                                    client</p>
                                </div>
                                <div className="w-full h-14 flex items-center bg-form-blue bordureTd">
                                    <div className="flex h-14 flex-col w-full ">
                                        <select name="income_tax" className="bg-none-input text-black w-full h-12 infos-register bg-transparent pl-6">
                                            <option value="IR">IR</option>
                                        </select>
                                        <span className="text-red-500 text-xs w-full register-error error-income_tax"></span>
                                    </div>
                                </div>
                            </div>
                            {/* Manque champ Acompte is client*/}
                            {
                                banksAccounts.map((banksAccount, index) =>

                                    <div className="flex w-full manager" key={banksAccount.id}>
                                        <div className="w-3/12 mr-2 h-14 flex items-center text-black uppercase text-sm font-semibold bordureTd pl-6"><p>Banque_client</p>
                                            <button type="button" className="p-2" onClick={addBanksAccounts}>+
                                            </button>
                                            <button type="button" className="p-2" onClick={() => removeBankForm(index)}>-
                                            </button>
                                        </div>

                                        <div className="w-full h-14 flex bordureTd text-black justify-evenly">
                                            {Object.keys(banksAccount).map(prop => prop === "id" 
                                            ? 
                                                '' 
                                            :
                                                <div className="flex flex-col h-14 mr-2 items-center" key={prop}>
                                                    <input 
                                                        size={`${['initial_treasury', 'delta_treasury'].includes(prop) ? "14" : "8"}`}
                                                        type="text" 
                                                        name={prop} 
                                                        placeholder={prop}
                                                        className={`w-full h-12 mr-2 bg-transparent text-sm ${prop === "name" ? "pl-6" : null}`}
                                                        onChange={(e) => updateForms(e, index, "bank")}
                                                    />
                                                    <span className={`text-red-500 text-xs w-full register-error error-bank_accounts-${index}-${prop}`}></span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            }
                            {/*<span className="register-error error-bank_accounts text-red-500 text-xs"></span>*/}
                            <div className="flex w-full">
                                <div className="w-3/12 mr-2 h-14 flex items-center bordureTd text-black uppercase bg-form-blue text-sm font-semibold pl-6"><p>bilan entree</p></div>
                                <div className="w-full h-14 flex items-center text-black">
                                    <div className="flex flex-col h-14 bg-form-blue mr-2 w-3/6 bordureTd">
                                        <input type="text" name="initial_treasury"
                                               className="w-full h-12 infos-register-bilan bg-transparent text-sm pl-6"
                                               placeholder="initial_treasury"/>
                                        <span className="text-red-500 mr-2 text-xs register-error error-last_balance_sheet_entry-initial_treasury"></span>
                                    </div>
                                    <div className="flex flex-col h-14 h-14 bg-form-blue w-3/6 bordureTd">
                                        <input type="text" name="delta_treasury"
                                               className="w-full h-12 rounded mr-2 infos-register-bilan bg-transparent text-sm"
                                               placeholder="delta_treasury"/>
                                        <span className="text-red-500 mr-2 text-xs register-error error-last_balance_sheet_entry-delta_treasury"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-8">
                            <button className="bg-primary text-white rounded-xl p-2" type="button" onClick={register}>Créer un profil
                            </button>
                        </div>
                    </div>

                </section>
            </div>
        </React.Fragment>
    )
}
export default RegistrationClient