// CurrencyInput.js
import React, { useState, useRef } from 'react';

const CurrencyInput = ({ tabIndex, value, onChange, onBlur, name }) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  const handleFocus = () => {
    setIsEditing(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    onBlur(); // Appel de la fonction onBlur passée en prop
  };

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const formatCurrency = (amount) => {
    const numericAmount = parseFloat(amount);

    if (!isNaN(numericAmount)) {
      return `${numericAmount.toFixed(2)} €`; // Assuming € as currency symbol
    } else {
      return ''; // If value is not a valid number, return empty string
    }
  };

  const hideCSS = {
    position: 'absolute',
    left: '-9999px',
    width: '50px',
  };

  const showCSS = {
    position: 'relative',
    width: '50px',
  };

  return (
    <div>
     <span
        onClick={handleFocus}
        className={`currency-display ${isEditing ? 'hidden' : 'block'}`}
      >
        {formatCurrency(value)}
      </span>
      <input
        ref={inputRef}
        type="number"
        tabIndex={tabIndex}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        min="0"
        step="0.01"
        size="8"
        className="input-operation-vente"
        style={isEditing ? showCSS : hideCSS} // Affichage conditionnel de l'input
      />
    </div>
  );
};

export default CurrencyInput;
