// FilterButton.js
import React from "react";
import { useFilterContext } from "./FilterContext";
import { useDispatch } from "react-redux";
import { setOptional } from "../app/filters";

const FilterButton = ({ onUpdateDates = () => {} }) => {
    const { filters } = useFilterContext();
    const dispatch = useDispatch();

    const handleSubmit = () => {
        console.log("filters submitted values", filters);
        dispatch(setOptional(filters));
        onUpdateDates();
    };

    return (
        <div className="bg-lila mt-[1.2rem] px-4 sidebar:py-1 rounded-lg flex h-[2.3rem] relative border border-[#EEEEEE]">
            <button onClick={handleSubmit} type="button" className="text-white font-semibold sidebar:text-lg text-xs text-center w-full">Filtrer</button>
        </div> 
    );
};

export default FilterButton;
