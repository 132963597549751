// FilterProvider.js
import React from "react";
import { FilterProvider } from "./FilterContext";

const withFilterProvider = (Component) => {
    return (props) => (
        <FilterProvider>
            <Component {...props} />
        </FilterProvider>
    );
};

export default withFilterProvider;
