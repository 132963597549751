import '../assets/style/dashboard.css'
import React, {useEffect} from "react";
import MesInfos from "../components/Mon BBB/mesInfos";
import {setPageTitle} from "../app/filters";
import {useDispatch} from "react-redux";

const MesInformations = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setPageTitle('Mon Blabliblou'))
    }, [])
    return (
        <div className="w-full content">
            <MesInfos/>
        </div>
    )
}
export default MesInformations