import React, {useState} from "react";
import '../../assets/style/dashboard.css';
import {getItem} from "../../services/LocaleStorage";
import {useSelector} from "react-redux";
import {getUser} from "../../app/user";
import {toast} from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import DescriptionIcon from "@material-ui/icons/Description";
import Loader from "../Loader";


const AddArchive = ({closeModal}) => {
    const frontUser = useSelector(getUser)
    const [isLoading, setIsLoading] = useState(false);

    const uploadCourrier = (e) => {
        const token = getItem('bbbToken')
        setIsLoading(true)
        const form = new FormData(document.getElementById('send-facture'))

        form.append('client_id', frontUser.id)
        //Compléter l'URL
        fetch(process.env.REACT_APP_API_URL + '', {
            method: 'POST',
            requirements: token,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: form
        }).then((response) => {
            if (response.ok) {
                setIsLoading(false)
                response.json().then((json) => {
                    toast.success('Ajout de l\'archive avec succès.')
                    //fetch archives ...
                    closeModal()

                })
            } else {
                setIsLoading(false)
                toast.error('L\'ajout de l\'archive a échoué.')
                closeModal()
            }
        })

    }

    return (
        <React.Fragment>

            <div
                className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
                <Loader active={isLoading}/>
                <div className="w-2/3 mx-auto bg-white rounded-lg shadow-xl p-8 z-100">
                    <button className="float-right" onClick={closeModal}><CloseIcon/></button>
                    <h1 className="mb-8 font-semibold">Importation Document <span className="uppercase">Admin</span></h1>
                    <form id="send-facture" onSubmit={uploadCourrier}>
                        <div className="w-full flex">
                            <div className="w-2/6">
                                <div
                                    className={`w-full flex justify-center rounded-xl py-2 mr-2 bg-grisClaire border-b border-gray-200 mb-2 `}>
                                    <input type="date" className="bg-none-input w-full px-6 font-semibold text-gray-500" placeholder="Date document"/>
                                </div>

                                <div
                                    className={`w-full flex justify-center rounded-xl py-2 mr-2 bg-grisClaire border-b border-gray-200 my-2`}>
                                    <input type="text" className="bg-none-input w-full px-6 font-semibold" placeholder="Intitulé du document"/>
                                </div>
                                <div
                                    className={`w-full flex justify-center rounded-xl py-2 mr-2 bg-grisClaire border-b border-gray-200 mb-2`}>
                                    <input type="text" className="bg-none-input w-full px-6 font-semibold" placeholder="Commentaire sur le document"/>
                                </div>
                            </div>
                            <div className="w-4/6 flex justify-center">
                                <div>
                                    <div className="w-full rounded-lg items-center mb-8">
                                        <div className="w-upload text-black h-full mx-auto bg-gris rounded-xl">
                                            <div
                                                className="bg-gris w-full rounded-lg flex justify-center items-center w-upload">
                                                <textarea className="bg-none-input" placeholder="Ecrire votre message"></textarea>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="flex">
                                        <button type="button"
                                                className={`w-full p-2 bg-greenSuccess rounded-xl text-white flex items-center justify-end`}
                                                onClick={(e) => uploadCourrier(e)}>
                                            <DescriptionIcon/>
                                            <span className="mx-auto px-6 ">Ok</span>
                                        </button>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}
export default AddArchive