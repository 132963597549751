import React from "react";
import '../assets/style/dashboard.css'
import AffectAperc from "../components/client/affectAperc";

const ExportAffectation = () => {

    return(
        <div className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
            <div className="w-2/3 h-11/12 m-auto bg-white rounded-lg shadow-xl p-8 z-100">
                <AffectAperc />
            </div>
        </div>
    )
}
export default ExportAffectation