// SearchBar.js
import React from "react";
import { useFilterContext } from "./FilterContext";
import SearchIcon from "@material-ui/icons/Search";

const SearchBar = () => {
    const { filters, updateFilters } = useFilterContext();

    return (
        <div>
            <div className="rounded-xl flex relative border border-[#EEEEEE]  h-[2.3rem] sidebar:h-[2.7rem] items-center text-xs sidebar:text-base sidebar:py-1">
                <input 
                    type="text" 
                    name="search" 
                    placeholder="Recherche..." 
                    className="w-5/6 rounded-xl pl-[1rem]" 
                    value={filters.searchTerms} 
                    onChange={(e) => {
                        updateFilters({ ...filters, searchTerms: e.target.value })
                    }}
                />
                <SearchIcon/>
            </div>

            <div className="w-full flex pt-[1.2rem]">

                <div className="w-1/2 mr-2 rounded-xl flex h-[2.3rem] relative border border-[#EEEEEE] sidebar:py-0.5 items-center">
                    <input 
                        type="number" 
                        name="min" 
                        placeholder="Min TTC" 
                        className="w-full px-4 py-1 rounded-xl text-xs sidebar:text-base" 
                        value={filters.minAmount}
                        onChange={(e) => {
                            updateFilters({ ...filters, minAmount: e.target.value })
                        }} 
                    />
                </div>
                <div className="w-1/2 ml-2 rounded-xl flex h-[2.3rem] relative border border-[#EEEEEE] sidebar:py-0.5  items-center">
                    <input 
                        type="number" 
                        name="max" 
                        placeholder="Max TTC" 
                        className="w-full px-4 py-1 rounded-xl text-xs sidebar:text-base" 
                        value={filters.maxAmount}
                        onChange={(e) => {
                            updateFilters({ ...filters, maxAmount: e.target.value })
                        }} 
                    />
                </div>
            </div>
            
            {/* <div className="bg-lila mt-[1.2rem] px-4 sidebar:py-1 rounded-lg flex h-[2.3rem] relative border border-[#EEEEEE]">
                <button onClick={handleSubmit} type="button" className="text-white font-semibold sidebar:text-lg text-xs text-center w-full">Filtrer</button>
            </div> */}

        </div>
        )
}
export default SearchBar