import React, {useState} from "react";
import {useSelector} from "react-redux";
import {PulseLoader} from "react-spinners";
import {toast} from "react-toastify";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import {getItem} from "../../services/LocaleStorage";
import {getUser} from "../../app/user";
import { alerts } from "../../utils/dictionary";
// import showSubCategories from "./affectAperc";

const ModalCategory = ({ 
    category, 
    subCats, 
    setCat, 
    affectationsInvoice, 
    affectationsOperation, 
    setPreventionAlert, 
    cancel, 
    close, 
    id, 
    info
}) => {
    const frontUser = useSelector(getUser)
    const [toggle, setToggle] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // Search Input : Showing sub-categories on clicking on the down arrow

    const validateSubmissionRequest = (subCat) => {
        if (affectationsInvoice?.length > 0 && affectationsInvoice.some(el => el.transaction?.sub_category?.id !== subCat?.id)) {
            let conflictedOpsCategs = affectationsInvoice
                .filter(el => el?.transaction?.sub_category_id?.id !== subCat?.id)
                .map(((el, index) => (
                    `<div class="flex items-center mt-2" key=${index}>
                        <span class="w-1/2 bg-gris p-3">${el?.transaction?.label}</span> : 
                        <span class="w-1/2 bg-gris font-semibold p-3">${el?.transaction?.sub_category?.display_name}</span>
                    </div>`
                )));

            setPreventionAlert({ 
                message: alerts(conflictedOpsCategs).AFFECTATIONS_OPS_CATEGS_CONFLICT, 
                confirm: () => {
                    sendCategory(subCat);
                    close();
                }, 
                cancel
            });

            setIsLoading(false);
            return;
        }

        if (affectationsOperation?.length > 0 && affectationsOperation.some(el => el.invoice?.sub_category?.id !== subCat?.id)) {
            let conflictedInvoicesCategs = affectationsOperation
                .filter(el => el?.invoice?.sub_category?.id !== subCat?.id)
                .map(((el, index) => (
                    `<div class="flex items-center mt-2" key=${index}>
                        <span class="w-1/2 bg-gris p-3">${el?.invoice?.label}</span> : 
                        <span class="w-1/2 bg-gris font-semibold p-3">${el?.invoice?.sub_category?.display_name}</span>
                    </div>`
                )));

            setPreventionAlert({ 
                message: alerts(conflictedInvoicesCategs).AFFECTATIONS_INVOICES_CATEGS_CONFLICT, 
                confirm: () => {
                    sendCategory(subCat);
                    close();
                }, 
                cancel
            });

            setIsLoading(false);
            return;
        }

        sendCategory(subCat);
    }

    const sendCategory = (subCat) => {
        setIsLoading(subCat.id);

        if (info === 'operation') {
            const token = getItem('bbbToken')
            let formdata = new FormData(document.getElementById('affectation-operation'));
            formdata.append('sub_category_id', subCat.id);
            formdata.append('client_id', frontUser.id);
            formdata.delete('operation_date');

            fetch(`${process.env.REACT_APP_API_URL}/transaction/${id}`, {
                method: 'POST', 
                body: formdata, 
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                if (response.ok) {
                    setCat(subCat);
                } else {
                    toast.error("L\'affectation de la catégorrie a échoué")
                }
            })
            .catch(err => {
                console.error(err);
                toast.error('Erreur lors de la modification');
            })
            .finally(() => setIsLoading(false));
        }
        
        if (info === 'invoice') {
            const token = getItem('bbbToken');

            //Modification des données de la facture modale affectation
            let form = new FormData(document.getElementById('affectation-operation'))
            form.append('sub_category_id', subCat.id)
            form.append('client_id', frontUser.id)

            fetch(`${process.env.REACT_APP_API_URL}/invoice/${id}`, {
                method: 'POST', 
                headers: {
                    'Authorization': `Bearer ${token}`,
                }, 
                body: form
            })
            .then(response => {
                if (response.ok) {
                    setCat(subCat);
                    toast.success('Mise à jour de la catégorie de la facture avec succès.');
                }
            })
            .catch(() => {
                toast.error('Erreur lors de la modification');
            })
            .finally(() => setIsLoading(false));
        }
    }

    return (
        <div className="item">
            <div className="category flex justify-between cursor-pointer" onClick={()=> setToggle(!toggle)}>
                <h6 className="cursor-pointer" >{category.display_name}</h6>
                <ArrowDropDownIcon className={`${toggle ? 'rotate' : ''}`}/>
            </div>
            <ul className={`${toggle ? '' : 'hidden'}`}>
                {subCats.map((subCat) =>
                    <li 
                        key={subCat.id} 
                        className="cursor-pointer" 
                        style={{ color: `${isLoading == subCat.id ? "#8000808f" : "#000"}` }} 
                        onClick={() => validateSubmissionRequest(subCat)}
                    >
                        <span>{subCat.display_name}</span>
                        {isLoading && isLoading == subCat.id ?
                            <span className="float-right pr-4">
                                <PulseLoader color={'purple'} size={5} />
                            </span>
                        :
                            null
                        }
                    </li>
                )}
            </ul>
        </div>
    )
}
export default ModalCategory