import React, {useEffect, useState} from "react";
import GestionCompte from "../components/Admin N/gestionCompte";
import MessagerieClientsAdminN from "../components/Admin N/Messagerie/messagerieClientsAdminN";
import MessagerieAdminPAdminN from "../components/Admin N/Messagerie/messagerieAdminPAdminN";
import {useDispatch} from "react-redux";
import {setPageTitle} from "../app/filters";

const MessagesTableAccessClient = () => {
    const [tab, setTab] = useState(1);
    const [messagerieClient, setMessagerieClient] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPageTitle('Messagerie'));
    });
    return (
        <div className="w-full content">
            <div className="flex w-full justify-around">
                <div className={`w-2/6 flex justify-center rounded-2xl py-2  mr-2 ${tab === 1 ? 'bg-lila text-white' : 'bg-gris text-lilaFonce'}`}>
                    <button onClick={() => setTab(1)}>Messagerie</button>
                </div>
                <div className={`w-2/6 flex justify-center rounded-2xl py-2 mr-2 ${tab === 2 ? 'bg-lila text-white' : 'bg-gris text-lilaFonce'}`}>
                    <button onClick={() => setTab(2)}>Obligations fiscales</button>
                </div>
                <div className={`w-2/6 flex justify-center rounded-2xl py-2 mr-2 ${tab === 3 ? 'bg-lila text-white' : 'bg-gris text-lilaFonce'}`}>
                    <button onClick={() => setTab(3)}>Gestion compte</button>
                </div>
            </div>

            {/*select messagerieClient AdminP/Client*/}
            <div className="flex w-full justify-around mt-8">
                <div className={`w-3/6 flex justify-center rounded-2xl py-2 mr-2 ${messagerieClient ? 'bg-lila text-white' : 'bg-gris text-lilaFonce'}`}>
                    <button onClick={() => setMessagerieClient(true)}>Messagerie <span className="uppercase">Client</span></button>
                </div>
                <div className={`w-3/6 flex justify-center rounded-2xl py-2 mr-2 ${!messagerieClient ? 'bg-lila text-white' : 'bg-gris text-lilaFonce'}`}>
                    <button onClick={() => setMessagerieClient(false)}>Messagerie <span className="uppercase">Admin p</span></button>
                </div>
            </div>
            {tab === 1 && messagerieClient ? <MessagerieClientsAdminN/> : tab === 1 && messagerieClient === false ? <MessagerieAdminPAdminN/> : ""}
            {tab === 3 && messagerieClient && <GestionCompte/>}
        </div>
    )
}

export default MessagesTableAccessClient
