import '../assets/style/dashboard.css'
import React, {useEffect, useState} from "react";
import ListeClientsAdminN from "../components/Admin N/listeClientsAdminN";
import {useDispatch} from "react-redux";
import {setPageTitle} from "../app/filters";

const AdminN = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setPageTitle('Accueil'))
    }, [])

    // eslint-disable-next-line no-unused-vars
    const [dataClients, setDataClients] = useState({
        data: [],
    })

    return (
        <ListeClientsAdminN dataClients={dataClients.data}/>
    )
}
export default AdminN