import React, {useEffect, useState} from 'react'
import '../assets/style/main.css'
import {
    ArrowBackIosOutlined,
    ArrowBackOutlined,
    ArrowLeft,
    ArrowRight, ArrowRightAlt, ArrowRightAltOutlined, ArrowRightAltSharp, ArrowRightOutlined, ArrowRightRounded, ArrowRightSharp,
    DoubleArrow,
    DoubleArrowOutlined,
    DoubleArrowRounded,
    DoubleArrowSharp,
    DoubleArrowTwoTone, SubdirectoryArrowRightOutlined
} from "@material-ui/icons";
import arrowRight from "../assets/img/doubleArrowRight.png"
import arrowLeft from "../assets/img/doubleArrowLeft.png"


const Pagination = ({data, paginate}) => {
    const [pageNumbers, setPageNumbers] = useState([]);
    const [currentGroup, setCurrentGroup] = useState(1);
    const numberPerGroup = 4
    // const [currentGroup, setCurrentGroup] = useState(initState);
    const [isData, setIsData] = useState("");


    useEffect(() => {
        if (data) {
            let tab = []
            for (let i = 1; i <= Math.ceil(data.count / data.per_page); i++) {
                tab.push(i);
            }
            setPageNumbers(tab)
            setIsData(data.count)
        }
    }, [data])

    const renderEmptyLi = (number) => {
        let tab = []
        for (let i = 1; i <= number; i++) {
            tab.push(<li key={i} className="p-2 flex justify-center items-center">
                <button className="p-4 text-lilaFonce w-4 h-2 flex justify-center items-center rounded-lg buttonsNumbers">
                    <span></span>
                </button>
            </li>)
        }
        return tab
    }


    return (
        <React.Fragment>
            {isData > 0 ? <nav>
                <ul className="flex float-right my-8 rounded-2xl">

                    {/*Arrow Left*/}
                    <li className="p-2 flex justify-center items-center">
                        <button className=""
                                onClick={currentGroup === 1 ? () => false : () => setCurrentGroup(currentGroup - 1)}>
                        {currentGroup === 1 ? '' : <img src={arrowLeft} alt="arrow"/>}
                        </button>
                    </li>


                    {/*Numbers Pagination*/}
                    {
                        pageNumbers.slice((currentGroup * numberPerGroup) - numberPerGroup, currentGroup * numberPerGroup).map((number) => {
                            return <li key={number} className="p-2 flex justify-center items-center">
                                <button className={`buttonsNumbers font-semibold ${data.page === number ? 'bg-[#F2F5FF] text-[#30006D]' : 'text-[#707579]'}`}
                                        onClick={() => paginate(data.per_page, number)}>
                                    <span>{number}</span>
                                </button>
                            </li>
                        })
                    }

                    {
                        pageNumbers.slice((currentGroup * numberPerGroup) - numberPerGroup, currentGroup * numberPerGroup).length < numberPerGroup ? renderEmptyLi(numberPerGroup - pageNumbers.slice((currentGroup * numberPerGroup) - numberPerGroup, currentGroup * numberPerGroup).length) : ''
                    }
                    {/*Arrow Right*/}
                    <li className="p-2 flex justify-center items-center">
                        <button className=""
                                onClick={pageNumbers.some(el => numberPerGroup * currentGroup + 1 === el) ? () => setCurrentGroup(currentGroup + 1) : () => false}>
                        {pageNumbers.some(el => numberPerGroup * currentGroup + 1 === el) ? <img src={arrowRight} alt="arrow"/> : ''}
                        </button>
                    </li>

                </ul>
            </nav> : <div></div>}
        </React.Fragment>
    )
}

export default Pagination