import React, {useState} from "react";
import {getItem} from "../../services/LocaleStorage";
import {toast} from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";

const PopupDelPiece = ({type, closeModale, closeAffect, fetchResources, idInvoice, isLastInvoice}) => {
    const [nameFile, setNameFile] = useState('');
    const [fileInBDD, setFileInBDD] = useState(false);

    let confirmBoxMessage;
    let notificationMessage;
    switch (type) {
        case 'achat':
            confirmBoxMessage = 'Vous allez supprimer une pièce d\'achat et les informations liées à cet achat seront supprimées avec la pièce';
            notificationMessage = 'La facture d\'achat a bien été supprimé.';
            break;
        case 'vente':
            confirmBoxMessage = 'Vous allez supprimer une pièce de vente et les informations liées à cette vente seront supprimées avec la pièce';
            notificationMessage = 'La facture de vente a bien été supprimé.';
            break;
        case 'courrier':
            confirmBoxMessage = 'Le document sera définitivement supprimé';
            notificationMessage = 'Le document a bien été supprimé.';
            break;
        default:
            confirmBoxMessage = 'Le document sera définitivement supprimé';
            notificationMessage = 'Le document a bien été supprimé.';
            break;
    }

    const delBDDImage = () => {
        //fetch
        //Lancer deux requêtes : réinitialisation des champs à 0 et suppression de la facture vente

        const token = getItem('bbbToken')
        // const form = new FormData()
        // form.append('amount', '0.00')
        // form.append('vat', '0.00')
        // form.append('amount_with_vat', '0.00')
        //
        // fetch(process.env.REACT_APP_API_URL + '/invoice/' + idInvoice, {
        //     method: 'POST',
        //     requirements: token,
        //     headers: {
        //         'Authorization': 'Bearer ' + token
        //     },
        //     body: form
        // }).then((response) => {
        //     if (response.ok) {
        //         response.json().then((json) => {
        //             console.log(json)
        //             closeModale()
        //             closeAffect()
        //             toast.success('Suppression de la facture de vente avec succès.')
        //             fetchResources()
        //         })
        //     } else {
        //         closeModale()
        //         closeAffect()
        //         toast.error('La suppression de la facture de vente a échoué.')
        //     }
        // })
        if (isLastInvoice) {
            console.log('supprime definitivement la facture')
            fetch(process.env.REACT_APP_API_URL + '/invoice/' + idInvoice, {
                method: 'DELETE',
                requirements: token,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then(response => {
                if (response.ok) {
                    fetchResources()
                    toast.info(notificationMessage)
                    closeModale()
                    closeAffect()
                }
            })
        } else {
            fetch(process.env.REACT_APP_API_URL + '/affectations/' + idInvoice, {
                method: 'DELETE',
                requirements: token,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then(response => {
                if (response.ok) {
                    fetchResources()
                    toast.info('La facture de vente a bien été (reset).')
                    closeModale()
                    closeAffect()
                }
            })
        }
        // setFileInBDD(false)
        // setNameFile('')
        // document.getElementById('invoice-receipt-input').value = null
    }

    return (
        <div
            className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
            <div className="w-2/3 mx-auto bg-white rounded-lg shadow-xl p-8 z-100">
                <button className="float-right pt-0" onClick={closeModale}><CloseIcon/></button>
                <div className="parent-table w-full">
                    <div className="flex text-black font-semibold bg-grisClaire inline p-2 mt-8 text-xl">
                        <p className="flex flex-col">
                            <span>
                                <span className="font-bold">Attention : </span>
                                {confirmBoxMessage}
                            </span>
                        </p>
                    </div>
                </div>
                <div className="float-right mt-8">
                    <button 
                        className="bg-greenSuccess text-white rounded-xl p-2" 
                        type="button" 
                        onClick={delBDDImage}
                    >
                        Confirmer
                    </button>
                </div>
            </div>
        </div>
    )
}
export default PopupDelPiece