import {createSlice} from "@reduxjs/toolkit";

export const loaderSlice = createSlice({
    name: 'loader',
    initialState: {
        isActive: false,
        counter: 0,
        isExportingAccount: false,
        areAllOpsLoaded: false,
    },
    reducers: {
        setLoaderSubscribe(state) {
            state.counter++
            state.isActive = true
        },
        setLoaderUnsubscribe(state) {
            state.counter === 0 ? state.counter = 0 : state.counter--
            if (state.counter === 0) {
                state.isActive = false
            }
        },
        setIsExportingAccount(state, action) {
            state.isExportingAccount = action.payload;
        },
        setAreAllOpsLoaded(state, action) {
            state.areAllOpsLoaded = action.payload;
        }
    },
})

export const {setLoaderUnsubscribe, setLoaderSubscribe, setIsExportingAccount, setAreAllOpsLoaded} = loaderSlice.actions
export default loaderSlice.reducer

export function getLoader(state) {
    return state.loader.isActive
}

export function getIsExportingAccount(state) {
    return state.loader.isExportingAccount;
}

export function getAreAllOpsLoaded(state) {
    return state.loader.areAllOpsLoaded;
}
