// NotificationsBar.js
import React from "react";
import { useSelector } from "react-redux";

import mailIcon from "../assets/img/mail-icon.png";
import notificationIcon from "../assets/img/notification-icon.png";
import { getMessages, getOtherNotifications } from "../app/notifications";

const NotificationsBar = () => {
    const messages = useSelector(getMessages);
    const otherNotifications = useSelector(getOtherNotifications);

    return (
        <div className="top-0 ml-auto mr-[5.3rem] relative pt-[2.1rem] flex items-center notification-section">
        <div className="notification-container">
            <img src={notificationIcon} className="img-notification" alt="img-notification"/>
            {otherNotifications.count > 0 ?
                <span className="notification-badge" style={{background: '#ff9d41'}}>
                    {otherNotifications.count > 99 ? '+99' : otherNotifications.count}
                </span>
            :
                ''
            }
        </div>
        <div className="notification-container">
            <img src={mailIcon} className="img-mailicon" alt="img-mail"/>
            {messages.count > 0 ? 
                <span className='notification-badge' style={{background: '#ff9d41'}}>
                    {messages.count > 99 ? '+99' : messages.count}
                </span>
            : 
                ''
            }
        </div>
    </div>
    )
}

export default NotificationsBar;