import React, {useEffect, useState} from "react";
import PopupDelRowTransa from "./PopupDelRowTransa";
import deletePng from "../../assets/img/deletePng.png";
import add from "../../assets/img/add.png";

const RowOperationPopup = ({
    row, 
    addRow, 
    emitChange, 
    removeRow, 
    isLast, 
    isAlone = false, 
    montRestant
}) => {
    const [isInBdd, setIsInBdd] = useState(false);
    const [openPopupDelTransa, setOpenPopupDelTransa] = useState(false);

    const closeModal = () => {
        setOpenPopupDelTransa(false)
    }

    useEffect(() => {
        setIsInBdd(row.isInBDD)
    }, []);

    return (
        <React.Fragment>
            <div className="h-10 w-full col-start-1"/>
            <div className="h-10 w-full">
                <div className="w-0.5 mt-0.5 bg-lilaFonce h-full mx-auto"/>
            </div>
            <div className="h-10 w-full font-semibold col-span-2 flex items-end">
                {isLast &&  montRestant !== 0 ? <p className="w-10/12 mx-auto text-sm">Montant restant à affecter</p> : false}
            </div>
            <div className="h-10 w-full">
                <div className="w-0.5 mt-0.5 bg-lilaFonce h-full mx-auto"/>
            </div>
            <div className="h-10 w-full"/>
            <div className="col-start-2">
                <input onChange={(e) => emitChange("designation", e.target.value, row.id)}
                       placeholder="Désignation de l'opération *" id="error-label"
                       className="text-center bg-[#F2F5FF] w-full py-2 rounded-lg font-semibold shadow-md" defaultValue={row.designation}/>
                <span className="text-redDanger"></span>
            </div>
            {
            <div className="col-span-2">
                 {isLast &&  montRestant !== 0 ?  (
                     <p className="text-redCorail font-bold mx-auto text-center bg-[#F2F5FF] rounded-lg py-2 w-10/12 h-full shadow-md "> {montRestant + "€"}</p>) : false}
             </div>
            }

            <div className="bg-[#F2F5FF] rounded-lg flex items-center shadow-md">
                <input type="number"
                       onChange={(e) => emitChange("montant", isNaN(Number(e.target.value)) ? 0 : Number(e.target.value), row.id)}
                       placeholder="montant"
                       step="0.01"
                       min="0"
                       className="text-center w-2/3 py-2 bg-transparent font-semibold rounded-xl" defaultValue={row.montant}/>
                <span>€</span>
            </div>
            <div className="h-full px-2 flex gap-5 ml-2">
                {isLast ?
                    <button onClick={addRow} className="bg-lilaFonce px-[0.8rem] h-full rounded-lg text-white"> <img src={add} alt="add-row" /></button> :
                    <div className="h-full w-[2.9rem]"/>}
                {isAlone && !isInBdd ? <div className="h-full px-4"/> :
                    <button onClick={isInBdd ? () => setOpenPopupDelTransa(true) : () => removeRow(row.id, isInBdd)}
                            className="bg-[#F2F5FF] px-[0.8rem] h-full rounded-lg text-lilaFonce">
                        <img src={deletePng} alt="delete-row"/></button>
                }
            </div>
            {openPopupDelTransa && <PopupDelRowTransa isInBDD={isInBdd} rowId={row.id} closeModale={closeModal} removeRow={removeRow}/>}

        </React.Fragment>
    )
}

export default RowOperationPopup