import React, {useEffect, useRef, useState} from "react";
import "../assets/style/dashboard.css";
import "../assets/style/mes_simulation.css";
import SimusTable from "../components/Tables/SimusTable";
// import {useMediaQuery} from "react-responsive";
import {getItem} from "../services/LocaleStorage";
import CreateIcon from '@material-ui/icons/Create';
import Loader from "../components/Loader";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import StatsAndGraphs from "../components/Mes Simus/statsAndGraphs";
import {setPageTitle} from "../app/filters";
import {getUser} from "../app/user";

const SimusPage = () => {
    // const user = useSelector(state => state.user)

    let dateInputRef = useRef(null);
    let [categories, setCategories] = useState(null);
    let [startAt, setStartAt] = useState('?');
    let [endAt, setEndAt] = useState('?');
    let [isLoading, setIsLoading] = useState(false);
    // const [statSelect, setStatSelect] = useState(1);
    // const [selectEnCours, setSelectEnCours] = useState(0);
    const dispatch = useDispatch();
    let [data, setData] = useState([]);
    const frontUser = useSelector(getUser);

    const childRefRecettes = useRef();
    const childRefDepenses = useRef();

    // const ordi = useMediaQuery({query: "(min-width: 1000px)"});
    // const mobile = useMediaQuery({query: "(max-width: 999px)"});

    // const updateCheckbox = (checked) => {
    //     document.getElementById("tout-derouler").checked = checked;
    // };

    useEffect(() => {
        dispatch(setPageTitle('Mon Previsionnel'));
    });

    const getCategoriesList = () => {
        const token = getItem('bbbToken')

        return fetch(process.env.REACT_APP_API_URL + '/category?include[]=sub_categories',
            {
                method: 'GET',
                // requirements: token,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
            .then(res => res.json())
            .then(res => res)
    }

    const mergeCategoriesHelper = (res, categories, type, objExample) => {
        if (res.length == 0) {
            res =
                [
                    {
                        type: "vente",
                        ...objExample
                    },
                    {
                        type: "depense",
                        ...objExample
                    }
                ]
        }

        if (res.length == 1) {
            if (res[0].type != type) {
                res.push({
                    type,
                    ...objExample,
                    endAt: res[0].end_at
                })
            }
        }

        // first remove the category that already filled in res
        const obj = res.find((row) => row.type === type)

        for (let index = 0; index < categories.length; index++) {
            const catObj = categories[index]
            for (let key in obj.data_by_categories) {
                if (catObj.display_name === key) {
                    categories.splice(index, 1)
                    index--;
                    break;
                }
            }
        }

        // now adding the missing categories to res
        const defaultAttributes = {
            amount: 0,
            grand_amount: 0,
            vat: 0,
            vat_amount: 0
        }

        categories.map((catObj) => {
            obj.data_by_categories[catObj.display_name] = {}
            catObj.sub_categories.map((subObj) => {
                obj.data_by_categories[catObj.display_name][subObj.display_name] = {
                    total: {...defaultAttributes},

                    // use 'from' instead of 'fill' function since fill will create array of 12
                    // that will point to the same object, so doing something like this
                    // arr[0].el = 0, it will look like all the values of the array changed to be 0
                    // but it looks like this because all the element of the array point to the
                    // same object
                    monthly: Array.from({length: 12}, () => {
                        return {...defaultAttributes}
                    })
                }
            })
        })
        return res
    }

    const mergeCategories = (res, categories, startAtDate) => {

        // console.log("LIST CATEGORIES  = ", categories, "\nDATA = ", res)
        // return res
        if (categories.length == 0)
            return res

        const objExample =
            {
                amount: 0,
                grand_amount: 0,
                vat: 0,
                vat_amount: 0,
                start_at: startAtDate,
                initial_treasury: 100.0,
                data_by_categories: {}
            }
        res = mergeCategoriesHelper(res, [...categories], 'depense', objExample)
        res = mergeCategoriesHelper(res, [...categories], 'vente', objExample)

        // NOTE: In case we don't have data, the next block will be taking care of
        // filling the data with the info coming from categories
        return res
    }

    const getData = (endAtDate = endAt, startAtDate = startAt, showLoader = true) => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)

        let userId;
        if (urlParams.get('vuserid')) {
            userId = parseInt(urlParams.get('vuserid'))
        } else {
            userId = frontUser.id
        }

        const token = getItem('bbbToken')
        setIsLoading(showLoader)
        // fetch(process.env.REACT_APP_API_URL + '/expectation?end_at=2022-12-31&start_at=2022-01-01&client_id=' + vuserid.toString(),
        const url = `${process.env.REACT_APP_API_URL}/expectation?end_at=${endAtDate.toString()}&start_at=${startAtDate.toString()}&client_id=${userId.toString()}`;
        fetch(url,
            {
                method: 'GET',
                // requirements: token,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
            .then(res => res.json())
            .then(res => {
                console.log("Categories = ", categories)
                if (!categories) {
                    getCategoriesList()
                        .then(cat => {
                            console.log(cat)
                            setCategories(cat)
                            res = mergeCategories(res, cat, startAtDate)
                            setData(res)
                        })
                } else {
                    res = mergeCategories(res, categories, startAtDate)
                    setData(res)
                }
                setIsLoading(false)
            })
    }

    const onBlurInputDate = (e, startAtDate = startAt) => {
        let endAtDate = ''
        const dt = new Date(e.target.value.replace(/-/g, '/'))
        dt.setMonth(dt.getMonth() + 12)
        dt.setDate(dt.getDate() - 1)
        const dd = String(dt.getDate()).padStart(2, '0');
        const mm = String(dt.getMonth() + 1).padStart(2, '0');
        const yyyy = dt.getFullYear();
        endAtDate = yyyy + '-' + mm + '-' + dd
        setEndAt(endAtDate)
        getData(endAtDate.valueOf(), startAtDate.valueOf())
    }

    const sendData = (arr, type) => {
        toast.info("En cours d'envoie !")
        const targetData = data.find((row) => row.type == type)
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const vuserid = parseInt(urlParams.get('vuserid'))
        let dataToSend = {
            client_id: vuserid,
            type,
            start_at: targetData.start_at == '?' ? startAt : targetData.start_at,
            initial_treasury: targetData.initial_treasury,
            data_by_categories: {}
        }

        arr.forEach((row) => {
            dataToSend.data_by_categories[row.date] = {}
            row.subRows.forEach((subRow) => {
                const monthly = (Object.values(subRow.monthly))
                const obj = {
                    amount: 0,
                    vat_amount: 0,
                    grand_amount: 0,
                    vat: 0
                }
                monthly.forEach((item) => {
                    obj.amount += parseFloat(item.amount)
                })
                obj.vat_amount = (parseFloat(obj.amount) * ((parseFloat(subRow.vat) / 100.00)))
                obj.vat = parseFloat(subRow.vat)
                obj.grand_amount = obj.vat_amount + obj.amount
                dataToSend.data_by_categories[row.date][subRow.date] = {
                    total: {
                        amount: obj.amount,
                        grand_amount: obj.grand_amount,
                        vat: obj.vat,
                        vat_amount: obj.vat_amount
                    },
                    monthly
                }
            })
        })

        const token = getItem('bbbToken')
        console.log("dataToSend", dataToSend)
        fetch(process.env.REACT_APP_API_URL + '/expectation',
            {
                method: 'POST',
                // requirements: token,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(dataToSend)
            })
            .then(res => res.json())
            .then(() => {
                getData(endAt, startAt, false)
                toast.info("L'enregistrement a été modifiée !")
            })
    }

    const toggleCB = (e) => {
        childRefDepenses.current.toggleCheckbox(e.target.checked);
        childRefRecettes.current.toggleCheckbox(e.target.checked);
    }

    const recette = data.find((row) => row.type == 'vente')
    const depense = data.find((row) => row.type == 'depense')

    return (
        <React.Fragment>
            <div className="w-full content dashboard">
                <div className="sub-btn">

                </div>
                {/*<NavLink to="/account">Aller au compte de : {user.clientName}</NavLink>*/}

                <StatsAndGraphs/>

            </div>

            <section className="mes-simulation">
                <div className="content">
                    {data.length ?
                        <div className="flex checkbox-container">
                            <input
                                id="tout-derouler"
                                type="checkbox"
                                onClick={toggleCB}
                            />
                            <label htmlFor="tout-derouler">Tout dérouler</label>
                        </div> :
                        ""}
                    <div className="date-exercice">
                        <div>
                            <p>exercice prévisionnel du <input
                                onChange={() => {
                                    // let startAtDate = ''
                                    // startAtDate = e.target.value.replace(/-/g, '/').split('/').join('-')
                                    // console.log('startAtDate MED', startAtDate)
                                    // console.log('startAtDate MED e.target.value', e.target.value)
                                    // setStartAt(startAtDate)
                                    // onBlurInputDate(e, startAtDate)
                                }}
                                onKeyDown={(e) => {
                                    if (e.key == 'Enter') {
                                        let startAtDate = ''
                                        startAtDate = e.target.value.replace(/-/g, '/').split('/').join('-')
                                        setStartAt(startAtDate)
                                        onBlurInputDate(e, startAtDate)
                                        // onBlurInputDate(e)
                                    }
                                }}
                                ref={(ref) => dateInputRef = ref} type="date" name="periodeaffiche"/> au {isNaN(endAt.split('-')[0]) ? "?" : endAt.split('-').reverse().join('/')}
                                <CreateIcon onClick={() => {
                                    dateInputRef.focus()
                                }}/></p>
                        </div>
                    </div>
                </div>
                {/*if ordi */}
                <div className="loader-parent">
                    <Loader className="loader" active={isLoading}/>
                </div>
                {data.length ? <>
                        {recette && <SimusTable display_name="Recettes" type="vente" onSave={sendData} data={recette} ref={childRefRecettes}/>}
                        {depense && <SimusTable display_name="Dépenses" type="depense" onSave={sendData} data={depense} ref={childRefDepenses}/>}
                    </> :
                    <div className="content">
                        <p className="no-such-data">No Such data between {startAt} and {endAt}</p>
                    </div>
                }
            </section>
        </React.Fragment>
    );
};

export default SimusPage;
