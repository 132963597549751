import React, {useState} from "react";
import {useSelector} from "react-redux";
import {PulseLoader} from "react-spinners";
import {toast} from "react-toastify";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import {getItem} from "../../services/LocaleStorage";
import {getUser} from "../../app/user";
// import showSubCategories from "./affectAperc";

const ModalCategory = ({category, subCats, setCat, id, info}) => {
    const frontUser = useSelector(getUser)
    const [toggle, setToggle] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // Search Input : Showing sub-categories on clicking on the down arrow

    const sendCategory = (subCat) => {
        setIsLoading(subCat.id);
        if (info === 'operation') {
            const token = getItem('bbbToken')
            const authUrl = process.env.REACT_APP_API_URL + '/transaction/' + id
            let formdata = new FormData(document.getElementById('affectation-operation'))
            formdata.append('sub_category_id', subCat.id)
            formdata.append('client_id', frontUser.id)
            formdata.delete('operation_date')

            fetch(authUrl, {
                method: 'POST', body: formdata, headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response => {
                setIsLoading(false);
                if (response.ok) {
                    setCat(subCat);
                } else {
                    toast.error("L\'affectation de la catégorrie a échoué")
                }
            })
            .catch(err => {
                setIsLoading(false);
                console.error(err);
                toast.error("Erreur lors de la modification");
            });
        }
        
        if (info === 'invoice') {
            const token = getItem('bbbToken');

            //Modification des données de la facture modale affectation
            let form = new FormData(document.getElementById('affectation-operation'))
            form.append('sub_category_id', subCat.id)
            form.append('client_id', frontUser.id)

            const url = '/invoice/' + id;
            fetch(process.env.REACT_APP_API_URL + url, {
                method: 'POST', headers: {
                    'Authorization': 'Bearer ' + token,
                }, body: form
            })
            .then(response => {
                setIsLoading(false);
                if (response.ok) {
                    setCat(subCat);
                    toast.success('Mise à jour de la catégorie de la facture avec succès.')
                }
            })
            .catch(() => {
                setIsLoading(false);
                toast.error("Erreur lors de la modification")
            });
        }
    }

    return (
        <div className="item">
            <div className="category flex justify-between cursor-pointer" onClick={()=> {setToggle(!toggle)}}>
                <h6 className="cursor-pointer" >{category.display_name}</h6>
                <ArrowDropDownIcon className={`${toggle ? 'rotate' : ''}`}/>
            </div>
            <ul className={`${toggle ? '' : 'hidden'}`}>
                {subCats.map((subCat) =>
                    <li key={subCat.id} className="cursor-pointer" style={{ color: `${isLoading == subCat.id ? "#8000808f" : "#000"}` }} onClick={() => sendCategory(subCat)}>
                        <span>{subCat.display_name}</span>
                        {isLoading && isLoading == subCat.id ?
                            <span className="float-right pr-4"><PulseLoader color={'purple'} size={5} /></span>
                        :
                            null
                        }
                    </li>
                )}
            </ul>
        </div>
    )
}
export default ModalCategory