class Http {
    apiURL = process.env.REACT_APP_API_URL
    /**
     *
     * @param {string} path
     * @param {Object} body
     * @param {Object} headers
     */
    post = async (path, body, headers = {}) => {
        const formData = new FormData()
        Object.keys(body).forEach(key => {
            formData.append(key, body[key])
        });

        var clientID = this.getClientIdFromUrl()
        if (clientID)
            formData.append('client_id', clientID)

        const response = await fetch(`${this.apiURL}${path}`, {
            method: 'POST',
            body: formData,
            headers: {
                ...headers,
            },
        })
        let resBody = await response.json()
        if (response.ok) {
            return resBody
        } else if (!response.ok) {
            let errorMessage = resBody.message ?? 'Une erreur s\'est produite! Veuillez verifier votre requete et re-essayez'
            return new Promise((resolve, reject) => reject({ status: response.status, message: errorMessage }))
        }
    }

    /**
     *
     * @param {string} path
     * @param {Object} headers
     */
    get = async (path, headers = {}) => {
        const response = await fetch(`${this.apiURL}${path}`, {
            method: 'GET',
            headers: headers,
        })
        let body = await response.json()
        if (response.ok) {
            return body
        } else {
            let errorMessages = body.message ?? 'Une erreur s\'est produite! Veuillez verifier votre requete et re-essayez'
            return new Promise((resolve, reject) => reject({ status: response.status, message: errorMessages }))
        }
    }

    getClientIdFromUrl = () => {
        const usp = new URLSearchParams(window.location.search)
        const clientID = usp.get('vuserid')
        if (!clientID) return false
        return clientID
    }

    getClientRoleFromUrl = () => {
        const usp = new URLSearchParams(window.location.search)
        const clientType = usp.get('vusertype')
        if (!clientType) return false
        return clientType
    }
}

const http = new Http()

export default http