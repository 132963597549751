import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React, {useContext, useEffect, useState} from "react"
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import Auth from "../../context/Auth";
import {login} from "../../services/AuthApi";
import {
    PulseLoader
} from "react-spinners";
import CreateAccount from "./createAccount";
import ReCAPTCHA from "react-google-recaptcha";

//composant AuthForm equivalent à class
const AuthForm = () => {

    // States
    const [isPasswordHidden, setIsPasswordHidden] = useState(true)
    const [passwordType, setPasswordType] = useState('password')
    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [responseRecaptcha, setResponseRecaptcha] = useState('');
    const [espaceCreateAccount, setEspaceCreateAccount] = useState(false);

    //
    const {isAuthenticated, setIsAuthenticated} = useContext(Auth)
    const [user, setUser] = useState({
        username: "",
        password: ""
    })

    const changeVisibility = (val) => {
        setIsPasswordHidden(val)
        setPasswordType(val ? 'password' : 'text')
    }
    /**
     * Actualise l'Etat User lorsque les inputs écoutés changent de valeur
     * @param currentTarget
     */
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        // const name = currentTarget.name
        // const value = currentTarget.value
        setUser({...user, [name]: value})
    }

    useEffect(() => {
        const parentDiv = document.getElementById("captcha-parent")
        setTimeout(() => {
            ReactDOM.render(
                <ReCAPTCHA
                    sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    onChange={verifyCallback}
                />,
                parentDiv,
            );
        }, 1000)
    }, []);

    let verifyCallback = function (response) {
        setResponseRecaptcha(response)
    };

    const submit = async (e) => {
        e.preventDefault()

        if (user.cgv_check != 'on' || user.confidentialite_check != 'on') {
            setIsLoading(false);
            setError('Veuillez accepter les CGV et la politique de confidentialité');
            return;
        }

        setError("")
        setIsLoading(true);
        // if (responseRecaptcha !== '') {
            try {
                // const response = await login(this.state.user);
                const response = await login(user);
                // Si la connexion est réussie
                if (response === true) {
                    //on l'authentifie grace a la variable setIsAuthenticated
                    setIsAuthenticated(response);
                } else {
                    //Sinon on affiche l'ereur
                    setIsLoading(false);
                    setError('Vos identifiants sont invalides')
                }
            } catch ({response}) {
                console.log(response);
            }
        // } else {
        //     setError('Veuillez vérifier que vous êtes un humain')
        //     setIsLoading(false);
        // }
    }

    return (
        <React.Fragment>
            {espaceCreateAccount ?
                <CreateAccount/>

                :

                <div className="text-center flex justify-center items-center w-full h-full" id="registration">
                    <div>
                        <form className="h-full text-sm 2xl:text-lg">
                            <h1 className="mb-10 font-semibold text-black text-[1.5rem]">
                                Je me connecte à mon espace <a href="/" className="text-primary font-bold">Blabliblou</a>
                            </h1>
                            {/* Username */}
                            {/*<div className={`w-3/6 flex justify-center rounded-2xl py-2 ${!tab ? 'bg-lila text-white' : 'bg-gris text-lilaFonce'}`}>*/}
                            {/*    <button onClick={() => setTab(false)}>Liste client</button>*/}
                            {/*</div>*/}
                            <div>
                                <input className="w-full text-primary font-semibold bg-gris rounded-2xl p-6 h-14 placeholder-connexion"
                                       placeholder="Votre email de connexion"
                                       name="username"
                                    // onChange={ (currentTarget) => this.handleChange(currentTarget)}
                                       onChange={handleChange}
                                />
                            </div>
                            {/* Password */}
                            <div className="my-2 flex bg-gris rounded-2xl">
                                <input className="w-5/6 p-6 h-14 rounded bg-none-input text-primary font-semibold placeholder-connexion"
                                       type={passwordType}
                                       placeholder="Votre mot de passe" name="password"
                                    // onChange={ (currentTarget) =>this.handleChange(currentTarget)}
                                       onChange={handleChange}
                                />

                                <p className="w-1/6 m-auto">
                                    <button type="button" className="w-full"
                                        // onClick={() => this.changeVisibility(!this.state.isPasswordHidden)}
                                            onClick={changeVisibility.bind(this, !isPasswordHidden)}
                                    >
                                        {/*{setVisibilityButton}*/}
                                        {isPasswordHidden ? <VisibilityIcon fontSize="small" className="text-primary"/> :
                                            <VisibilityOffIcon fontSize="small"/>}
                                    </button>
                                </p>
                            </div>
                            <span className="text-red-500">{error}</span>
                            {/* Password forgotten button */}

                            <div className="h-8 flex mt-10 flex justify-center ">
                                <a className="text-primary underline" href="/forgot-password">Vous avez oublié votre mot de passe ?</a>
                            </div>

                            {/* Captcha */}
                            <div id="captcha-parent" className="hidden mx-auto justify-center my-8 w-3/6 ">
                            </div>
                            {/* Sign in button */}
                            <div className="flex mt-2">
                                {/*<div className="w-2/3"></div>*/}
                                <div className="w-full h-14">

                                    {isLoading ?
                                        <button className="w-full h-full bg-primary text-white rounded-2xl">
                                            <PulseLoader
                                                color={'white'} size={10}/>
                                        </button>
                                        :
                                        <button className="w-full rounded-2xl h-full bg-primary font-semibold text-white"
                                            // onClick={(e) => this.submit(e)}
                                                onClick={submit}
                                        >Rejoindre mon espace
                                        </button>
                                    }
                                </div>
                            </div>

                            {/* Sign up button */}
                            <div className="flex mt-5">
                                <div className="border w-full h-14 border-lilaFonce rounded-2xl">
                                    <button className="w-full h-full text-primary font-semibold" onClick={() => setEspaceCreateAccount(true)}> Créer un compte </button>
                                </div>
                            </div>

                            {/* CGV & Confidentiality policy */}
                            <div className="mt-10">
                                <div className="flex w-full">
                                    <input 
                                        type="checkbox" 
                                        className="black mr-2" 
                                        name="cgv_check" 
                                        onChange={handleChange}
                                        required 
                                    />
                                    <span className="text-black">
                                        J'ai lu et j'accepte les <Link to="/CGV" className="nav-link">conditions générales d'utilisation et de vente</Link>
                                    </span>
                                </div>
                                <div className="flex w-full mt-2">
                                    <input 
                                        type="checkbox" 
                                        className="black mr-2" 
                                        name="confidentialite_check" 
                                        onChange={handleChange} 
                                        required 
                                    />
                                    <span className="text-black">
                                        J'ai lu et j'accepte la <Link to="/confidentialite" className="nav-link">politique de confidentialité</Link>
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default AuthForm