import '../../../assets/style/dashboard.css'
import React from "react";
import {getItem} from "../../../services/LocaleStorage";
import {Link} from "react-router-dom";

const ResetPasswordSuccess = () => {

    const handleSubmit = (e) => {
        e.preventDefault()
        window.location.href = '/dashboard'
    }
    return (
            <div className="text-center flex justify-center items-center w-full h-full" id="registration">
                <div className="w-[34rem]">
                    <form onSubmit={handleSubmit} className="flex flex-col items-center " action="">

                        <div className="text-[1.5rem] w-full text-black font-bold mb-[3.5rem]">
                            <p>Votre mot de passe a été mis à jour avec succès!</p>
                        </div>

                        <div className=" w-[31.1rem]">
                            <div className="w-full">
                                <button className="bg-primary text-white rounded-xl p-2 w-full" type="submit">Continuer vers la page d'accueil</button>
                            </div>
                        </div>

                    </form>

                </div>
            </div>
    )
}
export default ResetPasswordSuccess