import {configureStore} from "@reduxjs/toolkit";
import userSlice from "./user";
import filtersSlice from "./filters";
import loaderSlice from "./loader";
import notificationsSlice from "./notifications";

export default configureStore({
    reducer: {
        user: userSlice,
        filters: filtersSlice,
        loader: loaderSlice,
        notifications: notificationsSlice,
    },
});
