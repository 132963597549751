import React, {useEffect, useState} from 'react'
import RowOperationPopup from "./RowOperationPopup";
import '../../assets/style/clientInfo.css'
import CloseIcon from "@material-ui/icons/Close";
import {getItem} from "../../services/LocaleStorage";
import {useSelector} from "react-redux";
import {getUser} from "../../app/user";
import {toast} from "react-toastify";

const OperationPopup = ({close, opParent, fetchOperationsBancaires}) => {
    const user = useSelector(getUser);
    const [dataOpParent, setdataOpParent] = useState(null);
    const [montTTCRestant, setMontTTCRestant] = useState(0);
    const [isloading, setIsloading] = useState(false);

    const [returnData, setReturnData] = useState([]);
    useEffect(() => {
        if (dataOpParent !== null) {

            if (dataOpParent.children.length !== 0) {
                //retourne les information children de l'op bancaire parent
                setReturnData(dataOpParent.children.map((children) => {
                    return {
                        id: children.id,
                        designation: children.label,
                        montant: children.amount_with_vat,
                        isInBDD: true,
                        type: children.type,
                        amount: children.amount,
                        vat: children.vat,
                        vat_value: children.vat_value,
                        vat_blocked: children.vat_blocked,
                        code_fiscal: children.code_fiscal,
                        operation_date: children.operation_date.split(' ')[0],
                        parent_id: dataOpParent.id,
                        has_parent: children.has_parent
                    }
                }))
            }else{
                setReturnData([{
                    id: Date.now(),
                    designation: "",
                    montant: "",
                    isInBDD: false,
                    type: dataOpParent?.type,
                    amount: 0,
                    vat: Number(dataOpParent?.vat),
                    vat_value: 0,
                    vat_blocked: dataOpParent?.vat_blocked,
                    code_fiscal: dataOpParent?.code_fiscal,
                    operation_date: dataOpParent?.operation_date?.split(' ')[0],
                    parent_id: dataOpParent?.id,
                    has_parent: true
                }])
            }

        }
    }, [dataOpParent]);

    useEffect(() => {
        console.log(opParent)
    }, [opParent]);


    useEffect(() => {
        if (dataOpParent !== null) {
            let total = 0
            returnData.forEach((el) => {
                total += Number(el.montant)
            })
            setMontTTCRestant(Number((dataOpParent.amount_with_vat - total).toFixed(2)))
        }

    }, [returnData, dataOpParent])

    useEffect(() => {
        if(opParent)
        {
            setdataOpParent(opParent)

        }
    }, []);

    //Ajout d'une ligne
    const addRow = () => {
        setReturnData(prevState => [...prevState, {
            id: Date.now(),
            designation: "",
            montant: "",
            isInBDD: false,
            type: dataOpParent.type,
            amount: 0,
            vat: Number(dataOpParent.vat),
            vat_value: 0,
            vat_blocked: dataOpParent.vat_blocked,
            code_fiscal: dataOpParent.code_fiscal,
            operation_date: dataOpParent.operation_date.split(' ')[0],
            parent_id: dataOpParent.id,
            has_parent: true
        }])
    }

    const emitChange = (property, newVal, rowId) => {
        let updatedRow = returnData.find(row => row.id === rowId)

        updatedRow[property] = newVal

        const updatedReturnData = returnData.map((row) =>
            row.id === rowId ? updatedRow : row
        )

        setReturnData(updatedReturnData)

        if (property === "montant") {
            let allAffect = 0
            returnData.forEach(row => allAffect = allAffect + row.montant)
            let newData = dataOpParent
            newData.montTTCRestant = newData.amount_with_vat - allAffect
            setdataOpParent(newData)
        }
    }
    const removeRow = (rowId, isInBDD) => {
        if (isInBDD) {
            const token = getItem('bbbToken')

            fetch(process.env.REACT_APP_API_URL + '/transaction', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({transactions_id: [rowId]})
            }).then((data) => {
                if (data.ok) {
                    toast.success('La suppression a été effectuée avec succès.')
                    //A verifier ici
                    deleteTransaction(rowId)
                    addRow()
                    fetchOperationsBancaires()
                }
            }).catch(() => {
                toast.error('La suppression a échouée.')
            })
        } else if (returnData.length > 1) {
            deleteTransaction(rowId)
        }
    }

    const removeRows = () => {
        const rowsIds = []
        returnData.forEach(child => {
            if(child.isInBDD){
                rowsIds.push(child.id)
            }
        })

        if (rowsIds.length !== 0) {
            const token = getItem('bbbToken')

            fetch(process.env.REACT_APP_API_URL + '/transaction', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({transactions_id: rowsIds})
            })
            .then((data) => {
                if (data.ok) {
                    toast.success('La suppression a été effectuée avec succès.')
                    close()
                    fetchOperationsBancaires()
                }
            })
            .catch(() => {
                toast.error('La suppression a échouée.')
            });
        } else{
            close()
        }
    }

    const deleteTransaction = (rowId) => {
        const updatedReturnData = returnData.filter(row => row.id !== rowId)
        let allAffect = 0
        updatedReturnData.forEach(row => allAffect = allAffect + row.montant)
        let newData = dataOpParent
        newData.montTTCRestant = newData.amount_with_vat - allAffect
        setdataOpParent(newData)
        setReturnData(updatedReturnData)
    }

    const sendData = () => {
        if (montTTCRestant !== 0) return
        setIsloading(true)
        const token = getItem('bbbToken')

        Promise.all(returnData.map((el) => {
            el.montant = Number(el.montant)
            const endpoint = el.isInBDD ? "/transaction/" + el.id : "/transaction"
            const htAmount = el.montant / ((el.vat / 100) + 1)
            const vatValue = el.montant - htAmount
            const form = new FormData()
            form.append("label", el.designation)
            form.append("amount_with_vat", el.montant)
            form.append("client_id", user.id)
            form.append("type", el.type)
            form.append("amount", htAmount)
            form.append("vat", el.vat)
            form.append("vat_value", vatValue)
            form.append("vat_blocked", dataOpParent.vat_blocked ?? false)
            // form.append("code_fiscal", el.code_fiscal)
            form.append("operation_date", el.operation_date)
            form.append("parent_id", dataOpParent.id)
            form.append("bank_id", dataOpParent.bank_id)

            console.log(document.getElementById('error-label').value)

            return fetch(process.env.REACT_APP_API_URL + endpoint, {
                method: 'POST',
                requirements: token,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                body: form
            })
        }))
        .then(() => {
            close()
            fetchOperationsBancaires()

            setIsloading(false)
        })
        .catch((error) => {
            toast.error('Erreur')
            setIsloading(false)
            close()
            console.log(error)
        });
    }

    return (
        <React.Fragment>
            <div
                className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
                <div className="w-[63.5rem] mx-auto bg-white rounded-lg shadow-xl p-[2.1em] z-100">
                    <button className="float-right pt-0" onClick={close}><CloseIcon/></button>
                    <h1 className="font-bold text-[24px] text-left z-100 pb-[2.1rem]">Dérouler l'opération</h1>
                    {dataOpParent !== null && <div id="rowsList" className="pb-4 grid grid-cols-custom-subgrid overflow-y-scroll max-h-60 min-h-32 transition-all duration-1000 ease-in-out 2xl:max-h-96 ">
                        <div
                            className="col-span-5 grid grid-cols-customGrid min-h-[6.5rem] bg-[#F2F5FF] rounded-lg shadow-md p-4 auto-cols-auto text-base font-semibold">
                            <p className=" text-[#717579]">Date</p>
                            <p className=" text-[#717579]">Libellé de l'opération</p>
                            <p className=" text-[#717579]">Montant HT</p>
                            <p className=" text-[#717579]">TVA</p>
                            <p className=" text-[#717579]">Montant TTC</p>

                            <p>{new Date(dataOpParent?.operation_date).toLocaleDateString('fr-FR')}</p>
                            <p>{dataOpParent.label}</p>
                            <p>{dataOpParent.amount} €</p>
                            <p>{dataOpParent.vat_value} ({dataOpParent.vat}%)</p>
                            <p>{dataOpParent.amount_with_vat} €</p>
                        </div>
                        {returnData.map((row, index) => index === returnData.length - 1 ?
                            <RowOperationPopup key={row.id} row={row} addRow={addRow} emitChange={emitChange}
                                               removeRow={removeRow} isLast={true} isAlone={returnData.length === 1}
                                               montRestant={montTTCRestant}/>
                            : <RowOperationPopup key={row.id} row={row} addRow={addRow} emitChange={emitChange}
                                                 removeRow={removeRow} isLast={false}/>
                        )}
                    </div>}

                    <div className={` w-full flex justify-end mx-auto mt-10 ` }>
                        <button className="bg-redCorail text-xs 2xl:text-sm font-semibold px-16 py-3 rounded-2xl text-white" onClick={removeRows}>Annuler le
                            Déroulement
                        </button>
                        {montTTCRestant === 0 && !isloading &&
                            <button
                                className="bg-greenSuccess text-xs 2xl:text-sm font-semibold px-16 py-3 rounded-2xl text-white ml-6" onClick={sendData}>Valider</button>
                            // :
                            // <button
                            //     className="bg-greenSuccess cursor-not-allowed opacity-50 text-xs 2xl:text-sm font-semibold px-5 py-3 rounded-xl text-white">Valider</button>
                        }

                    </div>

                </div>
            </div>


            {/*<div*/}
            {/*     className="w-full h-full fixed top-0 left-0 flex text-black z-500000 opacity-50 bg-black items-center desktop-operation">*/}
            {/*     <div className="w-2/3 mx-auto bg-white rounded-lg shadow-xl p-8 z-100">*/}
            {/*         <button className="float-right pt-0" onClick={closeModale}><CloseIcon/></button>*/}
            {/*         <div*/}
            {/*             className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">*/}
            {/*             <div className="w-2/3 mx-auto bg-white rounded-lg shadow-xl p-8 z-100">*/}
            {/*                 <button className="float-right pt-0" onClick={closeModale}><CloseIcon/></button>*/}
            {/*                 <div className="parent-table w-full">*/}
            {/*                     <div className="flex text-black font-semibold bg-grisClaire inline p-8 mt-8 text-xl rounded-xl">*/}

            {/*                         <p className="flex flex-col"><span><span*/}
            {/*                             className="font-bold">Attention : </span>Vous allez supprimer une ligne d'opération bancaire.</span>*/}
            {/*                             <span>Cette suppression est définitive.</span>*/}
            {/*                         </p>*/}

            {/*                     </div>*/}
            {/*                     <div className="float-right mt-8">*/}
            {/*                         <button onClick={confirmDel} className="bg-greenSuccess text-white rounded-xl p-2" type="button"> Confirmer*/}
            {/*                         </button>*/}

            {/*                     </div>*/}
            {/*                 </div>*/}

            {/*             </div>*/}
            {/*         </div>*/}
            {/*     </div>*/}
            {/* </div>*/}
        </React.Fragment>

    )
}

export default OperationPopup