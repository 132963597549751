import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import {PulseLoader} from "react-spinners";

const PopupEnforceAffectation = ({enforceAffectation, closePopup, isLoading}) => {
    const accept = () => {
        enforceAffectation(true);
    }

    const reject = () => {
        enforceAffectation(false);
    }

    return (
        <div
            className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
            <div className="w-1/3 mx-auto bg-white rounded-lg shadow-xl p-8 z-100 relative">
                <button className="absolute top-0 right-0 px-2 rounded-xl m-4" onClick={closePopup}><CloseIcon/></button>
                <div className="parent-table w-full">
                    <div className="text-black font-semibold bg-grisClaire text-center p-2">
                        <h1 className="text-xl">Voulez vous forcer la pièce?</h1>
                        <div className="flex justify-center mt-8 mb-3">
                            {isLoading ? 
                                <PulseLoader color={'purple'} size={10}/>
                            :
                                <>
                                    <button className="p-2 bg-primary rounded-xl text-white flex items-center justify-center mr-2" onClick={accept}>
                                        Oui
                                    </button>
                                    <button className="p-2 bg-primary rounded-xl text-white flex items-center justify-center" onClick={reject}>
                                        Non
                                    </button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PopupEnforceAffectation;