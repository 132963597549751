import React from 'react';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

// Déclaration de la fonction sortData en dehors de la portée du composant SortableTableHeader
export const sortData = (data, sortTerms, sortOrder) => {
    if (sortTerms && sortOrder) {
        data.sort((a, b) => {
            const valueA = a[sortTerms];
            const valueB = b[sortTerms];

            if (typeof valueA === 'string' && typeof valueB === 'string') {
                return sortOrder === 'ASC' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            } else {
                return sortOrder === 'ASC' ? valueA - valueB : valueB - valueA;
            }
        });
    }
    return data;
};

const SortableTableHeader = ({ label, sortKey, currentSortKey, sortOrder, onSortChange, className, style }) => {
    const handleClick = () => {
        if (sortKey === currentSortKey) {
            onSortChange(sortKey, sortOrder === 'ASC' ? 'DESC' : 'ASC');
        } else {
            onSortChange(sortKey, 'ASC');
        }
    };

    return (
        <th onClick={handleClick} className={className} style={{ ...style, cursor: 'pointer' }}>
            {label}
            {sortOrder && ( // Si un tri est en cours, affiche les deux flèches de tri
                <>
                    {sortOrder === 'ASC' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    {sortOrder === 'ASC' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                </>
            )}
        </th>

        // Si on ne doit pas afficher les deux flèches de tri mais une seule flèche, affiche ce qui suit

        // <th onClick={handleClick} className={className} style={{ ...style, cursor: 'pointer' }}>
        //     {label}
        //     {currentSortKey === sortKey && ( // Vérifie si le tri en cours concerne cette colonne
        //         <>
        //             {sortOrder === 'ASC' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} {/* Affiche la flèche de tri en fonction de la direction */}
        //         </>
        //     )}
        // </th>

    );
};

export default  SortableTableHeader;
