import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {getUser} from "../../app/user";
import {useDispatch} from "react-redux";
import Loader from "../../components/Loader";
import {getItem} from "../../services/LocaleStorage";
import ClientsAffectModal from "./ClientsAffectModal";
import mailround from '../../assets/img/mailround.png';
import mailIcon from '../../assets/img/mail-icon.png'

import {toast} from "react-toastify";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Pagination from "../Pagination";
import {PulseLoader} from "react-spinners";
import SortableTableHeader from './SortableTableHeader';

const ListeClients = ({dataClients, loading, loadingComp, dataAdminN, fetchClients}) => {

    const token = getItem('bbbToken')
    const user = useSelector(getUser)
    const [clientCurrent, setClientCurrent] = useState(null);
    // Affectation
    let adminsN = []

    const selectClients = (client) => {
        setClientCurrent(client)
    }

    const selectAdminsN = () => {
        adminsN = []
        document.querySelectorAll('.admin-n-checkbox:checked').forEach(checkbox => {
            adminsN.push(checkbox.dataset.id)
        })
        fetchAffectation()
    }

    // Attacher un/des clients à un/plusieurs admins
    const fetchAffectation = () => {
        const url = process.env.REACT_APP_API_URL + '/user/set-client-admin'
        const formData = new FormData()
        adminsN.forEach(id => {
            formData.append('admins[]', id)
        })
            formData.append('clients[]', clientCurrent.id)
        fetch(url, {
            method: 'POST',
            'Access-Control-Allow-Credentials': true,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: formData,
        }).then(response => {
            if (response.ok) {
                fetchClients()
                toast.success("Affectation réussie")
            }
            setClientCurrent(null)

        })
            .catch((err) => {
                toast.error("L'affectation a échoué")
            })
    }

    const closeModal = () => {
        setClientCurrent(null)
    }

    const menuEnter = (clientId) => {
        document.getElementById("admins-of-client-" + clientId).classList.toggle("hidden")
    }

    const [sortKey, setSortKey] = useState('creationDate');
    const [sortOrder, setSortOrder] = useState('ASC');

    const handleSortChange = (key, order) => {
        setSortKey(key);
        setSortOrder(order);
        // Appeler la fonction de récupération des clients avec les nouveaux paramètres de tri
        fetchClients(dataClients.per_page, dataClients.page, key, order);
    };
    

    return (
        <React.Fragment>
            <section className="mt-28">
                <Loader active={loading}/>
                <div className="relative">
                    <div className="overflow-auto rounded-lg w-full">
                        <table className="w-full">
                            <thead className="bgPurpuleCustom text-white">
                            <tr>
                                {/* <th className="w-12 p-3 text-sm font-semibold tracking-wide whitespace-nowrap text-left">Date clôture</th> */}
                                <SortableTableHeader
                                label="Date clôture"
                                sortKey="creationDate"
                                currentSortKey={sortKey}
                                sortOrder={sortOrder}
                                onSortChange={handleSortChange}
                                className="w-12 p-3 text-sm font-semibold tracking-wide whitespace-nowrap text-left"
                                // style={{  }}
                                />
                                <th className="w-12 p-3 text-sm font-semibold tracking-wide whitespace-nowrap text-left">Statut</th>
                                {/* <th className="p-4 text-sm font-semibold tracking-wide whitespace-nowrap text-left">Nom client</th> */}
                                <SortableTableHeader
                                label="Nom client"
                                sortKey="displayName"
                                currentSortKey={sortKey}
                                sortOrder={sortOrder}
                                onSortChange={handleSortChange}
                                className="p-4 text-sm font-semibold tracking-wide whitespace-nowrap text-left"
                                />
                                <th className="p-4 text-sm font-semibold tracking-wide whitespace-nowrap text-left">Message clients </th>
                                <th className="p-4 text-sm font-semibold tracking-wide whitespace-nowrap text-left">Prochaine Echéance</th>
                                <th className="p-4 text-sm font-semibold tracking-wide whitespace-nowrap text-left">Message Admin P</th>
                                <th className="p-4 text-sm font-semibold tracking-wide whitespace-nowrap text-left">Paiement </th>
                                <th className="p-4 text-sm font-semibold tracking-wide whitespace-nowrap text-left">Admin N</th>
                                <th className="p-4 text-sm font-semibold tracking-wide whitespace-nowrap text-left">Statut client</th>
                                <th className="p-4 text-sm font-semibold tracking-wide whitespace-nowrap text-left">Connexion à l'espace client</th>
                            </tr>

                            </thead>
                            {
                                loadingComp ? <tbody>
                                    <tr>
                                        <td className="p-0" colSpan="3">
                                            <PulseLoader
                                                color={'purple'} size={10}/>
                                        </td>
                                    </tr>
                                    </tbody> :
                                    dataClients.data.length === 0 ?
                                        <tbody>
                                        <tr>
                                            <td className="p-4" colSpan="3">Vous n'avez aucun client</td>
                                        </tr>
                                        </tbody>
                                        :
                                        <tbody className="divide-y divide-gray-200">
                                        {dataClients?.data.map((client, index) => {
                                            return <tr key={client.id} className={` ${index % 2 === 0 ? "bg-grisbleu" : ""}`} >
                                                <td className="p-3 text-sm text-gray-700 whitespace-nowrap text-left">{client.creation_date}</td>
                                                <td className="p-3 text-sm text-gray-700 whitespace-nowrap text-left">{client.status}</td>
                                                <td className="p-3 text-sm text-gray-700 whitespace-nowrap text-left">{client.display_name}</td>
                                                <td className="p-3 text-sm text-gray-700 flex justify-center">
                                                    <img src={mailround} className="img-mail" alt="img-mail"/>
                                                </td>
                                                <td className="p-3 text-sm text-gray-700 whitespace-nowrap text-left">--</td>
                                                <td className="p-3 text-sm text-gray-700 flex justify-center">
                                                    <img src={mailIcon} className="img-mailicon" alt="img-mail"/>
                                                </td>
                                                <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                                  <div className="colors">
                                                      <span className="bg-redDanger"></span>
                                                  </div>
                                                </td>
                                                <td className="relative text-xs" id={"td-client-" + client.id}>
                                                    <button type="button"
                                                            onClick={() => selectClients(client)}>{client.managers.length > 0 ? client.managers.map(
                                                        (admin, index) => {
                                                            return <p key={admin.id}>{admin.display_name}</p>
                                                        }
                                                    ) : null}
                                                        <ArrowDropDownIcon/>
                                                        </button>
                                                    <div id={"admins-of-client-" + client.id}
                                                         className="absolute bleu-bg text-white bg-opacity-100 rounded-xl h-full w-40 z-10 top-3/4 over overflow-y-hidden hidden">
                                                        <ul>
                                                            {client.managers.length === 0 ?
                                                                <li/> :

                                                                client.managers.map((admin) => {
                                                                    return <li key={admin.id} className="text-xs p-2">{admin.display_name}</li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </td>

                                                <td className="p-3 text-sm text-gray-700 whitespace-nowrap colors flex">
                                                    {client.creation_date}
                                                    <span className="bg-greenSuccess"></span>
                                                </td>
                                                <td className="p-3 text-sm text-gray-700 whitespace-nowrap text-center">
                                                    <a href={"/?vusertype=" + client.role + "&vuserid=" + client.id}
                                                       className="bg-lilaFonce p-3 rounded-lg text-white" target="_blank"><ExitToAppOutlinedIcon/></a>

                                                </td>



                                            </tr>
                                        })
                                        }
                                        </tbody>
                            }
                        </table>

                    </div>
                    {user.role === "admin_p" ?
                        <button className="btn-add"> <Link to='/register-client'
                        >+</Link></button>
                        : null
                    }
                </div>

                {clientCurrent !== null && <ClientsAffectModal closeModal={closeModal} dataAdminN={dataAdminN} selectAdminsN={selectAdminsN} client={clientCurrent}/>}
            </section>
            <div className="w-full flex justify-between items-center">
                <p className="pl-4">Affichage {dataClients.per_page} sur  {dataClients.count}</p>
                <Pagination data={dataClients} paginate={fetchClients}/>
            </div>


        </React.Fragment>
    )
}
export default ListeClients