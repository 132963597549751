import React, {useContext} from "react";
import {Redirect, Route} from "react-router-dom"
import Auth from "../../context/Auth";
import {useSelector} from "react-redux";
import {getUser} from "../../app/user";

/**
 * Vérifie si l'on est connecté.
 * Si oui, renvoie la Route demandée
 * Sinon redirige vers la page de login
 *
 * @param roles
 * @param path
 * @param component
 * @returns {JSX.Element}
 * @constructor
 */
const AuthenticatedRoute = ({roles = ['client', 'admin_n', 'admin_p'], path, component}) => {
    const {isAuthenticated} = useContext(Auth)

    const user = useSelector(getUser)

    return isAuthenticated ? (
        roles.includes(user.role) ? <Route exact path={path} component={component}/> : <Redirect to="/dashboard"/>
    ) : (
        <Redirect to="/"/>
    )
}
// test : to={"/dashboard?vusertype="+roles[2]+"&vuserid="+25}
export default AuthenticatedRoute