import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import { PulseLoader } from "react-spinners";

import { getItem } from "../../services/LocaleStorage";

const PopupAddBankAccount = ({ close }) => {
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (e) => {
    e.preventDefault()
    const token = getItem('bbbToken')
    const formData = new FormData(document.getElementById('add-bank-account'))
    const urlParams = new URLSearchParams(window.location.search)
    const vuserid = parseInt(urlParams.get('vuserid'))

    if (vuserid) {
      formData.append('client_id', vuserid)
    }

    try {
      setIsLoading(true)
      const response = await fetch(process.env.REACT_APP_API_URL + '/bank_account', {
        method: 'POST',
        requirements: token,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        body: formData
      })

      setIsLoading(false)
      close()
      if (response.ok) {
        toast.success('Compte bancaire a été ajouté avec succès')
        window.location.reload()
      } else {
        let data = await response.json()
        if (data.message) {
          toast.error(data.message)
        } else {
          for (const k in data) {
            if (data.hasOwnProperty(k)) {
              toast.error(`${k} : ${data[k]}`)
            }
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center">
      <div className="p-[3rem] mx-auto bg-white rounded-lg shadow-xl z-100 text-[#717579]">
        <button className="float-right pt-0" onClick={close}><CloseIcon/></button>
        <div className="parent-table w-full mt-[0.3rem]">
          <h1 className="text-[32px] font-bold text-black leading-8">Ajouter un compte bancaire</h1>
          <div className="w-full items-center mt-[1.6rem]">
            <form id="add-bank-account" onSubmit={onSubmit}>
              <div className={`w-full flex justify-center rounded-xl py-2 mr-2 bg-grisClaire border-b border-gray-200 mb-2`}>
                  <input type="text" name="name" className="bg-none-input w-full px-6 font-semibold" placeholder="Nom"/>
              </div>
              <div className={`w-full flex justify-center rounded-xl py-2 mr-2 bg-grisClaire border-b border-gray-200 mb-2`}>
                  <input type="text" name="number" className="bg-none-input w-full px-6 font-semibold" placeholder="Numéro de compte"/>
              </div>
              <div className={`w-full flex justify-center rounded-xl py-2 mr-2 bg-grisClaire border-b border-gray-200 mb-2`}>
                  <input type="text" name="address" className="bg-none-input w-full px-6 font-semibold" placeholder="Adresse"/>
              </div>
              <div className={`w-full flex justify-center rounded-xl py-2 mr-2 bg-grisClaire border-b border-gray-200 mb-2`}>
                  <input type="text" name="iban" className="bg-none-input w-full px-6 font-semibold" placeholder="IBAN"/>
              </div>
              <div className={`w-full flex justify-center rounded-xl py-2 mr-2 bg-grisClaire border-b border-gray-200 mb-2`}>
                  <input type="text" name="bic" className="bg-none-input w-full px-6 font-semibold" placeholder="BIC"/>
              </div>
              <div className="text-right mt-8">
                <button className="bg-[#09BD72] h-[2.8rem] text-white rounded-full p-2 uppercase font-semibold cursor-pointer" type="submit">
                  {
                    isLoading ? 
                      <span className="ml-auto text-[10px] indicateursView:text-[18px]">
                          <PulseLoader color={'white'} size={7}/>
                      </span>
                  : 
                    'Sauvegarder'
                  }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupAddBankAccount;