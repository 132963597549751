// confidentialite.js
import React from "react";

const confidentialite = () => {
    return (
        <div className="page-content">
            <h3 className="mb-10 text-3xl text-center font-extrabold underline underline-offset-3 decoration-8">
                Politique de confidentialité
            </h3>
            <div>
                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    I – Notion clés du Règlement Général sur la Protection des Données (RGPD)
                </h4>
                <p className="mt-3 mb-3">
                    Définitions : 
                </p>
                <ul>
                    <li>
                        <p>
                            <span className="font-extrabold">Données Personnelles</span> : toutes informations qui permettent, sous quelque forme que ce soit, 
                            l’identification des personnes physiques auxquelles elles s’appliquent.
                        </p>
                    </li>
                    <li>
                        <p>
                            <span className="font-extrabold">Personnes concernées</span> : personnes qui peuvent être identifiées, 
                            directement ou indirectement dans le cadre des activités de la Société (activité commerciale, marketing, relation client, etc.), 
                            c’est-à-dire l’ensemble des Utilisateurs, Clients et Prospects de BLABLIBLOU.
                        </p>
                    </li>
                    <li>
                        <p>
                            <span className="font-extrabold">Responsable de traitement</span> : organisme qui – seul ou conjointement avec d’autres – détermine le mode de traitement de données, 
                            c’est-à-dire sa finalité (objectifs poursuivis) et ses moyens (conditions de mises en œuvre, notamment sur le plan technique, 
                            matériel et organisationnel).
                        </p>
                    </li>
                    <li>
                        <p>
                            <span className="font-extrabold">Sous-traitant</span> : organisme qui traite des données pour le compte et sur instruction d’un autre organisme, 
                            Responsable de traitement.
                        </p>
                    </li>
                    <li>
                        <p>
                            <span className="font-extrabold">Traitement de Données Personnelles</span> : toute opération appliquée à des données ou des ensembles de données à caractère personnel, 
                            telles que la collecte, l’enregistrement, l’organisation, la structuration, la conservation, 
                            l’adaptation ou la modification, l’extraction, la consultation, l’utilisation, 
                            la communication par transmission, la diffusion ou toute autre forme de mise à disposition, 
                            le rapprochement ou l’interconnexion, la limitation, l’effacement ou la destruction.
                        </p>
                    </li>
                </ul>

                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    II – Données Personnelles concernées
                </h4>
                <p className="mt-3 mb-3">
                    Les Données Personnelles concernées par les présentes :
                </p>
                <ul>
                    <li>
                        <span className="font-extrabold">Données d’identification</span> : nom et prénom ; adresse mail ; mot de passe ; numéro de téléphone ; profession ; entreprise (nom, raison sociale ou SIRET).
                    </li>
                    <li>
                        <span className="font-extrabold">Données de connexion</span> : pays de connexion ; adresse IP ; log ; User ID, etc.
                    </li>
                    <li>
                        <span className="font-extrabold">Données web</span> : cookies et données de navigation ; avis et commentaires laissés sur de multiples canaux, tels que nos sites web ou encore les réseaux sociaux.
                    </li>
                    <li>
                        <span className="font-extrabold">Données financières</span> : données relatives à la carte de crédit de la Personne concernée dans le cadre du paiement de l’abonnement effectué par l’intermédiaire d’un prestataire.
                    </li>
                    <li>
                        <span className="font-extrabold">Données bancaires</span> : données relatives au compte bancaire de la Personne concernée dans le cadre de la synchronisation bancaire effectuée par l’intermédiaire de nos prestataires.
                    </li>
                </ul>

                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    III –Finalités associées à ces traitements de Données Personnelles
                </h4>
                <p className="mt-3 mb-3">
                    Les raisons pour lesquelles nous collectons vos données sont les suivantes :
                </p>
                <ul>
                    <li>Créer et gérer votre compte BLABLIBLOU ;</li>
                    <li>Gérer, suivre et traiter votre essai gratuit et le cas échéant, votre abonnement BLABLIBLOU ;</li>
                    <li>Déployer les services de synchronisation et de paiement via une vérification, authentification et identification des données transmises par la personne concernée ;</li>
                    <li>Gérer votre accès et l’utilisation de BLABLIBLOU via la mise en œuvre d’un support technique ;</li>
                    <li>Mettre en place et vous adresser des conseils et astuces autour de nos services par voie électronique (blog et centre d’aide) ;</li>
                    <li>Constituer et mettre à jour une base d’Utilisateurs, de Clients et Prospects (statistiques et mesures d’audience) ;</li>
                    <li>Mettre en place et vous adresser par voie électronique des informations commerciales et publicitaires adaptés à vos préférences et besoins éventuels (newsletters, sollicitations et messages promotionnels) ;</li>
                    <li>Gérer la gestion des avis et retours des Personnes concernées sur nos services ou contenus ;</li>
                    <li>Optimiser le fonctionnement de BLABLIBLOU via une amélioration de nos services et de notre site internet en fonction de votre expérience ;</li>
                    <li>Assurer la continuité des services et maintenir un environnement sécurisé via la détection de logiciels malveillants et la gestion des incidents de sécurité.</li>
                </ul>

                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    IV – Destinataires de vos Données Personnelles
                </h4>
                <p className="mt-3 mb-3">
                    L’accès aux Données personnelles est strictement encadré. La Société s’assure que les données ne soient accessibles qu’à des destinataires internes ou externes autorisés.
                </p>
                <h5 className="mb-2 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    Destinataires internes :
                </h5>
                <ul>
                    <li>Le personnel habilité des services marketing, commercial, relations client, administratif et techniques.</li>
                </ul>
                <h5 className="mb-2 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    Destinataires externes :
                </h5>
                <ul>
                    <li>Les partenaires et sous-traitants de la Société et, plus particulièrement, leur personnel habilité à accéder aux seules données nécessaires à la mise en œuvre de leurs services ;</li>
                    <li>Les organismes, les auxiliaires de justice et les officiers ministériels, dans le cadre de leur mission de recouvrement de créances ;</li>
                    <li>Les destinataires de vos Données Personnelles au sein de la Société sont soumis à une obligation de confidentialité spécifique. La Société décide quel destinataire est habilité, en interne, à recevoir des données.</li>
                </ul>
                <p className="mt-5 mb-5">
                    La politique d’habilitation est régulièrement mise à jour et tient compte des arrivées et des départs des salariés de la Société ayant accès aux données. 
                    Si un salarié se rend compte qu’il dispose d’un accès à des données auxquelles il ne devrait pas avoir accès, 
                    il a pour obligation de prévenir sans délai le service compétent. 
                    Tous les accès concernant des traitements relatifs aux Données Personnelles des Personnes concernées font l’objet d’une mesure de traçabilité.
                    Par ailleurs, vos Données Personnelles peuvent être transmises à des fournisseurs de services tiers qui sont tenus de les utiliser dans le cadre des seules missions que la Société leur a confié, notamment : 
                </p>
                <ul>
                    <li>Pour la mise en œuvre de la synchronisation bancaire, la Société est en relation avec des sociétés financières avec lesquelles elle a passé une convention de partenariat spécifique ;</li>
                    <li>
                        Lorsque la Société recourt à des Sous-traitants et contractants indépendants pour l’accompagner dans la fourniture d’un certain nombre de services : 
                        plateforme de messagerie client, publicité, statistiques, gestion et hébergement de données, services de paiement, etc. 
                        Ces prestataires disposent d’un accès limité aux données des Personnes concernées, 
                        dans le cadre de la stricte exécution de ces prestations.
                    </li>
                    <li>Lorsque la Personne concernée autorise le site web d’un tiers à accéder à ses données.</li>
                </ul>
                <p className="mt-5 mb-5">
                    Dans ce contexte, la Société s’assure que la sécurité de vos Données est préservée via un contrôle strict :
                </p>
                <ul>
                    <li>
                        Dans le cas où des informations personnelles sont transférées au sein de l’Union Européenne, 
                        la Société s’assure de l’adhésion de ces fournisseurs de services tiers aux principes du 
                        “Règlement général de la protection des données” (RGPD);
                    </li>
                    <li>
                        Dans le cas où des informations personnelles sont transférées en dehors de l’Union européenne, la Société s’assure que le pays tiers concerné dispose d’un niveau de protection jugé adéquat par 
                        la réglementation européenne (par exemple en cas de transfert de données aux États-Unis, contrôle de l’adhésion du fournisseur de services tiers aux principes du “Privacy Shield”).
                    </li>
                </ul>
                <p className="mt-5 mb-5">
                    Vos Données Personnelles pourront aussi être communiquées à toute autorité légalement habilitée à en connaître. 
                    La Société peut notamment effectuer la transmission de données pour donner suite aux réclamations présentées 
                    contre elle et se conformer aux procédures administratives et judiciaires. 
                    Dans ce cas, la Société n’est pas responsable des conditions dans lesquelles les personnels de ces autorités ont accès et exploitent vos données.
                </p>

                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    V – Durée de la conservation de vos données personnelles
                </h4>
                <p className="mt-5 mb-5">
                    La Société conserve vos données une certaine durée pour vous fournir ses services ou son assistance. 
                    La Société peut également conserver certaines de vos informations si nécessaire, 
                    même après que vous ayez fermé votre compte ou qu’elle n’en ait plus besoin pour vous fournir ses services. 
                    Vos Données Personnelles ne feront cependant pas l’objet de cessions, locations ou échanges au bénéfice de tiers.
                </p>
                <p className="mt-5 mb-5">
                    La durée de conservation des données est définie par la Société au regard des contraintes légales et 
                    contractuelles qui pèsent sur elle et à défaut, en fonction de ses besoins : 
                </p>
                <h5 className="mb-2 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    Durées de conservation pour chaque catégorie de Données Personnelles
                </h5>
                <ul>
                    <li>
                        <span className="font-extrabold">Données relatives aux Utilisateurs et Clients</span> (données d’identification, données web, suivi de la relation client) :<br />
                        Les données relatives aux Utilisateurs et Clients sont conservées toute 
                        la durée d’ouverture du Compte et jusqu’à 30 jours après, sur demande. 
                        Cette durée peut être augmentée de 3 ans à des fins d’animation et de prospection.
                    </li>

                    <li>
                        <span className="font-extrabold">Données relatives aux Prospects</span> (données d’identification et données web) :<br />
                        Les données relatives aux Prospects sont conservées pour une durée maximum de 3 ans à compter 
                        de leur collecte ou du dernier contact émanant du Prospect.
                    </li>
                    <li>
                        <span className="font-extrabold">Données techniques</span> (données de connexion et cookies) :<br />
                        Les données de connexion (adresses IP et logs des Personnes concernées) sont conservées pour une durée 
                        d’un an à compter de la dernière connexion ou de la dernière utilisation de BLABLIBLOU. 
                        Les cookies peuvent être conservés pour une durée de 12 mois à compter de la dernière manifestation 
                        du consentement.
                    </li>
                    <li>
                        <span className="font-extrabold">Données financières</span> (modalités de paiement) :<br />
                        Les transactions financières relatives au paiement des frais d’abonnement via le site, 
                        sont confiées à un prestataire de services de paiement qui en assure l’hébergement, 
                        le bon déroulement et la sécurité. Destinataire de vos Données Personnelles relatives 
                        à vos numéros de cartes bancaires, il les recueille et conserve, en notre nom et pour 
                        notre compte pendant le temps de l’exécution des opérations de paiement. 
                        Nous n’avons jamais accès à vos données de paiement.
                    </li>
                    <li>
                        <span className="font-extrabold">Données bancaires</span> (données de connexion, synchronisation des comptes et reprises d’historiques) :<br />
                        La collecte des transactions bancaires est confiée à l’un de nos prestataires de synchronisation bancaire 
                        qui en assurent l’hébergement, le bon déroulement et la sécurité. Chacun recueille et conserve, 
                        en notre nom et pour notre compte les données de connexion et les données relatives aux transactions 
                        bancaires pendant le temps de votre utilisation de BLABLIBLOU. Nous n’avons jamais accès aux données 
                        d’identification à l’interface bancaire.
                    </li>
                </ul>
                <p className="mt-5 mb-5">
                    Les données permettant d’établir la preuve d’un droit ou d’un contrat (données clients, etc.) 
                    ou conservées au titre du respect d’une obligation légale (données de facturation, etc.), 
                    font l’objet d’une politique d’archivage intermédiaire pour une durée n’excédant pas la durée nécessaire 
                    aux finalités pour lesquelles elles sont conservées, conformément aux dispositions en vigueur. 
                </p>
                <p className="mt-5 mb-5">
                    Passé les délais fixés, les données sont soit supprimées, soit conservées après avoir été anonymisées, 
                    notamment pour des raisons d’usages statistiques. Il est rappelé aux Personnes concernées que la suppression 
                    ou l’anonymisation des données stockées dans ses systèmes sont des opérations irréversibles et 
                    que la Société n’est plus, par la suite, en mesure de les restaurer.
                </p>

                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    VI – Sécurité appliquées aux Données Personnelles
                </h4>
                <p className="mt-5 mb-5">
                    En tant que Responsable de traitement, la Société s’engage à aligner ses pratiques pour respecter 
                    la réglementation européenne et garantir un niveau de sécurité adapté au risque (article 32 §1 du RGPD).
                </p>
                <p className="mt-5 mb-5">
                    Les mesures techniques de sécurité prises par la Société dans le cadre de ses traitements de Données Personnelles 
                    comprennent, de manière non-exhaustive, les mesures suivantes :
                </p>
                <ul>
                    <li>
                        Les mots de passe et les noms d’utilisateurs sont demandés avant d’accéder à tout 
                        environnement électronique dans lequel des Données Personnelles sont stockées et un journal 
                        des accès est tenu ;
                    </li>
                    <li>
                        Tout support électronique et tout logiciel sur lequel des Données Personnelles sont stockées est 
                        régulièrement mis à jour et protégé contre les malware et l’accès non-autorisé ;
                    </li>
                    <li>
                        Des mesures de sécurité adéquates (ex : pare-feu etc..) sont mises en place à l’interface entre 
                        les environnements accessibles aux tiers et les zones de stockage de l’entreprise, ainsi que 
                        des mesures de lutte contre les attaques virtuelles menaçant la sécurité des données (IDS/IPS etc.) ;
                    </li>
                    <li>
                        La transmission des Données Personnelles est toujours réalisée par l’intermédiaire de services de 
                        communications cryptés (email, FTP, partage de fichier, mode de sécurisation HTTPS) ;
                    </li>
                    <li>
                        L’environnement (serveurs, etc.) de stockage des Données Personnelles pour le compte du Responsable 
                        de traitement fait l’objet d’une protection physique et l’accès à celui-ci doit être contrôlé et 
                        limité aux seuls membres du personnel autorisés ;
                    </li>
                    <li>
                        Les Données Personnelles peuvent être anonymisées ou supprimées à l’aide de méthodes adéquates. 
                        La suppression des Données Personnelles stockées dans un environnement électronique a pour 
                        effet de rendre impossible toute récupération des données.
                    </li>
                </ul>

                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    VII – Droit sur vos Données Personnelles 
                </h4>
                <p className="mt-5 mb-5">
                    Afin de permettre une mise à jour régulière des données à caractère personnel collectées par la Société, 
                    celle-ci pourra solliciter les Personnes concernées qui auront pour obligation de satisfaire aux 
                    demandes de la Société. 
                </p>
                <p className="mt-5 mb-5">
                    En application de la réglementation applicable aux données à caractère personnel, les Personnes concernées 
                    disposent quant à elles des droits suivants :
                </p>
                <ul>
                    <li>
                        <span className="font-extrabold">Droit d’accès</span> (article 15 du RGPD) : ils peuvent exercer leur droit d’accès, pour connaître les 
                        Données Personnelles les concernant.
                    </li>
                    <li>
                        <span className="font-extrabold">Droit de rectification</span> (article 16 du RGPD) : si les Données Personnelles détenues par la Société 
                        sont inexactes, elles peuvent demander la mise à jour des informations ; Les personnes concernées 
                        sont informées que la Société ne procèdera à aucune modification dite de « confort », 
                        celles-ci étant possible depuis l’onglet “Profil” dans la section “Paramètres” du compte BLABLIBLOU. 
                        Seules des modifications substantielles sur l’état civil, l’identité, la profession et 
                        les coordonnées de la personne concernée seront réalisées.
                    </li>
                    <li>
                        <span className="font-extrabold">Droit de suppression</span> (article 5 du RGPD portant sur la “purge” de 
                        données et article 17 du RGPD concernant l’effacement des données ou encore “droit à l’oubli”) : 
                        les Personnes concernées peuvent demander la suppression (totale ou partielle) de leurs Données Personnelles, 
                        conformément aux réglementations applicables en matière de protection des données ;
                    </li>
                    <li>
                        <span className="font-extrabold">Droit à la limitation du traitement</span> (article 18 du RGPD) : les Personnes 
                        concernées peuvent demander à la Société de limiter le traitement de leurs Données Personnelles 
                        conformément aux hypothèses prévues par le RGPD ;
                    </li>
                    <li>
                        <span className="font-extrabold">Droit de s’opposer au traitement des données</span> (article 21 du RGPD) : 
                        les Personnes concernées peuvent s’opposer à ce que leurs données soient traitées conformément 
                        aux hypothèses prévues par le RGPD ;
                    </li>
                    <li>
                        <span className="font-extrabold">Droit à la portabilité</span> (article 20 du RGPD) : 
                        elles peuvent réclamer que la Société leur remette les Données Personnelles qu’elles ont fournies 
                        pour les transmettre à une nouvelle entité, dans le cadre strict des réglementations applicables 
                        en matière de protection des données.
                    </li>
                </ul>
                <p className="mt-5 mb-5">
                    Vous pouvez exercer un ou plusieurs de ces droits en nous contactant via le module dédié. 
                </p>
                <p className="mt-5 mb-5">
                    Une réponse vous sera adressée dans le mois suivant la réception de la demande. Ce délai d’un mois 
                    peut être prolongé de deux mois si la complexité de la demande et/ou le nombre de demandes l’exigent. 
                    Afin de nous prémunir de tout risque de fuite de données ou d’usurpation d’identité, certaines demandes 
                    devront être accompagnées de la photocopie d’un titre d’identité en cours de validité signé.
                </p>
                <p className="mt-5 mb-5">
                    Malgré tout, en cas de violation de l'intégrité de vos données, et conformément à la loi, nous notifierons 
                    l'incident à la CNIL et vous en serez avertis dans les délais légaux. Les partenaires concernés 
                    seront également prévenus. Nous prendrons par ailleurs toutes les mesures nécessaires pour que 
                    l’incident ne se reproduise pas.
                </p>

                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    VIII – Acceptation
                </h4>
                <p className="mt-5 mb-5">
                    En utilisant notre site internet et notre plateforme, vous acceptez toutes les dispositions de cette 
                    politique de confidentialité et vous acceptez que BLABLIBLOU recueille et traite vos données conformément 
                    à la présente politique de confidentialité.
                </p>

                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    IX – Responsable du traitement des données
                </h4>
                <p className="mt-5 mb-5">
                    Nom : MONTNACH<br />
                    Prénom : Benoit<br />
                    Fonction : Président<br />
                    Adresse de messagerie : <a href="mailto:contact@blabliblou.fr" className="text-purple-600">contact@blabliblou.fr</a><br />
                </p>

                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    X. - Contact
                </h4>
                <p className="mt-5 mb-5">
                    Pour toute information complémentaire ou commentaire concernant cette politique de confidentialité, 
                    veuillez nous contacter par mail à <a href="mailto:webmaster@blabliblou.fr" className="text-purple-600">webmaster@blabliblou.fr</a>
                </p>
            </div>
        </div>
    );
}

export default confidentialite;