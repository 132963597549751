import Loader from "../Loader";
import React, {useEffect, useState} from "react";

// eslint-disable-next-line no-unused-vars
function EcartResults({ datasetResult, datasetCA, months }) {
    // eslint-disable-next-line no-unused-vars
    const [isLoading, setIsLoading] = useState(false);
    const [payes, setPayes] = useState({});
    const [prevu, setPrevu] = useState({});
    const [estime, setEstime] = useState({});
    const [previous, setPrevious] = useState({});
    const [caHT, setCaHT] = useState({});
    const [caPrevu, setCaPrevu] = useState({});

    useEffect(() => {
        console.log(datasetCA)
        if (datasetResult.length !== 0) {
            setEstime(datasetResult[2].data)
            setPayes(datasetResult[0].data)
            setPrevu(datasetResult[1].data)
            setPrevious(datasetResult[3].data)
            setCaHT(datasetCA[0].data)
            setCaPrevu(datasetCA[1].data)
        }
    }, [datasetResult, datasetCA]);

    // Fonction de formatage des nombres avec Intl.NumberFormat
    const formatNumber = (number, maximumFractionDigits = 4) => {
        return new Intl.NumberFormat('fr-FR', {
            maximumFractionDigits: maximumFractionDigits,
            useGrouping: true,
        }).format(number);
    };


    return (
        <div className="w-full">
            <Loader active={isLoading}/>
            <section className="msg-table w-full">
                <div className="parent-table text-xs 2xl:text-sm overflow-x-hidden w-full">
                    <h3 className="font-semibold text-black text-xl mb-8">Écarts entre les Résultats</h3>
                    <React.Fragment>
                        <table className="table-auto overflow-hidden min-w-full">

                            <thead className="bg-bleuTable">
                                <tr className="text-left">
                                    <th className="px-3">Résultat HT</th>
                                    <th className="px-3">Résultat HT estimé</th>
                                    <th className="px-3 flex flex-col" style={{ lineHeight: '1.4' }}>
                                        <span>Écart Résultat HT /</span>
                                        <span>Résultat HT estimé</span>
                                    </th>
                                    <th className="px-3">Résultat HT prévu</th>
                                    <th className="px-3">Écart Résultat HT / CA prévu</th>
                                    <th className="px-3 flex flex-col" style={{ lineHeight: '1.4' }}>
                                        <span>% avancement CA HT /</span>
                                        <span>CA HT prévu</span>
                                    </th>
                                    <th className="px-3">Résultat HT n-1</th>
                                    <th className="px-3 flex flex-col" style={{ lineHeight: '1.4' }}>
                                        <span>Écart Résultat HT /</span>
                                        <span>Résultat HT n-1</span>
                                    </th>
                                </tr>
                            </thead>


                            {months.length === 0 ?
                                <tbody>
                                <tr>
                                    <td className="p-0" colSpan="3">Aucunes données...</td>
                                </tr>
                                </tbody> :

                                <tbody>

                                {
                                    months.map((month, index) => {
                                        return (
                                            <tr key={index} className={'border-b border-gray-200 font-bold ' + (index % 2 === 0 ? "bg-bleuPale" : "")} style={{ fontWeight: 'bold' }}>
                                                <td className="text-black">{formatNumber(payes[index])}</td>
                                                <td className="text-black">{formatNumber(estime[index])}</td>
                                                <td className="text-redText">{formatNumber(payes[index] - estime[index])}</td>
                                                <td className="text-black">{formatNumber(prevu[index])}</td>
                                                <td className="text-redText">{formatNumber(payes[index] - caPrevu[index])}</td>
                                                <td className="text-redText">{caPrevu[index] === 0 ? <span className="text-2xl">∞</span> : formatNumber((100 / caPrevu[index]) * caHT[index]) + "%"}</td>
                                                <td className="text-black">{formatNumber(previous[index])}</td>
                                                <td className="text-greenText">{previous[index] === 0 ? <span className="text-2xl">∞</span> : formatNumber((100 / previous[index]) * payes[index]) + "%"}</td>
                                            </tr>
                                        );
                                    })

                                }
                                </tbody>
                            }
                        </table>

                    </React.Fragment>
                </div>

            </section>

        </div>
    )

}

export default EcartResults