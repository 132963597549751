const alerts = (extra = false) => ({
  	AFFECTATIONS_OPS_CATEGS_CONFLICT: extra
    ? 
		<>
			<p className="font-semibold">Les opérations bancaires ci-dessous sont associées déjà à d'autres catégories différentes. Veuillez confirmer pour les écraser et appliquer la catégorie sélectionnée.</p>
			<br />
			<div dangerouslySetInnerHTML={{ __html: extra }} />
		</>
    : 
		"Les opérations bancaires suivantes sont associées à d'autres catégories différentes. Veuillez confirmer pour les écraser et appliquer la catégorie sélectionnée.",

  	AFFECTATIONS_INVOICES_CATEGS_CONFLICT: extra
    ?
		<>
			<p className="font-semibold">Les factures ci-dessous sont associées déjà à d'autres catégories différentes. Veuillez confirmer pour les écraser et appliquer la catégorie sélectionnée.</p>
			<br />
			<div dangerouslySetInnerHTML={{ __html: extra }} />
		</>
    : 
		"Les factures ci-dessous sont associées déjà à d'autres catégories différentes. Veuillez confirmer pour les écraser et appliquer la catégorie sélectionnée."
});

export {
  alerts
}
