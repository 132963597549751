import {createSlice} from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        currentUser: {isVirtual: false},
        virtualUser: {isVirtual: true},

        isVirtualConnexion: false,
        // urlParameters: {
        //     vusertype: null,
        //     vuserid: null
        // }
    },
    reducers: {
        setUser: (state, action) => {
            // on écrit dans le state
            // action.payload vaut l'objet user
            for (const [key, value] of Object.entries(action.payload)) {
                state.currentUser[key] = value
            }
        },
        setVirtualUser: (state, action) => {
            // on écrit dans le state
            // action.payload vaut l'objet obj
            for (const [key, value] of Object.entries(action.payload)) {
                state.virtualUser[key] = value
            }
            // Mettre isVirtualConnexion à true (quand on se connecte à l'espace client : OK )
            state.isVirtualConnexion = true
        },
        logoutVirtualUser: (state) => {
            state.isVirtualConnexion = false
            state.virtualUser = {isVirtual: true}
        },
        logoutUserState: (state) => {
            state.currentUser = {isVirtual: false}
            state.isVirtualConnexion = false
            state.virtualUser = {isVirtual: true}
        },
        // Hydrate le store des paramètres d'URL
        // setUrlParameters: (state, action) => {
        //     console.log(action.payload)
        //     state.urlParameters.vuserid = action.payload.vuserid
        //     state.urlParameters.vusertype = action.payload.vusertype
        //
        // }
    },
})

export const {setUser, setVirtualUser, logoutVirtualUser, logoutUserState, /*setUrlParameters*/} = userSlice.actions
export default userSlice.reducer

export function getUser(state) {
    // let data = state.user.virtualUser
    // data['currentUser'] = state.user.currentUser
    // return data
    return state.user.isVirtualConnexion ? state.user.virtualUser : state.user.currentUser
}

export function getRealUser(state) {
    return state.user.currentUser
}

export function getRealUserRole(state) {
    return state.user.currentUser.role
}

export function isVirtualConnexion(state) {
    return state.user.isVirtualConnexion
}