import '../assets/style/dashboard.css'
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import MesDocuments from "../components/Mon BBB/mesDocuments";
import MesFactures from "../components/Mon BBB/mesFactures";
import MonContrat from "../components/Mon BBB/monContrat";
import http from '../services/Http'
import MesInfos from "../components/Mon BBB/mesInfos";
import MonArchivage from "../components/Mon BBB/monArchivage";
import {setPageTitle} from "../app/filters";


const MonBbb = () => {
    const user = useSelector(state => state.user.currentUser);
    const [isClient, setIsClient] = useState(false);
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(setPageTitle('Mon Blabliblou'))
    });

    useEffect(() => {
        if (user.role === "client" || http.getClientRoleFromUrl() === "client") {
            setIsClient(true);
        }
    }, [user]);

    return (
        <React.Fragment>
            <div className="w-full mb-0 content mt-[6.4rem] pt-[4.1rem]">
                <MesInfos/>
            </div>
            {isClient && <React.Fragment>
                <div className="w-full mt-0 content">
                    <MonContrat/>
                    <MesFactures/>
                    <MesDocuments/>
                    <MonArchivage/>
                </div>

            </React.Fragment>
            }

        </React.Fragment>
    )
}
export default MonBbb