// import axios from "axios";
import jwtDecode from "jwt-decode"
import { getItem, addItem, removeItem } from "./LocaleStorage";

/**
 * Cherche la présence d'un token lance tokenIsValid()
 * @returns {boolean|boolean}
 */
export function hasAuthenticated(){
    const token = getItem('bbbToken')
    const result = token ? tokenIsValid(token) : false

    if (false === result){
        removeItem('bbbToken')
    }
    return result
}

export async function login(credentials){
    return fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
    .then(async (response) => {
        if (response.ok) {
            return response.json().then(response => {
                addItem('bbbToken', response.token);
                return true;
            });
        } else {
            return response.json().then(response => {
                return response.message;
            });
        }
    });
}

// Déconnexion
export function logout(){
    removeItem('bbbToken')
}

/**
 * Vérifie la validité d'un token (expiration)
 * @param token
 * @returns {boolean}
 */
function tokenIsValid(token) {
    const {exp: expiration} = jwtDecode(token)
    return expiration * 1000 > new Date().getTime();

}