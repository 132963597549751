import React, {useEffect, useState} from "react";
import {getItem} from "../../services/LocaleStorage";
import {useSelector} from "react-redux";
import {getRealUser, getUser} from "../../app/user";
import {ArrowDownward, DeleteOutlineOutlined, Search} from "@material-ui/icons";
import AddDoc from "./addDoc";
import Loader from "../Loader";
import PopupDelDoc from "./popupDelDoc";
import Pagination from "../Pagination";


const MesDocuments = () => {
    const user = useSelector(getUser)
    const realUser = useSelector(getRealUser)

    const [dataDocuments, setDataDocuments] = useState([])
    const [addDocumentModalOpen, setAddDocumentModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openPopupDelDoc, setOpenPopupDelDoc] = useState(false);
    const [docId, setDocId] = useState('');

    //Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [docsPerPage, setDocsPerPage] = useState(8);
    const [currentDocs, setCurrentDocs] = useState([])

    const paginate = (pageNumbers) => {
        setCurrentPage(pageNumbers)
    }

    useEffect(() => {

        const last = currentPage * docsPerPage
        const first = (currentPage * docsPerPage) - docsPerPage
        const newCurrDocs = dataDocuments.slice(first, last)
        setCurrentDocs(newCurrDocs)
    }, [dataDocuments, currentPage])
    //Fin Pagination
    const closeModal = () => {
        setAddDocumentModalOpen(false)
        setOpenPopupDelDoc(false)
    }

    useEffect(() => {
        fetchDoc()
    }, []);

    const fetchDoc = () => {
        const token = getItem('bbbToken')
        try {
            setIsLoading(true)
            fetch(process.env.REACT_APP_API_URL + '/user/' + user.id + '/document', {
                method: 'GET',
                requirements: token,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            }).then(response => {
                if (response.ok) {
                    setIsLoading(false)
                    response.json().then(response => {
                        setDataDocuments(response)
                    })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const del = (docId) => {
        setOpenPopupDelDoc(true)
        setDocId(parseInt(docId))
    }


    return (
        <div className="w-full">
            <Loader active={isLoading}/>
            <div  className="">
                <h1 className="font-bold text-black text-xl my-8">Mes documents</h1>

                <div className="overflow-auto rounded-lg w-full">
                    <table className="w-full">
                        <thead className="bgPurpuleCustom text-white">
                        <tr>
                            <th className="p-4 text-sm font-semibold tracking-wide text-left">
                                <div className="flex flex-col justify-start">
                                    <span>Titre</span>
                                    <span className="invisible">invisible</span>
                                </div>
                            </th>
                            <th className="p-4 text-sm font-semibold tracking-wide text-left">
                                <div className="flex flex-col justify-start">
                                    <span>Détails</span>
                                    <span className="invisible">invisible</span>
                                </div>
                            </th>

                            <th className="p-4 text-sm font-semibold tracking-wide text-center">
                                <div className="flex flex-col justify-start">
                                    <span></span>
                                    <span className="invisible">invisible</span>
                                </div>

                            </th>
                            <th className="text-sm font-semibold tracking-wide text-center absolute">
                                {realUser.role !== "client" &&
                                    <button 
                                        type="button" 
                                        className="btn-add"
                                        onClick={() => setAddDocumentModalOpen(true)}
                                    >
                                        +
                                    </button>
                                }
                            </th>
                        </tr>
                        </thead>
                        {dataDocuments === null || dataDocuments.length === 0 ?
                            <tbody>
                                <tr>
                                    <td className="p-4" colSpan="4">Vous n'avez aucun documents</td>
                                </tr>
                            </tbody>
                        :
                            <tbody className="divide-y divide-gray-200">
                                {dataDocuments.map((document, index) => {
                                    return (
                                        <tr key={dataDocuments.id} className={`${index % 2 === 0 ? "bg-grisbleu" : ""}`}>
                                            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{document.name}</td>
                                            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{document.descriptionn}</td>
                                            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                                <div className=" flex justify-between">
                                                    <div>
                                                        <a 
                                                            href={process.env.REACT_APP_STORAGE_URL + document.path} 
                                                            target="_blank" 
                                                            rel="noreferrer"
                                                            className=" inline-block bg-lilaFonce text-white p-2 rounded-lg"
                                                        >
                                                            <Search/>
                                                        </a>

                                                        <button type="button" className="ml-2 bg-lilaFonce text-white p-2 rounded-lg">
                                                            <a 
                                                                href={process.env.REACT_APP_STORAGE_URL + document.path} 
                                                                download
                                                            >
                                                                <ArrowDownward/>
                                                            </a>
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button 
                                                            type="button" 
                                                            className="bg-redCorail text-white p-2 rounded-lg ml-2"
                                                            onClick={() => del(document.id)}
                                                        >
                                                            <DeleteOutlineOutlined/>
                                                        </button>
                                                    </div>

                                                </div>

                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        }
                    </table>
                </div>
                <div className="mr-20"><Pagination postsPerPage={docsPerPage} totalPosts={dataDocuments.length} paginate={paginate} currentPage={currentPage}/></div>
            </div>
            {addDocumentModalOpen && <AddDoc close={closeModal} fetchDocuments={fetchDoc}/>}
            {openPopupDelDoc && <PopupDelDoc closeModale={closeModal} docId={docId} fetchDoc={fetchDoc}/>}
        </div>
    )
}
export default MesDocuments