import React, {useState} from 'react'
import Index from '../pages/'
import {Switch, Route, BrowserRouter as Router} from 'react-router-dom'
import dashboard from "../pages/dashboard";
import AuthenticatedRoute from "../components/auth/AuthenticatedRoute";
import '../assets/style/homepage.css'
import logo_public from '../assets/img/logo_public.jpeg'
import ForgotPassword from "../components/Mobile/ResetPassword/ForgotPassword";
import PasswordRecoverySent from "../components/Mobile/ResetPassword/PasswordRecoverySent";
import ResetPassword from "../components/Mobile/ResetPassword/ResetPassword";
import ResetPasswordSuccess from "../components/Mobile/ResetPassword/ResetPasswordSuccess";
import {ToastContainer} from "react-toastify";
import CGV from '../pages/CGV';
import confidentialite from '../pages/confidentialite';

const PublicLayout = () => {
    const [state, setState] = useState({
        requestResetPasswordComponent: 'submitEmailForm',
        submitPasswordComponent: 'submitNewPassword',
    })

    const formSubmitted = () => {
        setState({requestResetPasswordComponent: 'recoveryEmailSent'})
    }

    const passwordReset = () => {
        setState({submitPasswordComponent: 'ResetPasswordSuccess'})
    }

    const requestResetPasswordComponent = () => {
        return state.requestResetPasswordComponent == 'submitEmailForm' ?
            <ForgotPassword switchComponentToRecoveryEmailSent={formSubmitted}/> : <PasswordRecoverySent/>
    }

    const SubmitNewPasswordComponent = () => {
        return state.submitPasswordComponent == 'submitNewPassword' ? <ResetPassword switchComponentToPasswordReset={passwordReset}/> :
            <ResetPasswordSuccess/>
    }

    return (
        <div className="lg:flex h-screen md:flex">
            <ToastContainer autoClose={3000} pauseOnHover={false}/>

            {/* Left menu */}
            <div>
                {/* Logo */}
                <a href="/">
                    <img src={logo_public} className="text-center w-full h-full object-cover object-top"/>
                </a>
            </div>

            {/* Auth component */}
            <div className="w-full h-full" id="connect-page">
                <Router>
                    <Switch>
                        <Route exact path="/" component={Index}/>
                        <Route exact path="/forgot-password" component={requestResetPasswordComponent}/>
                        <Route path="/reset-password" component={SubmitNewPasswordComponent}/>
                        <AuthenticatedRoute path="/dashboard" component={dashboard}/>
                        <Route exact path="/CGV" component={CGV} />
                        <Route exact path="/confidentialite" component={confidentialite} />
                    </Switch>
                </Router>
            </div>
        </div>
    );
}

export default PublicLayout