import '../../../assets/style/dashboard.css'
import React, {useEffect, useState} from "react";
import {getItem} from "../../../services/LocaleStorage";
import http from '../../../services/Http';
import {PulseLoader} from "react-spinners";
import {Link} from "react-router-dom";

const ResetPassword = ({switchComponentToPasswordReset}) => {
    const [state, setState] = useState({
        loading: false,
        passwordError: null,
        password: null,
        password_confirmation: null,
    })
    var token = null
    useEffect(() => {
        try {
            const usp = new URLSearchParams(window.location.search)
            if (usp.has('token')) token = usp.get('token')
        } catch (error) {
            //
        }
    })

    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget
        setState(state => ({...state, [name]: value}))
    }


    const handleSubmit = (e) => {
        e.preventDefault()

        if (!state.password || state.password.length < 6 || state.password != state.password_confirmation) {
            setState({passwordError: 'Veuillez saisir et confirmer un mot de passe de 6 caractères au moin'})
        } else
            setState({passwordError: ''})

        const requestBody = {
            new_password: state.password,
            token,
        }
        setState(state => ({...state, loading: true}))
        http.post('/reset-password', requestBody)
            .then(data => {
                setState(state => ({...state, loading: false}))
                switchComponentToPasswordReset()
            })
            .catch(err => {
                // TODO: catch update
                setState(state => ({...state, loading: false}))
                setState({passwordError: err.message})
            })
    }
    return (

        <div className="m-auto text-center md:w-1/3 md:pt-28 lg:w-1/3 lg:pt-28" id="registration">
            <h1 className="font-bold text-black mb-8">Mot de passe oublié</h1>
            <form onSubmit={handleSubmit} className=" text-sm 2xl:text-lg">

                <div className="m-2 border w-full rounded-xl flex">
                    <input className="w-full text-center p-2 h-14 rounded-2xl bg-violetClaire" type="password" name="password" placeholder="Mon nouveau mot de passe"
                           onChange={handleChange}/>
                </div>

                <div className="m-2 border w-full rounded-xl flex">
                    <input className="w-full text-center p-2 h-14 rounded-2xl bg-violetClaire" type="password" name="password_confirmation" placeholder="Confirmer mon mot de passe"
                           onChange={handleChange}/>
                </div>
                <span className="error text-red-500 font-semibold">{state.passwordError}</span>

                <div className="flex flex-col w-full">
                    <div className="m-2 w-full">


                        {state.loading ?
                            <button className="bg-primary text-white rounded-xl p-2 w-full">
                                <PulseLoader
                                    color={'white'} size={10}/>
                            </button>
                            :
                            <button className="bg-primary text-white rounded-xl p-2 w-full" type="submit">Mettre à jour le mot de passe</button>
                        }


                    </div>
                    <div className="m-2 w-full">
                        <div className="w-full text-lilaFonce rounded-xl font-semibold p-2 w-full border border-lilaFonce">
                            <Link to="/dashboard">Revenir à la page d'accueil</Link>
                        </div>
                    </div>
                </div>

            </form>
        </div>

    )
}
export default ResetPassword