import React from 'react'
import {PulseLoader} from "react-spinners";

const Loader = ({active}) => {
    if (!active) return ''

    return (
        // Container, Will wover the parent
        <div className="fixed bottom-0 left-0 right-0 top-0 bg-gray-100 opacity-50 z-500000 ">
            {/* A sub container will be relative covering the mainn containner, giving the ability of centering element inside it */}
            <div className="relative h-full w-full">
                {/* Loading will be shownn in the center of the container */}
                <div className="absolute bg-purple-800 rounded-full w-24 h-24 text-center pt-9" style={{top: 'calc(50% - 50px)', left: 'calc(50% - 25px)'}}>
                    <PulseLoader
                        color={'white'} size={12}
                    />
                </div>
            </div>
        </div>
    )
}


export default Loader