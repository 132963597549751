import React, {useState} from 'react'

export default function MonthTable({data, startAt, setData, id, onBlur}) {

    const monthName = {
        0: 'Janv',
        1: 'Fev',
        2: 'Mars',
        3: 'Avril',
        4: 'Mai',
        5: 'Juin',
        6: 'Juillet',
        7: 'Aout',
        8: 'Sept',
        9: 'Oct',
        10: 'Nov',
        11: 'Dec',
    }

    const [monthMode, setMonthMode] = useState({})

    // a substract 1 because in our month array we start the count from zero
    let startMonth = parseInt(startAt.split('-')[1]) - 1

    const onClickEvent = (key) => {
        let tmpMonthMode = {...monthMode}

        tmpMonthMode[key] = false
        setMonthMode(tmpMonthMode)
    }

    const onBlurEvent = () => {
        let tmpMonthMode = {...monthMode}

        for (const tmpKey in tmpMonthMode) {
            tmpMonthMode[tmpKey] = true
        }
        setMonthMode(tmpMonthMode)
        onBlur()
    }

    const onChange = (e, key) => {
        let tmpData = {...data}

        tmpData[key].amount = parseFloat(e.target.value)
        tmpData[key].amount = isNaN(tmpData[key].amount) ? 0 : tmpData[key].amount
        tmpData[key].vat_amount = tmpData[key].amount * (tmpData[key].vat / 100.0)
        tmpData[key].grand_amount = tmpData[key].amount + tmpData[key].vat_amount

        setData(id, tmpData)
    }

    const Input = (value, key) => {
        return <input autoFocus value={value} onChange={(e) => {onChange(e, key)}} onBlur={() => {onBlurEvent(key)}} />
    }

    const RenderCellsData = () => {
        let arr = []
        for (const key in data)
        {
            arr.push(<td onClick={() => {onClickEvent(key)}} key={key}>{monthMode[key] || monthMode[key] == undefined ? data[key].amount: Input(data[key].amount, key)}</td>)
        }
        return arr
    }

    const RenderCellsHeader = () => {
        let arr = []
        for (let i = 0; i < 12; i++) {
            arr.push(<th>{monthName[(startMonth + i) % 12]}</th>)
        }
        return arr
    }
    return (
            <table>
                <thead>
                    <tr>
                        {RenderCellsHeader()}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {RenderCellsData()}
                    </tr>
                </tbody>
            </table>
    )
}
