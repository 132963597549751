import '../assets/style/dashboard.css'
import React, {useEffect, useState} from "react";
import ListeAdminN from "../components/Admin P/listeAdminN";
import ListeClients from "../components/Admin P/listeClients";
import {getItem} from "../services/LocaleStorage";
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setPageTitle} from "../app/filters";

const AdminP = () => {
    const token = getItem('bbbToken')
    const location = useLocation()
    const [tab, setTab] = useState(location.state ? location.state.list : true)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setPageTitle('Accueil'))
    }, [])
    
    //Admins N
    const [dataAdminN, setDataAdminN] = useState({
        data: [],
        isLoading: false,
        isLoadingComp: false,
    })
    //Clients
    const [dataClients, setDataClients] = useState({
        data: [],
        isLoading: false,
        isLoadingComp: false,
        per_page: 10,
        page: 1,
        count: 0
    })

    useEffect(() => {

    }, [dataClients])

    const fetchAdminN = async () => {
        setDataAdminN(dataAdminN => ({...dataAdminN, isLoading: false, isLoadingComp: true}))
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/admins_n?include[]=conversation', {
                method: 'GET',
                requirements: token,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
            setDataAdminN({
                data: await response.json(),
                isLoading: false,
                isLoadingComp: false,
            })
            console.log(dataAdminN.data)
        } catch (error) {
            setDataAdminN(dataAdminN => ({...dataAdminN, isLoading: false, isLoadingComp: false}))
        }
    }
    // const fetchClients = async (perPage = dataClients.per_page, page = dataClients.page) => {
    const fetchClients = async (perPage = dataClients.per_page, page = dataClients.page, sortTerms, sortOrder) => {

        try {
            setDataClients(dataClients => ({...dataClients, isLoading: false, isLoadingComp: true}))

            let url = `${process.env.REACT_APP_API_URL}/clients?per_page=${perPage}&page=${page}&include[]=managers`;
        
            // Vérifiez si les termes de tri sont fournis
            if (sortTerms && sortOrder) {
                url += `&sortTerms=${sortTerms}&sortOrder=${sortOrder}`;
            }
    
            const response = await fetch(url, {
                method: 'GET',
                requirements: token,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            });
            if (response.ok) {
                const clients = await response.json();
    
                setDataClients({
                    isLoading: false,
                    isLoadingComp: false,
                    data: clients.data,
                    count: clients.count,
                    page: clients.page,
                    per_page: clients.per_page
                });
            }
            
        } catch (error) {
            setDataClients(dataClients => ({...dataClients, isLoading: false, isLoadingComp: false}))
        }
    }

    useEffect(() => {
        if (tab && !dataAdminN.data.length) {
            fetchAdminN()
        } else if (!dataClients.data.length) {
            fetchClients()
        }
    }, [tab]);

    return (
        <div className="w-full content">
            <div className="flex w-full justify-around pt-[4.1rem]">
                <div
                    className={`w-3/6 flex justify-center rounded-2xl py-2 mr-2  ${tab ? 'bg-lila text-white' : 'bg-gris text-lilaFonce'}`}>
                    <button onClick={() => setTab(true)}>Liste Admin N</button>
                </div>
                <div className={`w-3/6 flex justify-center rounded-2xl py-2 ${!tab ? 'bg-lila text-white' : 'bg-gris text-lilaFonce'}`}>
                    <button onClick={() => setTab(false)}>Liste client</button>
                </div>
            </div>
            {tab ? <ListeAdminN dataAdminN={dataAdminN.data} loading={dataAdminN.isLoading} loadingComp={dataAdminN.isLoadingComp}/> :
                <ListeClients dataClients={dataClients} loading={dataClients.isLoading} loadingComp={dataClients.isLoadingComp} dataAdminN={dataAdminN.data}
                              fetchClients={fetchClients}/>}
        </div>
    )
}
export default AdminP