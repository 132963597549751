import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, {useEffect, useState} from "react";
import PopupMessagerieClientOperation from "./PopupMessagerieClientOperation";
import PopupMessageWithAdminP from "./PopupMessageWithAdminP";
import { useSelector } from "react-redux";
import { getRealUser } from "../../../app/user";

const MessagerieAdminPAdminN = ({conversationsStore}) => {
    const [openMsgModale, setOpenMsgModale] = useState(false);
    const [openedConversation, setOpenedConversation] = useState(null);
    const realUser = useSelector(getRealUser)

    const closeModal = () => {
        setOpenMsgModale(false)
        setOpenedConversation(null)
    }

    useEffect(() => {
        // 
    }, [])

    /**
     * 
     * @param {string} text 
     * @returns string
     */
    const truncate = (text) => {
        // 
        return text
    }

    /**
     * Red If CONVERSATION.LAST_MESSAGE is sent by the client
     * Orange If CONVERSATION.LAST_MESSAGE is sent by the AdminN
     * Green If CONVERSATION.STATUT is manually closed by admin N
     * @param {Object} conversation 
     * @returns String
     */
     const conversationStatus = (conversation) => {
        if(conversation.status == 'closed') return 'green'
        
        if(conversation.last_message?.sender_id == realUser.id && realUser.role != 'admin_n') return 'orange'

        return 'red'
    }

    const conversationDate = (date = new Date()) => {
        var value = (new Intl.DateTimeFormat('fr-FR', { dateStyle: 'short' }).format(date));
        return value
    }
    
    const renderConversations = () => {

        return (
            conversationsStore.adminpConversations.map(conversation =>
                <tr key={conversation.id} onClick={() => {
                    setOpenedConversation(conversation)
                    setOpenMsgModale(true);
                }}>
                    <td>{
                        conversation.last_message
                        ? conversationDate(new Date(conversation.last_message.created_at))
                        : conversationDate(new Date(conversation.created_at))
                    }</td>
                    <td>{truncate(conversation.last_message ? conversation.last_message.body : '')}</td>
                    <td className="colors">
                        <span className={conversationStatus(conversation)}/>
                    </td>
                    <td>{conversation.correspondant.display_name}</td>
                    <td>{conversation.status == 'closed' ? '' : conversation.priority}</td>
                </tr>
            )
        )

    }

    return (
        <section className="messages">
            <div className="list-msg relative">
                <div className="add cursor-pointer" onClick={() => (setOpenMsgModale(true))}>+</div>
                <div className="table-list">
                    <table className="overflow-hidden min-w-full">
                        <thead className="rounded-lg text-white">
                        <tr>
                            <th>Date</th>
                            <th>Messages</th>
                            <th>
                                <span>Statut</span>
                                <div className="colors">
                                    <span className="green"></span>
                                    <span className="orange"></span>
                                    <span className="red"></span>
                                </div>
                            </th>
                            <th>Origine</th>
                            <th>Priorité</th>
                        </tr>
                        </thead>
                        <tbody>
                            { renderConversations() }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="bottom">
                <div className="results">Affichage {conversationsStore.adminpConversations.length} résultats sur {conversationsStore.adminpConversations.length}</div>
                <div className="paginations">
                    <div className="left-arrows">
                        <a href="#">
                            <ChevronLeftIcon/>
                            <ChevronLeftIcon/>
                        </a>
                    </div>
                    <div className="number">
                        <a href="#" className="active">1</a>
                        <a href="#">2</a>
                        <a href="#">3</a>
                        <a href="#">4</a>
                    </div>
                    <div className="right-arrows">
                        <a href="#">
                            <ChevronRightIcon/>
                            <ChevronRightIcon/>
                        </a>
                    </div>
                </div>
            </div>
            {openMsgModale && <PopupMessageWithAdminP closeModal={closeModal} conversation={openedConversation}/>}
        </section>


    )
}
export default MessagerieAdminPAdminN