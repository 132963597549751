import React, {forwardRef, useImperativeHandle, useState} from 'react'
import {NavigateBefore, NavigateNext} from "@material-ui/icons";
import MobileVenteTableFragment1 from "./Vente Fragment/MobileVenteTableFragment1";
import MobileVenteTableFragment2 from "./Vente Fragment/MobileVenteTableFragment2";

const MobileVente = forwardRef((props, ref) => {

    let compteurSliderVente = 0;

    const slide = (offset) => {
        const newCompteur = compteurSliderVente +offset
        if(newCompteur <0){
            compteurSliderVente  = 0
        }else if(newCompteur<2){
            compteurSliderVente   = newCompteur
        }else{
            compteurSliderVente  = 1
        }
        let slide1 = document.getElementById("slide-vente-1")
        let slide2 = document.getElementById("slide-vente-2")

        const w = slide1.getBoundingClientRect().width
        const decal = -w * compteurSliderVente

        const slideArray = [slide1, slide2]
        slideArray.forEach(el =>{
            el.style.transform = `translateX(${decal}px)`
        })
    }

    return (
        <div>
            <div className="w-full flex justify-center py-20">
                <div className="flex flex-col justify-around w-4/6 text-black ">
                    <div className="mb-3">
                        <p>CA HT Facturé</p>
                        <p className="text-grisFonce font-bold">45 244 €</p>
                    </div>
                    <div  className="mb-3">
                        <p>CA HT Encaissé</p>
                        <p className="text-lilaFonce font-bold">38 754 €</p>
                    </div>
                    <div  className="mb-3">
                        <p>CA HT Encaissé</p>
                        <p className="text-ciel font-bold ">38 754 €</p>
                    </div>
                    <div>
                        <div className="flex text-black justify-between">
                            <p>Encaissement du CA HT sur la période en cours</p>
                        </div>
                        <div className="w-full rounded-lg h-8 bg-gris mt-3">
                            <div className="bg-gradient-to-r from-purple-700 to-blue-500 w-4/6 h-8"/>
                        </div>
                        <div className="mb-8">
                            <p>70%</p>
                        </div>
                    </div>
                    <div className="font-bold">
                        <div className="py-1"> <p>Stock</p></div>
                        <div className="rounded-lg px-20 py-1 bg-gris  mb-3 w-4 h-8"/>
                    </div>
                    <div className="font-bold">
                        <div className=" py-1"> <p>Vente non facturées en cours</p></div>
                        <div className="rounded-lg px-20 py-1 bg-gris w-4 h-8"/>
                    </div>
                </div>
            </div>

            <div className="flex overflow-hidden">
                <MobileVenteTableFragment1/>
                <MobileVenteTableFragment2/>

            </div>
            <div className="flex justify-center">
                <button onClick={()=> slide(-1)}><NavigateBefore/></button>
                <button onClick={()=> slide(1)}><NavigateNext/></button>
            </div>
        </div>
    )
})

export default MobileVente