/**
 * Détruit le token
 */
export function removeItem() {
    window.localStorage.clear();
}

export function getItem(item) {
    return window.localStorage.getItem(item);
}

export function addItem(localeStorageName, newItem) {
    window.localStorage.setItem(localeStorageName, newItem);
}