import '../../../assets/style/dashboard.css'
import React from "react";
import {getItem} from "../../../services/LocaleStorage";
import {Link} from "react-router-dom";
import {PulseLoader} from "react-spinners";

const PasswordRecoverySent = () => {

    let newUser = {
        username: 'Wiwiii',
        display_name: 'Wiwiii',
        phone: '0658497521',
        password: 'wiwiipassword',
        creation_date: ''
    }

    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget
        document.querySelectorAll('.error').innerText = ''
        newUser = {...newUser, [name]: value}
    }

    const handleError = (id, error) => {
        document.getElementById(id + '-error').innerText = error
    }

    const handleSubmit = (e) => {


        // JSON.stringify({
        //     email: 'lolitappa@gmail.com',
        //     display_name: 'momo',
        //     phone: '2822822454',
        //     password: 'password',
        //     creation_date: '2020-09-27',
        //     username: 'lolila',
        //     status: 'OFFRE'
        // })
    }
    return (
        <div className="text-center flex justify-center items-center w-full h-full" id="registration">
            <div className=" w-[31.1rem]">
                <h1 className="font-semibold text-black mb-8 text-[1.5rem]">Mot de passe oublié</h1>
                <form onSubmit={handleSubmit} className="text-sm 2xl:text-lg">

                    <div className="m-2 w-full text-black mb-8">
                        <p>Un lien pour réinitialiser votre mot de passe a été envoyé à l'adresse e-mail de votre compte.</p>
                    </div>

                    <div className="flex flex-col w-full">
                        <div className="w-full text-lilaFonce rounded-xl font-semibold p-2 border border-lilaFonce">
                            <Link to="/dashboard">Revenir à la page d'accueil</Link>
                        </div>
                    </div>

                </form>

            </div>
        </div>


)
}
export default PasswordRecoverySent