import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import {getItem} from "../../services/LocaleStorage";
import {toast} from "react-toastify";

const PopupDelDoc = ({closeModale, docId, fetchDoc}) => {

    const delDoc = () => {
        const token = getItem('bbbToken')

        const fetchDel = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + '/user/document/' + docId, {
                    method: 'DELETE',
                    requirements: token,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                })
                if (response.status === 200) {
                    fetchDoc()
                    toast.success('Le document a été supprimé avec succès.')
                    closeModale()
                } else {
                    toast.error('La suppression du document a échoué')

                }
            } catch (error) {
                toast.error('La suppression du document a échoué')
                closeModale()

            }
        }
        fetchDel()
    }

    return (
        <div
            className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
            <div className="w-2/3 mx-auto bg-white rounded-lg shadow-xl p-8 z-100">
                <button className="float-right pt-0" onClick={closeModale}><CloseIcon/></button>
                <div className="parent-table w-full">
                    <div className="flex text-black font-semibold bg-grisClaire inline p-8 mt-8 text-xl rounded-xl">

                        <p className="flex flex-col"><span><span
                            className="font-bold">Attention : </span>Vous allez supprimer une ligne et le document qui lui est associé. Cette action est définitive.</span>
                        </p>

                    </div>
                </div>
                <div className="float-right mt-8">
                    <button className="bg-greenSuccess text-white rounded-xl p-2" type="button" onClick={delDoc}> Confirmer
                    </button>

                </div>
            </div>
        </div>
    )
}
export default PopupDelDoc