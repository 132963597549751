import React from "react";

const PopupAffectAlreadyExist = ({closeModale}) => {

    return (
        <div
            className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
            <div className="w-2/3 mx-auto bg-white rounded-lg shadow-xl p-8 z-100">
                <div className="parent-table w-full">
                    <div className="flex text-black font-semibold bg-grisClaire inline p-2 mt-8 text-xl">

                        <p className="flex flex-col"><span><span className="font-bold">Attention : </span>Vous ne pouvez pas affecter cette pièce à cette opération bancaire car une autre pièce est déjà affecter</span>
                        </p>

                    </div>
                </div>
                <div className="float-right mt-8">
                    <button className="bg-greenSuccess text-white rounded-xl p-2" type="button" onClick={closeModale}>OK, j'ai compris</button>

                </div>
            </div>
        </div>
    )
}
export default PopupAffectAlreadyExist