import Loader from "../Loader";
import React, {useEffect, useState} from "react";
import {PulseLoader} from "react-spinners";
import {Doughnut} from "react-chartjs-2";

// eslint-disable-next-line no-unused-vars
function DetailTableDashboard({ dataset, months, statSelect, detailFactures, datasetCA, datasetFrais, isLoading }) {
    // eslint-disable-next-line no-unused-vars
    const [data, setData] = useState({});
    const [caHT, setCaHT] = useState({});
    const [caPrevu, setCaPrevu] = useState({});

    const [fraisHT, setFraisHT] = useState({});
    const [fraisPrevu, setFraisPrevu] = useState({});
    const [doughnutData, setDoughnutData] = useState(null);
    const [operationsByCateg, setOperationsByCateg] = useState(null);

    useEffect(() => {
        if (data.length !== 0) {
            setData(data)
            if (statSelect === 3) {
                setCaHT(datasetCA[0].data)
                setCaPrevu(datasetCA[1].data)
            }
            if (statSelect === 4) {
                setFraisHT(datasetFrais[0].data)
                setFraisPrevu(datasetFrais[1].data)
            }

        }
    }, [data, datasetCA, datasetFrais]);

    useEffect(() => {
		// Pull the top categories from the data
		if (detailFactures) {
            // Prepare the summary global table
            if (statSelect == 3) {
                setOperationsByCateg(detailFactures.slice(0, 5));
            } else if (statSelect == 4) {
                setOperationsByCateg(detailFactures.slice(0, 10));
            }
		}
	}, [detailFactures]);

    useEffect(() => {
        if (operationsByCateg) {
            const doughnutData = {
                labels: operationsByCateg?.map(category => category.displayName),
                datasets: [
                    {
                        label: 'Total',
                        backgroundColor: [
                            '#65269e',
                            '#f87c22',
                            '#ab31e4',
                            '#059595',
                            '#c2027d'
                        ],
                        hoverBackgroundColor: [
                            '#501800',
                            '#4B5000',
                            '#175000',
                            '#003350',
                            '#35014F'
                        ],
                        data: operationsByCateg?.map(category => category.totalAmount)
                    }
                ]
            };
            setDoughnutData(doughnutData);
        }
    }, [operationsByCateg]);

    // const doughnutDirection = window.innerWidth >= 1300 ? "right" : "right";

    const optionsDoughnut = {
        legend: {
            labels: {
                usePointStyle: true,
                boxWidth: 40,
                padding: 20,
                generateLabels: function (chart) {
                    var data = chart.data;
                    const total = (chart.data.datasets[0].data.reduce((a, b) => a + b, 0));
                    // console.log(chart.data);
                    return data.labels.map(function (label, i) {
                        return {
                            // And finally :
                            text: label + ' - ' + Math.floor(chart.data.datasets[0].data[i] * 100 / total) + "%",
                            fillStyle: chart.data.datasets[0].backgroundColor[i],
                            // hidden: isNaN(data.dataset[0].data[i]) || chart.getDatasetMeta(0).data[i].hidden,
                            index: i
                        };
                    });
                }
            },
            display: true,
            // position: doughnutDirection,
            position: "bottom",
            align: 'start',
            textDirection: true

        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    let dataset = data.datasets[tooltipItem.datasetIndex];
                    let meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    let total = meta.total;
                    let currentValue = dataset.data[tooltipItem.index];
                    let percentage = parseFloat((currentValue / total * 100).toFixed(1));
                    return currentValue + ' (' + percentage + '%)';
                },
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        }
    }

    return (
        <React.Fragment>
            <div className="w-full flex flex-col 2xl:flex-row">
                <Loader active={isLoading} />
                {/*Dognut*/}
                <div className="w-full 2xl:w-4/12">
                    {doughnutData ? 
                        <Doughnut data={doughnutData} options={optionsDoughnut} />
                    :
                        null
                    }
                </div>
                {/*Tableau détail*/}
                <section className="msg-table w-full 2xl:w-8/12">
                    <div className="parent-table text-xs 2xl:text-sm overflow-x-hidden w-full">
                        {statSelect === 0 && <PulseLoader color={'purple'} size={10} />}
                        {statSelect === 3 && <h3 className="font-semibold text-black text-xl mb-8">Les 5 ventes les plus importantes</h3>}
                        {statSelect === 4 && <h3 className="font-semibold text-black text-xl mb-8">Les 10 Frais / Dépenses les plus importantes</h3>}
                        <React.Fragment>
                            <table className="table-auto overflow-hidden min-w-full text-xs 2xl:text-sm">
                                <thead className={`${statSelect === 3 && 'bg-roseTable'} ${statSelect === 4 && 'bg-orangeTable '}`}>
                                    {statSelect === 3 ? 
                                        <tr>
                                            <th className="pl-2 2xl:pl-6">Affectations</th>
                                            <th>Mont HT</th>
                                            <th>% CA HT encaissé</th>
                                            <th>Mont HT prévu</th>
                                            <th>% CA HT prévu</th>
                                            <th>Ecart CA HT encaissé / CA prévu</th>
                                            <th>% Avancement CA / HT prévu</th>
                                        </tr>
                                    :
                                        <tr>
                                            <th className="pl-2 2xl:pl-6">Affectations</th>
                                            <th>Mont HT</th>
                                            <th>% Dépenses HT encaissées</th>
                                            <th>Mont HT prévu</th>
                                            <th>% Dépenses HT prévues</th>
                                            <th>Ecart Dépenses HT encaissées / Dépenses prévues</th>
                                            <th>% Avancement Dépenses / HT prévues</th>
                                        </tr>
                                    }
                                </thead>
                                {detailFactures == null || detailFactures?.length == 0 ?
                                    <tbody>
                                        <tr>
                                            <td className="p-0" colSpan="3">Vous n'avez aucun détail pour le moment</td>
                                        </tr>
                                    </tbody> 
                                :
                                    statSelect === 3 ? 
                                        <tbody>
                                            {operationsByCateg?.map((detail, index) => {
                                                return <tr key={index} className={'border-b border-gray-200 font-semibold ' + (index % 2 === 0 ? "bg-rosePale" : "")}>
                                                        <td className="text-black">{detail.displayName ?? 'N/A'}</td>
                                                        <td className="text-black">{Number(detail.totalAmount).toFixed(2)}</td>
                                                        <td className="text-black">{Number(caHT[index]).toFixed(2)}</td>
                                                        <td className="text-black">--</td>
                                                        <td className="text-black">{Number(caPrevu[index]).toFixed(2)}</td>
                                                        <td className="text-black">{detail.totalAmount - caPrevu[index]}</td>
                                                        <td className="text-black">--</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody> 
                                    :
                                        <tbody>
                                            {operationsByCateg?.map((detail, index) => {
                                                return <tr key={index} className={'border-b border-gray-200 font-semibold ' + (index % 2 === 0 ? "bg-rosePale" : "")}>
                                                        <td className="text-black">{detail.displayName ?? 'N/A'}</td>
                                                        <td className="text-black">{Number(detail.totalAmount).toFixed(2)}</td>
                                                        <td className="text-black">{Number(fraisHT[index]).toFixed(2)}</td>
                                                        <td className="text-black">--</td>
                                                        <td className="text-black">{Number(fraisPrevu[index]).toFixed(2)}</td>
                                                        <td className="text-black">{detail.totalAmount - fraisPrevu[index]}</td>
                                                        <td className="text-black">--</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                }
                            </table>

                        </React.Fragment>
                    </div>
                </section>
            </div>
            <section className="msg-table w-full">
                <div className="parent-table text-xs 2xl:text-sm overflow-x-hidden w-full">
                    {statSelect === 3 && <h3 className="font-semibold text-black text-xl mb-8">Détail de toutes les ventes et recettes</h3>}
                    {statSelect === 4 && <h3 className="font-semibold text-black text-xl mb-8">Détail de tous les Frais / Dépenses</h3>}
                    <React.Fragment>
                        <table className="table-auto overflow-hidden min-w-full text-xs 2xl:text-sm ">
                            <thead className={`${statSelect === 3 && 'bg-roseTable text-left '} ${statSelect === 4 && 'bg-orangeTable text-left '}`}>
                                {statSelect === 3 ? 
                                    <tr>
                                        <th className="pl-2 2xl:pl-6">Affectations</th>
                                        <th>Mont HT</th>
                                        <th>% CA HT encaissé</th>
                                        <th>Mont HT prévu</th>
                                        <th>% CA HT prévu</th>
                                        <th>Ecart CA HT encaissé / CA prévu</th>
                                        <th>% Avancement CA / HT prévu</th>
                                    </tr>
                                :
                                    <tr>
                                        <th className="pl-2 2xl:pl-6">Affectations</th>
                                        <th>Mont HT</th>
                                        <th>% Dépenses HT encaissées</th>
                                        <th>Mont HT prévu</th>
                                        <th>% Dépenses HT prévues</th>
                                        <th>Ecart Dépenses HT encaissées / Dépenses prévues</th>
                                        <th>% Avancement Dépenses / HT prévues</th>
                                    </tr>
                                }
                            </thead>

                            {data?.length === 0 ?
                                <tbody>
                                    <tr>
                                        <td className="p-0" colSpan="3">Vous n'avez aucun détail pour le moment</td>
                                    </tr>
                                </tbody> 
                            :
                                statSelect === 3 ? 
                                    <tbody>
                                        {detailFactures?.map((detail, index) => {
                                            return <tr key={index} className={'border-b border-gray-200 font-semibold ' + (index % 2 === 0 ? "bg-rosePale" : "")}>
                                                    <td className="text-black">{detail.displayName}</td>
                                                    <td className="text-black">{Number(detail.totalAmount).toFixed(2)}</td>
                                                    <td className="text-black">{Number(caHT[index]).toFixed(2)}</td>
                                                    <td className="text-black">--</td>
                                                    <td className="text-black">{Number(caPrevu[index]).toFixed(2)}</td>
                                                    <td className="text-black">{detail.totalAmount - caPrevu[index]}</td>
                                                    <td className="text-black">--</td>
                                                </tr>
                                            })
                                        }
                                    </tbody> 
                                :
                                    <tbody>
                                        {detailFactures?.map((detail, index) => {
                                            return <tr key={index} className={'border-b border-gray-200 font-semibold ' + (index % 2 === 0 ? "bg-rosePale" : "")}>
                                                    <td className="text-black">{detail.displayName}</td>
                                                    <td className="text-black">{Number(detail.totalAmount).toFixed(2)}</td>
                                                    <td className="text-black">{Number(fraisHT[index]).toFixed(2)}</td>
                                                    <td className="text-black">--</td>
                                                    <td className="text-black">{Number(fraisPrevu[index]).toFixed(2)}</td>
                                                    <td className="text-black">{detail.totalAmount - fraisPrevu[index]}</td>
                                                    <td className="text-black">--</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                            }
                        </table>
                    </React.Fragment>
                </div>
            </section>
        </React.Fragment>
    )
}

export default DetailTableDashboard