// FilterContext.js
import React, { createContext, useState, useContext } from "react";

const FilterContext = createContext();

export const useFilterContext = () => useContext(FilterContext);

export const FilterProvider = ({ children }) => {
    const [filters, setFilters] = useState({
        searchTerms: "",
        minAmount: "",
        maxAmount: ""
    });

    const updateFilters = (newFilters) => {
        setFilters(newFilters);
    };

    return (
        <FilterContext.Provider value={{ filters, updateFilters }}>
            {children}
        </FilterContext.Provider>
    );
};
