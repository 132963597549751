import '../../../assets/style/dashboard.css'
import React, {useState} from "react";
import {getItem} from "../../../services/LocaleStorage";
import http from '../../../services/Http'
import {PulseLoader} from "react-spinners";
import {Link} from "react-router-dom";

const ForgotPassword = ({switchComponentToRecoveryEmailSent}) => {
    const [state, setState] = useState({errorMessage: null, successMessage: null, loading: false})


    const handleSubmit = (e) => {
        e.preventDefault()
        const email = document.querySelector('#email').value
        if (!email) return
        const body = {
            mail_address: email
        }
        setState(state => ({...state, loading: true}))
        http.post('/request-reset-password-link', body)
            .then(data => {
                setState({successMessage: data.message, loading: false})
                switchComponentToRecoveryEmailSent()
            })
            .catch(err => {
                // TODO: catch update
                if(err.status === 417) {
                    setState({errorMessage: "Veuillez vérifier si l'adresse entrée est correcte", loading: false})
                }else{
                    setState({errorMessage: err.message, loading: false})
                }

            })
    }
    return (
        <div className="text-center flex justify-center items-center w-full" id="registration">
            <div className=" w-[31.1rem]">
                <h1 className="font-bold text-black mb-[3.8rem] text-[1.5rem]">Mot de passe oublié</h1>
                <form onSubmit={handleSubmit} className="text-sm 2xl:text-lg">

                    <div className="mb-[1.2rem] border bg-violetClaire w-full rounded-2xl flex">
                        <input readOnly={state.loading} type="email" className="w-full text-left pl-6 px-2 h-14 rounded-3xl bg-violetClaire" name="email" id="email"
                               placeholder="Mon mail de connexion"/>
                    </div>
                    {state.errorMessage || state.successMessage ? <p className="error p-5 text-red-500 font-semibold" id="error">{state.errorMessage}</p> : null}

                    <div className="h-auto md:h-72 lg:h-72 relative block flex-col w-full">
                        <div className="w-full">
                            {state.loading ?
                                <button className="text-white rounded-xl p-2 w-full bg-primary">
                                    <PulseLoader
                                        color={'white'} size={10}/>
                                </button>
                                :
                                <button className='text-white rounded-xl p-2 w-full bg-primary font-semibold' type="submit">Réinitialiser le mot de passe</button>
                            }

                        </div>
                        <div className="md:absolute lg:absolute md:bottom-0 lg:bottom-0 w-full text-lilaFonce rounded-xl font-semibold p-2 border border-lilaFonce">
                            <Link to="/dashboard">Revenir à la page d'accueil</Link>
                        </div>
                    </div>

                </form>
            </div>

        </div>

    )
}

export default ForgotPassword