import React from 'react'
import arrow from "../../assets/img/ArrowDown.png";

function ArrowShowTreso({setSelectState, selectedState, state}) {
    let showDetails = (e) => {
        const parent = e.target.closest('.general-stats');

        e.target.classList.add('rotate')

        for (let i = 0; i < parent.children.length; i++) {
            if (parent.children[i].classList.contains('clicked')) {
                parent.children[i].classList.remove('clicked')
                parent.children[i].children[1].classList.remove('rotate')
            }
        }
        e.target.closest('.item').classList.add('clicked')
        setSelectState(state)
        console.log(state)
    }

    let hideDetails = (e) => {
        const parent = e.target.closest('.general-stats');
        e.target.classList.remove('rotate')
        for (let i = 0; i < parent.children.length; i++) {
            if (parent.children[i].classList.contains('clicked')) {
                parent.children[i].classList.remove('clicked')
                parent.children[i].children[1].classList.remove('rotate')
            }
        }
        setSelectState(5)
        console.log(state)
    }
    return (
        // <ArrowDropDownIcon className="arrow" onClick={selectedState === state ? hideDetails : showDetails}/>
        <div className="mt-8">
             <img src={arrow} className="arrow" onClick={selectedState === state ? hideDetails : showDetails} alt="msg"/>
        </div>

    )
}
export default ArrowShowTreso