const formatCurrency = (amount) => {
    const numericAmount = parseFloat(amount);

    if (!isNaN(numericAmount)) {
      return `${numericAmount.toFixed(2)} €`; // Assuming € as currency symbol
    } else {
      return ''; 
    }
}

export default formatCurrency;
