import React from "react";
import '../../assets/style/dashboard.css';
import {useHistory} from "react-router-dom";
import {getItem} from "../../services/LocaleStorage";
import {useSelector} from "react-redux";
import {getUser} from "../../app/user";
import {useMediaQuery} from "react-responsive";
import CloseIcon from "@material-ui/icons/Close";


const ClientsAffectModal = ({closeModal, dataAdminN, selectAdminsN, client}) => {
    return (
        <React.Fragment>

            <div className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center">
                <div className="w-2/3 mx-auto bg-white rounded-lg shadow-xl p-8 z-100">
                    <button className="float-right" onClick={closeModal}><CloseIcon/></button>
                    <div className="parent-table w-full flex justify-center">
                        <ul>
                            {dataAdminN.map(adminN => {
                                return <li key={adminN.id} className="flex justify-between">
                                    <label htmlFor={"admin-n-checkbox" + adminN.id}>{adminN.display_name}</label>
                                    <input type="checkbox" data-id={adminN.id} defaultChecked={client.managers.some(elem => elem.id === adminN.id)} name="admin_n" id={"admin-n-" + adminN.id} className="admin-n-checkbox ml-8"/>
                                </li>
                            })}
                            <button className="p-2 bg-greenSuccess mt-10 rounded-xl text-white" onClick={selectAdminsN}>valider</button>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ClientsAffectModal