import React, { useState } from 'react'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CreateIcon from '@material-ui/icons/Create';
import HelpIcon from '@material-ui/icons/Help';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DoneIcon from '@material-ui/icons/Done';
import {DeleteForeverOutlined, DeleteOutlineOutlined} from "@material-ui/icons";
import ArrowShowDetails from "../../../../dashboard/ArrowShowDetails";

const MobileFraisTableFragment2 =  () => {
    let keyVal = 0

    let [data, setData] = useState([
        {
            key: 0,
            accessStatus: 'read',
            date: '01/12/20',
            label: ['CB CEDEO ref 0456645454'],
            msg: {
                status: 'read',
                number: 0
            },
            debit: 1251.8,
            credit: 0,
            piece: {
                status: 'red',
                path: '/'
            },
            status : {
                color: 'red',
                type: 'text'
            },
            subRows: [
                {
                    key: 0,
                    accessStatus: 'read',
                    date: '01/12/20',
                    label: ['Achat de matière première'],
                    msg: {
                        status: 'read',
                        number: 0
                    },
                    debit: 1251.8,
                    credit: 0,
                    tva: [-111, 20],
                    piece: {
                        status: 'orange',
                        path: '/'
                    },
                    status : {
                        color: 'orange',
                        type: 'icon'
                    },
                }
            ]
        }
    ])


    let rowFunc = (rows, isSubRow) => {
        let ret = []
        rows.forEach(subRow => {
            keyVal++;
            subRow.key = keyVal;
            if (subRow.accessStatus === 'read')
            {
                ret.push(
                        <tr key={keyVal.toString()} className="border-b border-gray-200 " data-key={keyVal.toString()}>
                            <td className="py-3 pl-6 text-left">
                                <div className="relative inline-block">
                                    <AttachFileIcon />
                                    <span className={subRow.piece.status + " top-icon"}/>
                                </div>
                            </td>
                            <td className="py-3 pl-6 text-center justify-center">
                               <span>edit</span>
                            </td>

                            <td className="py-3 pl-6 text-center">
                                 <span>tirelire</span>
                            </td>
                            {/*<td className="py-3 pl-6 text-center">*/}
                            {/*    <button className="bg-rouge p-2 rounded-lg text-white">*/}
                            {/*        <DeleteOutlineOutlined/></button>*/}
                            {/*</td>*/}
                        </tr>
                )
            } else {
                ret.push(
                    <tr  key={keyVal.toString()} className="border-b border-gray-200 modify" data-key={keyVal.toString()}>
                        <td className="py-3 pl-6 text-left whitespace-nowrap">
                            <span className="default-text"><input defaultValue={subRow.date} type="text" /></span>
                        </td>
                        <td className="py-3 pl-6 text-leftalign-center">
                            <div className="inline-flex">
                                <h3><input defaultValue={subRow.label[0]} /></h3>
                            </div>
                        </td>
                        <td className="py-3 px-6 text-center">
                            <div className="flex items-center justify-center">
                                <MailOutlineIcon />
                            </div>
                        </td>
                        <td className="py-3 pl-6 text-left">
                            <span className="default-text"><input defaultValue={subRow.debit} /></span>
                        </td>
                        <td className="py-3 pl-6 text-left">
                        </td>
                        <td className="py-3 pl-6 text-left">
                            <span className="default-text"><input defaultValue={subRow.tva[0]} /></span>
                            <span><input defaultValue={subRow.tva[1]} /></span>
                        </td>
                        <td className="py-3 pl-6 text-center justify-center">
                            <div className="relative inline-block">
                                <AttachFileIcon />
                                <span className={subRow.piece.status + " top-icon"}></span>
                            </div>
                        </td>
                        <td className="py-3 pl-6 text-center">
                            <div onClick={saveRow} className="green icon">
                                <DoneIcon className={subRow.status.color} size="small" />
                            </div>
                        </td>
                    </tr>
                )
            }
        })
        return ret
    }

    let renderTable = () => {
        let res = data
        let ret = []
        data.forEach(row => {
            keyVal++;
            ret.push(<tbody key={keyVal.toString()}>{rowFunc([row], false)}</tbody>)
            keyVal++;
            ret.push(<tbody className="sub-rows" key={keyVal.toString()}>{rowFunc(row.subRows, true)}</tbody>)
        })
        return (ret)
    }


    let addRow = (e) => {
        e.preventDefault()
        const newData = [...data, {
            key: 0,
            accessStatus: 'modify',
            date: '...',
            label: ['...', 'Achat de matiere premiere'],
            msg: {
                status: 'read',
                number: 0
            },
            debit: 0,
            credit: 0,
            tva: [0, 0],
            piece: {
                status: 'red',
                path: '/'
            },
            status : {
                color: 'red',
                type: 'text'
            },
            subRows: []
        }]
        setData(newData)
    }


    let editRow = (e) => {
        let key = e.target.closest('tr').getAttribute('data-key')
        let tmpData = [...data]
        tmpData.forEach(row => {
            if (row.key == key) {
                row.accessStatus = 'modify'
            }
            row.subRows.forEach(subRow => {
                if (subRow.key == key) {
                    subRow.accessStatus = 'modify'
                }
            })
        });
        setData(tmpData)
    }

    let saveRow = (e) => {
        let el = e.target.closest('tr')
        let key = el.getAttribute('data-key')
        let input = el.getElementsByTagName('input')
        const newData =[...data]
        newData.forEach(row => {
            if (row.key == key) {
                row.accessStatus = 'read'
                row.date = input[0].value
                row.label[0] = input[1].value
                row.debit = input[2].value
                row.tva = [input[3].value, input[4].value]
            }
            row.subRows.forEach(subRow => {
                if (subRow.key == key) {
                    subRow.accessStatus = 'read'
                    subRow.date = input[0].value
                    subRow.label[0] = input[1].value
                    subRow.debit = input[2].value
                    subRow.tva = [input[3].value, input[4].value]
                }
            })
        })
        setData(newData)
    }
    return (
        <React.Fragment>

            <section className="operations operations-mobile w-full flex-shrink-0 ">
                <div id="slide-frais-2" className="parent-table flex-shrink-0 transition duration-1000 ease-in-out w-full">
                    <table className="w-full table-auto overflow-hidden">
                        <thead>
                            <tr className="capitalize text-sm leading-normal">
                                <th className="pl-6 text-left">
                                    <div className="text text-center">
                                        <span className="label">Pièces</span>
                                    </div>
                                    <div className="icons relative">
                                        <div>
                                            <ul className="flex">
                                                <li className="green-border">3</li>
                                                <li className="orange-border">1</li>
                                                <li className="red">2</li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                                <th className="pl-6 text-left">
                                    <div className="text text-center">
                                        <span className="label">Statut</span>
                                    </div>
                                    <div className="icons relative">
                                        <div>
                                            <ul className="flex">
                                                <li className="green-border">3</li>
                                                <li className="orange-border">1</li>
                                                <li className="red">2</li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                                <th className="pl-6 text-left">
                                    <div className="text text-center">
                                        <span className="label">Paiement</span>
                                    </div>
                                    <div className="icons relative">
                                        <div>
                                            <ul className="flex">
                                                <li className="green-border">3</li>
                                                <li className="orange-border">1</li>
                                                <li className="red">2</li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        {renderTable()}
                    </table>
                </div>
            </section>
        </React.Fragment>

    )
}

export default MobileFraisTableFragment2
