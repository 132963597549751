const tresoGraphDataInit = [
    {
        label: "Tréso en cours",
        backgroundColor: '#b260fd',
        data: Object.values([0]),
        borderRadius: 10
    },
    {
        label: "Tréso prévu",
        backgroundColor: '#670fb6',
        data: Object.values([0]),
        borderRadius: 10
    },
    {
        label: "Tréso n-1",
        backgroundColor: '#30006d',
        data: Object.values([0]),
        borderRadius: 10
    }
];

const resultatGraphDataInit = [
    {
        label: "Résultat HT payés",
        backgroundColor: '#63d2b9',
        data: Object.values([0]),
        borderRadius: 10
    },
    {
        label: "Résultat HT prévu",
        backgroundColor: '#047b7b',
        data: Object.values([0]),
        borderRadius: 10
    },
    {
        label: "Résultat HT estimé",
        backgroundColor: '#022b2f',
        data: Object.values([0]),
        borderRadius: 10
    },
    {
        label: "Résultat n-1",
        backgroundColor: '#9ddddd',
        data: Object.values([0]),
        borderRadius: 10
    },
    {
        label: "Marge commerciale",
        backgroundColor: '#ccf4ff',
        data: Object.values([0]),
        borderRadius: 10
    },
];

const caGraphDataInit = [
    {
        label: "CA HT encaissé",
        backgroundColor: '#ff6489',
        data: Object.values([0]),
        borderRadius: 10
    },
    {
        label: "CA HT prévu",
        backgroundColor: '#c6007f',
        data: Object.values([0]),
        borderRadius: 10
    },
    {
        label: "CA HT estimé",
        backgroundColor: '#500234',
        data: Object.values([0]),
        borderRadius: 10
    },
    {
        label: "CA HT n-1",
        backgroundColor: '#cd2561',
        data: Object.values([0]),
        borderRadius: 10
    }
];

const fraisGraphDataInit = [
    {
        label: "Dépenses HT payées",
        backgroundColor: '#f9a150',
        data: Object.values([0]),
        borderRadius: 10
    },
    {
        label: "Dépenses HT prévues",
        backgroundColor: '#f25e0a',
        data: Object.values([0]),
        borderRadius: 10
    },
    {
        label: "Dépenses HT estimées",
        backgroundColor: '#b25600',
        data: Object.values([0]),
        borderRadius: 10
    },
    {
        label: "Dépenses HT n-1",
        backgroundColor: '#ffa471',
        data: Object.values([0]),
        borderRadius: 10
    },
];

export {
    tresoGraphDataInit,
    resultatGraphDataInit,
    fraisGraphDataInit,
    caGraphDataInit,
}
