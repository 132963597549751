import React from 'react'
import AuthForm from '../components/auth'
import '../assets/style/homepage.css'

class HomePage extends React.Component {
    render() {
        return (
            <div className="w-full">
                <AuthForm />
            </div>
        )
    }
}

export default HomePage