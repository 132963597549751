import React, {useState} from "react";
import {getItem} from "../../services/LocaleStorage";
import {toast} from "react-toastify";
import {PulseLoader} from "react-spinners";
import CloseIcon from "@material-ui/icons/Close";

const PopupDeleteRowVente = ({close, idToSupp, fetchEarnings, isLastInvoice}) => {
    const [isLoading, setIsLoading] = useState(false);

    const deleteVente = () => {
        const token = getItem('bbbToken');

        setIsLoading(true);
        if (isLastInvoice) {
            fetch(process.env.REACT_APP_API_URL + '/invoice/' + idToSupp, {
                method: 'DELETE',
                requirements: token,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            })
            .then(response => {
                setIsLoading(false);
                if (response.ok) {
                    fetchEarnings();
                    toast.success('La facture de vente a bien été supprimée.');
                    close();
                } else {
                    response.json().then((res) => {
                        const message = res.message ?? 'La suppression a échouée.';
                        toast.error(message);
                        close();
                    });
                }
            });
            
        } else {
            fetch(process.env.REACT_APP_API_URL + '/invoice/soft-delete/' + idToSupp, {
                method: 'DELETE',
                requirements: token,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
            .then((response) => {
                setIsLoading(false);
                if (response.ok) {
                    toast.success('La facture de vente a bien été supprimée.');
                    fetchEarnings();
                    close();
                } else {
                    response.json().then((res) => {
                        const message = res.message ?? 'La suppression a échouée.';
                        toast.error(message);
                        close();
                    });
                }
            });
        }
    }

    return (

        <div
            className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
            <div className="w-2/3 mx-auto bg-white rounded-lg shadow-xl p-8 z-100">
                <button className="float-right pt-0" onClick={close}><CloseIcon/></button>
                <div className="parent-table w-full">
                    <h1 className="text-black font-bold text-2xl">Validation d'une suppression de ligne</h1>
                    <div className="flex text-black font-semibold bg-grisClaire inline p-2 mt-8 text-xl">
                        <p className="flex flex-col">
                            <span>
                                <span className="font-bold">Attention : </span>
                                Si vous confirmez, la pièce comptable associée sera supprimée de votre stockage numérique
                            </span>
                        </p>
                    </div>
                </div>
                <div className="float-right mt-8">
                    <button className="bg-greenSuccess text-white rounded-xl p-2" type="button" onClick={deleteVente}>
                        {isLoading ? <PulseLoader color={'white'} size={10}/> : 'Confirmer' }
                    </button>
                </div>
            </div>
        </div>
    )
}
export default PopupDeleteRowVente