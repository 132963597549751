import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import '../../assets/style/dashboard.css';
import {getItem} from "../../services/LocaleStorage";
import {getUser} from "../../app/user";
import Loader from "../Loader";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import {toast} from "react-toastify";

const RegistrationAdminN = ({close}) => {
    const user = useSelector(getUser)
    const [monTab, setMonTab] = useState([]);
    const [registerValidationErrors, setRegisterValidationErrors] = useState({})
    const [successMessage, setSuccessMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const [isPasswordHidden, setIsPasswordHidden] = useState(true)
    const [passwordType, setPasswordType] = useState('password')

    const changeVisibility = (val) => {
        setIsPasswordHidden(val)
        setPasswordType(val ? 'password' : 'text')
    }

    useEffect(() => {
        setMonTab([
            "Nom perso",
            "Prenom",
            "Adresse",
            "TELEPHONE PERSO",
            "MAIL PERSO",
            "Statut",
            "Date de naissance",
            "Login admin n",
            "PWD ADMIN N",
        ])
    }, [user]);

    const handleSubmit = (e) => {
        e.preventDefault()

        let emptyInput = false
        document.getElementById("forms-main-div").querySelectorAll("input").forEach(input => {
            if (input.value === "") {
                emptyInput = true
            }
        })

        if (emptyInput) {
            toast.error("Veuillez saisir le formulaire au complet.")
            return
        }

        setRegisterValidationErrors({})
        const token = getItem('bbbToken')
        const authUrl = process.env.REACT_APP_API_URL + '/register'

        let formdata = new FormData(document.getElementById('registration'));
        formdata.append("status", "OFFRE");
        formdata.append("role", "admin_n")
        formdata.append("created_at", new Date().toISOString().split('T')[0]);
        formdata.append("creation_date", new Date().toISOString().split('T')[0]);

        setIsLoading(true)
        fetch(authUrl, {
            method: 'POST',
            'Access-Control-Allow-Credentials': true,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: formdata,
        },)
            .then(async response => {
                if (response.ok) {
                    response.json().then(response => {
                        setIsLoading(false)
                        toast.success("Inscription réussie.")
                        close()
                    })
                } else {
                    setIsLoading(false)
                    toast.error('Une erreur est survenue !')
                    response.json().then(errors => {
                        setRegisterValidationErrors(errors)
                        setSuccessMessage('')
                    })

                }
            })
    }

    return (

        <React.Fragment>

            <Loader active={isLoading}/>

            <div id="forms-main-div" className="parent-table">
                <h1 className="font-semibold text-lg mb-8">Création d'un <span className="uppercase"> admin N</span></h1>
                <div className="flex pb-0">
                    <div className="w-4/12 table-auto overflow-hidden">
                        {monTab.map((val, index) => (
                            <div key={val} className="registration-row-admin-n ">
                                <div className="uppercase text-black p-2 h-14 text-xs 2xl:text-sm border border-white">{val}</div>
                            </div>
                        ))}
                    </div>
                    <div className="w-8/12 text-xs 2xl:text-sm">
                        <form className="pl-0" id="registration">
                            {/*display_name*/}
                            <div className="h-14 registration-row-admin-n border border-white flex flex-col">
                                <input type="text" name="display_name" className="w-full p-2 rounded bg-transparent h-12 pl-6"
                                       placeholder="display_name"/>
                                <span
                                    className="text-red-500 text-xs ">{registerValidationErrors["display_name"] ? registerValidationErrors["display_name"] : ""}</span>
                            </div>
                            {/*Prénom ok*/}
                            <div className="h-14 registration-row-admin-n border border-white flex flex-col">
                                <input type="text" name="firstname" className="w-full p-2 rounded bg-transparent h-12 pl-6"
                                       placeholder="first_name"/>
                                <span
                                    className="text-red-500 text-xs ">{registerValidationErrors["first_name"] ? registerValidationErrors["first_name"] : ""}</span>
                            </div>
                            {/*Adresse décomposé*/}
                            <div className="flex w-full">
                                <div className="registration-row-admin-n flex items-center text-black w-full">
                                    <div className="flex flex-col h-14 mr-2">
                                        <input type="text" name="num"
                                               className="w-full h-12 rounded mr-2 infos-register-address bg-transparent pl-6"
                                               placeholder="num" size="2"/>
                                        <span className="text-red-500 mr-2 register-error error-address-num"></span>
                                    </div>
                                    <div className="flex flex-col h-14 mr-2">
                                        <input type="text" name="street"
                                               className="w-full h-12 rounded mr-2 infos-register-address bg-transparent"
                                               placeholder="street"/>
                                        <span className="text-red-500 mr-2 register-error error-address-street"></span>
                                    </div>
                                    <div className="flex flex-col h-14 mr-2">
                                        <input type="text" name="comp"
                                               className="w-full h-12 rounded mr-2 infos-register-address bg-transparent"
                                               placeholder="comp"/>
                                        <span className="text-red-500 mr-2 register-error error-address-comp"></span>
                                    </div>
                                    <div className="flex flex-col h-14 mr-2">
                                        <input type="text" name="code"
                                               className="w-full h-12 rounded mr-2 infos-register-address bg-transparent"
                                               placeholder="code" size="4"/>

                                        <span className="text-red-500 mr-2 register-error error-address-code"></span>
                                    </div>
                                    <div className="flex flex-col h-14">
                                        <input type="text" name="ville"
                                               className="w-full h-12 rounded infos-register-address bg-transparent" placeholder="ville"
                                        />
                                        <span className="text-red-500 mr-2 register-error error-address-ville"></span>
                                    </div>
                                </div>
                            </div>
                            {/*phone*/}
                            <div className="h-14 registration-row-admin-n border border-white flex flex-col">
                                <input type="text" name="phone" className="w-full p-2 rounded bg-transparent h-12 pl-6"
                                       placeholder="phone"/>

                                <span className="text-red-500 text-xs"> {registerValidationErrors["phone"] ? registerValidationErrors["phone"] : ""}</span>
                            </div>
                            {/*email*/}
                            <div className="h-14 registration-row-admin-n border border-white flex flex-col">
                                <input type="email" name="email" className="w-full p-2 rounded bg-transparent h-12 pl-6"
                                       placeholder="email"/>
                                <span className="text-red-500 text-xs ">{registerValidationErrors["email"] ? registerValidationErrors["email"] : ""}</span>
                            </div>
                            {/*statut social pas encore ajouté*/}
                            <div className="h-14 registration-row-admin-n border border-white flex flex-col">
                                <select name="statut_social" className="w-full p-2 rounded bg-transparent h-12 pl-6 uppercase">
                                    <option value="Salarie">Salarie</option>
                                </select>
                                <span
                                    className="text-red-500 text-xs ">{registerValidationErrors["statut_social"] ? registerValidationErrors["statut_social"] : ""}</span>
                            </div>
                            {/*Date de naissance pas encore ajouté*/}
                            <div className="h-14 registration-row-admin-n border border-white flex flex-col">
                                <input type="date" name="birthdate" className="w-full p-2 rounded bg-transparent h-12 pl-6"
                                       placeholder="birthdate"/>
                                <span
                                    className="text-red-500 text-xs ">{registerValidationErrors["birthdate"] ? registerValidationErrors["birthdate"] : ""}</span>
                            </div>
                            {/*référence ce génère automatiquement par l'application*/}
                            {/*username*/}
                            <div className="h-14 registration-row-admin-n border border-white flex flex-col">
                                <input className="w-full p-2 rounded bg-transparent h-12 pl-6" type="text" name="username"
                                       placeholder="username"/>

                                <span className="text-red-500 text-xs">{registerValidationErrors["username"] ? registerValidationErrors["username"] : ""}</span>
                            </div>

                            {/*password*/}
                            <div className="h-14 registration-row-admin-n flex border border-white">
                                <input type={passwordType} name="password" className="w-full p-2 rounded bg-transparent h-12 pl-6"
                                       placeholder="password"/>
                                <p className="w-1/6 m-auto">
                                    <button type="button" className="w-full"
                                            onClick={changeVisibility.bind(this, !isPasswordHidden)}>
                                        {isPasswordHidden ? <VisibilityOffIcon fontSize="small"/> :
                                            <VisibilityIcon fontSize="small"/>}
                                    </button>
                                </p>
                            </div>
                        </form>

                    </div>

                </div>

                {successMessage &&
                    <p className="p-3 pl-1 text-purple-200 md:w-2/3 lg:w-2/3 mt-5 bg-purple-800">{successMessage}</p>}
                {registerValidationErrors["error"] && <span className="text-red-500">{registerValidationErrors["error"]}</span>}

                <div className="float-right mt-8">
                    <button className="bg-greenSuccess text-white rounded-xl py-2 px-8" type="button" onClick={handleSubmit}>Ok
                    </button>
                </div>
            </div>

        </React.Fragment>
    )
}
export default RegistrationAdminN