import '../../assets/style/dashboard.css'
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import MesDocuments from "../Mon BBB/mesDocuments";
import MesFactures from "../Mon BBB/mesFactures";
import MonContrat from "../Mon BBB/monContrat";
import http from '../../services/Http'
import MesInfos from "../Mon BBB/mesInfos";
import MonArchivage from "../Mon BBB/monArchivage";


const GestionCompte = () => {
    const user = useSelector(state => state.user.currentUser)
    const [isClient, setIsClient] = useState(false);
    const [isAdminP, setIsAdminP] = useState(false);

    useEffect(() => {
        if (user.role === "client" || http.getClientRoleFromUrl() === "client")
            setIsClient(true)
        if (user.role === "admin_p" || http.getClientRoleFromUrl() === "admin_p")
            setIsAdminP(true)
    }, [user]);

    return (
        <React.Fragment>
            <div className="w-full mb-24">
                <MesInfos/>
            </div>
            {isClient && <React.Fragment>
                <MonContrat/>
                <MesFactures/>
                <MesDocuments/>
                <MonArchivage/>
            </React.Fragment>
            }

        </React.Fragment>
    )
}
export default GestionCompte