import React from "react";
import CloseIcon from "@material-ui/icons/Close";

const PopupOCRReprocess = ({ close, confirmOCRReprocess }) => {
	return (
		<div
            className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
            <div className="w-2/3 mx-auto bg-white rounded-lg shadow-xl p-8 z-100">
                <button className="float-right pt-0" onClick={close}><CloseIcon/></button>
                <div className="parent-table w-full">
                    <div className="flex text-black font-semibold bg-grisClaire inline p-2 mt-8 text-xl">
                        <p className="flex flex-col">
                            <span>
                                <span className="font-bold">Attention : </span>
                                Confirmez que vous souhaitez redéfinir les paramètres du document : date, libellé, montant TTC et TVA
                            </span>
                        </p>
                    </div>
                </div>
                <div className="flex justify-end gap-4 mt-8">
                    <button 
                        className="bg-greenSuccess text-white rounded-xl p-2" 
                        type="button" 
                        onClick={() => confirmOCRReprocess()}
                    >
                        Confirmer
                    </button>
                    <button 
                        className="bg-greenSuccess text-white rounded-xl p-2" 
                        type="button" 
                        onClick={() => close()}
                    >
                        Annuler
                    </button>
                </div>
            </div>
        </div>
	)
}

export default PopupOCRReprocess;
