import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { getItem } from "../../../services/LocaleStorage";
import { toast } from "react-toastify";
import { getRealUser, getUser } from "../../../app/user";
import http from "../../../services/Http";


const PopupMessageWithAdminN = ({ closeModal, conversation, correspondant }) => {

    const [state, setData] = useState({
        messages: [],
        loading: false,
        sending: false,
        correspondant: conversation?.correspondant,
    }, [])
    const [messageContent, setMessageContent] = useState('')

    const token = getItem('bbbToken')
    const realUser = useSelector(getRealUser)

    /**
     * Chat
     */
    // Will display the toast notification only on the first message received
    const [notified, setNotified] = useState(false)
    const mercureUrl = process.env.REACT_APP_MERCURE_URL

    // Pagination
    const [__conversationResponse, __setConversationResponse] = useState(JSON.stringify({
        count: null,
        data: [],
        page: 1,
        per_page: 5,
    }))
    const [allMessagesSeen, setAllMessagesSeen] = useState(false)
    const scrollRef = useRef()
    const loadMoreMessagesBtnRef = useRef()


    useEffect(() => {
        fetchMessages(1, 5)

        // Initialize infinite scroll
        infiniteScroll()

        const eventSource = createEventSource()
        subscribeToConversation(eventSource)

        // Unsubscribe from the chat chanel o component did unmount
        return () => {
            eventSource.close()
        }
    }, [])


    /**
     * <<<< Pagination on scrolling to top
     */

    const fetchPrev = () => {
        const _data = JSON.parse(__conversationResponse)
        if (_data.per_page * _data.page < _data.count) {
            fetchMessages(_data.page + 1, _data.per_page)
        } else {
            setAllMessagesSeen(true)
        }
    }

    /**
     * 
     * Fetch messages whenever the user reaches the top of conversation
     * @returns void
     */
    const infiniteScroll = () => {
        const chatContainer = scrollRef.current

        if (!chatContainer) return
        chatContainer.addEventListener('scroll', () => {
            if (chatContainer.scrollTop == 0) {
                loadMoreMessagesBtnRef.current.click()
            }
        })
    }
    /**
     * 
     * Pagination on scrolling to top >>>>
     */


    const createEventSource = () => {
        // The subscriber subscribes to updates for the https://example.com/users/dunglas topic
        // and to any topic matching https://example.com/books/{id}
        const url = new URL(mercureUrl);
        var senderId = realUser.id
        var receiverId = correspondant.id

        if (conversation) {
            senderId = conversation.sender_id
            receiverId = conversation.receiver_id
        }

        url.searchParams.append('topic', process.env.REACT_APP_API_URL + '/conversation/listen/admins-' + senderId + '_' + receiverId);
        // The URL class is a convenient way to generate URLs such as https://localhost/.well-known/mercure?topic=https://example.com/books/{id}&topic=https://example.com/users/dunglas
        return new EventSource(url);
    }

    /**
     * 
     * @param {EventSource} eventSource 
     */
    const subscribeToConversation = (eventSource) => {
        // The callback will be called every time an update is published
        eventSource.onmessage = (res) => {
            // New message received
            const incommingMessage = JSON.parse(res.data)

            // Skip messages sent by me
            if (incommingMessage.sender_id == realUser.id) return

            const messages = state.messages
            messages.push(incommingMessage)
            setData(oldState => {
                return { ...oldState, sending: false, messages }
            })
            scrollToBottom()
            if (!notified) {
                toast.info('Nouveau message: ' + incommingMessage.body)
                setNotified(true)
            }
        };
    }

    const conversationDate = (date = new Date()) => {
        var value = (new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium', timeStyle: 'medium' }).format(date));
        return value
    }

    const sortMessagesByCreatedAt = (messages) => {
        return messages.sort((a, b) => {
            var da = new Date(a.created_at)
            var db = new Date(b.created_at)
            if (da > db) return 1
            return -1
        })
    }

    const sendMessage = (e) => {
        e.preventDefault()
        if (!messageContent.trim()) {
            alert('Rien à envoyer!')
            return
        }

        const payload = {
            "sender_id": realUser.id,
            "receiver_id": correspondant.id,
            "message_body": messageContent.trim(),
        }
        setData({ ...state, sending: true })

        fetch(`${process.env.REACT_APP_API_URL}/message/admins/send`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(payload)
        }).then(response => {
            if (response.ok) {
                response.json().then(resData => {
                    const messages = state.messages
                    messages.push(resData)
                    setData(oldState => {
                        return { ...oldState, sending: false, messages }
                    })
                })
                setMessageContent('');
                document.getElementById('msg-content').value = ''
                scrollToBottom()
            } else if (!response.ok) {
                setData({ ...state, sending: false })
                toast.error('Une erreur s\'est produite! Veuillez verifier votre requete et re-essayez')
            }
        })
            .catch(e => { })

        // Uncomment to display toast notification when you send a message
        // toast.info('Message envoyé')
    }

    const scrollToBottom = () => {
        const scrollingElement = document.querySelector('.scrollable')
        var scroller = 0
        const sc = setInterval(() => {
            scroller = scroller < scrollingElement.scrollHeight ? scroller + 5 : scroller
            scrollingElement.scrollTop = scroller
            if (scroller >= scrollingElement.scrollHeight) clearInterval(sc)
        }, 1);
    }

    /**
     * 
     * @param {int} page 
     * @param {int} perPage 
     */
    const fetchMessages = async (page = 1, perPage = 10) => {
        if (!conversation) {
            return;
        }

        setData({ ...state, loading: true });

        fetch(`${process.env.REACT_APP_API_URL}/conversation/${conversation.id}/messages?page=${page}&per_page=${perPage}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        })
        .then(response => {
            if (response.ok) {
                response.json().then(data => {


                    /**
                     * <<<<<<
                     */
                    const oldData = JSON.parse(__conversationResponse)
                    data.data = [...oldData.data, ...data.data]
                    __setConversationResponse(JSON.stringify(data))
                    // >>>>>>

                    setData((oldState) => ({
                        ...oldState,
                        messages: data.data,
                        loading: false,
                    }))
                })
            } else if (!response.ok) {
                setData({ ...state, loading: false })
                return new Promise((resolve, reject) => reject('Une erreur s\'est produite! Veuillez verifier votre requete et re-essayez'))
            }
        });
    }


    const conversationMessages = () => {
        return (
            <React.Fragment>
                <div className="chat">
                    {sortMessagesByCreatedAt(state.messages).map(message =>
                        <div key={message.id} className={"cnv " + (message.sender_id == realUser.id ? 'me' : 'other')}>
                            <div className="name">
                                <h4>{message.sender_id == correspondant.id ? correspondant.display_name : realUser.display_name}</h4>
                                <span>{conversationDate(new Date(message.created_at))}</span>
                                {/* <span>Jan 19, 2021, 08:22 AM</span> */}
                            </div>
                            <div className="text">
                                <p>{message.body}</p>
                            </div>
                        </div>
                    )}
                </div>
                <form className="add-msg" onSubmit={e => sendMessage(e)}>
                    <div>
                        <textarea id="msg-content" onChange={e => setMessageContent(e.target.value)} type="text" placeholder="Envoyer une réponse ..." defaultValue={messageContent}></textarea>
                    </div>
                    <div>
                        {
                            state.sending ?
                                <PulseLoader color={'purple'} size={10} />
                                : <ArrowUpwardIcon />
                        }
                        <input type="submit" />
                    </div>
                </form>
            </React.Fragment>
        )
    }

    return (
        <div className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
            <div className="w-2/3 mx-auto bg-white rounded-lg shadow-xl p-8 z-100">
                <div className="parent-table w-full">

                    <div className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
                        <div ref={scrollRef} className="scrollable w-2/3 mx-auto overflow-y-scroll max-h-96 2xl:max-h-full 2xl:overflow-y-hidden bg-white rounded-lg shadow-xl p-8 z-100 relative">
                            <button type="button" className="float-right pt-0" onClick={closeModal}><CloseIcon /></button>
                            <div className="w-full flex flex-col h-full">
                                <button ref={loadMoreMessagesBtnRef} id="loadMoreBtn" onClick={() => fetchPrev()}></button>
                                <section className="flex flex-col msg-diag w-full">

                                    <div className="msg w-full">

                                        <h3>Messagerie ADMIN</h3>
                                        {
                                            !allMessagesSeen ? '' : <p className="text-center py-2 text-lilaFonce">Vous avez tout vu!</p>
                                        }
                                        {
                                            (state.loading) ?
                                                <p className="text-center">
                                                    <PulseLoader color={'purple'} size={10} />
                                                </p>
                                                : ''
                                        }

                                        {conversationMessages()}

                                    </div>

                                </section>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PopupMessageWithAdminN