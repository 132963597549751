import React, {useEffect, useState} from 'react'
import './../assets/style/clientInfo.css'
import './../assets/style/dashboard.css'

import VenteTable from "../components/Tables/VenteTable";
import FraisTable from "../components/Tables/FraisTable";
import {useMediaQuery} from "react-responsive";
import MobileFrais from "../components/Mobile/Tables/Mes encours/MobileFrais";
import MobileVente from "../components/Mobile/Tables/Mes encours/MobileVente";
import http from '../services/Http'
import {getItem} from '../services/LocaleStorage';
import {useDispatch, useSelector} from "react-redux";
import {getUser} from "../app/user";
import Courriers from "../components/Tables/Courriers";
import {getDateFilter, getOptional, setPageTitle} from "../app/filters";

const MesEncoursPage = () => {
    const frontUser = useSelector(getUser)

    const [selectEnCours, setSelectEnCours] = useState(1);
    const [spents, setSpents] = useState({ data: [], per_page: 50, page: 1, count: 0 })
    const [earnings, setEarnings] = useState({ data: [], per_page: 50, page: 1, count: 0 })
    const [courriers, setCourriers] = useState({ data: [], per_page: 20, page: 1, count: 0 });
    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(false)
    const [loadingComponent, setLoadingComponent] = useState(false)
    const [sortTerms, setSortTerms] = useState("operation_date");
    const [sortOrder, setSortOrder] = useState("DESC");
    const token = getItem('bbbToken')
    const optional = useSelector(getOptional)
    const [displayBlockedRow, setDisplayBlockedRow] = useState(true)
    const [displayUnblockedRow, setDisplayUnblockedRow] = useState(true)
    const [buttonsTableFilters, setButtonsTableFilters] = useState(
        {
            receipt: ["with", "to_be_modified", "without"],
            affectation: ["with", "to_be_modified", "without"],
            payment: ["fully", "partially", "zero"],
        }
    )
    const [badges, setBadges] = useState({});


    const updateBoutonTableauFilters = (type, value) => {
        if (buttonsTableFilters[type].includes(value)) {
            setButtonsTableFilters({ ...buttonsTableFilters, [type]: buttonsTableFilters[type].filter(item => item !== value) })
        } else {
            setButtonsTableFilters({ ...buttonsTableFilters, [type]: [...buttonsTableFilters[type], value] })
        }
    }

    //filtre de dates
    const dateFilters = useSelector(getDateFilter)
    const dispatch = useDispatch()

    useEffect(() => {
        if (selectEnCours === 1)
            fetchSpents(spents.per_page, 1)
        if (selectEnCours === 2)
            fetchEarnings(earnings.per_page, 1)
        if (selectEnCours === 3)
            fetchCourriers()
    }, [selectEnCours, dateFilters, optional, sortTerms, sortOrder, buttonsTableFilters, displayBlockedRow, displayUnblockedRow])

    useEffect(() => {
        dispatch(setPageTitle('Mes Documents'))
    }, [])

    const setSortTermsData = (value) => {
        setSortTerms(value)
        console.log(value)
    }
    const setSortOrderData = ( value) => {
        setSortOrder(value)
        console.log(value)
    }

    const fetchSpents = async (perPage = spents.per_page, page = spents.page) => {
        let body = {
            client_id: frontUser.id,
            type:"achat",
            page: page,
            per_page: perPage,
            from_operation_date: dateFilters[0] !== null ? dateFilters[0].getFullYear().toString() + "-" + (dateFilters[0].getMonth() + 1).toString().padStart(2, "0") + "-" + dateFilters[0].getDate().toString().padStart(2, "0") : null,
            to_operation_date: dateFilters[1] !== null ? dateFilters[1].getFullYear().toString() + "-" + (dateFilters[1].getMonth() + 1).toString().padStart(2, "0") + "-" + dateFilters[1].getDate().toString().padStart(2, "0") : null,
            sort: {
                field: sortTerms,
                order: sortOrder
            },
            filters: {
                ...optional,
                isBlocked: displayUnblockedRow === displayBlockedRow ? "all" : displayBlockedRow ? "blocked" : "unblocked"
            },
            badges: buttonsTableFilters
        }

        let response = await fetch(process.env.REACT_APP_API_URL +'/filter-invoices' , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(body)

        });

        if (response.status === 200){
            let body = await response.json()
            setSpents(body)
            setLoadingComponent(false)
            setBadges({ ...body?.badges })
        } else {
            setSpents({data: [], per_page: 10, page: 1, count: 0})
            setLoadingComponent(false)
        }
    }

    const fetchEarnings = async (perPage = earnings.per_page, page = earnings.page) => {
        let body = {
            client_id: frontUser.id,
            type:"vente",
            page: page,
            per_page: perPage,
            from_operation_date: dateFilters[0] !== null ? dateFilters[0].getFullYear().toString() + "-" + (dateFilters[0].getMonth() + 1).toString().padStart(2, "0") + "-" + dateFilters[0].getDate().toString().padStart(2, "0") : null,
            to_operation_date: dateFilters[1] !== null ? dateFilters[1].getFullYear().toString() + "-" + (dateFilters[1].getMonth() + 1).toString().padStart(2, "0") + "-" + dateFilters[1].getDate().toString().padStart(2, "0") : null,
            sort: {
                field: sortTerms,
                order: sortOrder
            },
            filters: {
                ...optional,
                isBlocked: displayUnblockedRow === displayBlockedRow ? "all" : displayBlockedRow ? "blocked" : "unblocked"
            },
            badges: buttonsTableFilters
        }
        
        let response = await fetch(process.env.REACT_APP_API_URL +'/filter-invoices' , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(body)
        });

        if (response.status === 200){
            let body = await response.json()
            setEarnings(body)
            setLoadingComponent(false)
            setBadges({ ...body?.badges })
        } else {
            setEarnings({data: [], per_page: 10, page: 1, count: 0})
            setLoadingComponent(false)
        }
    }

    const fetchCourriers = (perPage = courriers.per_page, page = courriers.page) => {
        let endpoint = '/invoice?operation_type=courrier&client_id=' + frontUser.id.toString() + "&per_page=" + perPage.toString() + "&page=" + page.toString();
        if (dateFilters[0] !== null) {
            endpoint += "&from_operation_date=" + dateFilters[0].getFullYear().toString() + "-" + (dateFilters[0].getMonth() + 1).toString().padStart(2, "0") + "-" + dateFilters[0].getDate().toString().padStart(2, "0")
        }
        if (dateFilters[1] !== null) {
            endpoint += "&to_operation_date=" + dateFilters[1].getFullYear().toString() + "-" + (dateFilters[1].getMonth() + 1).toString().padStart(2, "0") + "-" + dateFilters[1].getDate().toString().padStart(2, "0")
        }

        http.get(endpoint, {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
        .then((data) => {
            setCourriers(data)
            setLoadingComponent(false)
        })
        .catch(() => {
            setCourriers({data: [], per_page: 10, page: 1, count: 0})
            setLoadingComponent(false)
        });
    }

    const ordi = useMediaQuery({query: '(min-width: 1000px)'})
    const mobile = useMediaQuery({query: '(max-width: 999px)'})
    return (
        <div className="w-full flex justify-end ">
            <div className="topTableCalc w-full pl-6 sidebar:pl-[3.3rem] pr-6">
                <div className="flex w-full justify-around pt-[4.1rem]">
                    <div
                        className={`w-3/6 flex justify-center rounded-2xl py-2 mr-2 ${selectEnCours === 1 ? 'bg-lila text-white' : 'bg-gris text-lilaFonce'}`} onClick={() => setSelectEnCours(1)}>
                        <a  href="#" className={selectEnCours === 1 ? 'active' : ''}>Dépenses</a>
                    </div>
                    <div
                        className={`w-3/6 flex justify-center rounded-2xl py-2 mr-2 ${selectEnCours === 2 ? 'bg-lila text-white' : 'bg-gris text-lilaFonce'}`} onClick={() => setSelectEnCours(2)}>
                        <a href="#" className={selectEnCours === 2 ? 'active' : ''}>Ventes</a>
                    </div>
                    <div
                        className={`w-3/6 flex justify-center rounded-2xl py-2  ${selectEnCours === 3 ? 'bg-lila text-white' : 'bg-gris text-lilaFonce'}`} onClick={() => setSelectEnCours(3)}>
                        <a href="#" className={selectEnCours === 3 ? 'active' : ''}>Courriers</a>
                    </div>
                </div>
                {/* if ordi */}
                {ordi && selectEnCours === 1 &&
                    <span>
                        <FraisTable 
                            fetchSpents={fetchSpents} 
                            spents={spents} 
                            badges={badges} 
                            loading={loading} 
                            loadingComp={loadingComponent}
                            setSortTerms={setSortTermsData} 
                            setSortOrder={setSortOrderData} 
                            updateBoutonTableauFilters={updateBoutonTableauFilters} 
                            buttonsTableFilters={buttonsTableFilters}
                            toggleDisplayBlockedRow={() => setDisplayBlockedRow(!displayBlockedRow)} 
                            toggleDisplayUnblockedRow={() => setDisplayUnblockedRow(!displayUnblockedRow)}
                            displayBlockedRow={displayBlockedRow} displayUnblockedRow={displayUnblockedRow}
                        />
                    </span>
                }
                {ordi && selectEnCours === 2 &&
                    <span>
                        <VenteTable 
                            fetchEarnings={fetchEarnings} 
                            earnings={earnings} 
                            badges={badges} 
                            loading={loading} 
                            loadingComp={loadingComponent} 
                            setSortTerms={setSortTermsData}
                            setSortOrder={setSortOrderData} 
                            updateBoutonTableauFilters={updateBoutonTableauFilters} 
                            buttonsTableFilters={buttonsTableFilters}
                            toggleDisplayBlockedRow={() => setDisplayBlockedRow(!displayBlockedRow)} 
                            toggleDisplayUnblockedRow={() => setDisplayUnblockedRow(!displayUnblockedRow)}
                            displayBlockedRow={displayBlockedRow} displayUnblockedRow={displayUnblockedRow}
                        />
                    </span>
                }
                {ordi && selectEnCours === 3 &&
                    <span>
                        <Courriers 
                            fetchCourriers={fetchCourriers} 
                            courriers={courriers} 
                            loading={loading} 
                            setSortTerms={setSortTermsData}  
                            setSortOrder={setSortOrderData} 
                            loadingComp={loadingComponent}
                        />
                    </span>
                }
                {/* if mobile */}
                {mobile && selectEnCours === 1 && <span><MobileFrais/></span>}
                {mobile && selectEnCours === 2 && <span><MobileVente/></span>}
            </div>
        </div>
    )
}

export default MesEncoursPage