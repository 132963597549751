import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import { PulseLoader } from "react-spinners";

import calendar from "../../assets/img/periode.png";
import { getItem } from "../../services/LocaleStorage";
import { getUser } from "../../app/user";

const PopupSyncBO = ({ close, bankAccountId }) => {
    const [startDate, setStartDate] = useState(new Date().getFullYear() + "-01-01");
    const [endDate, setEndDate] = useState(new Date().getFullYear() + "-12-31");
    const [isLoading, setIsLoading] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const user = useSelector(getUser)

    /** Sync bank operations (trx) based on selected dates */
    const syncBO = async () => {
        setIsLoading(true)
        const token = getItem("bbbToken")

        const endpoint = `/bank_account/sync-transactions?client_id=${user.id}&bank_account_id=${bankAccountId}&start_date=${startDate}&end_date=${endDate}`
        let response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
            method: 'GET', 
            'Access-Control-Allow-Credentials': true,
            headers: {
                'Authorization': 'Bearer ' + token, 'Content-Type': "application/json"
            }
        })
        setIsLoading(false)
        if (response.status == 200) {
            let data = await response.json()
            if (data.status == 'warning') {
                toast.warn('Pas de transactions à importer')
            } else {
                toast.success(data.message)
            }
        } else {
            let data = await response.json()
            if (data.error) {
                toast.error(data.error)
            } else {
                toast.error('Impossible de récupérer les opérations')
            }
        }
        close()
    }

    return (
        <div className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
            {!confirmed ? 
                <>
                    <div className="sidebar:w-[65.8rem] mx-auto bg-white rounded-lg shadow-xl sidebar:p-[4.6rem] p-8 w-2/3 z-100">
                        <button className="float-right pt-0 text-[#717579]" onClick={close}><CloseIcon/></button>
                        <div className="parent-table w-full mt-[2.2rem]">
                            <div className="flex text-black bg-grisClaire p-8 mt-8 rounded-xl text-base sidebar:text-[24px]">
                                <div className="mr-4"><p className="font-bold">Attention:</p></div>
                                <div>
                                    <p>les opérations présentes à cette période seront écrasées, ainsi que toutes les associations réalisées à ces dates d’opération.</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between mt-8">
                            <button className="bg-redCorail text-white rounded-xl p-2" type="button" onClick={close}>
                                Annuler
                            </button>
                            <button className="bg-[#09BD72] text-white rounded-xl p-2" type="button" onClick={() => setConfirmed(true)}> 
                                Confirmer
                            </button>
                        </div>
                    </div>
                </>
            :
                <div className="w-[28.3rem] p-[3rem] mx-auto bg-white rounded-lg shadow-xl z-100 text-[#717579]">
                    <button className="float-right pt-0" onClick={close}><CloseIcon/></button>
                    <div className="parent-table w-full mt-[0.3rem]">
                        <h1 className="text-[32px] font-bold text-black leading-8">Synchroniser les opérations bancaires</h1>
                        <div className="w-full flex items-center mt-[1.6rem] h-[2rem]">
                            <img className="w-[31px] h-[31px]" src={calendar} alt="calendar"/>
                            <h2 className="pl-[0.9rem] text-[24px] font-semibold">Période</h2>
                        </div>
                        {/*mes dates*/}
                            <div className="flex justify-between mt-[0.9rem]">
                                <div className=" px-[0.9rem] flex justify-center w-[10.6rem] rounded-lg border border-[#EEEEEE] py-1 h-[3.4rem] items-center mr-[1.2rem]">
                                    <div className="flex text-xs items-center dates-filtre font-bold">
                                        <span>Du</span>
                                        <input id="input-period-from" type="date" className="bg-none-input custom-calendar" min="1990-01-01" max="2300-01-01"  defaultValue={new Date().getFullYear() + "-01-01"} onChange={(e) => {setStartDate(e.target.value)}}/>
                                    </div>
                                </div>

                                <div className=" px-[0.9rem] flex justify-center w-[10.6rem] rounded-lg border border-[#EEEEEE] py-1 h-[3.4rem] items-center">
                                    <div className="flex text-xs items-center dates-filtre font-bold ">
                                        <span>Au</span>
                                        <input id="input-period-to" type="date" className="bg-none-input custom-calendar" min="1990-01-01" max="2300-12-31"  defaultValue={new Date().getFullYear() + "-12-31"} onChange={(e) => {setEndDate(e.target.value)}}/>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="text-right mt-8">
                        <button className="bg-[#09BD72] w-[6.4rem] h-[2.8rem] text-white rounded-full p-2 uppercase font-semibold cursor-pointer" type="button" onClick={syncBO}>
                            {isLoading ? 
                                <span className="ml-auto text-[10px] indicateursView:text-[18px]">
                                    <PulseLoader color={'black'} size={7}/>
                                </span>
                            : 'Sync'}
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}

export default PopupSyncBO;