// CGV.js
import React from "react";
import { Link } from "react-router-dom";

const CGV = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL ?? 'https://blabliblou.fr';
    return (
        <div className="page-content">
            <h3 className="mb-10 text-3xl text-center font-extrabold underline underline-offset-3 decoration-8">
                CONDITIONS GÉNÉRALES D’UTILISATION ET DE VENTE - BLABLIBLOU
            </h3>
            <div>
                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    I - CHAMP D'APPLICATION DES CONDITIONS GÉNÉRALES DE VENTE 
                </h4>
                <p className="mt-3 mb-3">
                    Les présentes conditions générales s’appliquent automatiquement à tous les utilisateurs de BLABLIBLOU, 
                    qu’ils soient abonnés ou non. Elles régissent les conditions d’accès, d’utilisation et de vente de BLABLIBLOU. 
                </p>
                <p className="mt-3 mb-3">
                    Votre utilisation de BLABLIBLOU et de tous les autres services connexes que la Société 
                    fournit (support technique depuis le site, blog, centre d’aide, etc.) est donc régie par 
                    les présentes conditions générales.  
                </p>
                <p className="mt-3 mb-3">
                    La Société BLABLIBLOU se réserve le droit de changer ou de mettre à jour ces termes et 
                    conditions périodiquement et à sa seule discrétion en les publiant sur son site ou en fournissant 
                    toute autre notification. Vos droits sous les présentes conditions générales seront soumis 
                    à la version en vigueur publiée sur notre site vitrine au moment de la création de votre compte BLABLIBLOU.
                </p>
                <p className="mt-3 mb-3">
                    Il appartient au visiteur de prendre régulièrement connaissance des versions les plus récentes publiées 
                    sur le site internet BLABLIBLOU. Le fait de s’inscrire ou d’utiliser BLABLIBLOU est considéré comme 
                    valant acceptation par le client de l’intégralité des termes et conditions des présentes conditions générales.
                </p>
                <p className="mt-3 mb-3">
                    Les CGV sont validées à chaque connexion à l'espace Client par des check-box obligatoires. 
                    Cette validation vaut acceptation de l'ensemble des CGV en vigueur au moment de la connexion.
                </p>

                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    II - DEFINITIONS ET INTERPRETATIONS
                </h4>
                <p className="mt-3 mb-3">
                    <span className="underline underline-offset-3 decoration-8 italic">Société</span> : Désigne la société LIBERTACOM, 
                    société par actions simplifiée au capital social de 2000 €, dont le siège social est situé au 
                    Lieu-dit Le Bayle 31480 GARAC, immatriculée au Registre du Commerce et des Sociétés de Toulouse 
                    sous le numéro 803 781 947, représentée par M. Benoit Montnach agissant et ayant les pouvoirs 
                    nécessaires en tant que président., éditeur du Site. 
                </p>
                <p className="mt-3 mb-3">
                    <span className="underline underline-offset-3 decoration-8 italic">Abonnement</span> : mode de facturation appliquée 
                    par la Société en échange de ses prestations. 
                </p>
                <p className="mt-3 mb-3">
                    <span className="underline underline-offset-3 decoration-8 italic">Client</span> : personne acceptant 
                    les présentes conditions générales et qui souscrit à un Abonnement à BLABLIBLOU. 
                </p>
                <p className="mt-3 mb-3">
                    <span className="underline underline-offset-3 decoration-8 italic">Compte</span> : espace propre à chaque 
                    Utilisateur de BLABLIBLOU. 
                </p>
                <p className="mt-3 mb-3">
                    <span className="underline underline-offset-3 decoration-8 italic">Contrat</span> : dispositions, termes et conditions 
                    découlant du présent document ainsi que de la Politique de confidentialité et de la Charte des Cookies incorporées 
                    au contrat par référence.
                </p>
                <p className="mt-3 mb-3">
                    <span className="underline underline-offset-3 decoration-8 italic">Données Client</span> : toutes les données et informations 
                    implémentées par le Client dans BLABLIBLOU ou collectées et traitées au moyen des services de BLABLIBLOU 
                    pour le compte du Client.<br />
                    Sont compris les textes, images, données comptables et tout contenu, de quelque nature qu’il soit, 
                    échangé lors de l’utilisation du support technique du Site et/ ou du Compte.<br />
                    Ces données peuvent concerner tant le Client que ses propres clients, ou toutes autres informations 
                    transmises par lui.
                </p>
                <p className="mt-3 mb-3">
                    <span className="underline underline-offset-3 decoration-8 italic">Prospect</span> : client potentiel de BLABLIBLOU 
                    pour lequel des opérations commerciales et/ou de communication sont mises en place. 
                </p>
                <p className="mt-3 mb-3">
                    <span className="underline underline-offset-3 decoration-8 italic">Services</span> : ensemble des services accessibles 
                    via BLABLIBLOU dans les conditions prévues aux présentes.
                </p>
                <p className="mt-3 mb-3">
                    <span className="underline underline-offset-3 decoration-8 italic">Site</span> : ensemble des sites 
                    (site applicatif, site vitrine, blog, centre d’aide) édités par la Société. 
                </p>
                <p className="mt-3 mb-3">
                    <span className="underline underline-offset-3 decoration-8 italic">Utilisateur</span> : personne recourant aux 
                    Services proposés par BLABLIBLOU et/ou ayant ouvert un Compte sur le Site.
                </p>
                <p className="mt-3 mb-3">
                    <span className="underline underline-offset-3 decoration-8 italic">Application ou logiciel</span> : application ou logiciel 
                    mis à disposition par la Société.
                </p>

                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    III - DESCRIPTION DES SERVICES BLABLIBLOU
                </h4>
                <p className="mt-3 mb-3">
                    BLABLIBLOU désigne une solution logicielle hébergée à distance et rendue disponible sur un site Internet 
                    et une application mobile, ainsi que le support technique qui y est associé. 
                </p>
                <p className="mt-3 mb-3">
                    Le logiciel vise à permettre au Client la gestion et le suivi économique de l’activité d’une ou plusieurs 
                    sociétés et d’un ou plusieurs clients. Le support technique désigne l’assistance client relative 
                    à son utilisation du logiciel.
                </p>
                <p className="mt-3 mb-3">
                    BLABLIBLOU ne désigne pas une prestation d’expertise comptable au sens de la loi du 17 janvier 1948 et 
                    du décret du 19 juillet 1948. 
                </p>
                <p className="mt-3 mb-3">
                    Le support se limite à une assistance technique pour l’utilisation des fonctionnalités 
                    de BLABLIBLOU par le Client.
                </p>
                <p className="mt-3 mb-3">
                    BLABLIBLOU vise à simplifier la tenue de la comptabilité des Clients grâce à un rapprochement 
                    des documents ou des pièces et des opérations bancaires. Le logiciel permet au client d’identifier 
                    des opérations bancaires, d’importer et de stocker des pièces et de créer des indicateurs 
                    de gestion sous la forme d’un tableau de bord pour piloter l’activité des sociétés et des clients.
                </p>
                <p className="mt-3 mb-3">
                    BLABLIBLOU utilise pour cela une fonctionnalité d’océrisation des documents ou des pièces comptables 
                    puis rapproche lesdites pièces des écritures bancaires afin de proposer un projet d’affectation comptable.
                </p>
                <p className="mt-3 mb-3">
                    BLABLIBLOU permet donc la catégorisation de transactions bancaires. Une transaction bancaire 
                    est un mouvement financier qui apparaît sur un relevé bancaire sous la forme d’une date, 
                    d’un libellé et d’un montant. Le Client peut directement synchroniser des transactions bancaires 
                    vers BLABLIBLOU à partir de ses services bancaires existants. La Société s’appuie sur 
                    l’agrégateur de transactions bancaires tiers « LINXO CONNECT ».
                </p>
                <p className="mt-3 mb-3">
                    Dans le respect des modalités qui régissent sa relation avec sa banque, le Client doit fournir 
                    ses identifiants bancaires en ligne à l’agrégateur de transactions bancaires tiers s’il souhaite 
                    profiter de ce service. En vue de permettre au partenaire d’assurer la sécurité́ des Données Clients, 
                    il est convenu que la Société ne détiendra ni ne stockera les identifiants d’accès aux comptes bancaires. 
                    Par ailleurs, l’accès aux comptes se faisant en lecture seule, aucune opération bancaire 
                    ne peut être engagée directement par la Société.
                </p>
                <p className="mt-3 mb-3">
                    L'application LINXO CONNECT d'agrégation bancaire utilisée par BLABLIBLOU est un établissement de paiement 
                    PSD2 agréé par l'ACPR, certifiée ISO 27001 et couvrant 400 banques françaises. BLABLIBLOU limite 
                    son service d'agrégation bancaire à ceux proposés par son partenaire LINXO et ne peut être tenu 
                    pour responsable si le ou les banques du client ne sont pas couverts par LINXO. Les CGV de LINXO 
                    sont accessibles sur www.blabliblou/CGV_LINXO.
                </p>
                <p className="mt-3 mb-3">
                    Dans le cadre de cette convention, le partenaire s’engage à mettre en place les moyens financiers, 
                    matériels et humains nécessaires lui permettant d’assurer le bon déploiement et la disponibilité́ permanente 
                    de l’API au profit des utilisateurs du logiciel. Le partenaire garantit et s’engage en outre 
                    à tout faire pour mettre en place toutes les procédures de sécurité́ selon les standards les plus élevés, 
                    propres à garantir l’intégrité́ des données ou qui seraient demandées par la Société́.
                </p>
                <p className="mt-3 mb-3">
                    BLABLIBLOU s'appuie sur un jeu d'algorithmes pour proposer des catégorisations bancaires pertinentes 
                    et des associations entre les documents-pièces et les opérations bancaires. 
                </p>
                <p className="mt-3 mb-3">
                    BLABLIBLOU propose aussi divers simulateurs au Client : outil de prévisions d'activité, simulateurs 
                    de charges sociales, fiscales, simulateur de courriers administratifs. 
                </p>
                <p className="mt-3 mb-3">
                    BLABLIBLOU propose deux catégories d’utilisateur ayant accès à des fonctionnalités et des droits différents : 
                </p>
                <ul>
                    <li>
                        Profil « Administrateur » : sont des profils professionnels qui doivent être validés par la Société 
                        avant d’avoir accès aux fonctionnalités. Cette validation passe par la transmission de documents 
                        et informations qui seront demandées par la Société (K-bis, RIB professionnel, …)<br />
                        Les profils « administrateurs » peuvent souscrire de nouvelles licences sur lesquelles 
                        ils auront un pouvoir de gestion.<br />
                        L’administrateur est le seul à avoir accès aux exports des écritures sous la forme de Fichiers 
                        d’Ecriture Comptable (FEC).
                    </li>
                    <li>
                        L’utilisateur « Classique » : Ce profil peut être créé par toute personne. Il donne accès aux fonctionnalités 
                        essentielles mais ne permet pas les exports sous le format des écritures sous la forme de 
                        Fichiers d’Ecriture Comptable (FEC).
                    </li>
                </ul>

                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    IV – RESPONSABILITE DE LA SOCIETE
                </h4>
                <p className="mt-3 mb-3">
                    Tout traitement par la Société de données à caractère personnel du Client, ou de ses propres clients, 
                    à l’occasion du Contrat s’effectue conformément à la réglementation en vigueur. 
                    La Société n’agit que selon les instructions du Client et ne traite les données à disposition 
                    que pour les seules finalités d’exécuter les obligations qui lui incombent en exécution du Contrat 
                    et de prévenir ou résoudre des problèmes techniques ou de service, pour se conformer 
                    aux obligations légales et réglementaires, ou après autorisation expresse par écrit du Client. 
                </p>
                <p className="mt-3 mb-3">
                    Il est précisé que dans le cadre de ses fonctionnalités, BLABLIBLOU a recours à des outils d’océrisation 
                    permettant la reconnaissance des caractères alphanumériques présents sur les documents transmis. 
                    Ces outils d’océrisation présentent des risques d’erreurs inhérents à leur fonctionnement. 
                    BLABLIBLOU ne saurait être tenu responsable d’erreurs liées à l’océrisation car l’utilisateur 
                    demeure responsable des vérifications de ses propres documents. Un choix sans OCR est laissé 
                    à discrétion de l’utilisateur.
                </p>
                <p className="mt-3 mb-3">
                    Il est également précisé que dans le cadre de ses fonctionnalités, BLABLIBLOU a recours à un jeu algorithmique 
                    permettant de proposer de manière intelligente et automatisé des affectations comptables cohérentes. 
                </p>
                <p className="mt-3 mb-3">
                    Néanmoins, BLABLIBLOU ne saurait être responsable d’erreurs induites par cette automatisation, 
                    l’utilisateur demeure responsable des vérifications d’affectation et des choix opérés en la matière.
                </p>
                <p className="mt-3 mb-3">
                    BLABLIBLOU permet également de réaliser des exports de données générées par l'Application sous forme 
                    de fichiers Excel, Pdf et un projet d'écritures comptables des opérations bancaires et documents-pièces 
                    au format FEC.txt.
                </p>
                <p className="mt-3 mb-3">
                    Les écritures comptables générées dans le fichier FEC sont basées sur un plan comptable limité proposé 
                    par BLABLIBLOU en <b>annexe 1</b> des présentes CGV, et validé par le Client à chaque connexion à son compte. 
                    En aucun cas BLABLIBLOU ne pourra être tenu pour responsable du caractère restrictif du plan comptable, 
                    ni des écritures comptables générées dans le fichier FEC issues des choix de catégorisation 
                    du Client et de la qualité des documents ou des pièces importées. 
                </p>
                <p className="mt-3 mb-3">
                    Le fichier FEC exporté ne peut pas être considéré comme une tenue de comptabilité exhaustive et exacte.
                </p>
                <p className="mt-3 mb-3">
                    Si le fichier est intégré à une application comptable, les écritures comptables importées seront 
                    sous la responsabilité du Client.
                </p>
                <p className="mt-3 mb-3">
                    BLABLIBLOU produit des indicateurs de gestion de trésorerie, de chiffres d'affaires, de dépenses et 
                    de résultats - décrits en annexe 2 - basés sur les informations et documents-pièces fournies par le client. 
                    Ainsi, ces indicateurs sont donnés à titre informatif et ne peuvent être considérées comme 
                    des données comptables ou états financiers exacts. 
                </p>
                <p className="mt-3 mb-3">
                    Suivant les règles de l’art communément admises, la Société met en œuvre tous les efforts raisonnables 
                    afin de maintenir BLABLIBLOU à la disposition du Client 24 heures sur 24 et 7 jours sur 7. 
                </p>
                <p className="mt-3 mb-3">
                    Néanmoins, la Société se réserve le droit de rendre moins accessible ou indisponible, 
                    pendant une durée déterminée, la plateforme BLABLIBLOU et/ou tout ou partie de ses fonctionnalités 
                    pour corriger tout bug, installer des mises à jour ou encore faire tout diagnostic ou toute maintenance. 
                </p>
                <p className="mt-3 mb-3">
                    De la même manière, le site ou l’application peuvent être rendus indisponibles en raison 
                    de maintenances opéré sur les API utilisées par BLABLIBLOU et notamment celle de son partenaire LINXO.
                </p>
                <p className="mt-3 mb-3">
                    La responsabilité de la Société ne saurait être déduite de toute suspension ou indisponibilité de BLABLIBLOU 
                    et/ou de l’accès à ses serveurs, en totalité ou en partie.
                </p>
                <p className="mt-3 mb-3">
                    En cas de questions concernant l’utilisation de BLABLIBLOU, La Société fournit un support technique. 
                    Les coordonnées et les heures d’ouverture de l’assistance client sont consultables sur le site Web BLABLIBLOU.
                </p>
                <p className="mt-3 mb-3">
                    Dans le cadre de sa mission de mise en œuvre des services BLABLIBLOU pour le compte de ses Clients, 
                    la Société peut communiquer les données collectées en tant que de besoin à des fournisseurs de services tiers. 
                    Ces transferts de données sont réalisés dans la limite nécessaire à l’exécution des tâches 
                    qui leurs sont confiées. Ces tiers sont pleinement informés de la confidentialité des données 
                    qui leur sont communiquées et ont l’obligation d’assurer la protection de ces données. 
                    La Société s’engage à prendre les mesures de sécurité techniques et organisationnelles nécessaires 
                    pour respecter la règlementation.
                </p>

                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    V – RESPONSABILITE DU CLIENT
                </h4>
                <p className="mt-3 mb-3">
                    Le Client demeure responsable de l’exactitude, la qualité et la légalité des Données Client et 
                    des moyens par lesquels il les obtient.
                </p>
                <p className="mt-3 mb-3">
                    Le Client s’engage à utiliser BLABLIBLOU de bonne foi et aux fins pour lesquelles il est commercialisé, 
                    dans le respect du présent Contrat et du droit applicable.
                </p>
                <p className="mt-3 mb-3">
                    Le Client s’interdit de mettre BLABLIBLOU à disposition de tout tiers autre que ceux autorisés 
                    au titre du Contrat, de distribuer BLABLIBLOU par une concession, un bail ou une sous-licence 
                    sans l’accord écrit préalable de la Société, de permettre, directement ou indirectement, 
                    l’accès ou l’utilisation de BLABLIBLOU en contournant la protection logique ou physique, 
                    de copier ou de reproduire tout ou partie de BLABLIBLOU, d’accéder à BLABLIBLOU en vue d’élaborer un produit 
                    ou service concurrent. En conséquence, la Société se réserve le droit de résilier l’Abonnement, 
                    sans que cela ne puisse engager sa responsabilité, dès lors que le Client ne respecte pas cet engagement.
                </p>
                <p className="mt-3 mb-3">
                    Le Client demeure responsable de la compatibilité de BLABLIBLOU avec son appareil mobile, son navigateur web, 
                    son réseau et ses connexions Internet et/ou la configuration de ses systèmes informatiques et 
                    de tous frais y afférents.
                </p>
                <p className="mt-3 mb-3">
                    L’accès à BLABLIBLOU étant subordonné à la disponibilité du réseau de l’opérateur mobile ou 
                    du fournisseur d’accès Internet du Client, la Société ne saurait en aucune manière être tenue 
                    pour responsable de la disponibilité des services de l’opérateur ou du fournisseur d’accès du Client, 
                    y compris en cas de perte, dommage, erreur ou échec de transmission de données.
                </p>
                
                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    VI - TARIFS ET CONDITIONS FINANCIERES
                </h4>
                <p className="mt-3 mb-3">
                    Le coût et les modalités d’abonnement à BLABLIBLOU sont indiqués de manière claire au moment 
                    du processus d’inscription et demeurent disponibles en ligne à l’adresse suivante : <a href={baseUrl} className="text-purple-600">{baseUrl}</a><br />
                    Les montants y sont indiqués en euros et dépendent du choix de l’abonnement, des options et de l’utilisation 
                    de l’OCR. Les tarifs sont fournis en annexe 3 des présentes CGV.
                </p>
                <p className="mt-3 mb-3">
                    La Société utilise les moyens d’un prestataire de paiement afin de garantir la qualité et la sécurité du paiement en ligne. 
                    Le prestataire de paiement collecte notamment, lors d’un paiement en ligne, des Données Personnelles 
                    concernant vos moyens de paiement (numéro de carte bancaire, date de fin de validité de la carte bancaire, 
                    cryptogramme visuel, ce dernier n’étant pas conservé, etc.).
                </p>
                <p className="mt-3 mb-3">
                    La Société propose, pour les utilisateurs « classiques », une période d’essai gratuite et limitée 
                    dans le temps, destinée à permettre au Client d’évaluer l’adéquation de BLABLIBLOU à ses besoins. 
                    Cet essai gratuit dure 14 jours, sauf stipulation écrite contraire émanant de la Société. 
                    À l’expiration de la période d’essai gratuite, l’Utilisateur a le choix de s’abonner ou pas. 
                    En cas d’abonnement, le montant de la redevance sera calculé conformément au tarif en vigueur 
                    à la date de souscription, sauf s’il en a été conclu différemment entre les parties. 
                    Le Client fournira à la Société des informations de facturation et de paiement complètes 
                    et à jour afin de permettre la facturation et le paiement des sommes dues par le Client en exécution du Contrat. 
                    Sauf disposition spécifique, les redevances sont payables terme à échoir 
                    (paiement chaque mois de la redevance du mois d’abonnement à venir). 
                    Il incombe au Client d’informer la Société de toute modification relative à ces informations de paiement. 
                </p>
                <p className="mt-3 mb-3">
                    Les factures sont payables à date d’émission au comptant. Des pénalités de retard calculées 
                    au taux d’intérêt appliqué par la Banque Centrale Européenne à son opération  de refinancement la plus récente, 
                    majorée de 5 points de pourcentage, sur le montant TTC de la redevance, seront automatiquement 
                    et de plein droit acquises à la société, sans formalité ni mise en demeure préalable 
                    et entraînera l’exigibilité immédiate de la totalité des sommes dues à la société par le Client, 
                    sans préjudice de tout autre action que la société serait en droit d’intenter, à ce titre, à l’encontre du Client. 
                    Le Client en situation de retard de paiement sera de plein droit débiteur à l’égard de la société 
                    d’une indemnité forfaitaire de 40 € pour frais de recouvrement.
                </p>
                <p className="mt-3 mb-3">
                    La Société se réserve le droit de modifier les tarifs de ses services à tout moment. Le Client est informé 
                    que son choix s’est porté sur la version de BLABLIBLOU au jour de sa commande et que ce choix 
                    n’était en aucune manière déterminé par la délivrance ou l’ajout d’une fonctionnalité ou d’une caractéristique future.
                </p>
                <p className="mt-3 mb-3">
                    Sauf stipulation contraire, toutes les notifications et accords fournis en exécution du Contrat sont transmis 
                    par courriel et sont réputés communiqués le premier jour ouvrable qui suit son envoi. 
                    Les notifications associées aux conditions financières sont adressées à l’adresse courriel indiquée 
                    à cet effet par le Client.
                </p>

                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    VII. DROITS EXCLUSIFS ET DROIT D’USAGE
                </h4>
                <p className="mt-3 mb-3">
                    Le Contrat s’entend sans aucune cession de propriété ou de titularité de droits de propriété intellectuelle 
                    quels qu’ils soient. La Société se réserve tous les droits et titres de propriété relatifs 
                    au contenu de BLABLIBLOU. Aucun droit, autre que ceux expressément accordés aux présentes, 
                    ne saurait être concédé au Client par le Contrat.
                </p>
                <p className="mt-3 mb-3">
                    La Société concède au Client un droit d’utilisation de BLABLIBLOU. Ce droit d’usage s’entend 
                    pour le territoire du monde entier et pour la durée du Contrat. Le Client concède à la Société un droit 
                    d’hébergement, de reproduction et de représentation des Données Client. Ce droit est accordé pour 
                    le monde entier et pour la durée du Contrat, augmentée du temps des prescriptions légales en vigueur. 
                    Ce droit est accordé aux seules fins d’exécution des obligations au titre du Contrat. 
                    La Société n’acquiert en vertu du Contrat aucun droit de propriété relatif aux Données Client.
                </p>
                <p className="mt-3 mb-3">
                    Le Client concède à la Société le droit d’utiliser et d’incorporer dans BLABLIBLOU toute suggestion, 
                    demande d’amélioration, recommandation, correction ou commentaire faits par le Client ou les utilisateurs. 
                    Ce droit est irrévocable et s’entend sans aucune contrepartie financière, ce que le Client accepte expressément.

                </p>
                <p className="mt-3 mb-3">
                    Le Client s’interdit de céder ses droits ou obligations en vertu du Contrat sans l’accord préalable 
                    écrit de la Société qui ne pourra refuser un tel accord que pour motif légitime. Aucun tiers ne peut prétendre 
                    au bénéfice du Contrat et des obligations qui y sont stipulées.
                </p>
                <p className="mt-3 mb-3">
                    Le Contrat constitue l’intégralité de l’accord entre les parties eu égard à son objet, et annule et remplace 
                    l’ensemble des contrats, négociations et discussions antérieures entre les parties s’y rapportant.<br />
                    Le Client autorise la société à céder le Contrat en application de l’article 1216 du code civil.
                </p>

                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    VIII - PROTECTION DES DONNEES PERSONNELLES ET COOKIES 
                </h4>
                <p className="mt-3 mb-3">
                    Chaque partie devra traiter les informations relatives à l’autre partie comme étant de nature confidentielle. 
                    Elle s’abstiendra en conséquence, sans limitation de durée, de les utiliser ou les divulguer, 
                    sauf dans les cas stipulés au Contrat ou avec l’autorisation écrite de l’autre partie. 
                    La Société est autorisée à divulguer les informations de l’autre partie à son personnel, 
                    ses partenaires et ses mandataires sociaux, et si cela est imposé par la loi, par une décision judiciaire, 
                    et par toute autorité gouvernementale ou de régulation. Dans chacune de ces hypothèses, 
                    la Société veille à ce que soient transmises uniquement les données strictement requises.
                </p>
                <p className="mt-3 mb-3">
                    Le client reconnait avoir connaissance de la Politique de confidentialité consultable 
                    à l’adresse <Link to="/confidentialite" className="text-purple-600">{baseUrl}/confidentialite</Link> exposant les mesures 
                    techniques et organisationnelles mises en œuvre par la Société en matière de traitements de données personnelles. 
                </p>
                <p className="mt-3 mb-3">
                    La Société pourra aussi collecter de manière automatisée des informations concernant un Utilisateur 
                    à chacun de ses visites sur le Site par le biais de traceurs ou cookies. Pour plus d’informations 
                    sur les modalités de collecte de données personnelles dans ce cadre, il convient de se référer 
                    à notre Charte des Cookies, dont le client reconnaît avoir connaissance et consultable 
                    à l’adresse <a href={baseUrl} className="text-purple-600">{baseUrl}</a>.
                </p>

                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    IX - GARANTIES, LIMITATION ET EXCLUSION DE RESPONSABILITE
                </h4>
                <p className="mt-3 mb-3">
                    La Société garantit qu’elle ne fera pas évoluer à la baisse la sécurité globale BLABLIBLOU durant 
                    la durée du Contrat, que BLABLIBLOU fonctionnera de manière substantiellement conforme à sa description 
                    telle qu’elle figure dans les Documents contractuels, qu’elle n’altèrera pas de manière substantielle 
                    les fonctionnalités existantes de BLABLIBLOU et que BLABLIBLOU n’introduira pas de Code malveillant 
                    dans les systèmes du Client.
                </p>
                <p className="mt-3 mb-3">
                    La Société garantit le Client contre toute réclamation, demande, poursuite judiciaire ou procédure faite 
                    ou engagée à son encontre par un tiers alléguant que l’utilisation de BLABLIBLOU conformément au 
                    Contrat viole ses droits de propriété intellectuelle.
                </p>
                <p className="mt-3 mb-3">
                    La Société l’indemnisera de tous dommages et intérêts, honoraires et frais de justice qui en résulteraient 
                    par une condamnation définitive ou de tous montants qu’il paierait en vertu d’un règlement amiable 
                    de ladite réclamation validé par un Tribunal, sous réserve que le Client notifie immédiatement 
                    la Société par écrit de cette réclamation, que le Client donne à la Société le contrôle exclusif 
                    du litige et/ou du règlement amiable de cette réclamation (étant entendu que la Société ne peut accepter 
                    un règlement amiable que s’il dégage sans condition le Client de toute responsabilité) et que le Client 
                    apporte toute l’assistance requise à la Société, aux frais de la Société si besoin était.
                </p>
                <p className="mt-3 mb-3">
                    Le Client garantira contre toute réclamation, demande, poursuite judiciaire ou procédure faite ou engagée 
                    contre la Société par un tiers alléguant que les Données Client, ou l’utilisation de BLABLIBLOU par le Client 
                    en violation du Contrat, viole l’un quelconque de ses droits ou le droit applicable. Cette garantie couvre 
                    la Société contre tout dommage, honoraire et frais de justice qui en résulterait ou de tout montant 
                    que la Société paierait en vertu d’un règlement amiable de ladite réclamation validé par un tribunal, 
                    sous réserve que la Société notifie par écrit le Client de l’existence de cette réclamation 
                    dans les meilleurs délais après en avoir eu connaissance, que la Société donne au Client le contrôle exclusif 
                    de la contestation et du règlement amiable de cette réclamation, étant entendu qu’un tel règlement amiable 
                    devra dégager la Société de toute responsabilité sans terme ni condition et que la Société apporte 
                    au Client toute l’assistance raisonnable qui pourra être sollicitée par le Client, le cas échéant 
                    aux frais du Client. Ces deux précédents paragraphes décrivent l’intégralité de la responsabilité 
                    qui pourrait être encourue par une partie vis-à-vis de l’autre pour les types de réclamations qui y sont décrites.
                </p>
                <p className="mt-3 mb-3">
                    Si la Société est informée d’une réclamation en contrefaçon se rapportant à BLABLIBLOU, elle peut, 
                    à sa discrétion et sans frais pour le Client, modifier BLABLIBLOU de manière à mettre fin à la situation 
                    de contrefaçon, ou obtenir un droit d’utilisation autorisant le Client à continuer d’utiliser BLABLIBLOU 
                    conformément au Contrat ou résilier le Contrat ou supprimer la partie contrefaisante et ce en respectant 
                    un préavis écrit de 30 jours calendaires et en remboursant de tous montants payés d’avance au titre 
                    de la période contractuelle en cours.
                </p>
                <p className="mt-3 mb-3">
                    En cas de litige ayant pour objet le contrat ou son exécution, la responsabilité des parties est limitée 
                    pour chaque litige au montant payé à la Société au titre du contrat dans les 12 mois qui précèdent 
                    le fait générateur du litige. La responsabilité cumulée des litiges ayant pour objet le contrat 
                    ou son exécution est limitée au montant total payé à la Société au titre du contrat. 
                    Ces limitations n’ont toutefois pas vocation à s’appliquer en cas d’action en contrefaçon 
                    ou concurrence déloyale de la part de la Société.
                </p>

                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    X - DUREE DE VALIDITE ET RESILIATION
                </h4>
                <p className="mt-3 mb-3">
                    Le Contrat prend effet à compter de la date d’inscription de l’Utilisateur. Sous réserve de la satisfaction 
                    de la période d’Essai Gratuite, l’Abonnement prend effet pour une période initiale d’un mois, 
                    sauf stipulation contraire.<br />
                    L’Abonnement est ensuite reconduit tacitement pour des durées successives égales à la durée de 
                    la période initiale. Sauf à ce que le Client soit toujours en période d’Essai gratuite, 
                    la Société se réserve le droit de réviser le montant des redevances, à chaque reconduction, 
                    en notifiant le Client par tout moyen et en respectant un délai de préavis de 30 jours. 
                </p>
                <p className="mt-3 mb-3">
                    En cas de manquement grave ou de manquements répétés par le Client, la Société est en droit 
                    de résilier l’Abonnement si ledit manquement n’a pas été corrigé dans un délai de 15 jours suivant 
                    la notification faite à la partie défaillante, en l’occurrence le Client.
                </p>
                <p className="mt-3 mb-3">
                    Le Client peut quant à lui résilier l’Abonnement sans aucun préavis. Si le Client résilie l’Abonnement, 
                    la Société procèdera à l’arrêt des futurs prélèvements suivant le principe des redevances payables 
                    terme à échoir (paiement chaque mois de la redevance du mois d’abonnement à venir). 
                    En aucun cas la résiliation ne dégage le Client de son obligation de payer tous montants qui pourraient 
                    être dus à la Société au titre de la période précédant la date d’effet de la résiliation.
                </p>
                <p className="mt-3 mb-3">
                    Le Client pourra, dans un délai de 3 ans suivant la date d’effet de la résiliation du Contrat, 
                    ou dans un délai de 30 jours seulement, si le Client en fait le choix explicite au moment de sa résiliation, 
                    se voir mettre à sa disposition ses Données Client pour exportation ou téléchargement. 
                    Passé ce délai, la Société n’aura pas l’obligation de conserver ou de fournir les Données Client 
                    et sera autorisée, si bon lui semble, à détruire ou anonymiser toutes les copies des Données Client stockées sur ses systèmes.
                </p>
                <p className="mt-3 mb-3">
                    Toute stipulation du Contrat qui, de façon expresse ou tacite, a pour vocation à continuer de produire 
                    des effets après la résiliation ou l’expiration du Contrat restera en vigueur après ladite résiliation 
                    ou expiration. La résiliation ou l’expiration du Contrat n’ont pas vocation à diminuer les droits, recours, 
                    obligations ou engagements dont les parties peuvent se prévaloir ou auxquels elles sont tenues 
                    à la date de résiliation ou d’expiration.
                </p>

                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    XI - RÈGLEMENT AMIABLE DES LITIGES ET DROIT DE RETRACTATION - CLIENT CONSOMMATEUR
                </h4>
                <h5 className="mb-2 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    Règlement amiable des litiges :
                </h5>
                <p className="mt-3 mb-3">
                    Le Client consommateur est informé qu'il peut en tout état de cause recourir à une médiation conventionnelle, 
                    notamment auprès de la Commission de la médiation de la consommation (C. consom. art. L 612-1) 
                    ou auprès des instances de médiation sectorielles existantes, ou à tout mode alternatif de règlement 
                    des différends (conciliation, par exemple) en cas de contestation.
                </p>
                <p className="mt-3 mb-3">
                    Les litiges entrant dans le champ d'application de l'article L. 612-1 du Code de la consommation 
                    sont les litiges définis à l'article L. 611-1 du Code de la consommation à savoir les litiges 
                    de nature contractuelle, portant sur l'exécution d'un contrat de vente ou de fournitures de services, 
                    opposant un consommateur à un professionnel. Le texte couvre les litiges nationaux et les litiges transfrontaliers.
                </p>
                <p className="mt-3 mb-3">
                    Pour toute difficulté, nous vous invitons à nous contacter préalablement ou à contacter :
                </p>
                <ul>
                    <li>Notre service d’assistance technique : <a href="mailto:webmaster@blabliblou.fr" className="text-purple-600">webmaster@blabliblou.fr</a> ;</li>
                    <li>Notre service client : <a href="mailto:contact@blabliblou.fr" className="text-purple-600">contact@blabliblou.fr</a>.</li>
                </ul>
                <p className="mt-3 mb-3">
                    <span className="font-extrabold">Vous pouvez, à vos frais, vous faire assister par un conseil.</span><br />
                    Le Client reconnaît avoir eu communication, préalablement à l'achat immédiat ou à la passation 
                    de sa commande et à la conclusion du contrat, d'une manière claire et compréhensible, des présentes Conditions Générales de Vente 
                    et de toutes les informations listées à l'article L.221-5 du Code de la consommation et notamment les informations suivantes :
                </p>
                <ul>
                    <li>Les caractéristiques essentielles du Service ;</li>
                    <li>Le prix des Services et des frais annexes (livraison, par exemple) ;</li>
                    <li>
                        En l'absence d'exécution immédiate du contrat, la date ou le délai auquel le Prestataire 
                        s'engage à fournir les Services commandés ;
                    </li> 
                    <li>
                        Les informations relatives à l'identité du Prestataire, à ses coordonnées postales, téléphoniques 
                        et électroniques, et à ses activités, si elles ne ressortent pas du contexte ;
                    </li> 
                    <li>Les informations relatives aux garanties légales et contractuelles et à leurs modalités de mise en œuvre ;</li> 
                    <li>Les fonctionnalités du contenu numérique et, le cas échéant, à son interopérabilité ;</li> 
                    <li>La possibilité de recourir à une médiation conventionnelle en cas de litige.</li>
                </ul>
                <h5 className="mb-2 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    Droit de rétractation :
                </h5>
                <p className="mt-3 mb-3">
                    Le Client est informé qu'il peut se rétracter sous un délai de 14 jours après avoir conclu 
                    la vente à distance (C. consom. art. L121-20-12). Pour se faire il convient d’envoyer un formulaire 
                    de rétractation à l’adresse suivante : <a href="mailto:contact@blabliblou.fr" className="text-purple-600">contact@blabliblou.fr</a>.
                </p>
                <p className="mt-3 mb-3">
                    Un modèle de formulaire de rétractation est disponible à l’adresse suivante :<br /> 
                    <a href="https://entreprendre.service-public.fr/vosdroits/R38397" className="text-purple-600">
                        https://entreprendre.service-public.fr/vosdroits/R38397
                    </a>.
                </p>
                <p className="mt-3 mb-3">
                    Le délai court à compter du jour de l’abonnement.
                </p>
                <p className="mt-3 mb-3">
                    Lorsque le formulaire de rétractation est pris en compte, le remboursement de la totalité des sommes versées 
                    interviendra dans un délai de 14 jours.
                </p>
                <p className="mt-3 mb-3">
                    Les mêmes règles s’appliquent aux produits soldés.
                </p>

                <h4 className="mt-3 mb-3 text-lg font-extrabold underline underline-offset-3 decoration-8">
                    XII - DROIT APPLICABLE ET COMPETENCE JURIDICTIONNELLE
                </h4>
                <p className="mt-3 mb-3">
                    Les présentes Conditions Générales sont régies, interprétées et appliquées conformément au droit français. 
                    A défaut de règlement amiable, toute difficulté relative à l’interprétation ou à l’exécution du 
                    Contrat relèvera de la compétence exclusive des juridictions légalement compétentes.
                </p>
            </div>
        </div>
    );
}

export default CGV;