import React, {useEffect, useState} from "react";
import MessagerieClientsAdminN from "../components/Admin N/Messagerie/messagerieClientsAdminN";
import MessagerieAdminPAdminN from "../components/Admin N/Messagerie/messagerieAdminPAdminN";
import http from '../services/Http'
import { getItem } from "../services/LocaleStorage";
import {getRealUser, getUser} from "../app/user";
import { useSelector } from "react-redux";

const MessagesTableAdminN = () => {
    const [tab, setTab] = useState(1);
    const [messagerie, setMessagerie] = useState(true);
    const token = getItem('bbbToken')
    const frontUser = useSelector(getUser)
    const realUser = useSelector(getRealUser)

    // conversations
    const [conversationsStore, setConversations] = useState({
        loading: false,
        clientConversations: [],
        adminpConversations: [],
    })

    useEffect(() => {
        fetchConversations();
    }, []);

    const fetchConversations = () => {
        setConversations({...conversationsStore, loading: true})

        http.get('/conversation?include[]=last_message&include[]=receiver&include[]=sender&include[]=transaction&client_id=' + realUser.id, {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        })
        .then(response => {
            // Separate admin and client messages
            const clientConversations = [];
            const adminpConversations = [];

            response.forEach(item => {
                const correspondant = item.receiver.id !== frontUser.id ? item.receiver : item.sender
                item.correspondant = correspondant
                if(correspondant.role === 'client') {
                    clientConversations.push(item);
                } else {
                    adminpConversations.push(item);
                }
            });

            setConversations({
                loading: false,
                clientConversations,
                adminpConversations,
            });
        })
        .catch(() => {
            setConversations({...conversationsStore, loading: false});
        });
    }

    return (
        <div className="w-full content">
            <div className="flex w-full justify-around pt-[4.1rem]">
                <div className={`w-2/6 flex justify-center rounded-2xl py-2  mr-2 ${tab === 1 ? 'bg-lila text-white' : 'bg-gris text-lilaFonce'}`}>
                    <button onClick={() => setTab(1)}>Messagerie</button>
                </div>
                <div className={`w-2/6 flex justify-center rounded-2xl py-2 mr-2 ${tab === 2 ? 'bg-lila text-white' : 'bg-gris text-lilaFonce'}`}>
                    <button onClick={() => setTab(2)}>Obligations fiscales</button>
                </div>
                <div className={`w-2/6 flex justify-center rounded-2xl py-2 mr-2 ${tab === 3 ? 'bg-lila text-white' : 'bg-gris text-lilaFonce'}`}>
                    <button onClick={() => setTab(3)}>Gestion compte</button>
                </div>
            </div>

            {/*select messagerie AdminP/Client*/}
            <div className="flex w-full justify-around mt-8">
                <div className={`w-3/6 flex justify-center rounded-2xl py-2 mr-2 ${messagerie ? 'bg-lila text-white' : 'bg-gris text-lilaFonce'}`}>
                    <button onClick={() => setMessagerie(true)}>Messagerie <span className="uppercase">Client</span></button>
                </div>
                <div className={`w-3/6 flex justify-center rounded-2xl py-2 mr-2 ${!messagerie ? 'bg-lila text-white' : 'bg-gris text-lilaFonce'}`}>
                    <button onClick={() => setMessagerie(false)}>Messagerie <span className="uppercase">Admin p</span></button>
                </div>
            </div>
            {/*Ajouter tab 3 : gestionClient*/}
            {
                tab === 1 && messagerie ?
                <MessagerieClientsAdminN conversationsStore={ conversationsStore }/>
                : tab === 1 && messagerie === false ?
                <MessagerieAdminPAdminN conversationsStore={ conversationsStore }/>
                : ""
            }
        </div>
    )
}

export default MessagesTableAdminN