import {createSelector, createSlice} from "@reduxjs/toolkit";

export const filtersSlice = createSlice({
    name: 'filters',
    initialState: {
        dates: {
            dateStart: "",
            dateEnd: "",
        },
        bankAccount: null,
        bankAccounts: [],
        pageTitle: "",
        updater: false,
        optional:{
            searchTerms: "",
            minAmount: "",
            maxAmount: "",
        }
    },
    reducers: {
        setDateStart(state, action) {
            if (action.payload.validity) {
                state.dates.dateStart = action.payload.value
            } else {
                //Si la date n'est pas valide alors mettre à vide
                state.dates.dateStart = ""
            }
        },
        setDateEnd(state, action) {
            if (action.payload.validity) {
                state.dates.dateEnd = action.payload.value
            } else {
                //Si la date n'est pas valide alors mettre à vide
                state.dates.dateEnd = ""
            }
        },
        setMyBankAccount(state, action) {
            state.bankAccount = action.payload
        },
        //Tableau comptes bancaires
        setBankAccounts(state, action) {
            state.bankAccounts = action.payload
        },
        addBankInList(state, action) {
            if (!state.bankAccounts.some(ba => ba.id === action.payload.id)) {
                state.bankAccounts.push(action.payload)
                if (state.bankAccount === null) {
                    state.bankAccount = state.bankAccounts[0]
                }
            }
        },
        setPageTitle(state, action) {
            state.pageTitle = action.payload
        },
        updateDate(state, action) {
            state.updater = !state.updater
        },

        setTerms(state, action) {
            state.searchTerms = action.payload
        },
        setOptional(state, action) {
            state.optional = action.payload
        },
        clearOptional(state, action) {
            state.optional = {
                searchTerms: "",
                minAmount: "",
                maxAmount: "",
            }
        }
    },
})

export const {
    setDateStart,
    setDateEnd,
    setMyBankAccount,
    setBankAccounts,
    addBankInList,
    setPageTitle,
    updateDate,
    setTerms,
    setMinAmount,
    setMaxAmount,
    setOptional,
    clearOptional
} = filtersSlice.actions
export default filtersSlice.reducer

//Liste des dépendances utilisées par les createSelector ci-dessous.
const selectDateStart = state => state.filters.dates.dateStart
const selectDateEnd = state => state.filters.dates.dateEnd
const updater = state => state.filters.updater
// CreateSelector permet d'avoir un selector qui ne se rafraichi que si une ou plusieurs des ses dépandences changent.

/*
Retourne les dates choisies par l'utilisateur sous forme de tableau de deux Objets Date(). Le premier élément est la date de début et le deuxième la date de fin. Si une date n'est pas choisie, l'élément correspondant vaut null.
 */
export const getDateFilter = createSelector(selectDateStart, selectDateEnd, (dateStart, dateEnd) => {
    let start, end
    if (dateStart === "") {
        start = null
    } else {
        const startSplited = dateStart.split('-')
        start = new Date(startSplited[0], startSplited[1] - 1, startSplited[2])
    }

    if (dateEnd === "") {
        end = null
    } else {
        const endSplited = dateEnd.split('-')
        end = new Date(endSplited[0], endSplited[1] - 1, endSplited[2])
    }

    return [start, end]
})

/*
Retourne les dates choisies par l'utilisateur sous forme de tableau de deux strings de type "YYY-MM-JJ". Le premier élément est la date de début et le deuxième la date de fin. Si une date n'est pas choisie, l'élément correspondant vaut null.
 */
export const getDatesFilter = createSelector(selectDateStart, selectDateEnd, updater,(dateStart, dateEnd, updater) => {
    let start, end;
    if (dateStart == "") {
        const currentYear = new Date().getFullYear();
        start = new Date(currentYear, 0, 1);
    } else {
        start = new Date(dateStart);
    }

    if (dateEnd == "") {
        const currentYear = new Date().getFullYear();
        end = new Date(currentYear, 11, 31);
    } else {
        end = new Date(dateEnd);
    }

    start = start.getFullYear().toString() + "-" + (start.getMonth() + 1).toString().padStart(2, "0") + "-" + start.getDate().toString().padStart(2, "0");
    end = end.getFullYear().toString() + "-" + (end.getMonth() + 1).toString().padStart(2, "0") + "-" + end.getDate().toString().padStart(2, "0");

    return [start, end];
});

export function getBankAccount(state) {
    return state.filters.bankAccount
}

export function getFilters(state) {
    return state.filters
}

export function getBankAccounts(state) {
    return state.filters.bankAccounts
}
export function getSearchTerms(state) {
    return state.filters.searchTerms
}
export function getMinAmount(state) {
    return state.filters.minAmount
}
export function getMaxAmount(state) {
    return state.filters.maxAmount
}
export function getOptional(state) {
    // return an object that contain all optional properties that are not empty

    let optional = {}
    Object.entries(state.filters.optional).forEach(([key, value]) => {
        if (value !== "") {
            optional[key] = value
        }
    })
    return optional
}

export function getTrueOptional(state) {

    return state.filters.optional
}