import React from "react";
import {PulseLoader} from "react-spinners";
import CloseIcon from "@material-ui/icons/Close";

const PopupDelTransactions = ({closeModale, isLoadingComponent, delOp}) => {
    return (
        <div className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
            <div className="w-2/3 mx-auto bg-white rounded-lg shadow-xl p-8 z-100">
                <button className="float-right pt-0" onClick={closeModale}><CloseIcon/></button>
                <div className="parent-table w-full">
                    <div className="flex text-black font-semibold bg-grisClaire inline p-8 mt-8 text-xl rounded-xl">
                        <p className="flex flex-col">
                            <span>
                                <span className="font-bold">Attention : </span>
                                Vous allez supprimer les lignes d'opérations bancaires sélectionnées.
                            </span>
                            <span>Cette suppression est définitive. Toutes les affectations et messageries associées seront supprimées.</span>
                        </p>
                    </div>
                </div>
                <div className="float-right mt-8">
                    <button 
                        className="bg-greenSuccess text-white rounded-xl p-2" 
                        type="button" 
                        onClick={delOp}
                    >
                        {isLoadingComponent ? <PulseLoader color={'white'} size={10} /> : 'Confirmer'}
                    </button>
                </div>
            </div>
        </div>
    )
}
export default PopupDelTransactions