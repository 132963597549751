// StaticPublic.js
import React from "react";
import {ToastContainer} from "react-toastify";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

import CGV from '../pages/CGV';
import confidentialite from '../pages/confidentialite';
import "../assets/style/homepage.css";
import logo_public from '../assets/img/logo_public.jpeg'

const StaticPublic = () => {
    return (
        <div className="lg:flex h-screen md:flex">
            <ToastContainer autoClose={3000} pauseOnHover={false}/>

            {/* Left menu */}
            <div>
                {/* Logo */}
                <a href="/">
                    <img src={logo_public} className="text-center w-full h-full object-cover object-top"/>
                </a>
            </div>

            {/* Static pages */}
            <div className="w-full h-full" id="connect-page">
                <Router>
                    <Switch>
                        <Route exact path="/CGV" component={CGV} />
                        <Route exact path="/confidentialite" component={confidentialite} />
                    </Switch>
                </Router>
            </div>
        </div>
    );
}

export default StaticPublic;