import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, {useState} from "react";
import PopupMessagerieClientOperation from "./PopupMessagerieClientOperation";
import {PulseLoader} from "react-spinners";
import { useSelector } from "react-redux";
import { getRealUser } from "../../../app/user";

const MessagerieClientsAdminN = ({ conversationsStore }) => {
    const [openModale, setOpenModale] = useState(false);
    const [openedConversation, setOpenedConversation] = useState(null);
    const realUser = useSelector(getRealUser)

    const closeModal = () => {
        setOpenModale(false)
        setOpenedConversation(null)
    }
    const openModal = (conversation) => {
        setOpenedConversation(conversation)
        setOpenModale(true)
    }

    const formatDate = (value) => {
        const date = new Date(value)
        var formatedValue = (new Intl.DateTimeFormat('fr-FR', { dateStyle: 'short'}).format(date));
        return formatedValue
    }

    /**
     * Red If CONVERSATION.LAST_MESSAGE is sent by the client
     * Orange If CONVERSATION.LAST_MESSAGE is sent by the AdminN
     * Green If CONVERSATION.STATUT is manually closed by admin N
     * @param {Object} conversation 
     * @returns String
     */
    const conversationStatusClass = (conversation) => {
        if(conversation.status == 'closed') return 'green'
        
        if(conversation.last_message.sender_id == realUser.id && realUser.role != 'client') return 'orange'

        return 'red'
    }

    const renderData = () => {
        if(!conversationsStore.loading) return (
            <div className="table-list">
                <table className="overflow-hidden min-w-full">
                    <thead className="rounded-lg text-white">
                    <tr>
                        <th>Date</th>
                        <th>Messages</th>
                        <th>
                            <span>Statut</span>
                            <div className="colors">
                                <span className="green"></span>
                                <span className="orange"></span>
                                <span className="red"></span>
                            </div>
                        </th>
                        <th>Origine</th>
                        <th>Priorité</th>
                    </tr>
                    </thead>
                    <tbody>
                        { conversationsStore.clientConversations.map(conversation => 
                            <tr key={conversation.id} className="green" onClick={() => {
                                openModal(conversation)
                            }}>
                                <td>{formatDate(conversation.last_message.created_at)}</td>
                                <td>{conversation.last_message ? conversation.last_message.body : '--'}</td>
                                <td className="colors">
                                    <span className={conversationStatusClass(conversation)}/>
                                </td>
                                <td>{conversation.correspondant.display_name}</td>
                                <td>{conversation.status == 'closed' ? '' : conversation.priority }</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        )
        return ( <div className="text-center">
                <PulseLoader color={'purple'} size={10}/>                    
            </div>
        )
    }

    return (
        <section className="messages">
            <div className="list-msg relative">
                {
                    renderData()
                }
            </div>
            <div className="bottom">
                <div className="results">Affichage {conversationsStore.clientConversations.length} résultats sur {conversationsStore.clientConversations.length}</div>
                <div className="paginations">
                    <div className="left-arrows">
                        <a href="#">
                            <ChevronLeftIcon/>
                            <ChevronLeftIcon/>
                        </a>
                    </div>
                    <div className="number">
                        <a href="#" className="active">1</a>
                    </div>
                    <div className="right-arrows">
                        <a href="#">
                            <ChevronRightIcon/>
                            <ChevronRightIcon/>
                        </a>
                    </div>
                </div>
            </div>
            {openModale && <PopupMessagerieClientOperation
                    correspondant={openedConversation.correspondant}
                    operation={{...openedConversation.transaction, conversation: {id: openedConversation.id}}}
                    closeModal={closeModal}
                />}
        </section>

    )
}
export default MessagerieClientsAdminN