import React, {useRef, useEffect, useState} from 'react'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import triangleDown from "../../assets/img/triangleDown.png";
import triangleUp from "../../assets/img/triangleUp.png";
import HelpIcon from "@material-ui/icons/Help";
import Loader from '../../components/Loader';
import mailIcon from "../../assets/img/mail-icon.png";
import {DeleteOutlineOutlined, EditOutlined, Face} from "@material-ui/icons";
import BackupIcon from "@material-ui/icons/Backup";
import DescriptionIcon from "@material-ui/icons/Description";
import DeleteIcon from "@material-ui/icons/Delete";
import {getItem} from "../../services/LocaleStorage";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {getRealUser, getUser} from "../../app/user";
import PopupMessagerieClientOperation from "../Admin N/Messagerie/PopupMessagerieClientOperation";
import PopupDeleteRowFrais from "./PopupDeleteRowFrais";
import AffectAperc from "../client/affectAperc";
import {getBankAccount, getDateFilter} from "../../app/filters";
import Pagination from "../Pagination";
import LaunchOutlinedIcon from "@material-ui/icons/LaunchOutlined";
import PopupImportVente from "./PopupImportVente";
import PopupImportAchat from "./PopupImportAchat";
import {PulseLoader} from "react-spinners";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import {useDropzone} from "react-dropzone";
import {setLoaderSubscribe, setLoaderUnsubscribe} from "../../app/loader";
import http from "../../services/Http";
import PopupBlockTransactions from "../dashboard/PopupBlockTransactions";
import PopupConfirmUnblock from "../dashboard/PopupConfirmUnblock";
import PopupConfirmFecExport from "../dashboard/PopupConfirmFecExport";
import exportArrow from "../../assets/img/exportArrow.png";
import lock from "../../assets/img/lock.png";
import unlock from "../../assets/img/unlock.png";
import paiementSuccess from "../../assets/img/paiementSuccess.png";
import paiementPartially from "../../assets/img/paiementPartially.png";
import paiementDanger from "../../assets/img/paiementDanger.png";
import corbeille from "../../assets/img/corbeille.png";
import lockFilter from "../../assets/img/lockFilter.png";
import unlockFilter from "../../assets/img/unlockFilter.png";
import lockWhiteFilter from "../../assets/img/lockWhiteFilter.png"
import unlockWhiteFilter from "../../assets/img/unlockWhiteFilter.png"
import lockLibelle from "../../assets/img/lockLibelle.png";
import lockWhite from "../../assets/img/lockWhite.png";
import edit from "../../assets/img/edit.png";
import blockFilterBlack from "../../assets/img/blockFilterBlack.png";
import unblockFilterBlack from "../../assets/img/unblockFilterBlack.png";
import notPayed from "../../assets/img/notPayed.png";
import partiallypayed from "../../assets/img/partiallyPayed.png";
import formatCurrency from "../../utils/formatAmount";

const FraisTable = ({
    spents,
    loading,
    fetchSpents,
    badges,
    loadingComp,
    setSortTerms,
    setSortOrder,
    updateBoutonTableauFilters,
    buttonsTableFilters,
    displayBlockedRow,
    displayUnblockedRow,
    toggleDisplayBlockedRow,
    toggleDisplayUnblockedRow
}) => {
    let keyVal = 0

    const [loadingComponent, setLoadingComponent] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [nameFile, setNameFile] = useState('')
    const [idToSupp, setIdToSupp] = useState('')
    const [inputFile, setInputFile] = useState('')
    const frontUser = useSelector(getUser)
    const [msgModalOpen, setMsgModalOpen] = useState(false);
    const [fileInInput, setFileInInput] = useState(false);
    const [affectAchatModale, setAffectAchatModale] = useState(false);
    const [rowToAffect, setRowToAffect] = useState({});
    const [isLastRow, setIsLastRow] = useState(false);
    const dateFilters = useSelector(getDateFilter)
    const [files, setFiles] = useState([]);
    const [afficheBlockModale, setAfficheBlockModale] = useState(false);
    const [unblockAchatModale, setUnblockAchatModale] = useState(null);
    const realUser = useSelector(getRealUser)
    const token = getItem('bbbToken')
    const bankAccount = useSelector(getBankAccount)
    const [afficheFecExportModale, setAfficheFecExportModale] = useState(false);
    const [invoiceNotEditable, setInvoiceNotEditable] = useState([]);
    const [displayBlockAllButton, setDisplayBlockAllButton] = useState(true);
    const [toggleUseOcr, setToggleUseOcr] = useState(false);

    // console.log('SPENTS TOTALS', spents.totals);
    // const [totals, setTotals] = useState({
    //     credit: 0,//spents.totals.credit,
    //     debit: 0,//spents.totals.debit,
    //     vat: 0//spents.totals.vat
    // });

    // Function to scroll to and select a specific row
    const [highlightedRow, setHighlightedRow] = useState(null);
    const rowRefs = useRef({});
    const scrollToRow = (rowId) => {
        if (rowRefs.current[rowId]) {
            rowRefs.current[rowId].scrollIntoView({ behavior: "smooth", block: "center" });
            setHighlightedRow(rowId);
            setTimeout(() => setHighlightedRow(null), 2500); // Highlight fades after 2 seconds
        }
    };

    const dispatch = useDispatch()

    const [variables, setVariables] = useState({
        'remuneration_actuelle': null,
        'remuneration_estimee': null,
        'dette_sociale_actuelle': null,
        'dette_sociale_estimee': null,
        'dette_n-1': null,
    });

    const closeModal = () => {
        setMsgModalOpen(false)
    }
    const close = () => {
        setAffectAchatModale(false)
        setAfficheBlockModale(false)
        setUnblockAchatModale(null)
        setAfficheFecExportModale(false)
    }

    const closeModalDeleteRow = () => {
        setDeleteModalOpen(false)
    }
    const declenchInput = () => {
        document.getElementById('invoice-achat-input').click()
    }
    const removeAttachment = () => {
        document.getElementById('invoice-achat-input').value = null;
        setFileInInput(false);
    }

    const changeInputUpload = (e) => {
        let files;
        if (e.target.files) {
            files = e.target.files;
        }
        if (e.dataTransfer && e.dataTransfer.files) {
            files = e.dataTransfer.files;
        }

        let totalSize = 0;
        Array.from(files).forEach((file) => {
            totalSize += file.size;

            if ((file.size / 1024) / 1024 > 20) {
                toast.error('Un seul document ne doit pas dépasser 20MB');
                removeAttachment();
                return;
            }
        });

        const isAllowedSize = ((totalSize / 1024) / 1024 <= 128) && files.length <= 50;
        if (!isAllowedSize) {
            toast.error('Limite de 50 fichiers et 128MB en total est dépassée !');
            removeAttachment();
            return;
        }

        if (files.length == 1) {
            setFileInInput(true)
            let placeholder = document.getElementById('file-placeholder');
            const imgSrc = URL.createObjectURL(files[0]);
            const imgEmbed = `<embed className="object-cover h-full" src="${imgSrc}" alt="" id="balise-img"/>`;
            placeholder.innerHTML = imgEmbed;
        } else if (files.length > 1) {
            setFileInInput(true)
            let placeholder = document.getElementById('file-placeholder');
            placeholder.innerHTML = `<span>${files.length} fichiers séléctionnés</span>`;
        } else {
            setFileInInput(false)
        }
    }
    // Glisser - déposer file
    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        },
    });
    const dragNDrop = (e) => {
        document.getElementById('invoice-achat-input').files = e.dataTransfer.files;
        changeInputUpload(e);
    }

    const openAffectModale = (id) => {
        setRowToAffect(spents.data.find(elem => elem.id === id))
        setAffectAchatModale(true)
    }

    const checkAllBlockInvoices = () => {
        // tableau des opérations non bloquées pour les bloquer lors du clic sur "tous"
        const invoiceFiltered = spents.data.filter(el => el.is_not_editable !== true && el.sub_category !== null)
        setInvoiceNotEditable(invoiceFiltered)
    }

    const openMsgModale = (invoice) => {
        const id = invoice.id
        setRowToAffect(spents.data.find(elem => elem.id === id))
        setMsgModalOpen(true)
    }

    const uploadInvoiceAchat = async (e) => {
        const token = getItem('bbbToken')
        const form = new FormData(document.getElementById('send-achat-invoice'))
        form.append('client_id', frontUser.id)
        form.append('invoice_type', 'achat')
        form.append('use_ocr', toggleUseOcr)
        // TODO: comment n'est pas prévu en back
        // form.append('comment', "mon petit commentaire")

        if (fileInInput) {
            const toastId = toast.loading('Analyse est en cours, cette opération peut prendre quelques minutes...')
            setFileInInput(false)
            const response = await fetch(process.env.REACT_APP_API_URL + '/invoice/scan', {
                method: 'POST',
                requirements: token,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                body: form
            })
            if (response.ok) {
                toast.update(toastId, {
                    render: "L'ajout a été effectué avec succès",
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 3000
                })
                response.json().then((json) => {
                    //Permet de se retrouver sur le bon onglet après fermeture de la modale
                    fetchSpents(spents.per_page, spents.page)
                    document.getElementById("invoice-achat-input").value = null
                })
            } else {
                toast.update(toastId, {
                    render: "L'ajout a échoué !",
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 3000
                })
            }
        }
    }
    const toDeleteAchat = (id) => {
        setIdToSupp(id)
        setIsLastRow(!spents.data.some(el => el.id > id))
        setDeleteModalOpen(true)
    }

    //Chaque fois que la date change charger les variables complémentaire
    useEffect(() => {
        if (dateFilters[0] === null || dateFilters[1] === null) {
            return
        }

        Promise.all(Object.getOwnPropertyNames(variables)
            .map(prop => fetchComplementaryVariable(prop)))
            .then(responses => {
                let variaComp = {...variables}
                Object.getOwnPropertyNames(variables).forEach((propri, index) => {
                    variaComp[propri] = responses[index].length !== 0 ? responses[index][0] : null
                })
                setVariables(variaComp)
            })
    }, [dateFilters])

    //Charge les variables complémentaires
    const fetchComplementaryVariable = (prop) => {
        return new Promise((resolve, reject) => {
            const token = getItem('bbbToken')
            const periodFrom = dateFilters[0].getFullYear().toString() + "-01-01"
            const periodTo = dateFilters[0].getFullYear().toString() + "-12-31"

            let endpoint = process.env.REACT_APP_API_URL + '/user/' + frontUser.id + '/get-complementary-variable/' + prop + '?period_from=' + periodFrom + '&period_to=' + periodTo

            fetch(endpoint, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then((data) => {
                data.json().then(json => resolve(json))
            })
            .catch((error) => {
                // TODO: catch update
                reject(error.message)
            })
        })
    }

    //Envois des variables complémentaires
    const sendVariable = (e) => {
        if (dateFilters[0] === null || dateFilters[1] === null || dateFilters[0].getFullYear() !== dateFilters[1].getFullYear()) {
            toast.info('Veuillez saisir deux dates dans le menu de gauche d\'un ans d\'exercice.')
            return
        }
        let dateStart = dateFilters[0].getFullYear().toString() + "-01-01"

        let dateEnd = dateFilters[1].getFullYear().toString() + "-12-31"

        const token = getItem('bbbToken')

        const form = new FormData()
        form.append('period_from', dateStart)
        form.append('period_to', dateEnd)
        form.append('name', e.target.name.toString())
        form.append('value', e.target.value.toString())

        fetch(process.env.REACT_APP_API_URL + '/user/' + frontUser.id.toString() + '/set-complementary-variable', {
            method: 'POST',
            requirements: token,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: form
        })
        .then((response) => {
            if (response.ok) {
                response.json().then((json) => {
                    toast.success('Ajout de la variable complémentaire')
                })
            } else {
                toast.error('L\'ajout de la variable complémentaire a échoué.')
            }
        })
    }

    const blockAchatChecked = () => {
        setLoadingComponent(true);
        dispatch(setLoaderSubscribe())
        fetch(process.env.REACT_APP_API_URL + '/invoices/block', {
            method: 'POST', 
            'Access-Control-Allow-Credentials': true, 
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` 
            }, 
            body: JSON.stringify({invoices_id: invoiceNotEditable.map(el => el.id)})
        })
        .then(async (data) => {
            setLoadingComponent(false);
            dispatch(setLoaderUnsubscribe())
            const response = await data.json()
            if (data.ok) {
                fetchSpents()
                toast.success("Le blocage des factures d'achat a été effectué avec succès.")
            }else{
                toast.error(response.message ?? "Le blocage des factures d'achat a échoué.")
            }
            setInvoiceNotEditable([])
        })
    }

    const ifAchatBlockedUnchecked = (invoice) => {
        if (!invoice.is_not_editable ){
            setInvoiceNotEditable([invoice])
        } else {
            setUnblockAchatModale(invoice)
        }
    }

    useEffect(() => {
        if (invoiceNotEditable.length > 0) {
            setAfficheBlockModale(true)
        } else {
            setAfficheBlockModale(false)
        }
    }, [invoiceNotEditable]);

    const unblockinvoice = () => {
        setLoadingComponent(true);
        fetch(process.env.REACT_APP_API_URL + '/invoice/unblock', {
            method: 'POST', 
            'Access-Control-Allow-Credentials': true, 
            headers: {
                'Authorization': 'Bearer ' + token, 
                'Content-Type': "application/json"
            }, 
            body: JSON.stringify({id: unblockAchatModale.id})
        })
        .then((data) => {
            setLoadingComponent(false);
            dispatch(setLoaderUnsubscribe())
            if (data.ok) {
                fetchSpents()
                toast.success("La facture d'achat a été débloquée avec succès.")
            } else {
                toast.error("La facture d'achat n'a pas été débloquée.")
            }

            setUnblockAchatModale(null)
        })
    }

    const ifAchatFecChecked = () => {
        setAfficheFecExportModale(true)
    }

    useEffect(() => {
        spents.data.some(el => !el.is_not_editable && el.sub_category !== null) ? setDisplayBlockAllButton(true) : setDisplayBlockAllButton(false)
    }, [spents]);

	const displaySubCategoryName = (invoice) => {
		let subCategoryName = '';

		if (invoice?.sub_category?.display_name) {
			subCategoryName = invoice?.sub_category?.display_name;
		} else {
			// return <HelpIcon fontSize="small" />;
			return null;
		}

		if (invoice?.is_sub_categ_suggested) {
			return <span style={{ color: '#FD5353' }}>{subCategoryName} <HelpIcon fontSize="small" /></span>
		} else {
			return <span style={{ color: '#818181' }}>{subCategoryName}</span>
		}
	}

    const styles = {
		subCategoryName: {
			fontSize: '14px',
			fontWeight: '600',
		},
		invoiceLabel: {
			fontSize: '16px',
			fontWeight: '600',
		}
	}

    return (
        <React.Fragment>
            <div className="w-full flex py-20">
                <div className="flex flex-col w-3/6" id="variables">
                    <div className="w-full flex text-black">
                        <div className="w-10/12 flex">
                            <div className="w-3/6">
                                <div className="py-1 mb-3 font-semibold "><p>Rémunération estimée</p></div>
                                <div className="py-1 font-semibold"><p>Rémunération actuelle</p></div>
                            </div>
                            <div className="flex-col w-3/6">
                                <div className="mb-3 h-8">
                                    <input 
                                        type="text" 
                                        defaultValue={variables.remuneration_estimee?.value ?? ''}
                                        className="rounded-lg py-1 bg-gris w-full text-lilaFonce text-center"
                                        name="remuneration_estimee" 
                                        onBlur={(e) => sendVariable(e)}
                                    />
                                </div>
                                <div className="h-8">
                                    <input 
                                        type="text" 
                                        defaultValue={variables.remuneration_actuelle?.value ?? ''}
                                        className="rounded-lg py-1 bg-gris w-full text-lilaFonce text-center"
                                        name="remuneration_actuelle" 
                                        onBlur={(e) => sendVariable(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex text-black mt-8 pt-8">
                        <div className="w-10/12 flex">
                            <div className="w-3/6">
                                <div className="py-1 mb-3 font-semibold"><p>Dette sociale estimée</p></div>
                                <div className="py-1 mb-3 font-semibold"><p>Dette sociale actuelle</p></div>
                                <div className="py-1 font-semibold"><p>Dette n-1</p></div>
                            </div>
                            <div className="flex-col w-3/6">
                                <div className="mb-3 h-8">
                                    <input 
                                        type="text" 
                                        defaultValue={variables.dette_sociale_estimee?.value ?? ''}
                                        className="rounded-lg py-1 bg-gris w-full text-lilaFonce text-center"
                                        name="dette_sociale_estimee" 
                                        onBlur={(e) => sendVariable(e)}
                                    />
                                </div>
                                <div className="mb-3 h-8">
                                    <input type="text" 
                                        defaultValue={variables.dette_sociale_actuelle?.value ?? ''}
                                        className="rounded-lg py-1 bg-gris w-full text-lilaFonce text-center"
                                        name="dette_sociale_actuelle" 
                                        onBlur={(e) => sendVariable(e)}
                                    />
                                </div>
                                <div className="h-8">
                                    <input 
                                        type="text" 
                                        defaultValue={variables["dette_n-1"]?.value ?? ''}
                                        className="rounded-lg py-1 bg-gris w-full text-lilaFonce text-center"
                                        name="dette_n-1" 
                                        onBlur={(e) => sendVariable(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-3/6 flex justify-center">
                    <div className="vente-upload">
                        <div className="w-full rounded-lg items-center mb-8">
                            <div className="w-upload text-black h-full mx-auto bg-gris rounded-xl">
                                <h3 className="text-center mb-0 p-2 font-bold w-full">Joindre un fichier</h3>
                                <div className="bg-gris vente-upload w-full rounded-lg flex justify-center items-center ">
                                    <div
                                        className={`flex justify-center items-center ${fileInInput ? "" : " border-dashed border-2 border-grisDashed "} vente-upload charge-img rounded-xl mx-auto`}
                                        onClick={declenchInput} 
                                        onDrop={(e) => dragNDrop(e)}
                                    >
                                        <div id="file-placeholder" className={!fileInInput ? "hidden" : ""}></div>
                                        <div className={fileInInput ? "hidden" : "flex flex-col justify-center text-center"}>
                                            <div>
                                                <button type="button" className="svg-upload"><BackupIcon/></button>
                                            </div>
                                            <div className="text" title="Importer une facture d'achat">
                                                <form id="send-achat-invoice">
                                                    <input 
                                                        type="file" 
                                                        name="receipt[]" 
                                                        id="invoice-achat-input"
                                                        multiple="multiple"
                                                        className="hidden" 
                                                        onChange={(e) => changeInputUpload(e)}
                                                    />
                                                </form>
                                                <h5 className="font-semibold">Charger un fichier</h5>
                                                <h6>PDF, DOC, PPT, JPG, PNG</h6>
                                                <span>{nameFile}</span>

                                                <label className="relative my-2 inline-flex items-center cursor-pointer" onClick={(e)=> {e.stopPropagation()}}>
                                                    <input type="checkbox" checked={toggleUseOcr} className="sr-only peer shadow-inner" onChange={()=> {setToggleUseOcr(!toggleUseOcr)}}/>
                                                    <div
                                                        className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:shadow-inner rounded-full peer dark:bg-[#EFEFEF] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#5A45AA]"></div>
                                                    <span className="ms-3 text-sm font-medium text-[#5A45AA]">OCR</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <button type="button" disabled={!fileInInput}
                                    className={`w-full p-2 bg-greenSuccess rounded-xl ${fileInInput ? "" : "bg-opacity-50 cursor-not-allowed"} text-white flex items-center justify-center mr-2`}
                                    onClick={(e) => uploadInvoiceAchat(e)}>
                                <DescriptionIcon/>
                                <span className="mx-auto px-6 ">Envoyer</span>
                            </button>

                            <button disabled={!fileInInput} type="button"
                                    className={`w-full p-2 bg-redCorail rounded-xl ${fileInInput ? "" : "bg-opacity-50 cursor-not-allowed"} text-white flex items-center justify-center`}
                                    onClick={removeAttachment}>
                                <DeleteIcon/>
                                <span className="mx-auto px-6">Supprimer</span>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <Loader active={loading}/>
            {affectAchatModale &&
                <AffectAperc 
                    rowToAffect={rowToAffect} 
                    scrollToRow={() => scrollToRow(rowToAffect.id)}
                    close={close} 
                    fetchResources={() => fetchSpents(spents.per_page, spents.page)}
                    isLastInvoice={!spents.data.some(el => el.id > rowToAffect.id)}
                />
            }
                <div className="overflow-auto rounded-lg w-full">
                    <table className="w-full documents-table" style={{ tableLayout: 'fixed' }}>
                        <thead className="bgPurpuleCustom text-white">
                            <tr>
                                <th className="p-4 text-sm font-semibold tracking-wide text-left">
                                    <div className="flex flex-col justify-start whitespace-nowrap">
                                        <div className="flex items-center tracking-wide whitespace-nowrap" style={{ width: '90px' }}>
                                            <span>Date</span>
                                            <img 
                                                src={triangleDown} 
                                                alt="Triangle vers le bas" 
                                                className="mx-1 w-3.5 h-3.5 cursor-pointer" 
                                                onClick={() => {
                                                    setSortTerms("operation_date"); 
                                                    setSortOrder("DESC");
                                                }}
                                            /> 
                                            <img 
                                                src={triangleUp} 
                                                alt="Triangle vers le haut" 
                                                className="w-3.5 h-3.5 cursor-pointer" 
                                                onClick={() => {
                                                    setSortTerms("operation_date"); 
                                                    setSortOrder("ASC");
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <span className="invisible">invisible</span>
                                </th>
                                <th className="p-4 text-sm font-semibold tracking-wide text-left" style={{ width: '450px' }}>
                                    <div className="flex flex-col justify-start whitespace-nowrap">
                                        <span>Libellé des documents</span>
                                        <div className="flex">
                                            <span className={`px-1.5 py-1 text-base rounded-full mr-2 cursor-pointer bg-white `} onClick={() => {
                                                toggleDisplayBlockedRow()
                                            }}><img src={displayBlockedRow ? blockFilterBlack : lockFilter} alt="lock"/></span>

                                            <span className={`px-1.5 py-1 text-base rounded-full cursor-pointer bg-white`} onClick={() => {
                                                toggleDisplayUnblockedRow()
                                            }}><img src={displayUnblockedRow ? unblockFilterBlack : unlockFilter} alt="unlock"/></span>
                                        </div>
                                    </div>
                                </th>
                                <th className="p-4 text-sm font-semibold tracking-wide text-left" style={{ width: '80px' }}>
                                    <div className="flex flex-col justify-start">
                                        <span></span>
                                        <span className="invisible">invisible</span>
                                    </div>
                                </th>
                                <th className="p-4 text-sm font-semibold tracking-wide text-left">
                                    <div className="flex flex-col justify-start whitespace-nowrap">
                                        <div className="flex items-center tracking-wide whitespace-nowrap">
                                            <span>Mont. HT</span>
                                            <img 
                                                src={triangleDown} 
                                                alt="Triangle vers le bas" 
                                                className="mx-1 w-3.5 h-3.5 cursor-pointer" 
                                                onClick={() => {
                                                    setSortTerms("amount"); 
                                                    setSortOrder("DESC");
                                                }}
                                            /> 
                                            <img 
                                                src={triangleUp} 
                                                alt="Triangle vers le haut" 
                                                className="w-3.5 h-3.5 cursor-pointer" 
                                                onClick={() => {
                                                    setSortTerms("amount"); 
                                                    setSortOrder("ASC");
                                                }}
                                            />
                                        </div>
                                        <p className="whitespace-nowrap">
                                            <span className="inline-flex items-center justify-center h-6 w-6 text-sm rounded-full text-lilaFonce bg-white mr-2">-</span>
                                            <span>{formatCurrency(spents?.totals?.debit - spents?.totals?.vat)}</span>
                                        </p>
                                    </div>
                                    <span className="invisible">invisible</span>
                                </th>
                                <th className="p-4 text-sm font-semibold tracking-wide text-left">
                                    <div className="flex flex-col justify-start whitespace-nowrap">
                                        <div className="flex items-center tracking-wide whitespace-nowrap">
                                            <span>TVA</span>
                                            <img 
                                                src={triangleDown} 
                                                alt="Triangle vers le bas" 
                                                className="mx-1 w-3.5 h-3.5 cursor-pointer" 
                                                onClick={() => {
                                                    setSortTerms("vat"); 
                                                    setSortOrder("DESC");
                                                }}
                                            /> 
                                            <img 
                                                src={triangleUp} 
                                                alt="Triangle vers le haut" 
                                                className="w-3.5 h-3.5 cursor-pointer" 
                                                onClick={() => {
                                                    setSortTerms("vat"); 
                                                    setSortOrder("ASC");
                                                }}
                                            />
                                        </div>
                                        <p className="whitespace-nowrap">
                                            <span className="inline-flex items-center justify-center h-6 w-6 text-sm text-lilaFonce bg-white rounded-full mr-2">+</span>
                                            <span>{formatCurrency(spents?.totals?.vat)}</span>
                                        </p>
                                    </div>
                                    <span className="invisible">invisible</span>
                                </th>
                                <th className="p-4 text-sm font-semibold tracking-wide text-left">
                                    <div className="flex flex-col justify-start whitespace-nowrap">
                                        <div className="flex items-center tracking-wide whitespace-nowrap">
                                            <span>Mont. TTC</span>
                                            <img 
                                                src={triangleDown} 
                                                alt="Triangle vers le bas" 
                                                className="mx-1 w-3.5 h-3.5 cursor-pointer" 
                                                onClick={() => {
                                                    setSortTerms("amountWithVat"); 
                                                    setSortOrder("DESC");
                                                }}
                                            /> 
                                            <img 
                                                src={triangleUp} 
                                                alt="Triangle vers le haut" 
                                                className="w-3.5 h-3.5 cursor-pointer" 
                                                onClick={() => {
                                                    setSortTerms("amountWithVat"); 
                                                    setSortOrder("ASC");
                                                }}
                                            />
                                        </div>
                                        <p className="whitespace-nowrap">
                                            <span className="inline-flex items-center justify-center h-6 w-6 text-sm rounded-full text-lilaFonce bg-white mr-2">-</span>
                                            <span>{formatCurrency(spents?.totals?.debit)}</span>
                                        </p>
                                    </div>
                                    <span className="invisible">invisible</span>
                                </th>
                                <th className="p-4 text-sm font-semibold tracking-wide text-center">
                                    <div className="flex flex-col justify-start">
                                        <div>Pièces</div>
                                        <div className="flex justify-center gap-2 mt-2">
                                            <div className={`text-xs rounded-full ${!buttonsTableFilters.receipt.includes("with") ? 'text-white ' : 'text-vertBouton' }`}>
                                                <span onClick={() => updateBoutonTableauFilters('receipt', 'with')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-vertBouton ${!buttonsTableFilters.receipt.includes("with") ? 'bg-white text-vertBouton' : 'text-white bg-vertBouton' }`}>{badges.receipt?.with ?? "--"}</span>
                                            </div>
                                            <div className={`text-xs rounded-full ${!buttonsTableFilters.receipt.includes("to_be_modified") ? 'text-white ' : 'text-orange-500' }`}>
                                                <span onClick={() => updateBoutonTableauFilters('receipt', 'to_be_modified')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-orange-500 ${!buttonsTableFilters.receipt.includes("to_be_modified") ? 'bg-white text-orange-500' : 'text-white bg-orange-500' }`}>{badges.receipt?.to_be_modified ?? "--"}</span>
                                            </div>
                                            <div className={`text-xs rounded-full ${!buttonsTableFilters.receipt.includes("without") ? 'text-white ' : 'text-orange-500' }`}>
                                                <span onClick={() => updateBoutonTableauFilters('receipt', 'without')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-redCorail ${!buttonsTableFilters.receipt.includes("without") ? 'bg-white text-redCorail' : 'text-white bg-redCorail' }`}>{badges.receipt?.without ?? "--"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </th>
                                <th className="p-4 text-sm font-semibold tracking-wide text-center">
                                    <div className="flex flex-col">
                                        <div>Statut</div>
                                        <div className="flex justify-center gap-2 mt-2">
                                            <div className={`text-xs rounded-full ${!buttonsTableFilters.affectation.includes("with") ? 'text-white ' : 'text-vertBouton' }`}>
                                                <span onClick={() => updateBoutonTableauFilters('affectation', 'with')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-vertBouton ${!buttonsTableFilters.affectation.includes("with") ? 'bg-white text-vertBouton' : 'text-white bg-vertBouton' }`}>{badges.affectation?.with ?? "--"}</span>
                                            </div>
                                            <div className={`text-xs rounded-full ${!buttonsTableFilters.affectation.includes("to_be_modified") ? 'text-white ' : 'text-orange-500' }`}>
                                                <span onClick={() => updateBoutonTableauFilters('affectation', 'to_be_modified')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-orange-500 ${!buttonsTableFilters.affectation.includes("to_be_modified") ? 'bg-white text-orange-500' : 'text-white bg-orange-500' }`}>{badges.to_be_modified?.with ?? "--"}</span>
                                            </div>
                                            <div className={`text-xs rounded-full ${!buttonsTableFilters.affectation.includes("without") ? 'text-white ' : 'text-orange-500' }`}>
                                                <span onClick={() => updateBoutonTableauFilters('affectation', 'without')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-redCorail ${!buttonsTableFilters.affectation.includes("without") ? 'bg-white text-redCorail' : 'text-white bg-redCorail' }`}>{badges.affectation?.without ?? "--"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </th>
                                <th className="p-4 text-sm font-semibold tracking-wide text-center">
                                    <div className="flex flex-col">
                                        <div>Paiements</div>
                                        <div className="flex justify-center gap-2 mt-2">
                                            <div className={`text-xs rounded-full ${!buttonsTableFilters.payment.includes("fully") ? 'text-white ' : 'text-vertBouton' }`}>
                                                <span onClick={() => updateBoutonTableauFilters('payment', 'fully')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-vertBouton ${!buttonsTableFilters.payment.includes("fully") ? 'bg-white text-vertBouton' : 'text-white bg-vertBouton' }`}>{badges.payment?.fully ?? "--"}</span>
                                            </div>
                                            <div className={`text-xs rounded-full ${!buttonsTableFilters.payment.includes("partially") ? 'text-white ' : 'text-orange-500' }`}>
                                                <span onClick={() => updateBoutonTableauFilters('payment', 'partially')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-orange-500 ${!buttonsTableFilters.payment.includes("partially") ? 'bg-white text-orange-500' : 'text-white bg-orange-500' }`}>{badges.payment?.partially ?? "--"}</span>
                                            </div>
                                            <div className={`text-xs rounded-full ${!buttonsTableFilters.payment.includes("zero") ? 'text-white ' : 'text-orange-500' }`}>
                                                <span onClick={() => updateBoutonTableauFilters('payment', 'zero')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-redCorail ${!buttonsTableFilters.payment.includes("zero") ? 'bg-white text-redCorail' : 'text-white bg-redCorail' }`}>{badges.payment?.zero ?? "--"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </th>

                                {realUser.role !== 'client' ? 
                                    <th className="p-4 text-sm font-semibold text-black bg-white whitespace-nowrap">
                                        <div className="flex justify-center items-center px-4 cursor-pointer" onClick={ifAchatFecChecked}>
                                            <img src={exportArrow} className="exportFec" alt=""/>
                                            <span className="font-semibold text-[#9D9D9D] text-[14px] sidebar:text-[20px] ml-2">Export FEC</span>
                                        </div>
                                    </th> 
                                :
                                    <th></th>
                                }
                            </tr>
                        </thead>
                        {loadingComp ?
                            <tbody>
                                <tr>
                                    <td className="p-0" colSpan="3">
                                        <PulseLoader color={'purple'} size={10} />
                                    </td>
                                </tr>
                            </tbody> 
                        :
                            spents === null || spents.data?.length === 0 ?
                                <tbody>
                                    <tr>
                                        <td className="p-4" colSpan="3">Vous n'avez aucune facture d'achat</td>
                                    </tr>
                                </tbody> 
                            :
                                <tbody>
                                    {spents?.data.map((invoice, index) => {
                                        return (
                                            <tr 
                                                key={invoice.id} 
                                                ref={(el) => (rowRefs.current[invoice.id] = el)}
                                                className={`${ realUser.role === "admin_n" || realUser.role === "admin_p" ? "except-last-td" : ""} ${index % 2 === 0 ? "bg-grisbleu" : ""} ${highlightedRow == invoice.id ? "highlighted-row" : ""}`}
                                            >
                                                <td className="p-3 border-b border-gray-200 text-sm text-gray-700 whitespace-nowrap">{invoice.displayable_operation_date}</td>
                                                <td className="p-3 border-b border-gray-200 text-sm text-gray-700 whitespace-nowrap" title={invoice.label + ' ref ' + invoice.reference} style={{ wordBreak: 'break-all', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                                    <div className="flex flex-col">
                                                        <span style={styles.subCategoryName}>{displaySubCategoryName(invoice)}</span>
                                                        <div className="flex">
                                                            {invoice.is_not_editable ? <img src={lockLibelle} alt="locked" className="w-[12px] h-[15px] mr-2"/> : null}
                                                            <span className={`mr-1 ${invoice.label === null ? "text-redCorail" : ""}`} style={styles.invoiceLabel}>
                                                                {invoice.label ?? "???"}
                                                                <br /><span className={`${invoice.reference === null ? "text-redCorail" : ""}`}>{ invoice.reference ? `ref ${invoice.reference}` : "???" }</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="p-3 border-b border-gray-200 text-sm text-gray-700 whitespace-nowrap text-center" onClick={() => openMsgModale(invoice)}>
                                                    <img src={mailIcon} className="img-mailround" alt="message"/>
                                                </td>
                                                <td className={`${invoice.amount === null ? "text-redCorail" : "text-gray-700"} p-3 border-b border-gray-200 text-sm whitespace-nowrap`}>{invoice.amount ?? "???"}</td>
                                                <td className="p-3 border-b border-gray-200 text-sm text-gray-700 whitespace-nowrap"> <span className={`${invoice.vat_value === null ? "text-redCorail" : "text-black"} mr-2`}>{ invoice.vat_value ?? "???" }</span> <span>{invoice.vat ? invoice.vat + "%" : ""}</span></td>
                                                <td className={`${invoice.amount_with_vat === null ? "text-redCorail" : "text-gray-700"} p-3 border-b border-gray-200 text-sm whitespace-nowrap`}>{invoice.amount_with_vat ?? "???"}</td>
                                                <td className="p-3 border-b border-gray-200 text-sm text-gray-700 whitespace-nowrap">
                                                    <div className="flex justify-center">
                                                        {invoice.receipt_path !== null ?
                                                            <a href={process.env.REACT_APP_STORAGE_URL + invoice.receipt_path} target="_blank" rel="noreferrer">
                                                                <div className="relative p-0">
                                                                    <AttachFileIcon className="fill-black"/>
                                                                    <span className={`${invoice.receipt_path === null ? "bg-redCorail" : "bg-greenSuccess"} top-icon-rounded`}></span>
                                                                </div>
                                                            </a>
                                                        : 
                                                            <div className="relative p-0">
                                                                <button type="button">
                                                                    <AttachFileIcon className="fill-black"/>
                                                                </button>
                                                            </div>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="p-3 border-b border-gray-200 text-sm text-gray-700 whitespace-nowrap">
                                                    <div className="flex justify-center">
                                                        {
                                                            // invoice.is_not_editable && realUser.role === "client" ? null :
                                                            //         <>
                                                            //             {invoice.affectation_status ? <button className="bg-greenSuccess rounded-full p-2" onClick={() => openAffectModale(invoice.id)}>
                                                            //                     <EditOutlined
                                                            //                         className="text-white"/></button>
                                                            //                 : <button className="bg-redCorail rounded-lg px-6 py-2 text-white" onClick={() => openAffectModale(invoice.id)}>Associer</button>}
                                                            //         </>

                                                            invoice.affectation_status ?
                                                                invoice.is_not_editable ?
                                                                    <div className={`rounded-full flex items-center justify-center w-11 h-11 ${invoice.is_reported ? "bg-orange-500" : "bg-greenSuccess"}`}>
                                                                        <img src={lockWhite} alt="lock"/>
                                                                    </div>

                                                                : 
                                                                    <button className={`rounded-full flex items-center justify-center p-3 ${invoice.is_reported ? "bg-orange-500" : "bg-greenSuccess"}`} onClick={() => openAffectModale(invoice.id)}>
                                                                        <img src={edit} alt="edit"/>
                                                                    </button>

                                                                : 
                                                                    invoice.is_not_editable ?
                                                                        <div className={`rounded-full flex items-center justify-center w-11 h-11 bg-redCorail`}>
                                                                            <img src={lockWhite} alt="lock"/>
                                                                        </div>
                                                                    : 
                                                                        <button className="bg-redCorail rounded-xl px-6 py-2 text-white" onClick={() => openAffectModale(invoice.id)}>Associer</button>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="p-3 border-b border-gray-200 text-sm text-gray-700 whitespace-nowrap">
                                                    <div className="relative group cursor-pointer flex justify-center">
                                                        {invoice.payment?.payed.length === 0 ? 
                                                            null 
                                                        :
                                                            <div className="absolute border border-gray-200 bg-white rounded-lg hidden group-hover:block z-10 top-4 right-8 translate-x-full -translate-y-full p-3 ">
                                                                <div className="flex flex-col justify-center h-full">
                                                                    {invoice.payment.payed.map((elem, i) => {
                                                                        return  (
                                                                            <p className="flex justify-between" key={i}>
                                                                                <span className="text-gray-200 mr-8">{elem.date}</span>
                                                                                <span className="text-black font-bold"> {elem.amount} €</span>
                                                                            </p>
                                                                        )
                                                                    })}
                                                                    <p className="text-black font-semibold text-center pt-6">Restant dû <span className="text-redCorail font-bold">{invoice.payment.not_payed} €</span></p>
                                                                </div>
                                                            </div>
                                                        }
                                                        {invoice.amount_with_vat === null || invoice.amount_with_vat == invoice.payment?.not_payed ? 
                                                            <img src={notPayed} alt="paiement-status"/> 
                                                        : 
                                                            invoice.payment?.not_payed == 0 ? 
                                                                <img src={paiementSuccess} alt="paiement-status"/> 
                                                            :
                                                                <img src={partiallypayed} alt="paiement-status"/>
                                                        }
                                                    </div>
                                                </td>

                                                {realUser.role !== 'client' ? 
                                                    <>
                                                        <td className="h-full p-0">
                                                            <div className="flex items-center justify-center h-12 ">
                                                                {invoice.is_not_editable ?
                                                                    <>
                                                                        <div className="p-2 w-12 h-12 cursor-pointer" onClick={() => ifAchatBlockedUnchecked(invoice)} data-invoice-id={invoice.id}>
                                                                            <img src={lock} alt="lock"/>
                                                                        </div>
                                                                        <div className="p-2 w-12 h-12"></div>
                                                                    </>
                                                                : 
                                                                    <>
                                                                        <div className="p-2 w-12 h-12 cursor-pointer" onClick={() => ifAchatBlockedUnchecked(invoice)} data-invoice-id={invoice.id}>
                                                                            <img src={unlock} alt="lock"/>
                                                                        </div>
                                                                        <div className="p-2 w-12 h-12">
                                                                            <button className="bg-[#D7D7D7] w-[32px] h-[32px] text-white p-[7px] rounded-lg flex justify-center items-center" onClick={() => toDeleteAchat(invoice.id)}>
                                                                                <DeleteOutlineOutlined/>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </td>
                                                    </> 
                                                :   
                                                    <td className="border-b border-gray-200 text-sm text-gray-700 whitespace-nowrap">
                                                        <button className="p-3 bg-[#C4C4C4]  rounded-lg text-white" onClick={() => toDeleteAchat(invoice.id)}>
                                                            <img src={corbeille} alt="delete" className="max-w-[19px] w-[19px] h-[19px] "/>
                                                        </button>
                                                    </td>
                                                }
                                            </tr>
                                        )
                                    })}
                                    {displayBlockAllButton && realUser.role !== "client" ? 
                                        <tr>
                                            <td colSpan={9}></td>
                                            <td>
                                                <div className="flex justify-center mt-4">
                                                    <button><span className="text-[#B3B3B3] underline font-semibold" onClick={checkAllBlockInvoices}>Tous...</span></button>
                                                </div>
                                            </td>
                                        </tr> 
                                    : 
                                        null
                                    }
                                </tbody>
                            }
                    </table>
                </div>

                {msgModalOpen && <PopupMessagerieClientOperation closeModal={closeModal} operation={rowToAffect}/>}
                {deleteModalOpen && <PopupDeleteRowFrais close={closeModalDeleteRow} fetchSpents={() => fetchSpents(spents.per_page, spents.page)} idToSupp={idToSupp} isLastInvoice={isLastRow}/>}
                {afficheBlockModale && <PopupBlockTransactions closeModale={close} isLoadingComponent={loadingComponent} block={blockAchatChecked} info="achat"/>}
                {unblockAchatModale && <PopupConfirmUnblock closeModale={close} isLoadingComponent={loadingComponent} unblock={unblockinvoice} info="achat"/>}
                {afficheFecExportModale && <PopupConfirmFecExport closeModale={close}  type="achat"/>}

            {/*// </section>*/}
            <Pagination data={spents} paginate={fetchSpents}/>
        </React.Fragment>
    )
}

export default FraisTable