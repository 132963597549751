import React from "react";
import {getItem} from "../../services/LocaleStorage";
import {toast} from "react-toastify";
import BackupIcon from "@material-ui/icons/Backup";
import {getUser} from "../../app/user";
import {useSelector} from "react-redux";
import CloseIcon from "@material-ui/icons/Close";

const PopupImportAchat = ({close, fetchSpents}) => {
    const frontUser = useSelector(getUser)
    const declenchInput = () => {
        document.getElementById('declenche-input').click()
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const token = getItem('bbbToken')
        const authUrl = process.env.REACT_APP_API_URL + '/invoice/import'
        const formdata = new FormData(document.getElementById('send-import-facture-achat'));
        formdata.append('client_id', frontUser.id)
        formdata.append('type', 'achat')
        formdata.append('vat', '20')
        formdata.append('amount_with_vat', null)
        formdata.append('label', 'SERV BANCAIRES1')
        formdata.append('operation_date', new Date().toISOString().split('T')[0])
        //Ecrase les ancienne factures par les nouvelles ! Faire la condition d'écrasement.
        formdata.append('override', 'true')


        fetch(authUrl, {
                method: 'POST',
                body: formdata,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            },
        ).then(response => response.json())
            .then(res => {
                if (res.status === "Created") {
                    toast.success('Le document a été ajouté avec succès')
                                fetchSpents()
                                close()
                }
            })
            .catch(err => {
                toast.error('Erreur lors de l\'ajout du document')
                        close()
            })
    }
    return (

        <div className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
            <div className="w-2/3 mx-auto overflow-y-scroll max-h-96 2xl:max-h-full 2xl:overflow-y-hidden bg-white rounded-lg shadow-xl p-8 z-100 relative">
                <button className="pr-2 absolute right-0" onClick={close}><CloseIcon/></button>
                <div className="w-full flex flex-col h-full">
                    <div className="h-1/6">
                        <h1 className="text-black font-semibold text-2xl">Importation des factures d'achats</h1>
                        <p className="flex flex-col">
                            <span className="font-semibold">Format attendu : </span>
                            <span className="uppercase">date / numéro de facture / nom client / montant HT / code tva</span>
                        </p>
                    </div>
                    <div className="pt-8 w-full h-4/6 flex items-center justify-center">

                        <div className="w-5/6 flex ">
                            <div className="w-full text-black h-3/6 flex justify-center flex-col">

                                <div className="w-full p-8 bg-gris rounded-lg flex justify-center items-center ">
                                    <div className="flex justify-center items-center upload-row-vente vente-upload charge-img p-8"
                                         onClick={declenchInput}>

                                        <div className="w-full h-24 2xl:h-60 flex items-center justify-center">
                                            {/*<img className="w-full h-full" src={'https://bbb-api.mypreprod.fr/storage/invoices/'+affectation.receipt_path} alt=""/>*/}
                                            <div className="flex flex-col justify-center text-center p-2 text-sm ">
                                                <div><BackupIcon/></div>
                                                <div className="text">
                                                    <form id="send-import-facture-achat">
                                                        <input type="file" name="invoices_file" id="declenche-input" className="hidden"/>
                                                    </form>
                                                    <h5 className="font-semibold">Charger un fichier</h5>
                                                    <h6>PDF, DOC, PPT, JPG, PNG</h6>
                                                    {/*<span>{nameFile}</span>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="w-full flex justify-end">
                                    <div className="bg-greenSuccess text-xl w-3/6 mt-2 rounded-xl">
                                        <button type="button"
                                                className=" w-full bg-greenSuccess text-xl font-semibold px-6 py-2 mt-2 text-center rounded-xl text-white"
                                                form="send-import-facture-achat" onClick={handleSubmit}>OK
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </div>
    )
}
export default PopupImportAchat