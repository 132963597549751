import React, {useState} from "react";
import '../../assets/style/dashboard.css';
import {getItem} from "../../services/LocaleStorage";
import {useSelector} from "react-redux";
import {getUser} from "../../app/user";
import {toast} from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import BackupIcon from "@material-ui/icons/Backup";
import DescriptionIcon from "@material-ui/icons/Description";
import DeleteIcon from "@material-ui/icons/Delete";
import Loader from "../Loader";


const AddFacture = ({close, fetchFactures}) => {

    //Upload de document
    const [nameFile, setNameFile] = useState('')
    const frontUser = useSelector(getUser)
    const [fileInInput, setFileInInput] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const btnSupprimer = () => {
        document.getElementById("document-mon-bbb-input").value = null
        setFileInInput(false)
    }

    const changeInputUpload = (e) => {
        if (e.target.files.length > 0) {
            setFileInInput(true)
            let baliseIMG = document.getElementById('balise-img')
            baliseIMG.setAttribute('src', URL.createObjectURL(e.target.files[0]))

        } else {
            setFileInInput(false)
        }
    }

    const uploadCourrier = (e) => {
        const token = getItem('bbbToken')
        setIsLoading(true)
        const form = new FormData(document.getElementById('send-facture'))

        form.append('client_id', frontUser.id)

        if (fileInInput) {
            setFileInInput(false)
            fetch(process.env.REACT_APP_API_URL + '/client/bbb-invoice', {
                method: 'POST',
                requirements: token,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                body: form
            }).then((response) => {
                if (response.ok) {
                    setIsLoading(false)
                    response.json().then((json) => {
                        toast.success('Ajout de la facture avec succès.')
                        fetchFactures()
                        document.getElementById("document-mon-bbb-input").value = null
                        close()

                    })
                } else {
                    setIsLoading(false)
                    toast.error('L\'ajout de la facture a échoué.')
                    close()
                }
            })
        }
    }
    const declenchInput = () => {
        document.getElementById('document-mon-bbb-input').click()
    }

    return (
        <React.Fragment>

            <div
                className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
                <Loader active={isLoading}/>
                <div className="w-2/3 mx-auto bg-white rounded-lg shadow-xl p-8 z-100">
                    <button className="float-right" onClick={close}><CloseIcon/></button>
                    <h1 className="mb-8 font-semibold">Importation Document <span className="uppercase">Admin</span></h1>
                    <form id="send-facture" onSubmit={uploadCourrier}>
                        <div className="w-full flex">
                            <div className="w-2/6">
                                <div
                                    className={`w-full flex justify-center rounded-xl py-2 mr-2 bg-grisClaire border-b border-gray-200 mb-2 `}>
                                    <input type="date" name="pay_before" className="bg-none-input w-full px-6 font-semibold text-gray-500" placeholder="Date document"/>
                                </div>
                                <div
                                    className={`w-full flex justify-center rounded-xl py-2 mr-2 bg-grisClaire border-b border-gray-200 my-2`}>
                                    <input type="text" name="name" className="bg-none-input w-full px-6 font-semibold" placeholder="Intitulé du document"/>
                                </div>
                                <div
                                    className={`w-full flex justify-center rounded-xl py-2 mr-2 bg-grisClaire border-b border-gray-200 mb-2`}>
                                    <input type="text" name="description" className="bg-none-input w-full px-6 font-semibold" placeholder="Commentaire sur le document"/>
                                </div>
                            </div>
                            <div className="w-4/6 flex justify-center">
                                <div className="vente-upload">
                                    <div className="w-full rounded-lg items-center mb-8">
                                        <div className="w-upload text-black h-full mx-auto bg-gris rounded-xl">
                                            <h3 className="text-center mb-0 p-2 font-bold w-full">Joindre un fichier</h3>
                                            <div
                                                className="bg-gris vente-upload w-full rounded-lg flex justify-center items-center ">
                                                <div
                                                    className={`flex justify-center items-center ${fileInInput ? "" : " border-dashed border-2 border-grisDashed "} vente-upload charge-img rounded-xl mx-auto`}
                                                    onClick={declenchInput}>
                                                    <embed className={fileInInput ? "object-cover h-full" : "hidden"} src="" alt=""
                                                           id="balise-img"/>
                                                    <div
                                                        className={fileInInput ? "hidden" : "flex flex-col justify-center text-center"}>
                                                        <div>
                                                            <button type="button" className="svg-upload"><BackupIcon/></button>
                                                        </div>
                                                        <div className="text">
                                                            <input type="file" name="receipt" id="document-mon-bbb-input"
                                                                   className="hidden" onChange={(e) => changeInputUpload(e)}/>
                                                            <h5>Faites glisser la pièce ou cliquer pour importer</h5>
                                                            <span>{nameFile}</span>
                                                        </div>
                                                    </div>


                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                    <div className="flex">
                                        <button type="button" disabled={!fileInInput}
                                                className={`w-full p-2 bg-greenSuccess rounded-xl ${fileInInput ? "" : "bg-opacity-50 cursor-not-allowed"} text-white flex items-center justify-center mr-2`}
                                                onClick={(e) => uploadCourrier(e)}>
                                            <DescriptionIcon/>
                                            <span className="mx-auto px-6 ">Envoyer</span>
                                        </button>

                                        <button disabled={!fileInInput} type="button"
                                                className={`w-full p-2 bg-redCorail rounded-xl ${fileInInput ? "" : "bg-opacity-50 cursor-not-allowed"} text-white flex items-center justify-center`}
                                                onClick={btnSupprimer}>
                                            <DeleteIcon/>
                                            <span className="mx-auto px-6">Supprimer</span>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}
export default AddFacture