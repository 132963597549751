import React, {useEffect, useRef, useState} from 'react';
import { PulseLoader } from 'react-spinners';
import HelpIcon from '@material-ui/icons/Help';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import BackupIcon from '@material-ui/icons/Backup';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';

import {getItem} from "../../services/LocaleStorage";
import ModalCategory from "./modalCategory";
import {getUser} from "../../app/user";
import {toast} from "react-toastify";
import http from "../../services/Http";
import {useDispatch, useSelector} from "react-redux";
import PopupDelPiece from "../Tables/PopupDelPiece";
import PopupAffectAlreadyExist from "../Tables/PopupAffectAlreadyExist";
import {setLoaderSubscribe, setLoaderUnsubscribe} from "../../app/loader";
import {calculateHT, calculateTTC} from "../../utils/calculations";
import CurrencyInput from "../../utils/CurrencyInput";
import {getBankAccounts} from "../../app/filters";
import Pagination from "../Pagination";
import calendrier from "../../assets/img/calendrier.png";
import wallet from "../../assets/img/wallet.png";
import deleteSmall from "../../assets/img/deleteSmall.png";
import deleteVerySmall from "../../assets/img/deleteVerySmall.png";
import deleteImg from "../../assets/img/delete.png";
import PopupOCRReprocess from "../Tables/PopupOCRReprocess";
import formatCurrency from "../../utils/formatAmount";
import formatDate from "../../utils/formatDate";

const AffectAperc = ({
    close, 
    rowToAffect, 
    scrollToRow,
    fetchResources, 
    isLastInvoice,
    depth = 0,
}) => {
    const dispatch = useDispatch()

    const [categories, setCategories] = useState([])
    const [dateOperation, setDateOperation] = useState('')

    const [affectation, setAffectation] = useState({label: '', amount_with_vat: '', vat: '', amount: '' })
    const [nameFile, setNameFile] = useState('')
    const [extensionFile, setExtensionFile] = useState('')

    const [fileInBDD, setFileInBDD] = useState(false)
    //Va cacher des section et afficher le tableau des opérations bancaire
    const [openTabOpBancaire, setOpenTabOpBancaire] = useState(false)
    const [openPopupDelPiece, setOpenPopupDelPiece] = useState(false)
    const [openPopupAffectAlreadyExist, setOpenPopupAffectAlreadyExist] = useState(false)
    const [opBancaireSelected, setOpBancaireSelected] = useState([]);
    const [affectationsInvoice, setAffectationsInvoice] = useState([]);
    const [selectedCat, setSelectedCat] = useState(null);
    const [searchTermsCategory, setSearchTermsCategory] = useState("");
    const [searchTermsTimer, setSearchTermsTimer] = useState(null);
    const [opPagination, setOpPagination] = useState({ per_page: 10, page: 1})
    
    // eslint-disable-next-line no-unused-vars
    const [sortTerms, setSortTerms] = useState("operation_date");
    // eslint-disable-next-line no-unused-vars
    const [sortOrder, setSortOrder] = useState("DESC");

    const [opBancaires, setOpBancaires] = useState({data: [], count: 0 });
    const [startDate, setStartDate] = useState(new Date().getFullYear() + "-01-01");
    const [endDate, setEndDate] = useState(new Date().getFullYear() + "-12-31");
    const [selectedBankAccount, setSelectedBankAccount] = useState(null);
    const [selectedOp, setSelectedOp] = useState(null);
    const [operationsFilters, setOperationsFilters] = useState(
        {
            searchTerms: "",
            minAmount: "",
            maxAmount: "",
        }
    );
    const bankAccounts= useSelector(getBankAccounts)
    const [suggestedSubCategories, setSuggestedSubCategories] = useState([])
    const [numPiece, setNumPiece] = useState(null)
	const [isSubCategSuggested, setIsSubCategSuggested] = useState(false)
    const [comment, setComment] = useState(rowToAffect.comment);
    const [notPayed, setNotPayed] = useState(null);
	const [vat, setVat] = useState({ amount: rowToAffect.vat_value, rate: rowToAffect.vat });
    const [isSubmissionLoading, setIsSubmissionLoading] = useState(false);

    useEffect(() => {
        if (rowToAffect?.payment.not_payed !== undefined) {
            setNotPayed(rowToAffect.payment.not_payed);
        }
    }, [rowToAffect]);

    useEffect(() => {
        setOpPagination({ ...opPagination, page: 1})
    }, [startDate, endDate, selectedCat, selectedBankAccount]);

    useEffect(() => {
        if (selectedCat !== null) {
            fetchOperationsBancaires()
        }
    }, [opPagination]);
    const delImage = () => {
        document.getElementById('invoice-receipt-input').value = null
        setNameFile('')
    }

    //charger un fichier
    const declenchInput = () => {
        document.getElementById('invoice-receipt-input').click()
    }

    const changeInput = (e) => {
        if (e.target.files.length === 0) {
            setNameFile('')
            setExtensionFile('')
        } else {
            // take all elements of the file name except the last one
            setNameFile(e.target.files[0].name.split('.').slice(0, -1).join('.')) // jean.pierre.jpg => jean.piere
            setExtensionFile(e.target.files[0].name.split('.').at(-1)) // jean.pierre.jpg => jpg
        }
    }

    const openFile = () => {
        if (nameFile !== "" && affectation.receipt_path  !== null) {
            window.open(process.env.REACT_APP_STORAGE_URL + affectation.receipt_path, '_blank');
        }
    }

    useEffect(() => {
        if (affectation.id) return
        if (rowToAffect.receipt_path !== null) {
            let file = rowToAffect.receipt_path.split('/')
            file = file[file.length - 1]
            file = file.split('.')
            // take all elements of the file name except the last one
            setNameFile(file.slice(0, -1).join('.'))
            setExtensionFile(file.at(-1))
            setDateOperation(rowToAffect.operation_date.split(' ')[0])
            setAffectation(rowToAffect)
            setFileInBDD(rowToAffect.receipt_path !== null)
        } else {
            setAffectation(rowToAffect)
            setDateOperation('')
            setNameFile('')
            setExtensionFile('')
        }
        setSelectedCat(rowToAffect.sub_category)
    }, []);

    useEffect(() => {
        const baliseImgModal = document.getElementById('balise-img-modal')
        if (fileInBDD && extensionFile !== "") {
            if (baliseImgModal?.src === process.env.REACT_APP_STORAGE_URL + affectation.receipt_path) return
            if (baliseImgModal) {
                baliseImgModal.setAttribute('src', process.env.REACT_APP_STORAGE_URL + affectation.receipt_path)
            }
        } else if (nameFile !== "" && extensionFile !== "") {
            if (baliseImgModal) {
                baliseImgModal.setAttribute('src', URL.createObjectURL(document.getElementById('invoice-receipt-input').files[0]))
            }
        }

    }, [fileInBDD, nameFile, affectation, extensionFile]);

    const dragNDrop = (e) => {
        document.getElementById('invoice-receipt-input').files = e.dataTransfer.files
        if (document.getElementById('invoice-receipt-input').files.length !== 0) {
            setNameFile(document.getElementById('invoice-receipt-input').files[0].name.split('.').slice(0, -1).join('.'))
            setExtensionFile(document.getElementById('invoice-receipt-input').files[0].name.split('.').at(-1))
        }
    }

    const fetchCategories = async() => {
        if (!['achat', 'vente'].includes(rowToAffect.type)) return
        const token = getItem('bbbToken')

        let response = await fetch(process.env.REACT_APP_API_URL +'/categories/' + rowToAffect.type , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({searchTerms: searchTermsCategory})
        });

        if (response.status === 200){
            let body = await response.json();
            setCategories(body);
        }
    }

    // Search Input: showing Keywords after clicking on the input field
    const showKeywords = () => {
        document.querySelector('.search-pop-up').classList.remove('hide-pop-up')
        document.body.style = "overflow: hidden"
    }

    // Search Pop Up: Hiding the Pop Up
    const hideSearchPopUp = () => {
        document.querySelector('.search-pop-up').classList.add('hide-pop-up')
        document.body.style = "overflow: auto"
    }

    const uploadInvoice = async () => {
        //Envoie de la requête si l'input contient un fichier sinon return
        if (document.getElementById('invoice-receipt-input').files.length === 0 && !nameFile) {
            toast.info('Veuillez choisir un fichier')
            return
        }

        const token = getItem('bbbToken')
        const authUrl = process.env.REACT_APP_API_URL + '/invoice/' + affectation.id
        const formdata = new FormData(document.getElementById('send-invoice'));
        formdata.append('type', affectation.type)
        formdata.append("client_id", frontUser.id)

        let response = await fetch(authUrl, {
            method: 'POST', body: formdata, headers: {
                'Authorization': 'Bearer ' + token
            }
        })

        if (response.status === 200) {
            setFileInBDD(true)
            let body = await response.json()

            setAffectation(body)
            // setNameFile(response.receipt_path)
            let file = body.receipt_path.split('/')
            file = file[file.length - 1]
            file = file.split('.')
            // take all elements of the file name except the last one
            setNameFile(file.slice(0, -1).join('.'))
            setExtensionFile(file.at(-1))
            fetchResources()
            // close()
            toast.success('La facture a été modifié avec succès')
        } else {
            toast.error('La modification de la facture a échoué')
        }
    }

    const handleSubmit = async () => {
        const token = getItem('bbbToken');

        setIsSubmissionLoading(true);

        if(nameFile !== "") {
            uploadInvoice();
        }

        //Modification des données de la facture modale affectation
        let form = new FormData(document.getElementById('affectation-operation'));
        
        if (selectedCat) {
            form.append('sub_category_id', selectedCat.id);
			form.append('is_sub_categ_suggested', false);
        }

        form.append('client_id', frontUser.id);
        form.append('comment', comment ?? '');

        let vat;
        let amount;
        let amountWithVat;
        if (Object.keys(ocrReprocessedInvoice).length > 0) {
            if (ocrReprocessedInvoice.date) {
                form.append('operation_date', ocrReprocessedInvoice.date);
            }
            if (ocrReprocessedInvoice.libelle) {
                form.append('label', ocrReprocessedInvoice.libelle);
            }

            if (ocrReprocessedInvoice.montant_ht) {
                amount = parseFloat(ocrReprocessedInvoice.montant_ht);
                form.append('amount', amount);
            } else {
                amount = affectation.amount;
                form.append('amount', amount);
            }

            if (affectation.sub_category?.is_vat_blocked) {
                vat = affectation.vat;
                amountWithVat = ocrReprocessedInvoice.montant_ttc;
                
                // Calculate amount HT based on the blocked VAT rate and the OCR reprocessed TTC amount
                amount = calculateHT(ocrReprocessedInvoice.montant_ttc, affectation.vat);
                
                form.append('vat', vat);
                form.append('vat_value', (((amountWithVat * 100) - (amount * 100)) / 100).toFixed(2));
                form.append('amount_with_vat', amountWithVat);
                form.append('amount', amount);

            } else {

                vat = parseFloat(ocrReprocessedInvoice.vat);
                amount = parseFloat(ocrReprocessedInvoice.montant_ht);
                amountWithVat = parseFloat(ocrReprocessedInvoice.montant_ttc);

                form.append('vat', vat);
                form.append('amount', amount);
                form.append('amount_with_vat', amountWithVat);

                if (ocrReprocessedInvoice.vat_value) {
                    form.append('vat_value', parseFloat(ocrReprocessedInvoice.vat_value).toFixed(2));
                } else {
                    form.append('vat_value', (((amountWithVat * 100) - (amount * 100)) / 100).toFixed(2));
                }
            }
        }

        await fetch(`${process.env.REACT_APP_API_URL}/invoice/${affectation.id}`, {
            method: 'POST', 
            headers: {
                'Authorization': `Bearer ${token}`,
            }, 
            body: form
        })
        .then(response => {
            setIsSubmissionLoading(false);
            if (response.ok) {
                fetchResources();
                response.json().then(newInvoice => {
                    setAffectation(newInvoice);
                })
                .catch(() => {
                    toast.error("Erreur lors de la modification");
                })
                toast.success('Mise à jour des informations de la facture avec succès.');
            }
        })
        .catch(() => {
            setIsSubmissionLoading(false);
            toast.info("Erreur lors de la modification");
        })

        //Création des affectations
        Promise.all(opBancaireSelected.map((op) => {
            let form = new FormData()
            form.append('invoice_id', affectation.id)
            form.append('transaction_id', op.id)
            form.append('comment', 'mon comm daffect')
            form.append('amount', op.amount)

            if (selectedCat) form.append('sub_category_id', selectedCat.id);

            return fetch(`${process.env.REACT_APP_API_URL}/affectation`, {
                method: 'POST', 
                headers: {
                    'Authorization': `Bearer ${token}`,
                }, 
                body: form
            });
        }))
        .then(resp => {
            if (resp.some((r) => !r.ok)) {
                toast.error('L\'affectation a échoué');
                close();
            } else {
                toast.info('L\'affectation s\'est bien passée');
                fetchOperationsBancaires();
                scrollToRow();
                close();
            }
        });
    }

    const fetchAffectationInvoice = () => {
        // Chargement des affectations d'une invoice ici
        const token = getItem('bbbToken')

        let endpoint = '/affectations?include[]=transaction&invoice_id=' + rowToAffect.id
        http.get(endpoint, {
            'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token
        })
        .then((data) => {
            //data contient un obj affectation, invoice et transaction
            setAffectationsInvoice(data.affectations)
        })
        .catch(() => {
            setAffectationsInvoice([])
        });
    }

    //Concerne le tableau des opérations bancaire
    const [isLoading, setIsLoading] = useState(false)
    const frontUser = useSelector(getUser)
    const token = getItem('bbbToken')

    useEffect(() => {
        if (searchTermsTimer) {
            clearTimeout(searchTermsTimer)
        }
        setSearchTermsTimer(setTimeout(() => {
            fetchCategories()
        }, 500))
    }, [searchTermsCategory]);

    useEffect(() => {
        fetchAffectationInvoice()
    }, [affectation]);

    const closeModale = () => {
        setOpenPopupDelPiece(false)
        setOpenPopupAffectAlreadyExist(false)
    }

    const selectOperation = (op) => {
        const token = getItem('bbbToken')

        //Vérifier si l'opération à déjà une pièce affecté.
        let endpoint = '/affectations?transaction_id=' + op.id
        dispatch(setLoaderSubscribe())
        http.get(endpoint, {
            'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token
        })
        .then((data) => {
            // setAffectOfOpBancaire(data)let file
            if (data.affectations.length === 0) {
                // if (!opBancaireSelected.some((ope) => ope.id === op.id)) {
                //     setOpBancaireSelected(prevState => [...prevState, op])
                // }
                setOpenTabOpBancaire(false)

                let form = new FormData()
                form.append('invoice_id', affectation.id)
                if (numPiece) form.append('reference', numPiece)
                form.append('transaction_id', op.id)
                form.append('comment', 'commentaire')
                form.append('amount', op.amount)

                if (selectedCat) form.append('sub_category_id', selectedCat.id)

                const url = '/affectation'
                return fetch(process.env.REACT_APP_API_URL + url, {
                    method: 'POST', headers: {
                        'Authorization': 'Bearer ' + token,
                    }, body: form
                })
                .then(async resp => {
                    if (resp.ok) {
                        const body = await resp.json()
                        setAffectationsInvoice([...affectationsInvoice, body])
                        toast.info('L\'affectation s\'est bien passée')
                        fetchOperationsBancaires()
                        dispatch(setLoaderUnsubscribe())
                    } else {
                        toast.error('L\'affectation a échoué')
                        dispatch(setLoaderUnsubscribe())
                    }
                })
            } else {
                // L'opération a déja une pièce attaché alors on affiche une modale
                setOpenPopupAffectAlreadyExist(true)
            }
            dispatch(setLoaderUnsubscribe())
        })
        .catch(() => {
            console.log("ici?")
            dispatch(setLoaderUnsubscribe())
        });
    }

    /*Supprime la ligne d'affectation selectionné*/
    const deleteAffectation = (op) => {
        const updatedReturnOpBancaire = opBancaireSelected.filter(ope => ope.id !== op.id)
        setOpBancaireSelected(updatedReturnOpBancaire)
    }

    /*Supprime la ligne en BDD*/
    const deleteBDDAffectationInvoice = (affectation) => {
        dispatch(setLoaderSubscribe())
        fetch(process.env.REACT_APP_API_URL + '/affectation/' + affectation.id, {
            method: 'DELETE', requirements: token, headers: {
                'Authorization': 'Bearer ' + token
            },
        })
        .then(response => {
            if (response.ok) {
                dispatch(setLoaderUnsubscribe())
                fetchOperationsBancaires()
                fetchAffectationInvoice()
                toast.success('L\'affectation a été supprimé avec succès.')
            } else {
                dispatch(setLoaderUnsubscribe())
            }
        })
        .catch(() => {
            dispatch(setLoaderUnsubscribe())
        });
    }

    const fetchOperationsBancaires = async (perPage = opPagination.per_page, page = opPagination.page) => {
        if (selectedCat === null) return

        let updateOperationFilters = {}
        Object.entries(operationsFilters).forEach(([key, value]) => {
            if (value !== "") {
                updateOperationFilters[key] = value
            }
        })

        let body = {
            client_id: frontUser.id,
            type: rowToAffect.type === 'achat' ? 'achat' : 'vente',
            page: page,
            per_page: perPage,
            bank_account_id: selectedBankAccount === "Tous" ? null : selectedBankAccount,
            sort: {
                field: sortTerms,
                order: sortOrder
            },
            filters: {...updateOperationFilters, sub_category_id: selectedCat.id},
            badges: {
                receipt: ["without"]
            }
        }

        if (startDate !== "" && startDate !== null) {
            body.from_operation_date = startDate;
        }
        if (endDate !== "" && endDate !== null) {
            body.to_operation_date = endDate;
        }

        let response = await fetch(process.env.REACT_APP_API_URL +'/filter-transactions' , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(body)
        });

        if (response.status === 200){
            let body = await response.json()
            setOpBancaires({data: body?.data ?? [], count: body?.count ?? 0 })
        } else {
            setOpBancaires({data: [], count: 0 })
        }
    }

    // --- Partie Sub_cat ---
    const setCat = (cat) => {
        setSelectedCat(cat)
        hideSearchPopUp()
    }

    useEffect(() => {
        if (selectedCat?.is_vat_blocked) {
            updateCalculs("vat", selectedCat.vat)
        }
    }, [selectedCat]);

    const updateCalculs = (newValuePropName, newValue) => {
		let newValues = {[newValuePropName]: newValue}

        const updateVatAndAmount = (newAmount, vatRate, amountWithVat = affectation.amount_with_vat) => {
            const vatAmount = ((amountWithVat - newAmount) * 100).toFixed(2) / 100;
            setVat({ amount: vatAmount.toFixed(2), rate: vatRate });
            return vatAmount;
        };

        switch (newValuePropName) {
            case "vat": {
                const newAmount = calculateHT(affectation.amount_with_vat, newValue);
                newValues.amount = newAmount;
                updateVatAndAmount(newAmount, newValue);
                break;
            }
            case "amount": {
                const newAmountWithVat = calculateTTC(newValue, vat.rate);
                newValues.amount_with_vat = newAmountWithVat;
                updateVatAndAmount(newValue, vat.rate, newAmountWithVat);
                setNotPayed(newAmountWithVat);
                break;
            }
            case "amount_with_vat": {
                const newAmount = calculateHT(newValue, vat.rate);
                newValues.amount = newAmount;
                updateVatAndAmount(newAmount, vat.rate, newValues.amount_with_vat);
                setNotPayed(newValue);
                newValues.amount_with_vat = newValue;
                break;
            }
            default: {
                const newAmount = calculateHT(newValue, vat.rate);
                newValues.amount = newAmount;
                updateVatAndAmount(newAmount, vat.rate, newValues.amount_with_vat);
                setNotPayed(newValue);
                newValues.amount_with_vat = newValue;
                break;
            }
        }

      	setAffectation({...affectation, ...newValues})
    }

    const reset = () => {
        setOpenTabOpBancaire(false);
        setSelectedOp(null)
    }

    useEffect(() => {
        if (rowToAffect?.label) {
            fetchSuggestedSubCategories(rowToAffect);
        } else if (affectation?.label) {
            fetchSuggestedSubCategories(affectation);
        }
    }, [])

	useEffect(() => {
		if (selectedCat != null) {
			saveSuggestedSubCategory()
		}
	}, [isSubCategSuggested])

	const saveSuggestedSubCategory = async () => {
		let form = new FormData();

		if (selectedCat == null) return;

		form.append('sub_category_id', selectedCat.id)
		form.append('is_sub_categ_suggested', true)
        form.append('client_id', frontUser.id)

        const token = getItem('bbbToken')
		const url = `/invoice/${affectation.id}`
        let response = await fetch(process.env.REACT_APP_API_URL + url, {
            method: 'POST', headers: {
                'Authorization': 'Bearer ' + token,
            }, body: form
        })
        if (response.status === 200) {
			// fetchOperationsBancaires()
            fetchResources()
        }
	}

    const fetchSuggestedSubCategories = async (op = null) => {
        setIsLoading(true)
        const token = getItem('bbbToken')
        const url = process.env.REACT_APP_API_URL + '/transaction/categories/suggest'
        const formdata = new FormData();

        if (!op) op = affectation;

        formdata.append('text', op.label)
        formdata.append('item_type', op.type)

        try {
            const response = await fetch(url, {
                                method: 'POST',
                                body: formdata,
                                headers: {
                                    'Authorization': 'Bearer ' + token
                                }
                            })
                
            setIsLoading(false);

            if (response.ok) {
                const res = await response.json();

                // Sort the suggested categories by putting the selected one on top of the list
                const sortedSubCategories = res.results.sort((a, b) => {
                    if (a.id === op?.sub_category?.id) return -1; // Place checked item first
                    if (b.id === op?.sub_category?.id) return 1;
                    return 0; // Keep the order for unchecked items
                });

                setSuggestedSubCategories(sortedSubCategories);

                const shouldSuggestAndPreselectSubCateg = (!op?.sub_category?.id && !isSubCategSuggested || isSubCategSuggested);

                if (shouldSuggestAndPreselectSubCateg) {
                    setSelectedCat(res.results[0]);
                    setIsSubCategSuggested(true);
                    saveSuggestedSubCategory();
                }
            } else {
                toast.error("Une erreur s'est produite en essayant de suggérer des catégories")
            } 
            
        } catch (error) {
            toast.error("Une erreur s'est produite en essayant de suggérer des catégories")
        }
    }

	const displaySubCategoryName = () => {
		let subCategoryName = '';

		if (selectedCat?.display_name) {
			subCategoryName = selectedCat?.display_name;
		} else if (affectation.sub_category?.display_name) {
			subCategoryName = affectation.sub_category?.display_name;
		} else {
			return <HelpIcon fontSize="small" />;
		}

		if (isSubCategSuggested || affectation?.is_sub_categ_suggested) {
			return <>{subCategoryName} <HelpIcon fontSize="small" /></>
		}

		return subCategoryName;
	}

    const handleChange = (e) => {
        setComment(e.target.value);
    }

    const emptyComment = () => {
        setComment('');
    }

    const handleChangeSuggestedCateg = (sc) => {
        setSelectedCat(sc);
        if (affectation?.is_sub_categ_suggested) {
            setIsSubCategSuggested(true);
        }
    }

    // Keep the popup height resizable
    const [width, setWidth] = useState(1319);
    const [height, setHeight] = useState(590);

    const [maxConstraints, setMaxConstraints] = useState([0, 0]);
    const containerRef = useRef(null);

    useEffect(() => {
        const updateConstraints = () => {
            const containerWidth = containerRef.current.offsetWidth;
            const containerHeight = containerRef.current.offsetHeight;
            
            const maxWidth = containerWidth * 0.9;
            const maxHeight = containerHeight * 0.9;

            setMaxConstraints([maxWidth, maxHeight]);
        };
    
        updateConstraints();
        window.addEventListener('resize', updateConstraints);
    
        return () => {
            window.removeEventListener('resize', updateConstraints);
        };
    }, []);

    const onResize = (event, { size }) => {
        setWidth(size.width);
        setHeight(size.height);
    };

    const [isOCRPopupReprocessOpen, setIsOCRPopupReprocessOpen] = useState(false);
    const [isOCRReprocessConfirmed, setIsOCRReprocessConfirmed] = useState(false);
    const [isOCRReprocessLoading, setIsOCRReprocessLoading] = useState(false);
    const [ocrReprocessedInvoice, setOCRReprocessedInvoice] = useState({});

    const handleOCRReprocessedChanges = (e) => {
        const newValues = {};
        const fieldName = e.target.name;
        const fieldValue = ['montant_ht', 'montant_ttc'].includes(fieldName) ? (parseFloat(e.target.value) || 0) : e.target.value;

        if (fieldName == 'libelle') {
            newValues.libelle = fieldValue;
        } else if (fieldName == 'montant_ht') {
            const amountWithVAT = calculateTTC(fieldValue, ocrReprocessedInvoice.vat);
            newValues.montant_ht = fieldValue;
            newValues.montant_ttc = amountWithVAT;
            newValues.vat_value = amountWithVAT - fieldValue;
        } else if (fieldName == 'montant_ttc') {
            const amount = calculateHT(fieldValue, ocrReprocessedInvoice.vat);
            newValues.montant_ttc = fieldValue;
            newValues.montant_ht = amount;
            newValues.vat_value = fieldValue - amount;
        }

        setOCRReprocessedInvoice((prev) => ({
            ...prev,
            ...newValues
        }));
    }

    const confirmOCRReprocess = async () => {
        setIsOCRReprocessConfirmed(true);
        setIsOCRPopupReprocessOpen(false);
        setIsOCRReprocessLoading(true);

        const toastId = toast.loading('Analyse est en cours, cette opération peut prendre quelques minutes...');

        const token = getItem('bbbToken');
        await fetch(`${process.env.REACT_APP_API_URL}/invoice/${affectation.id}/rescan`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            if (response.ok) {
                response.json().then((res) => {
                    setOCRReprocessedInvoice(res.invoice);
                    toast.update(toastId, {
                        render: "L'analyse est terminée avec succès",
                        type: toast.TYPE.SUCCESS,
                        isLoading: false,
                        autoClose: 3000
                    });
                });
            }

            setIsOCRReprocessLoading(false);
            setIsOCRReprocessConfirmed(false);
        })
        .catch(error => {
            console.error(error);
            setIsOCRReprocessLoading(false);
            setIsOCRReprocessConfirmed(false);
            toast.update(toastId, {
                render: "Opération échouée",
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 3000
            });
        });
    }

    return (
        <div ref={containerRef} className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
            <ResizableBox
                id="resizable-element"
                width={width}
                height={height}
                className="m-auto"
                minConstraints={[1000, 590]}
                maxConstraints={maxConstraints}
                onResize={onResize}
                axis={'y'}
                resizeHandles={['se']}
            >
                <div className="w-11/12 xl:w-[1000px] 2xl:w-[1319px] h-full m-auto bg-white rounded-lg shadow-xl p-8 z-100 relative overflow-x-auto overflow-y-scroll">
                    <h3 className="font-bold text-xl pb-4">{!openTabOpBancaire ? "Détails du document" : "Suggestions d'opérations bancaires à associer"}</h3>
                    <button 
                        className="absolute top-0 right-0 px-2 rounded-xl m-4 z-100" 
                        onClick={()=> {return openTabOpBancaire ? reset() : close() }}
                    >
                        <CloseIcon/>
                    </button>
                    <section className="flex justify-between affect-aperc relative h-auto">
                        <div className="affect w-full mr-4">
                            <div className="op-label">
                                {/*{rowToAffect.type === "vente" ? <h3>Affectation de la vente</h3> : rowToAffect.type === "frais" ? <h3>Affectation de la pièce</h3> : ""}*/}

                                {!openTabOpBancaire && 
                                    <form id="affectation-operation" className="flex">
                                        <table className="mr-12">
                                            <thead>
                                                <tr>
                                                    <th>DATE</th>
                                                    <th>LIBELLÉ DU DOCUMENT</th>
                                                    {rowToAffect.type == 'vente' ? <th>Num pièce</th> : null}
                                                    <th>Montant HT</th>
                                                    <th>TVA</th>
                                                    <th>Montant TTC</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <input 
                                                            type="date" 
                                                            name="operation_date"
                                                            className="input-operation-vente" 
                                                            defaultValue={dateOperation}
                                                        />
                                                        {affectation.id}
                                                    </td>
                                                    <td className="bg-none">
                                                        <div className="flex" style={{ marginBottom: '0.15rem' }}>
                                                            {/*<input className="input-operation-vente" name="label" defaultValue={affectation.label}/>*/}
                                                            <span 
                                                                style={{ color: isSubCategSuggested || affectation.is_sub_categ_suggested ? '#FD5353' : '#818181' }}
                                                                className="text-black truncate" 
                                                                title={selectedCat?.display_name ?? affectation?.sub_category?.display_name}>
                                                                {/* {selectedCat?.display_name ?? affectation?.sub_category?.display_name ?? <HelpIcon/>} */}
                                                                {displaySubCategoryName()}
                                                            </span>
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <input 
                                                                name="label"
                                                                type="text" 
                                                                tabIndex={1}
                                                                className="input-operation-vente"
                                                                value={affectation.label}
                                                                onKeyPress={(e) => {
                                                                    if (e.key == 'Enter') {
                                                                        e.preventDefault();
                                                                        fetchSuggestedSubCategories();
                                                                    }
                                                                }}
                                                                onChange={e => setAffectation({ ...affectation, label: e.target.value })}
                                                            />
                                                        </div>
                                                    </td>
                                                    {rowToAffect.type == 'vente' ? 
                                                        <td>
                                                            <input 
                                                                type="text" 
                                                                tabIndex={2}
                                                                name="reference" 
                                                                style={{ width: '80px' }}
                                                                className="input-operation-vente"
                                                                onChange={(e) => setNumPiece(e.target.value)} 
                                                                value={numPiece ?? affectation.reference} 
                                                            />
                                                        </td> 
                                                    :
                                                        null
                                                    }
                                                    <td>
                                                        <CurrencyInput
                                                            name="amount"
                                                            tabIndex={3}
                                                            value={affectation.amount}
                                                            onChange={(newValue) => updateCalculs('amount', newValue)}
                                                            onBlur={() => {
                                                                if (affectation.amount === "") {
                                                                    updateCalculs('amount', "0.00");
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <span className="input-operation-vente font-normal">
                                                            {vat.amount} € (<span className="grayed-text">{affectation.vat} %</span>)
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <CurrencyInput
                                                            name="amount_with_vat"
                                                            tabIndex={4}
                                                            value={affectation.amount_with_vat}
                                                            onChange={(newValue) => updateCalculs('amount_with_vat', newValue)}
                                                            onBlur={() => {
                                                                if (affectation.amount_with_vat === "") {
                                                                    updateCalculs('amount_with_vat', "0.00");
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                            {Object.keys(ocrReprocessedInvoice).length > 0 && 
                                                <tfoot className="ocr-reprocessed">
                                                    <tr>
                                                        <td>{formatDate(ocrReprocessedInvoice.date)}</td>
                                                        <td>
                                                            <input 
                                                                type="text" 
                                                                name="libelle" 
                                                                value={ocrReprocessedInvoice.libelle} 
                                                                onChange={(e) => handleOCRReprocessedChanges(e)}
                                                            />                
                                                        </td>
                                                        {rowToAffect.type == 'vente' ? <td></td> : null}
                                                        <td>
                                                            <input
                                                                name="montant_ht"
                                                                value={ocrReprocessedInvoice.montant_ht}
                                                                onChange={(e) => handleOCRReprocessedChanges(e)}
                                                            />
                                                        </td>
                                                        <td>
                                                            {formatCurrency(ocrReprocessedInvoice.vat_value)} ({ocrReprocessedInvoice.vat} %)
                                                        </td>
                                                        <td>
                                                            <input
                                                                name="montant_ttc"
                                                                value={ocrReprocessedInvoice.montant_ttc}
                                                                onChange={(e) => handleOCRReprocessedChanges(e)}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            }
                                        </table>
                                        {/*<div className="w-12"></div>*/}
                                    </form>
                                }
                            </div>

                            {/*Affiche les affectations selectionné*/}
                            {openTabOpBancaire ? null : opBancaireSelected.map((op, index) => {
                                return <React.Fragment key={op.id}>
                                    <h3 className="mt-8">Affectation {index + 1}</h3>
                                    <div className="flex justify-between items-center">
                                        <table className="mr-2 affectations-piece">
                                            <thead>
                                                <tr>
                                                    <th>DATE</th>
                                                    <th>LIBELLÉ DES OPÉRATIONS</th>
                                                    <th>Montant HT</th>
                                                    <th>TVA</th>
                                                    <th>Montant TTC</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><span>{op.displayable_operation_date}</span></td>
                                                    <td>
                                                        {op.sub_category?.display_name ? 
                                                            <span className="truncate" title={op.sub_category?.display_name}>
                                                                {op.sub_category?.display_name}
                                                            </span> 
                                                        : 
                                                            null
                                                        }
                                                        <p>
                                                            {op.label}
                                                        </p>
                                                    </td>
                                                    <td><span>{op.amount}</span></td>
                                                    <td><span>{op.vat}</span></td>
                                                    <td><span>{op.amount_with_vat}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="w-12">
                                            <button 
                                                className="bg-[#C4C4C4] p-2 rounded-lg text-white shrink"
                                                onClick={() => deleteAffectation(op)}
                                            >
                                                <img src={deleteVerySmall} className="w-4 h-4" alt="delete"/>
                                            </button>
                                        </div>
                                    </div>
                                </React.Fragment>
                            })}
                            {/*{!openTabOpBancaire && <div className="flex flex-col mt-8 ">*/}
                            {/*    <h3>Suggestions</h3>*/}
                            {/*    <div className="flex justify-between items-center">*/}
                            {/*        <table className="mr-2 affectations-piece">*/}
                            {/*            <thead>*/}
                            {/*            <tr>*/}
                            {/*                <th>DATE</th>*/}
                            {/*                <th>LIBELLÉ DES OPÉRATIONS</th>*/}
                            {/*                <th>Montant HT</th>*/}
                            {/*                <th>TVA</th>*/}
                            {/*                <th>Montant TTC</th>*/}
                            {/*            </tr>*/}
                            {/*            </thead>*/}
                            {/*            <tbody>*/}
                            {/*            <tr>*/}
                            {/*                <td><input type="radio"/> <span>12/02</span></td>*/}
                            {/*                <td>label</td>*/}
                            {/*                <td><span>300</span></td>*/}
                            {/*                <td><span>20</span></td>*/}
                            {/*                <td><span>350</span></td>*/}
                            {/*            </tr>*/}
                            {/*            </tbody>*/}
                            {/*        </table>*/}
                            {/*        <div className="w-12"></div>*/}
                            {/*    </div>*/}
                            {/*</div>}*/}
                            {!openTabOpBancaire && 
                                <div className="w-full">
                                    <div className="ml-auto mr-0 w-3/6">
                                        <div className="flex mr-12">
                                            <button 
                                                onClick={() => setOpenTabOpBancaire(true)}
                                                className="px-6 mt-2 text-lilaFonce bg-gris rounded-lg flex items-center justify-center py-2 w-full"
                                            >
                                                Autre <ArrowDropDownIcon/>
                                            </button>
                                            {/*<div className="w-12 float-right ml-2"></div>*/}
                                        </div>
                                    </div>
                                    <div className="flex w-full">
                                        <div className="w-3/6">
                                            <h2 className="font-bold text-xl pb-4">{affectation.sub_category !== null ? "Editer la catégorie" : "Choisir la catégorie"}</h2>
                                            {/*Div suggestion de categories affiché seulement si le tableau des opération n'est pas affiché*/}
                                            <div className="flex ">
                                                <div className="select">
                                                    <ul className="font-semibold">
                                                        {isLoading ?
                                                            <PulseLoader color={'black'} size={24} />
                                                        :
                                                            suggestedSubCategories.map((sc, index) => (
                                                                <li className="flex text-sm" key={index}>
                                                                    <input 
                                                                        type="radio" 
                                                                        name="subCategoryId" 
                                                                        className="mr-2" 
                                                                        id={`suggestedCateg-${index}`}
                                                                        value={sc.id} 
                                                                        onChange={() => handleChangeSuggestedCateg(sc)}
                                                                        checked={sc.id == selectedCat?.id}
                                                                    />
                                                                    <label htmlFor={`suggestedCateg-${index}`}>{sc.display_name}</label>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>

                                                    <button 
                                                        type="button"
                                                        className="w-full text-lilaFonce bg-gris rounded-lg flex items-center justify-center p-2 mt-4"
                                                        onClick={showKeywords}
                                                    >
                                                        <span>Autres catégories</span>
                                                        <ArrowDropDownIcon/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="w-3/6 flex flex-col items-end"> */}
                                        <div className="w-full flex items-end flex-col">
                                            <div className="flex items-center mr-12 mt-4 ">
                                                <p className="text-gray-500 uppercase">tva</p>
                                                <div className="flex relative justify-between bg-grey rounded-xl py-2 px-2 ml-4">
                                                    {selectedCat?.is_vat_blocked || (selectedCat === null && affectation.sub_category?.is_vat_blocked) ? 
                                                        <select className="bg-grey w-full" disabled defaultValue={selectedCat?.vat ?? affectation.sub_category?.vat}>
                                                            <option value={selectedCat?.vat ?? affectation.sub_category?.vat}>
                                                                {selectedCat?.vat ?? affectation.sub_category?.vat}%
                                                            </option>
                                                        </select>
                                                    : 
                                                        <>
                                                            <span className="">{Number(affectation.vat)}%</span>
                                                            <ArrowDropDownIcon/>
                                                            <select className="bg-grey absolute w-full h-full opacity-0 top-0 left-0" value={vat.rate} onChange={e => updateCalculs('vat', e.target.value)}>
                                                                <option value="20.00">20%</option>
                                                                <option value="13.00">13%</option>
                                                                <option value="10.00">10%</option>
                                                                <option value="8.50">8.5%</option>
                                                                <option value="5.50">5.5%</option>
                                                                <option value="2.10">2.1%</option>
                                                                <option value="0.00">0%</option>
                                                            </select>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <div className="items-center mr-12 mt-4 relative">
                                                <textarea
                                                    name="comment" 
                                                    className="bg-grey p-4 rounded-xl placeholder:italic placeholder:text-slate-400" 
                                                    style={{ resize: 'none', height: '185px', minWidth: '300px', width: '100%', borderBottom: '35px solid rgb(238 238 238)' }}
                                                    placeholder="Écrivez ici votre commentaire..."
                                                    onChange={handleChange}
                                                    value={comment ?? ''}
                                                ></textarea>
                                                <img 
                                                    src={deleteImg} 
                                                    style={{ bottom: '15px', right: '5px' }}
                                                    className="w-6 h-6 absolute cursor-pointer" 
                                                    alt="delete"
                                                    onClick={emptyComment}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*Affiche les affectations en bdd*/}
                                    {!openTabOpBancaire && <div className="flex flex-col mt-4">
                                        {affectationsInvoice.length !== 0 ? <h2 className="font-bold text-xl">Opérations bancaires associées</h2> : null}

                                        {affectationsInvoice[0]?.transaction === null ? null : affectationsInvoice.map(affecta => {
                                            return <React.Fragment key={affecta.id}>

                                                {/*<h2>Affectations de la pièce</h2>*/}
                                                <div className="flex justify-between items-center">
                                                    <table className="mr-2 my-2  affectations-piece">
                                                        <thead>
                                                            <tr>
                                                                <th>DATE</th>
                                                                <th>LIBELLÉ DES OPÉRATIONS</th>
                                                                <th>Montant HT</th>
                                                                <th>TVA</th>
                                                                <th>Montant TTC</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="border-b text-center text-xs text-black border-gray-200">
                                                                <td><span>{affecta.transaction.displayable_operation_date}</span></td>
                                                                <td><span>{affecta.transaction.label}</span></td>
                                                                <td><span>{affecta.transaction.amount}</span></td>
                                                                <td><span>{affecta.transaction.vat}</span></td>
                                                                <td><span>{affecta.transaction.amount_with_vat}</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    {/*BDD DELETE*/}
                                                    <div className="w-12">
                                                        <button 
                                                            className="bg-[#C4C4C4] p-2 rounded-lg text-white shrink"
                                                            onClick={() => deleteBDDAffectationInvoice(affecta)}
                                                        >
                                                            <img src={deleteVerySmall} className="w-4 h-4" alt="delete"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        })}
                                    </div>}
                                    {notPayed !== null ? 
                                        <div className="mt-4">
                                            <p className="text-black font-bold text-center p-4 bg-[#f2f5ff] rounded-xl w-fit">
                                                Restant dû : <span className="text-redCorail font-bold">{notPayed} €</span>
                                            </p>
                                        </div>
                                    : 
                                        null
                                    }
                                </div>
                            }
                        </div>

                        {/*Section d'upload*/}
                        {/*{!openTabOpBancaire &&  <div className="flex justify-end w-min-content h-full">*/}
                        {/*    <div className="">*/}
                        {/*        <div*/}
                        {/*            className={`bg-gris w-full rounded-lg items-center mb-2 ${openTabOpBancaire ? 'w-upload-affect-none' : ''}`}>*/}
                        {/*            <div className="text-black pbott-4">*/}

                        {/*                <h3 className="text-center mb-0 p-2">{nameFile !== '' ? nameFile : 'Joindre un fichier'}</h3>*/}

                                        {/*<div id="upload-fichier"*/}
                                        {/*     className={`flex justify-center my-8 mx-auto items-center ${nameFile !== "" ? "" : " border-dashed border-2 border-grisDashed "} vente-upload charge-img-affect rounded-xl relative`}>*/}
                                        {/*    {nameFile !== "" && extensionFile !== "" ? <> <embed className="object-cover h-full rounded-xl h-12 mb-2" id="balise-img-modal"*/}
                                        {/*                                                         alt=""*/}
                                        {/*                                                         type={extensionFile === "pdf" ? "application/pdf" : "image/" + extensionFile}/>   <div className="bg-transparent w-full h-full absolute" onClick={openFile}></div> </> :*/}
                                        {/*        <div className="upload w-full h-full cursor-pointer" onClick={declenchInput} onDrop={(e) => {*/}
                                        {/*            dragNDrop(e)*/}
                                        {/*        }}>*/}
                        {/*                            <div className="flex flex-col justify-center text-center h-full">*/}
                        {/*                                <div>*/}
                        {/*                                    <button type="button" className="svg-upload"><BackupIcon/></button>*/}
                        {/*                                </div>*/}
                        {/*                                <div className="text">*/}
                        {/*                                    <h5>Charger un fichier</h5>*/}
                        {/*                                    <h6>PDF, DOC, PPT, JPG, PNG</h6>*/}
                        {/*                                </div>*/}
                        {/*                            </div>*/}
                        {/*                        </div>}*/}
                        {/*                    <form action="" id="send-invoice">*/}
                        {/*                        <input type="file" name="receipt" id="invoice-receipt-input"*/}
                        {/*                               className="hidden" onChange={(e) => changeInput(e)}/>*/}
                        {/*                    </form>*/}
                        {/*                </div>*/}


                        {/*            </div>*/}

                        {/*        </div>*/}
                        {/*        /!*Si le tableau des opération bancaire est afficher alors on masque ces boutons*!/*/}
                        {/*        {!openTabOpBancaire && opBancaireSelected.length === 0 && <div className="flex justify-center mt-2">*/}
                        {/*            /!*vérifie si pas de fichier en bdd et si il y a une image affiché*!/*/}
                        {/*            <button type="button"*/}
                        {/*                    className={`${!fileInBDD && nameFile !== "" ? "" : 'bg-opacity-50 cursor-not-allowed'} p-2 bg-greenSuccess rounded-xl text-white flex items-center justify-center mr-2`}*/}
                        {/*                    onClick={(e) => uploadInvoice(e, affectation.id)}>*/}
                        {/*                <DescriptionIcon/>*/}
                        {/*                <span className="mx-auto px-6 ">Envoyer</span>*/}
                        {/*            </button>*/}
                        {/*            <button*/}
                        {/*                className={`p-2 bg-redCorail  rounded-xl text-white flex items-center justify-center ${nameFile === "" ? 'bg-opacity-50 cursor-not-allowed' : ''}`}*/}
                        {/*                onClick={fileInBDD ? () => setOpenPopupDelPiece(true) : delImage}>*/}
                        {/*                <DeleteIcon/>*/}
                        {/*                <span className="mx-auto px-6">Supprimer</span>*/}
                        {/*            </button>*/}
                        {/*        </div>}*/}

                        {/*    </div>*/}
                        {/*</div>}*/}

                        {/*{!openTabOpBancaire && <div>*/}
                        {/*    <button*/}
                        {/*        className="p-2 bg-greenSuccess rounded-xl text-white absolute right-0 bottom-0" type="button"*/}
                        {/*        onClick={(e) => handleSubmit(e)}>OK*/}
                        {/*    </button>*/}
                        {/*</div>}*/}

                        {!openTabOpBancaire && 
                            <div className="flex flex-col justify-start items-end w-2/6 min-w-[270px]">
                                <div className="text-black bg-gris p-4 rounded-lg flex flex-col h-[405px] w-full sidebar:h-[405px]">
                                    <div className="flex justify-between ">
                                        <h2 className="text-center text-md pb-4 truncate font-bold cursor-default" title={nameFile !== "" ? nameFile : 'Associer un document'} >{nameFile !== '' ? nameFile : 'Associer un document'}</h2>
                                        {nameFile !== "" && extensionFile !== "" ? 
                                            <div 
                                                className="cursor-pointer w-6 h-6 shrink-0" 
                                                onClick={fileInBDD ? () => setOpenPopupDelPiece(true) : delImage}
                                            >
                                                <img src={deleteSmall} className="w-6 h-6" alt="delete"/>
                                            </div> 
                                        : 
                                            null
                                        }
                                    </div>
                                    <div id="upload-fichier"
                                        className={`flex justify-center items-center w-full h-full ${nameFile !== "" ? "" : " border-dashed border-2 border-grisDashed "} rounded-xl mx-auto relative`}>
                                        {nameFile !== "" && extensionFile !== "" ? 
                                            <>
                                                <embed 
                                                    className="object-cover w-full h-full rounded-xl"
                                                    id="balise-img-modal"
                                                    alt="" type={extensionFile === "pdf" ? "application/pdf" : "image/" + extensionFile}
                                                />
                                            </>
                                        :
                                            <>
                                                <div 
                                                    className="upload flex flex-col justify-center text-center h-full" 
                                                    onClick={declenchInput} onDrop={(e) => dragNDrop(e)}>
                                                    <div>
                                                        <button type="button" className="svg-upload"><BackupIcon/></button>
                                                    </div>
                                                    <div className="text" >
                                                        <h5>Charger un fichier</h5>
                                                        <h6>PDF, DOC, PPT, JPG, PNG</h6>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <form action="" id="send-invoice">
                                            <input 
                                                type="file" 
                                                name="receipt" 
                                                id="invoice-receipt-input"
                                                className="hidden" 
                                                onChange={(e) => changeInput(e)}
                                            />
                                        </form>
                                    </div>

                                    {nameFile !== "" ? 
                                        <div className="cursor-pointer text-right mt-2">
                                            <a href="#" onClick={openFile}><OpenInNewIcon /></a>
                                        </div>
                                    :   
                                        null
                                    }
                                </div>
                                <div className="w-full text-left">
                                    <label className="relative my-2 inline-flex items-center cursor-pointer" onClick={(e)=> {e.stopPropagation()}}>
                                        <input 
                                            type="checkbox" 
                                            className="sr-only peer shadow-inner" 
                                            checked={isOCRReprocessConfirmed} 
                                            onChange={()=> setIsOCRPopupReprocessOpen(!isOCRPopupReprocessOpen)} 
                                        />
                                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:shadow-inner rounded-full peer dark:bg-[#EFEFEF] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#5A45AA]"></div>
                                        <span className="ms-3 mr-3 text-sm font-medium text-[#5A45AA]">
                                            OCR 
                                        </span>
                                        {isOCRReprocessLoading ? <PulseLoader color={'purple'} size={8} /> : null}
                                    </label>
                                </div>
                                <button
                                    className="p-2 bg-greenSuccess rounded-2xl text-white px-8 mt-4" 
                                    type="button"
                                    onClick={(e) => handleSubmit(e)}
                                >
                                    {isSubmissionLoading ? <PulseLoader color={'white'} size={10} /> : 'Valider'}
                                </button>

                                {isOCRPopupReprocessOpen && 
                                    <PopupOCRReprocess 
                                        close={() => setIsOCRPopupReprocessOpen(false)}
                                        confirmOCRReprocess={() => confirmOCRReprocess(true)}
                                    />
                                }
                            </div>
                        }
                    </section>

                    {/*Affiche une sous-modale de confirmation de suppression de la modale*/}
                    {openPopupDelPiece && 
                        <PopupDelPiece
                            type={rowToAffect.type}
                            closeModale={closeModale} 
                            closeAffect={close} 
                            fetchResources={fetchResources}
                            idInvoice={affectation.id} 
                            isLastInvoice={isLastInvoice}
                        />}
                    {/*Affiche une sous-modale indiquant que l'opération bancaire choisie a déjà une affectation existante : entourer d'un if*/}
                    {openPopupAffectAlreadyExist && <PopupAffectAlreadyExist closeModale={closeModale}/>}

                    {/*Modal categorie/sous-categorie*/}
                    <section className="search-pop-up hide-pop-up">
                        <div className="layer">
                            <CloseIcon onClick={hideSearchPopUp}/>
                            <div className="items">
                                <div className="flex relative input-field">
                                    <input 
                                        type="text" 
                                        name="search" 
                                        placeholder="Recherche..." 
                                        onChange={(e)=> {setSearchTermsCategory(e.target.value)}}
                                    />
                                    <SearchIcon/>
                                </div>

                                <div id="keywords" className="keywords">
                                    {categories.map((category) => 
                                        <ModalCategory key={category.id} 
                                            category={category}
                                            subCats={category.sub_categories}
                                            setCat={setCat} 
                                            hideSearchPopUp={hideSearchPopUp} 
                                            info="invoice" 
                                            id={affectation.id}
                                        />)
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                    {openTabOpBancaire && 
                        <section className="flex">
                            <section className="fill-black mr-12 top-30">
                                <div className="close-btn">
                                    {/*<CloseIcon onClick={this.toggleMenu} />*/}
                                </div>
                                {/* Searchbar*/}
                                <div className="w-[17.5rem]">
                                    <div className="rounded-xl flex relative border border-[#EEEEEE] shadow-md h-[2.3rem] sidebar:h-[2.7rem] items-center text-xs sidebar:text-base sidebar:py-1">
                                        <input 
                                            type="text" 
                                            className="w-5/6 rounded-xl pl-[1rem]" 
                                            name="search" 
                                            placeholder="Recherche..." 
                                            value={operationsFilters.searchTerms} 
                                            onChange={(e) => setOperationsFilters({ ...operationsFilters, searchTerms: e.target.value })} 
                                        />
                                        <SearchIcon/>
                                    </div>

                                    <div className="w-full flex pt-[1.2rem]">
                                        <div className=" w-1/2 mr-2 rounded-xl flex h-[2.3rem] relative border border-[#EEEEEE] sidebar:py-0.5">
                                            <input 
                                                type="number" 
                                                className="w-full px-4 py-1 rounded-xl text-xs sidebar:text-base" 
                                                name="min" 
                                                placeholder="Min TTC" 
                                                onChange={(e) => setOperationsFilters({ ...operationsFilters, minAmount: e.target.value })} 
                                                value={operationsFilters.minAmount} 
                                            />
                                        </div>
                                        <div className=" w-1/2 ml-2 rounded-xl flex h-[2.3rem] relative border border-[#EEEEEE] sidebar:py-0.5">
                                            <input 
                                                type="number" 
                                                className="w-full px-4 py-1 rounded-xl text-xs sidebar:text-base" 
                                                name="max" 
                                                placeholder="Max TTC" 
                                                onChange={(e) => setOperationsFilters({ ...operationsFilters, maxAmount: e.target.value })} 
                                                value={operationsFilters.maxAmount} 
                                            />
                                        </div>
                                    </div>
                                    <div className="bg-lila mt-[1.2rem] px-4 sidebar:py-1 rounded-lg flex h-[2.3rem] relative border border-[#EEEEEE]">
                                        <button 
                                            type="button" 
                                            onClick={() => setOpPagination({...opPagination, page: 1})}
                                            className="text-white font-semibold sidebar:text-lg text-xs text-center w-full"
                                        >
                                            Filtrer
                                        </button>
                                    </div>

                                </div>
                                {/*end searchbar*/}

                                <div className="mb-2 rounded-xl pick-input mt-10">
                                    <div className="w-full flex">
                                        <img src={calendrier} alt="date"/>
                                        <h4 className="font-normal">Période Sélectionnée</h4>
                                    </div>
                                    <div className="flex justify-between w-[17.5rem] mt-[1.5rem] sidebar:mt-[0.9rem]">
                                        <div className=" px-[0.9rem] flex justify-center w-[48%] rounded-lg border h-[2.3rem] sidebar:py-1 sidebar:h-[2.7rem] items-center">
                                            <div className="flex text-xs items-center dates-filtre font-bold ">
                                                <span className="uppercase">Du</span>
                                                <input 
                                                    id="input-period-from" 
                                                    type="date" 
                                                    className="bg-none-input custom-calendar" 
                                                    min="1990-01-01" 
                                                    max="2300-01-01"
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                    defaultValue={new Date().getFullYear() + "-01-01"}
                                                />
                                            </div>
                                        </div>

                                        <div className=" px-[0.9rem] flex justify-center w-[48%] mt-[1.2rem] sidebar:mt-0 rounded-lg border h-[2.3rem] sidebar:py-1 sidebar:h-[2.7rem] items-center">
                                            <div className="flex text-xs items-center dates-filtre font-bold ">
                                                <span className="uppercase">AU</span>
                                                <input 
                                                    id="input-period-to" 
                                                    type="date" 
                                                    className="bg-none-input custom-calendar" min="1990-01-01" max="2300-12-31"
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                    defaultValue={new Date().getFullYear() + "-12-31"} 
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="mb-2 mt-8 pick-input">
                                    <div className="w-full flex mb-[0.9rem]">
                                        <img className="w-[25px] sidebar:w-[30px]" src={wallet} alt="wallet"/>
                                        <h4>Compte bancaire</h4>
                                    </div>
                                    <form className="mb-2 mt-2 w-full flex input-field-popup ml-0">
                                        <select className="bg-transparent" onChange={(e)=> {setSelectedBankAccount(e.target.value)}}>
                                            <option value="Tous">Tous</option>
                                            {bankAccounts.map((bankAccount) => <option key={bankAccount.id} value={bankAccount.id}>{bankAccount.name}</option>)}
                                        </select>
                                        <ArrowDropDownIcon size="small" className="pointer-events-none"/>
                                    </form>
                                </div>
                            </section>

                            <div className="flex flex-col w-full">
                                {openTabOpBancaire && opBancaires.data.map((operation) => {
                                    return affectationsInvoice.some(elem => elem.transaction?.id === operation.id) ? 
                                        null 
                                    : 
                                        <>
                                            {operation.children.length > 0 ?
                                                operation.children.map((child) => {
                                                    return affectationsInvoice.some(el => el.transaction?.id === child.id) ?
                                                        null
                                                    :
                                                        <div key={child.id} className="w-full relative">
                                                            <div className="justify-between items-center flex">
                                                                <input type="radio" name="op-to-affect" data-id={child.id} onClick={()=> {setSelectedOp(child)}}  className="op-radio" />
                                                                <table className="bg-[#f2f5ff] rounded-lg shadow-none shadow-inherit w-[97%] max-h-[106px] mt-8">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="p-3.5 text-[#717579] font-semibold text-left">Date</th>
                                                                            <th className="p-3.5 text-[#717579] font-semibold text-left">Libellé de l'opération</th>
                                                                            <th className="p-3.5 text-[#717579] font-semibold text-left">Montant HT</th>
                                                                            <th className="p-3.5 text-[#717579] font-semibold text-left">TVA</th>
                                                                            <th className="p-3.5 text-[#717579] font-semibold text-left">Montant TTC</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr key={child.id}>
                                                                            <td className="text-[#171b1e] p-3.5 align-bottom">
                                                                                <span>{child.displayable_operation_date}</span>
                                                                            </td>
                                                                            <td className="text-[#171b1e] p-3.5">
                                                                                <div className="flex">
                                                                                    {/*<input className="input-operation-vente" name="label" defaultValue={affectation.label}/>*/}
                                                                                    <span className="text-black">
                                                                                        {child.sub_category !== null ? child.sub_category.display_name : ""}
                                                                                    </span>
                                                                                </div>
                                                                                <span>{child.label}</span>
                                                                            </td>
                                                                            <td className="text-[#171b1e] p-3.5 align-bottom">{child.amount}</td>
                                                                            <td className="text-[#171b1e] p-3.5 align-bottom"> {child.vat}</td>
                                                                            <td className="text-[#171b1e] p-3.5 align-bottom">{child.amount_with_vat}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div> 
                                                })
                                            :
                                                <div key={operation.id} className="w-full relative">
                                                    <div className="justify-between items-center flex">
                                                        <input type="radio" name="op-to-affect" data-id={operation.id} onClick={()=> {setSelectedOp(operation)}}  className="op-radio" />
                                                        <table className="bg-[#f2f5ff] rounded-lg shadow-none shadow-inherit w-[97%] max-h-[106px] mt-8">
                                                            <thead>
                                                                <tr>
                                                                    <th className="p-3.5 text-[#717579] font-semibold text-left">Date</th>
                                                                    <th className="p-3.5 text-[#717579] font-semibold text-left">Libellé de l'opération</th>
                                                                    <th className="p-3.5 text-[#717579] font-semibold text-left">Montant HT</th>
                                                                    <th className="p-3.5 text-[#717579] font-semibold text-left">TVA</th>
                                                                    <th className="p-3.5 text-[#717579] font-semibold text-left">Montant TTC</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                <tr key={operation.id}>
                                                                    <td className="text-[#171b1e] p-3.5 align-bottom">
                                                                        <span>{operation.displayable_operation_date}</span>
                                                                    </td>
                                                                    <td className="text-[#171b1e] p-3.5">
                                                                        <div className="flex">
                                                                            {/*<input className="input-operation-vente" name="label" defaultValue={affectation.label}/>*/}
                                                                            <span className="text-black">
                                                                                {operation.sub_category !== null ? operation.sub_category.display_name : ""}
                                                                            </span>
                                                                        </div>
                                                                        <span>{operation.label}</span>
                                                                    </td>
                                                                    <td className="text-[#171b1e] p-3.5 align-bottom">{operation.amount}</td>
                                                                    <td className="text-[#171b1e] p-3.5 align-bottom"> {operation.vat}</td>
                                                                    <td className="text-[#171b1e] p-3.5 align-bottom">{operation.amount_with_vat}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div> 
                                            }
                                        </>
                                })}
                                    
                                <div className={`flex ${opBancaires.data.length !== 0 ? "justify-between items-center" : "justify-end"}`}>
                                    <Pagination data={{...opPagination, count: opBancaires.count}} paginate={(per_page, page) => setOpPagination({ ...opPagination, per_page, page })}/>
                                    {opBancaires.data.length !== 0 ?
                                        <div>
                                            <button className={`bg-vertBouton rounded-lg px-6 py-2 text-white ${selectedOp === null ? "cursor-not-allowed opacity-50" : ""}`} onClick={() => selectedOp !== null ? selectOperation(selectedOp) : null}> Valider</button>
                                        </div> 
                                    : 
                                        null
                                    }
                                </div>
                            </div>
                        </section>
                    }
                {/* </div> */}
                </div>
            </ResizableBox>
        </div>
    )
}

export default AffectAperc