import React from "react";
import ArrowShowTreso from "./ArrowShowTreso.js";
import {PulseLoader} from "react-spinners";
import tresoArrow from "../../assets/img/tresoArrow.png";
import resultatArrow from "../../assets/img/resultatArrow.png";
import encaisseArrow from "../../assets/img/caArrow.png";
import fraisArrow from "../../assets/img/fraisArrow.png";

export default function StatePanel({
    title,
    total_amount,
    setSelectState,
    selectedState,
    data,
    bgColor,
    nState,
    loadingComponent
}) {
    return (
        <div className={`item relative ${bgColor}`}>
            <div>
                <h3 className="text-[14px] indicateursView:text-[20px]">{title}</h3>
                <h1 className="text-[16px] indicateursView:text-[30px]">
                    {loadingComponent ? 
                        <PulseLoader color={'white'} size={7} /> 
                    : 
                        total_amount
                    } 
                    €</h1>
                <ul>
                    {data.map((row) => (
                        <li className="flex" key={row.title}>
                            <span className="text-[10px] indicateursView:text-[16px]">{row.title}</span>
                            <span className="ml-auto text-[10px] indicateursView:text-[18px]">
                                {loadingComponent ? 
                                    <PulseLoader color={'white'} size={7} /> 
                                : 
                                    row.amount
                                } 
                                €</span>
                        </li>
                    ))}
                </ul>
            </div>
            <ArrowShowTreso 
                setSelectState={setSelectState} 
                state={nState} 
                selectedState={selectedState}
            />
            {selectedState === 1 && bgColor === "bg-dashboard_tresoperiod" ? 
                <img 
                    src={tresoArrow} 
                    alt="arrow" 
                    className="absolute bottom-0 right-8 transform translate-y-full" 
                /> 
            : 
                selectedState === 2 && bgColor === "bg-dashboard_resultasht" ? 
                    <img 
                        src={resultatArrow} 
                        alt="arrow" 
                        className="absolute bottom-0 right-8 transform translate-y-full" 
                    /> 
                : 
                    selectedState === 3 && bgColor === "bg-dashboard_encaisseht" ? 
                        <img 
                            src={encaisseArrow} 
                            alt="arrow" 
                            className="absolute bottom-0 right-8 transform translate-y-full" 
                        /> 
                    : 
                        selectedState === 4 && bgColor === "bg-dashboard_fraisht" ? 
                            <img 
                                src={fraisArrow} 
                                alt="arrow" 
                                className="absolute bottom-0 right-8 transform translate-y-full" 
                            /> 
                        :
                            ''
            }
        </div>
    )
}